<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col class="text-center" cols="12">
            <h1 >Page Not Found</h1>
            <h4>The page you're trying to access was not found.</h4>
            <v-btn @click="$router.go(-1)" outlined primary class="mt-2"><v-icon small left>fa-arrow-left</v-icon> Go back</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'PageNotFound'  
}
</script>
