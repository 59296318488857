
import store from '@/store/index-store'
//import momentTz from 'moment-timezone'

export default {
    filters: {
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        //filter to return a different label for the vancouver timezone - it was asked by Merit Travel
        timezoneFilter: function(value){
            if(value === 'America/Vancouver'){
                return 'Pacific Time/Vancouver'
            }
        }
        
    },
    computed: {
        currentTimezone: function(){
            let timezone = "America/Vancouver" //default

            //gets if it's an employee
            if(store.getters.currentDivision){
                let division = store.getters.currentDivision
                timezone = division.company.settings.timezone
            }

            //gets if it's a customer

            //gets if it's an agent


            return timezone
        },
        loggedUser: function () {
            return store.getters.loggedUser
        },
        division: function () {
            return store.getters.currentDivision
        },
    }
}