<!-- This template is to show a custom field and its value from a Fareharbor booking -->
<template>
    <v-container class="py-0">
        <v-row no-gutters>
            <v-col>
                
                <p class="font-weight-bold mb-0">{{field.custom_field.title}}</p>

                <!-- if present we show this -->
                <p
                    v-if="field.custom_field.description_safe_html"
                    v-html="field.custom_field.description_safe_html"
                ></p>

                <p
                    v-if="field.custom_field.booking_notes_safe_html"
                    v-html="field.custom_field.booking_notes_safe_html"
                ></p>

                <div v-if="fieldValue">
                    <p v-if="fieldValue.value === 'True'">Yes</p>
                    <p v-else>{{fieldValue.value}}
                        <span v-if="fieldValue.price">- {{fieldValue.price | currency}}</span> <!-- price -->
                    </p>
                    
                    <p class=""
                        v-if="fieldValue.descriptionHtml"
                        v-html="fieldValue.descriptionHtml"
                    ></p>
                    <!-- description -->
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'BookingCustomField',
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    computed: {
        fieldValue: function () {
            //if field is extended option we need to compare the value to the extended options array
            if (this.field.custom_field.type === 'extended-option') {
                let result = {
                    name: '-',
                    price: 0,
                    descriptionHtml: ''
                }
                this.field.custom_field.extended_options.map(e => {
                    //if found return
                    if(Number(e.pk) === Number(this.field.value)){
                        result = {
                            value: e.name,
                            price: e.offset ? e.offset / 100 : 0,
                            descriptionHtml: e.description_safe_html //this is on the extended option level
                        }
                    }
                })       
                return result         
            } else {
                return {
                    value: this.field.value,
                    price: this.field.offset ? this.field.offset / 100 : 0,
                    descriptionHtml: '' //empty because this is in the field level and we show it if it exists already (above in the template)
                } 
            }
        }
    }

}
</script>

<style>
</style>