<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        persistent
        no-click-animation
    >
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>{{item.name}}</v-toolbar-title>

                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    bottom
                    color="cyan lighten-3"
                ></v-progress-linear>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text @click="cancel()">
                        <v-icon small left>fa-arrow-left</v-icon>Go back
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-row no-gutters v-if="hasLoaded">
                <v-col>
                    <v-card class="ma-0 pa-0 mx-auto" tile elevation="0">
                        <v-img
                            :src="item.fareharborDetails.image_cdn_url"
                            max-height="360px"
                            lazy-src="https://lola-static.s3-us-west-2.amazonaws.com/image-placeholder-mountains.png"
                            class="white--text align-center"
                            gradient="to top right, rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40)"
                        >
                            <v-card-title class="justify-center text-center">
                                <div>
                                    <span class="headline d-flex d-sm-none">{{item.name}}</span>
                                    <span class="display-1 d-none d-sm-flex">{{item.name}}</span>

                                    <!-- HEADLINE -->
                                    <!-- availability 1st priority -->
                                    <div v-if="availability.headline">
                                        <span class="headline">{{availability.headline}}</span>
                                    </div>

                                    <div v-else>
                                        <!-- item 2nd priority -->
                                        <div v-if="item.headline">
                                            <span class="headline">{{item.headline}}</span>
                                        </div>

                                        <!-- otherwise fareharbor item -->
                                        <div v-else>
                                            <span
                                                v-if="item.fareharborDetails.headline"
                                                class="headline"
                                            >{{item.fareharborDetails.headline}}</span>
                                        </div>
                                    </div>
                                </div>
                            </v-card-title>

                            <v-card-title class="justify-center text-center pt-0">
                                <div v-if="loading">
                                    <v-progress-circular
                                        indeterminate
                                        color="primary"
                                    ></v-progress-circular>
                                </div>
                                <div v-else>
                                    <span class="subtitle-1">
                                        {{availabilityFareharbor.start_at | moment('timezone', getGroupTimezone, 'DD MMMM YYYY hh:mm a' )}}
                                        <v-icon color="white" small class="px-2">fa-clock</v-icon>
                                        {{availabilityFareharbor.end_at | moment('timezone', getGroupTimezone, 'DD MMMM YYYY hh:mm a' )}}
                                    </span>
                                    <p class="body-2">{{getGroupTimezone | timezoneFilter}}</p>
                                    <v-btn @click="tab=2" :disabled="isSoldOut || isAlreadyInCart">
                                        {{ isSoldOut ? 'Sold out' : 'Book'  }}                                                                               
                                        <v-icon small right>fa-shopping-cart</v-icon>
                                    </v-btn>
                                    <p class="subtitle-2 mt-2" v-if="isAlreadyInCart">
                                        This trip is already in the shopping cart. <br />
                                        To make changes to it, please remove from the cart and add again.
                                    </p>
                                    <p class="caption mt-2">
                                        {{availabilityFareharbor.capacity}} seats available
                                    </p>
                                </div>                                
                            </v-card-title>
                        </v-img>

                        <v-tabs v-model="tab"  background-color="primary" grow show-arrows  slider-size="3">

                            <v-tabs-slider color="orange lighten-4"></v-tabs-slider>
                            <v-tab ><v-icon left>fa-map-marked</v-icon> Itinerary</v-tab>
                            <v-tab><v-icon left>fa-align-justify</v-icon> Important Information</v-tab>                            
                            <v-tab :disabled="isSoldOut || isAlreadyInCart"><v-icon left>fa-shopping-cart</v-icon>{{ isSoldOut ? 'Sold out' : 'Book'  }}</v-tab>

                            <v-tabs-items v-model="tab">
                                <v-tab-item>
                                    <!-- Section Itinerary -->
                                    <div class="mt-2 pa-4 tour-details">

                                        <v-card class="mx-auto pa-4" outlined>
                                            <!-- availability 1st priority -->
                                            <div v-if="availability.isCustomDetails">
                                                <div v-html="availability.itinerary"></div>
                                            </div>

                                            <div v-else>
                                                <!-- item 2nd priority -->
                                                <div v-if="item.isCustomDetails">
                                                    <div v-html="item.itinerary"></div>
                                                </div>

                                                <!-- otherwise fareharbor item -->
                                                <div v-else>
                                                    <div
                                                        v-html="item.fareharborDetails.booking_notes_safe_html"
                                                    ></div>
                                                </div>
                                            </div>
                                        </v-card>
                                    </div>
                                </v-tab-item>

                                <v-tab-item>
                                    <!-- Section Description -->
                                    <div class="mt-2 pa-4 tour-details">

                                        <v-card class="mx-auto pa-4" outlined>
                                            <!-- availability 1st priority -->
                                            <div v-if="availability.isCustomDetails">
                                                <div v-html="availability.description"></div>
                                            </div>

                                            <div v-else>
                                                <!-- item 2nd priority -->
                                                <div v-if="item.isCustomDetails">
                                                    <div v-html="item.description"></div>
                                                </div>

                                                <!-- otherwise fareharbor item -->
                                                <div v-else>
                                                    <div
                                                        v-html="item.fareharborDetails.description_safe_html"
                                                    ></div>
                                                </div>
                                            </div>
                                        </v-card>
                                    </div>
                                </v-tab-item>

                                <!-- FORM BOOK AVAILABILITY -->
                                <v-tab-item>
                                    <div class="mt-2 pa-4">
                                        <v-progress-linear
                                            v-if="loading"
                                            indeterminate
                                            color="primary"
                                        ></v-progress-linear>
                                        <FormAvailability
                                            v-if="!loading"
                                            :item="item" :availabilityLola="availability" :availability="availabilityFareharbor" :slug="slug" 
                                            :group="group"/>
                                    </div>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-tabs>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import {sendAlert} from '@/store/notifications-hub.js'
import FormAvailability from '@/components/GroupBooking/Availability/FormAvailability'

import filtersMixin from '@/mixins/filters-mixin.js'

export default {
    name: 'ModalBookAvailability',
    mixins: [filtersMixin],
    components: {
      FormAvailability
    },
    data: function () {
        return {
            loading: true,
            dialog: true,
            slug: '', //group slug 
            partnerSlug: '', //partner slug
            availability: {
              fareharborDetails: {
                startDate: '',
                endDate: '',
              }
            },
            item: {
              fareharborDetails: {
                image_cdn_url: ''
              }
            },
            group: {},
            availabilityFareharbor: { pk: '', capacity: 0 },
            hasLoaded: false,
            tab: 0
        }
    },

    computed: {
        ...mapGetters(['getGroupTimezone']),
        ...mapGetters(['getCartAvailabilities']),
        isSoldOut() {
            return this.availabilityFareharbor.capacity == 0
        },
        isAlreadyInCart() {
            return this.getCartAvailabilities.includes(this.availabilityFareharbor.pk)
        }
    },

    methods: {
        cancel() {
            this.$router.back()
        },

        initializeFareharbor(){
          this.$fareharbor.get(`/companies/${this.group.division.company.settings.fareharborShortname}/availabilities/${this.availability.fareharborDetails.availabilityPk}`).then(res => {
            this.availabilityFareharbor = res.data.availability //from fh
            
          }).catch(e => {
            sendAlert({ event: 'danger', data: e, message: 'There was an error fetching the availability. Please go back and try again.' })
          }).finally(() => {
            this.loading = false
          })

        },

    },

    mounted(){
        //this.$log.info('mounted modal')
    },
    
    beforeRouteEnter(to, from, next) { 

        next(vm => {

            // access to component's instance using `vm` .
            // this is done because this navigation guard is called before the component is created.            
            //for some reason it DOESNOT work with props - Filipe
           
            if(to.params.item && to.params.availability){
                //this.$log.info('vm modal')
              vm.item = to.params.item
              vm.availability = to.params.availability
              vm.slug = to.params.slug
              vm.partnerSlug = to.params.partnerSlug
              vm.group = to.params.group
              vm.hasLoaded = true
              vm.initializeFareharbor()
              next()
            }else{
                //this.$log.info('vm modal else')
                next(false)
                //this.$log.info('slug ' +  to.params.slug)
              //doesnt work yet i dont know why
              //vm.$router.back()
              vm.$router.push({name: 'group.booking.flow', params: {slug: to.params.slug, partnerSlug: to.params.partnerSlug, stepProp: 1}})
              
            }
        })

    },
}
</script>

<style>
.tour-details ul{
    padding-left: 40px !important;
}
</style>