<template>
  <div>
    <v-row>
      <v-col>
          <v-card outlined class="mx-auto">
              <v-card-title>
                  Orders
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="fa-search"
                    label="Search"
                    single-line
                    hide-details
                    ></v-text-field>
              </v-card-title>
              <v-card-text>
                <v-data-table :headers="headers" :items="orders" :search="search" :loading="loading">

                    <template v-slot:[`item.isPaid`]="{ item }">
                        <v-chip color="success" v-if="item.isPaid" small>Paid</v-chip>
                        <v-chip color="default" v-if="item.isRefunded" small>Refunded</v-chip>
                        <v-chip color="warning" v-if="!item.isPaid" small>Not paid</v-chip>
                    </template>

                    <template v-slot:[`item.amountTotal`]="{ item }">
                        <span>{{item.amountTotal | currency}}</span>
                    </template>

                    <template v-slot:[`item.amountPaid`]="{ item }">
                        <span>{{item.amountPaid | currency}}</span>
                    </template>

                    <template v-slot:[`item.amountRefunded`]="{ item }">
                        <span>{{item.amountRefunded | currency}}</span>
                    </template>

                    <template v-slot:[`item.amountRemaining`]="{ item }">
                        <span>{{item.amountTotal - item.amountPaid  | currency}}</span>
                    </template>

                    <template v-slot:[`item.createdAt`]="{ item }">
                        <span>{{item.createdAt | moment('timezone', currentTimezone, 'DD-MMM-YYYY HH:mm:ss z')}}</span>
                    </template>

                    <template v-slot:[`item.updatedAt`]="{ item }">
                        <span>{{item.updatedAt | moment('timezone', currentTimezone, 'DD-MMM-YYYY HH:mm:ss z')}}</span>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:[`activator`]="{ on }">
                                <v-icon v-on="on" small class="mr-2" @click="view(item)">fa-external-link-alt</v-icon>
                            </template>
                            <span>View Order</span>
                        </v-tooltip>
                    </template>

                </v-data-table>
              </v-card-text>
          </v-card>        
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { sendAlert } from '@/store/notifications-hub'
export default {
    name: 'CustomerOrders',
    data: function(){
        return{
            loading: true,
            orders: [],
            search: '',
            headers: [
                { text: 'ID', value: 'lolaId' },
                { text: 'Status', value: 'isPaid' },
                { text: 'Total Amount', value: 'amountTotal' },
                { text: 'Amount Paid', value: 'amountPaid' },
                { text: 'Amount Refunded', value: 'amountRefunded' },
                { text: 'Balance Remaining', value: 'amountRemaining' },
                { text: 'Date created', value: 'createdAt' },
                { text: 'Last updated', value: 'updatedAt' },
                { text: 'Actions', value: 'action', sortable: false },
            ],
        }
    },
    computed: {
        loggedUser: function () {
            return this.$store.getters.loggedUser
        },
    },
    methods: {
        view(order){
            //https://stackoverflow.com/questions/40015037/can-vue-router-open-a-link-in-a-new-tab
            let routeData = this.$router.resolve({name: 'orders', params: {id: order._id}})
            window.open(routeData.href, '_blank')
        }
    },
    mounted(){
        let id = this.loggedUser.user._id
        this.$tornos.get(`orders?customer=${id}`).then(res => {
            this.orders = res.data
        }).catch(e => {
            sendAlert({ event: 'warning', data: e, message: 'There was an error fetching the orders. Please try again' })
        }).finally(() => this.loading = false)
    }
}
</script>

<style>
</style>