<template>
  <div>
    <v-row>
      <v-col>
          <v-card outlined class="mx-auto">
              <v-card-title>
                  Bookings
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="fa-search"
                    label="Search"
                    single-line
                    hide-details
                    ></v-text-field>
              </v-card-title>
              <v-card-text>
                <v-data-table :headers="headers" :items="items" :search="search" :loading="loading" >

                    <template v-slot:item.item.amountTotal="{ item }">
                        <span>{{item.item.amountTotal | currency}}</span>
                    </template>

                    <!-- <template v-slot:item.amountPaid="{ item }">
                        <span>{{item.item.amountPaid | currency}}</span>
                    </template> -->

                    <template v-slot:item.item.fareharborBooking.availability.start_at="{ item }">
                        <span>{{item.item.fareharborBooking.availability.start_at | moment('timezone', item.order.group.partner.timezone, 'DD-MMM-YYYY HH:mm:ss z')}}</span>
                    </template>

                    <template v-slot:item.item.fareharborBooking.availability.end_at="{ item }">
                        <span>{{item.item.fareharborBooking.availability.end_at | moment('timezone', item.order.group.partner.timezone, 'DD-MMM-YYYY HH:mm:ss z')}}</span>
                    </template>

                    <template v-slot:item.item.status="{ item }">
                        <v-chip color="success" v-if="item.item.status === 'booked'" small>Booked</v-chip>
                        <v-chip color="default" v-if="item.item.status === 'rebooked'" small>Rebooked</v-chip>
                        <v-chip color="warning" v-if="item.item.status === 'cancelled'" small>Cancelled</v-chip>
                    </template>

                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" small class="mr-2" @click="view(item.order)">fa-external-link-alt</v-icon>
                            </template>
                            <span>View Order</span>
                        </v-tooltip>
                    </template>                    

                </v-data-table>
              </v-card-text>
          </v-card>        
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { sendAlert } from '@/store/notifications-hub'
export default {
    name: 'CustomerBookings',
    data: function(){
        return{
            loading: true,
            orders: [],
            search: '',
            headers: [
                { text: 'Booking ID', value: 'item.lolaId' },
                { text: 'Order ID', value: 'order.lolaId' },
                { text: 'Tour', value: 'item.title' },        
                { text: 'Total', value: 'item.amountTotal' },        
                // { text: 'Amount Paid', value: 'item.amountPaid' },
                { text: 'Status', value: 'item.status' },
                { text: 'Trip Start Date', value: 'item.fareharborBooking.availability.start_at' },
                { text: 'Trip End Date', value: 'item.fareharborBooking.availability.end_at' },
                { text: 'Actions', value: 'action', sortable: false },                  
            ],
            items: []
        }
    },
    computed: {
        loggedUser: function () {
            return this.$store.getters.loggedUser
        },
    },
    methods: {
        view(order){
            //https://stackoverflow.com/questions/40015037/can-vue-router-open-a-link-in-a-new-tab
            let routeData = this.$router.resolve({name: 'orders', params: {id: order._id}})
            window.open(routeData.href, '_blank')
        },

        //this functions gets the items as the root element of an item in the array
        //so it's easier for us to create the datatable
        formatData(){
            this.orders.forEach(order => { //items are inside an order
                order.items.forEach(item => { //order has many items, so we iterate again
                    this.items.push({
                        item,
                        order: order,
                    })
                })
            })
        }
    },
    mounted(){
        let id = this.loggedUser.user._id
        this.$tornos.get(`orders?customer=${id}&populate=group-partner`).then(res => {
            this.orders = res.data
             this.formatData()
        }).catch(e => {
            sendAlert({ event: 'warning', data: e, message: 'There was an error fetching the orders. Please try again' })
        }).finally(() => this.loading = false)
    }
}
</script>

<style>
</style>