import VueLogger from 'vuejs-logger'
import Vue from 'vue'
const isProduction = process.env.NODE_ENV === 'production' 
 
const options = {
    isEnabled: true,
    logLevel : isProduction ? 'warn' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
} 

Vue.use(VueLogger, options) 