/**
 * AUTH Module
 * This module uses Amplify Hub (to listen and publish notifications) and VUEX (to save state)
 * GOAL: this module is responsible for Authentication, for user actions sign up, sign in or logout
 * HOW: whenever there is a notification we fetch the API and change the state in the Cache
 * Router: we import the router here to redirect when a sign in or sign out happens
 */

import { Hub, Logger } from 'aws-amplify';
const logger = new Logger('AuthLogger');

// import axios from 'axios'
// const $tornos = axios.create({
//     //baseURL: process.env.VUE_APP_API_LOLAGROUPS_URL,
//     baseURL: process.env.VUE_APP_API_LOCALHOST_URL,    
//     headers: {
//         'x-api-key': process.env.VUE_APP_LOLAGUIDE_API_KEY             
//     }
// })

//const notificationsService = require('./notifications-hub')
import {sendAlert} from './notifications-hub'
//import router from '@/router/index-router'
//import store from '@/store/index-store'

const listener = (data) => {

    switch (data.payload.event) {
    
        case 'signIn': {

            /*

            //this.$log.info('user SIGNED IN')
            logger.info('user signed in');

            let cognitoId = data.payload.data.username //cognito username for the user
            let loggedUser = store.getters.loggedUser
            
            //this.$log.info(loggedUser)
            let userType = loggedUser.type
            //this.$log.info(userType)
            let urlToSearch = `/${userType}/?cognitoId=${cognitoId}` //url to search uses the userType that was set in the cache in the login form

            //this.$log.info(`New signin: ${cognitoId}, ${userType}, ${urlToSearch}`);
            
            $tornos.get(urlToSearch).then(res => {
                
                //this.$log.info('Signed In: ' + JSON.stringify(res.data))

                //if a user is found in lola db we continue
                if(res.data.length > 0){
                    store.dispatch('login', res.data[0])
                    
                    if(userType === 'employees'){
                        router.push({name: 'divisions.choose'}) //redirects to divisions if employees
                    }
                    
                
                //user not found on lola db
                //it could happen of a user logging in as a different role (customer) and they are an employee
                //cognito will find it but we won't in lola db, so we call logout to clear the amplify and lola sessions
                }else{
                    store.dispatch('logout', {userType} ) //pass the userType so we can still try to login with this user role
                    sendAlert({event: 'warning', data: null, message: 'User not found'})                    
                }
                                           
            }).catch(err => {
                sendAlert({event: 'error', data: err, message: 'There was an error fetching the user'})            
            })
            */

            break;
            
        }

        case 'signUp':{
            logger.info('New signup')      

            /*

            //if there's a logged user we DO NOT run this method, because it can be an employee adding more employees, partners (agents), or admins adding users
            if(!store.getters.isLoggedIn){

                let userType = store.getters.loggedUser.userType
                //saves in the corresponding table
                let url = `/${userType}` //url to search uses the userType that was set in the cache in the login form

                //this.$log.info(`New sign up: ${userType}, ${url}`);

                ////this.$log.info(`New sign up: ${JSON.stringify(data)}`);
                
                let userToSave = {
                    email: data.payload.data.user.username,
                    cognitoId: data.payload.data.userSub //user uuid from cognito
                }
                
                $tornos.post(url, userToSave).then(res => {
                    //this.$log.info(`User saved: ${res.data}`)
                    router.push({name: 'login', params: { userType: userType }}) //redirects                             
                }).catch(err => {
                    sendAlert({event: 'error', data: err, message: 'There was an error saving the user'})            
                })
                
                logger.info('user signed up');
                sendAlert({event: 'info', data: data.payload.event, message: 'Sign up successful'}) 
            
            }else{
                logger.info('Auth event triggered, nothing done. Logged user is not null')                
            }
            */
            
            break;
            
        }

        case 'signOut': {
            logger.info('user signed out');
            //sendAlert({event: 'info', data: data.payload.event, message: 'Logout successful'})            
            // try{
            //     //let userType = store.getters.loggedUser.userType
            //     router.push({name: 'login', params: {userType: 'employees'}}) //redirects
            // }catch(e){
            //     //this.$log.info('Error router push')
            // }
            break;
        }

        case 'signIn_failure': {
            logger.error('user sign in failed');
            sendAlert({event: 'error', data: data.payload.event, message: 'There was an error signing in'})     
            break;
        }
        case 'configured': {
            logger.info('the Auth module is configured');
        }
    }
}

Hub.listen('auth', listener);