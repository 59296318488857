<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        persistent
        no-click-animation
    >
        <v-card>
            <v-toolbar dark color="primary" elevation="2">
                <v-toolbar-title class="px-2">Manage item {{currentItemGroup.item.name}}</v-toolbar-title>

                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    bottom
                    color="cyan lighten-3"
                ></v-progress-linear>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-divider vertical></v-divider>

                    <v-btn dark text @click="cancel()">
                        <v-icon left small>fa fa-arrow-left</v-icon>Go back to items
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-row class="ma-0">
                <v-col>
                    <v-container fluid>
                        <v-sheet>
                            <v-card outlined>
                                <v-card-title>
                                    {{currentItemGroup.item.name}}
                                    <span
                                        v-if="currentItemGroup.item.headline"
                                    >- {{currentItemGroup.item.headline}}</span>
                                </v-card-title>
                                <v-card-subtitle
                                    class="pb-0"
                                >Item PK: {{currentItemGroup.item.fareharborDetails.pk}}
                                <br>
                                UUID: {{currentItemGroup.item._id}}
                                </v-card-subtitle>

                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <!-- 
                                                FOR THE UBC-MERIT THEY GONNA USE THE CUSTOM FIELDS WITH PRICE 
                                                <div class="pa-2">
                                                <p class="subtitle-1 font-weight-medium">Item type</p>
                                                <v-radio-group
                                                    v-model="currentItemGroup.item.itemType"
                                                    row
                                                >
                                                    <v-radio label="Item" value="item"></v-radio>
                                                    <v-radio label="Add-on" value="addon"></v-radio>
                                                </v-radio-group>
                                                <p>Choose whether this is an Item (tour) or and Add-on (optional activity)</p>
                                            </div> -->

                                              <div class="pa-2">
                                                <p class="subtitle-1 font-weight-medium">Item display order</p>
                                                <v-text-field
                                                    placeholder="Order for the item to be displayed in the group page..."
                                                    label="Item display order"
                                                    name="Item order"
                                                    type="number"
                                                    v-model="currentItemGroup.order"
                                                    hint="Numeric order for the item to be displayed in the group page. Items are displayed in ascending order (smallest to largest)"
                                                />
                                            </div>

                                            <div class="pa-2">
                                                <p class="subtitle-1 font-weight-medium">Headline</p>
                                                <v-text-field
                                                    placeholder="Optional headline to show along the tour title"
                                                    label="Headline (optional)"
                                                    name="Headline"
                                                    type="text"
                                                    v-model="currentItemGroup.item.headline"
                                                    counter="60"
                                                    hint="Item headline - a custom headline to show along the tour title. If empty no headline is shown"
                                                />
                                            </div>

                                            <div class="pa-2">
                                                <p class="subtitle-1 font-weight-medium">Name</p>
                                                <v-text-field
                                                    placeholder="Item name"
                                                    label="Item name"
                                                    name="Name"
                                                    type="text"
                                                    v-model="currentItemGroup.item.name"
                                                    counter="60"
                                                    hint="Item name - here you can customize the item name from Fareharbor"
                                                />
                                            </div>

                                            <div class="pa-2">
                                                <p
                                                    class="subtitle-1 font-weight-medium"
                                                >Hide from landing page?</p>
                                                <v-switch
                                                    v-model="currentItemGroup.isHidden"
                                                    label="Check this field if you want to hide this item from the group public landing page"
                                                ></v-switch>
                                            </div>


                                            <div class="pa-2">
                                                <p
                                                    class="subtitle-1 font-weight-medium"
                                                >Custom Details</p>
                                                <v-switch
                                                    v-model="currentItemGroup.item.isCustomDetails"
                                                    label="Would you like to provide custom details and itinerary for this item?"
                                                ></v-switch>
                                            </div>

                                            <div
                                                class="pa-2"
                                                v-if="currentItemGroup.item.isCustomDetails === true"
                                            >
                                                <p
                                                    class="subtitle-1 font-weight-medium"
                                                >Custom Description</p>
                                                <!-- Use the component in the right place of the template -->
                                                <QuillEditorLola
                                                    placeholder="Custom Important Information ..."
                                                    :folder="folder"
                                                    name="Custom Description"
                                                    storageLevel="protected"
                                                    v-model="currentItemGroup.item.description"
                                                    :content="currentItemGroup.item.description"
                                                    rows="10"
                                                    :min-height="400"
                                                    counter="2000"
                                                />
                                                <p>This field is the 'Important Information' that will be shown to customer on the booking page.</p>
                                            </div>

                                            <div
                                                class="pa-2"
                                                v-if="currentItemGroup.item.isCustomDetails === true"
                                            >
                                                <p
                                                    class="subtitle-1 font-weight-medium"
                                                >Custom Itinerary</p>
                                                <!-- Use the component in the right place of the template -->
                                                <QuillEditorLola
                                                    placeholder="Custom Itinerary ..."
                                                    :folder="folder"
                                                    name="Custom Itinerary"
                                                    storageLevel="protected"
                                                    v-model="currentItemGroup.item.itinerary"
                                                    :content="currentItemGroup.item.itinerary"
                                                    rows="10"
                                                    counter="2000"
                                                    :min-height="400"
                                                />
                                                <p>This field is the Itinerary that will be shown to customer on the booking page.</p>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn @click="cancel()">
                                        Cancel
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        @click="addItem"
                                        v-if="action === 'create'"
                                    >
                                        Add Item
                                        <v-icon small right>fa-plus-circle</v-icon>
                                    </v-btn>
                                    <v-btn color="primary" @click="editItem" v-else>
                                        Submit Changes
                                        <v-icon small right>fa-check-circle</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-sheet>
                    </v-container>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import QuillEditorLola from '@/components/QuillEditorLola'
import { sendAlert, setLoading } from '../../../store/notifications-hub'
import filterMixin from '@/mixins/filters-mixin.js'
//import uuidv4 from 'uuid/v4'
export default {
    components: {
        QuillEditorLola
    },
    mixins: [filterMixin],
    props: {
        itemGroup: {
            type: Object,
            required: true
        },
        action: {
            type: String,
            required: true
        },
        group: {
            type: Object,
            required: true
        },
        from: {
            type: String
        }
    },
    name: "ModalGroupEditItem",
    data: function () {
        return {
            loading: false,
            dialog: true,

            currentItemGroup: {}
        }
    },
    computed: {
        folder: function () {
            return `${this.division.company._id}/${this.division.shortname}/items`
        }
    },

    methods: {
        cancel() {
            this.$router.push({ name: 'groups.create.items', params: { groupId: this.group._id, from: this.from } })
        },

        //adds a new item to the db and associates to the group
        async addItem() {

            try {

                setLoading(true)

                //this.currentItemGroup.item._id = uuidv4()
                let savedItem = await this.$tornos.post('items', this.currentItemGroup.item) //saves 

                this.currentItemGroup.item = savedItem.data //.data because of axios - sets in the current item item

                let group = this.group
                group.items.push(this.currentItemGroup) //adds the itemGroup to the group
                await this.$tornos.put(`groups/${group._id}`, group)

                sendAlert({ event: 'info', data: null, message: 'Item created successfully' })

                //go back
                this.$router.push({ name: 'groups.create.items', params: { groupId: this.group._id, from: this.from } })
            } catch (e) {
                sendAlert({ event: 'danger', data: e, message: 'There was an error creating the item' })
            } finally {
                setLoading(false)
            }
        },

        //EDITs an existing item
        async editItem() {

            try {
                setLoading(true)

                //this.$log.info("edit item")

                //gets a new array with all elements except the one being edited (by _id)
                this.group.items = this.group.items.filter(e => e._id !== this.currentItemGroup._id) //removes the currentItem from the array by id
                
                this.group.items.push(this.currentItemGroup) //adds the itemGroup to the group
                await this.$tornos.put(`groups/${this.group._id}`, this.group)
                
                //updates the item in the db
                this.$tornos.put(`items/${this.currentItemGroup.item._id}`, this.currentItemGroup.item)

                sendAlert({ event: 'info', data: null, message: 'Item updated successfully' })

                //go back
                this.$router.push({ name: 'groups.create.items', params: { groupId: this.group._id, from: this.from } })
            } catch (e) {
                sendAlert({ event: 'danger', data: e, message: 'There was an error creating the item' })
            } finally {
                setLoading(false)
            }
        }
    },

    created() {
        //set to the data property so we dont mutate a prop
        this.currentItemGroup = this.itemGroup
    }
}

</script>