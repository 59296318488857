<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="px-0 pt-0">
        <div id="quill-editor" class>
          <!-- Use the component in the right place of the template -->
          <quill-editor
            :style="`height:${editorMinHeight}px;`"
            v-model="contentModel"
            ref="myQuillEditor"
            :options="editorOptions"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            @change="onEditorChange($event)"
          ></quill-editor>
        </div>
      </v-col>
      <v-col cols="12" class="mt-5 px-0">
        <p v-if="counter !== 0">{{counter}}</p>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import uploadMixin from '@/mixins/upload-mixin'
//import uuidv4 from 'uuid/v4'
//import Quill from 'quill'

export default {
  name: "QuillEditorLola",
  mixins: [uploadMixin],
  props: ['folder', 'minHeight', 'placeholder', 'content', 'storageLevel'],
  data() {
    return {
      counter: 0,
      contentModel: '',
      editorMinHeight: '100',
      editorOptions: {
        placeholder: this.placeholder ? this.placeholder : 'Insert text here...',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'image', 'link'],        // toggled buttons
            ['blockquote', 'code-block'],

            //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'size': [false, '6px', '8px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '42px', '48px', '54px', '60px', '80px', '92px'] }],  // custom dropdown
            [{ 'font': [] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'align': [] }]
          ],
          imageDrop: true,
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
            },
            modules: ['Resize', 'DisplaySize']
          },
          htmlEditButton:{

          },

          //uses the plugin to upload to quill
          //uses the mixin upload to upload to S3 and return an URL
          imageUpload: {
            upload: async (file) => {
              // return a Promise that resolves in a link to the uploaded image
              //protected level by default because who uses the editor is a logged in user
              return this.uploadFile(file, { folder: this.folder, level: this.s3Level })
            }

          }
        }
      }
    }
  },
  created() {
    ////console.log('this is current quill instance object', this.editor)
    if (this.minHeight) { //if the props exist we update the data attribute
      this.editorMinHeight = this.minHeight
    }

    //if the prop exists we set the model to its value
    if (this.content) {
      this.contentModel = this.content
    }


  },
  computed: {
    quillEditor() {
      return this.$refs.myQuillEditor.quill
    },
    //file access level for aws amplify
    s3Level() {
      return this.storageLevel ? this.storageLevel : 'public'
    }
  },
  methods: {

    onEditorBlur(quill) {
      this.$emit('editorBlur', quill)
    },
    onEditorFocus(quill) {
      this.$emit('editorFocus', quill)
    },
    onEditorReady(quill) {
      this.$emit('editorReady', quill)

    },
    onEditorChange({ quill, html, text }) {
      this.$emit('editorChange', { quill, html, text })

      //https://vuejs.org/v2/guide/forms.html
      //https://github.com/vuejs/vue/issues/8403
      //v-model expects an input event and a value
      this.$emit('input', html) //emits this event and the value as html so we can use the v-model on our beautiful componenet in other components

      //contador de letras
      let label = 'letter'
      let length = text.length - 1 //(minus 1 because quill by default has an empty /n line)

      if (length > 0) {
        if (length > 1) label += 's';
        this.counter = length + ' ' + label;
      } else {
        this.counter = 0
      }

    }

  }
}
</script>

<style >
.ql-editor {
  font-size: 16px;
}

.ql-container {
  min-height: inherit;
}

/* .quill-editor{
  min-height: 100px;
} */
</style>
