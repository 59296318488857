<template>
    <v-main>
    <div v-if="!isItemsInSameGroup">
        <v-alert
        border="bottom"
        elevation="2"
        color="warning"
        class="ma-5 text-center"
        >
        <p>
            You have items in your cart from different groups.
            You cannot book items from different groups in the same transaction.
            <br>
            Please, remove items from different groups from your cart or empty the cart and start again.
        </p>
        </v-alert>
    </div>
    <div v-else>
        <v-container class="fill-width ma-0 pa-0" fluid>
            <v-row align justify no-gutters>
                <v-col class="mt-0 pa-0" cols="12">
                    <v-sheet elevation="1" color="grey lighten-4 mb-n5" class="text-center">
                        <v-chip class="headline pa-6 ma-6" large>
                            Checkout
                            <v-icon right>fa fa-credit-card</v-icon>
                        </v-chip>
                        <div>
                            <p class="subtitle-1 mb-0">Items: {{ cart.total | currency }}</p>
                            <p class="subtitle-1 mb-0">
                                Add-ons/Extras: {{ cart.totalExtra | currency }}
                            </p>
                            <p class="title pb-2">Total: CAD {{ cart.totalSum | currency }}</p>
                        </div>
                    </v-sheet>

                    <v-container>
                        <!-- only shows to login if CUSTOMER PORTAL is activated for this group -->
                        <v-sheet
                            class="text-center pa-5 ma-2 mb-5"
                            elevation="2"
                            v-if="cart.items.length > 0 && group.isCustomerPortalEmail"
                        >
                            <v-sheet v-if="loggedUser && loggedUser.user" class="pa-3">
                                <p class="subtitle-1">
                                    Hello, {{ loggedUser.user.name }}! You are logged in as
                                    <strong>{{ loggedUser.user.email }}</strong>
                                </p>
                                <p>
                                    Review your details below and enter your payment information to
                                    finish this order.
                                </p>
                                <v-btn @click="logout">Logout</v-btn>
                            </v-sheet>

                            <v-sheet v-else>
                                <div>
                                    <p class="subtitle-1">
                                        Book now and after the booking you can create an account to
                                        manage your orders.
                                    </p>
                                </div>

                                <!-- COMMENT - LOGIN OR CREATE ACCOUNT  -->
                                <v-dialog v-model="dialogLogin" max-width="500px">
                                    <template v-slot:activator="{ on }">
                                        <v-btn small text color="primary" dark v-on="on">
                                            Already have an account? Login
                                            <v-icon small right>fa-sign-in-alt</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-container class="fill-width ma-0 pa-0" fluid>
                                        <v-row align justify no-gutters>
                                            <v-col class="pa-0">
                                                <v-card>
                                                    <v-card-text class="pa-0">
                                                        <login-form
                                                            :userType="`customers`"
                                                            :refFrom="`checkout`"
                                                        />
                                                        <v-divider></v-divider>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-dialog>
                            </v-sheet>
                        </v-sheet>

                        <!-- ############ ORDER STATUS PROCESSING ######### -->
                        <v-alert
                            v-if="processing"
                            border="bottom"
                            colored-border
                            elevation="2"
                            color="grey lighten-1"
                            class="ma-5 text-center"
                        >
                            <v-progress-circular
                                :rotate="360"
                                :size="100"
                                :width="15"
                                :value="processingValue"
                                color="primary"
                                >{{ processingValue }}
                            </v-progress-circular>
                            <p class="mt-2 title">Processing...</p>

                            <p class="caption" v-if="stepMessage">
                                {{ stepMessage }}
                            </p>
                            <h3 class="darken-4">
                                Please, do not leave this page until complete.
                            </h3>
                        </v-alert>

                        <v-sheet
                            class="text-center pa-5 ma-2"
                            elevation="2"
                            v-if="cart.items.length > 0"
                        >
                            <ValidationObserver ref="form" v-slot="observerGroup">
                                <v-form @submit.prevent="observerGroup.handleSubmit(submit)">
                                    <v-row class="my-2">
                                        <v-col cols="12">
                                            <p class="title text-left mb-0">
                                                Customer Details
                                            </p>
                                        </v-col>
                                        <v-col class cols="12" md="6">
                                            <ValidationProvider
                                                name="Full Name"
                                                rules="required|max:128"
                                                v-slot="{ errors }"
                                            >
                                                <v-text-field
                                                    outlined
                                                    label="Full Name (required)"
                                                    name="Full Name"
                                                    type="text"
                                                    v-model="payment.customer.name"
                                                    :messages="errors[0]"
                                                    counter="128"
                                                    hint="The name of the person making the booking"
                                                    placeholder="Full name"
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col class cols="12" md="6">
                                            <ValidationProvider
                                                name="Phone"
                                                rules="required|max:32"
                                                v-slot="{ errors }"
                                            >
                                                <v-text-field
                                                    outlined
                                                    label="Phone (required)"
                                                    name="Phone"
                                                    type="text"
                                                    v-model="payment.customer.phone"
                                                    :messages="errors[0]"
                                                    counter="32"
                                                    hint="The phone number of the person making the booking with country and area code (Ex: +86 yyy xxxx xxxx)"
                                                    placeholder="Phone number (Ex: +86 yyy xxxx xxxx)"
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col class cols="12" md="6">
                                            <ValidationProvider
                                                name="Email"
                                                rules="required|email|max:200"
                                                v-slot="{ errors }"
                                                vid="emailField"
                                            >
                                                <v-text-field
                                                    outlined
                                                    label="Email (required)"
                                                    name="Email"
                                                    type="text"
                                                    v-model="payment.customer.email"
                                                    :messages="errors[0]"
                                                    counter="200"
                                                    hint="The email of the person making the booking"
                                                    placeholder="Email address"
                                                    @input="(val) => (payment.customer.email = payment.customer.email.toLowerCase())"
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col class cols="12" md="6">
                                            <ValidationProvider
                                                name="'Confirm email'"
                                                rules="required|email|max:200|confirmed:emailField"
                                                v-slot="{ errors }"                                                
                                            >
                                                <v-text-field
                                                    onpaste="return false;" ondrop="return false;" autocomplete="off"
                                                    outlined
                                                    label="Confirm email (required)"
                                                    name="Confirm your email"
                                                    type="text"
                                                    v-model="payment.customer.emailConfirmation"
                                                    :messages="errors[0]"
                                                    counter="200"
                                                    hint="The email of the person making the booking"
                                                    placeholder="Confirm your email address"
                                                    @input="(val) => (payment.customer.emailConfirmation = payment.customer.emailConfirmation.toLowerCase())"
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                    </v-row>
                                </v-form>
                            </ValidationObserver>

                            <StripeElements
                                @paymentSucceeded="paymentSuccess"
                                @paymentFailed="paymentFailed"
                                @error="paymentError"
                                @clickPay="initiateCheckout"
                                :payment.sync="payment"
                            />

                            <v-alert
                                dismissible
                                colored-border
                                border="left"
                                elevation="2"
                                type="warning"
                                v-if="errorsValidate.length > 0"
                                class="ma-5"
                            >
                                <p class="title">
                                    One or more items failed validation. No bookings or payments
                                    were made.
                                </p>
                                <p
                                    v-for="(error, idxValidate) in errorsValidate"
                                    :key="idxValidate"
                                >
                                    <strong>{{ error.item }}:</strong>
                                    {{ error.error }}
                                </p>
                            </v-alert>

                            <v-alert
                                dismissible
                                colored-border
                                border="left"
                                elevation="2"
                                type="error"
                                v-if="errorsBook.length > 0"
                                class="ma-5"
                            >
                                <p class="title">
                                    One or more items could not be booked. No bookings or payments
                                    were made.
                                </p>
                                <p v-for="(error, idxError) in errorsBook" :key="idxError">
                                    <strong>{{ error.item }}:</strong>
                                    {{ error.error }}
                                </p>
                            </v-alert>

                            <!-- Order error -->
                            <v-alert
                                dismissible
                                colored-border
                                border="left"
                                elevation="2"
                                type="error"
                                v-if="errorsOrder.length > 0"
                                class="ma-5"
                            >
                                <p v-for="(error, idxError) in errorsOrder" :key="idxError">
                                    <strong>{{ error.message }}:</strong>
                                    {{ error.error }}
                                </p>
                            </v-alert>
                        </v-sheet>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
    </v-main>
</template>

<script>
import { sendAlert } from '@/store/notifications-hub'
import StripeElements from '@/components/GroupBooking/Steps/Step3/StripeElements'
import LoginForm from '@/components/Login/LoginForm'
import generate from 'nanoid/generate'
import nanoid from 'nanoid'
import { Auth } from 'aws-amplify'
import moment from 'moment-timezone'

export default {
    name: 'Step3CheckouPage',
    props: {
        group: {
            type: Object,
            required: true,
        },
    },
    components: { StripeElements, LoginForm },

    computed: {
        cart: function() {
            return this.$store.getters.getCart
        },
        groupTimezone: function() {
            return this.$store.getters.getGroupTimezone
        },

        isCustomerReady: function() {
            return this.payment.customer.name &&
                this.payment.customer.email &&
                this.payment.customer.phone
                ? true
                : false
        },
        loggedUser: function() {
            //if loggedUser is logged and is a customer we get it to set in the order, otherwise we return null
            return this.$store.getters.loggedUser &&
                this.$store.getters.loggedUser.user &&
                this.$store.getters.userType === 'customers'
                ? this.$store.getters.loggedUser
                : null
        },
        isItemsInSameGroup: function() {
            return this.cart?.items.every(el => el?.groupId === this.group?._id)
        }
    },

    watch: {
        'loggedUser.user': function(newVal) {
            //clears or fills in the booking contact info from logged user
            if (newVal) {
                this.payment.customer.name = this.loggedUser.user.name
                this.payment.customer.email = this.loggedUser.user.email
                this.payment.customer.phone = this.loggedUser.user.phone
            } else {
                this.payment.customer.name = ''
                this.payment.customer.email = ''
                this.payment.customer.phone = ''
                this.payment.customer.emailConfirmation = ''
            }
        },

        stepMessage: function(newVal) {
            this.$log.info(newVal)
        },
    },

    data: function() {
        return {
            dialogLogin: false,

            errorsValidate: [],
            errorsBook: [],

            errorsOrder: [],

            orderItems: [], //used by Lola to save the order when it succeeds

            payment: {
                customer: {
                    // name: 'Filipe Navas',
                    // email: 'filipe@lolaguide.com',
                    // phone: '+16046523599'
                    name: '',
                    email: '',
                    phone: '',
                    emailConfirmation: ''
                },
                paymentIntent: {},
                ready: false,
                description: '',
                loading: false,
            },
            paymentDefault: {
                customer: {
                    name: '',
                    email: '',
                    phone: '',
                },
                paymentIntent: {},
                ready: false,
                description: '',
                loading: false,
            },
            customerLola: null, //either logged in or newly created
            order: {},
            stepMessage: '', //to show to user when he clicks Pay
            processing: false,
            processingValue: 0,
            orderLolaId: this.generateNanoId('OR'), //generates an order ID to be used in the payment and FH bookings
            transactionIdLola: this.generateNanoId('TR'),
        }
    },
    methods: {
        // The payment flow is like this: we validate the bookings, then create the order and save it in DB, then attempts the payment.
        // In the server, we have a webhook that listens to the payment succeedeed and then creates the bookings in FH and emails the customer
        async initiateCheckout() {
            this.payment.loading = true //btn on stripe form

            this.stepMessage = 'Initiating checkout'
            this.processing = true
            this.processingValue = 5

            try {
                await this.validateForm()
                this.processingValue = 10
                this.stepMessage = 'Form valid'

                await this.setOrCreateCustomer()
                this.processingValue = 20
                this.stepMessage = 'Finished setting existent or creating customer'

                const bookingsToCreateInFH = await this.validateAndCreateFareharborPayload()
                this.processingValue = 30
                this.stepMessage = 'Validated and generated booking items'

                const waiversToCreate = await this.createWaivers()
                this.processingValue = 40
                this.stepMessage = 'Generated waivers'

                const orderToSave = await this.createOrder()
                this.processingValue = 50
                this.stepMessage = 'Created order'

                orderToSave.bookingsToCreate = bookingsToCreateInFH
                orderToSave.waiversToCreate = waiversToCreate
                this.processingValue = 60
                this.stepMessage = 'Set bookings and waivers in order'

                const savedOrder = await this.saveOrder(orderToSave)
                this.order = savedOrder
                this.processingValue = 70
                this.stepMessage = 'Saved order'

                await this.createPayment()
                this.payment.ready = true //changes so stripe form will attempt payment - here is the only place we set the green light on this prop so it will try to charge the card
                this.processingValue = 80
                this.stepMessage = 'Capturing payment'
            } catch (error) {
                this.payment.loading = false
                this.processing = false
                this.processingValue = 0
                this.stepMessage = 'There was an error processing the order'
                this.errorsOrder.push({
                    message: error.message
                        ? error.message
                        : 'There was an error. Please try again or contact support if you keep seeing this message.',
                    error: 'General error',
                })
                sendAlert({
                    event: 'error',
                    data: error,
                    message: 'There was an error:',
                })
                this.$log.error(error)
            }
        },

        //calback function when the payment on Stripe works fine (StripeElements component)
        async paymentSuccess() {
            this.stepMessage = 'Payment succeded'
            this.processingValue = 90

            this.sendEventToGoogleAnalytics()

            // this is this to give a nice sense of progress for the customer, nothing functional
            setTimeout(() => {
                this.processingValue = 99
            }, 2000)

            setTimeout(() => {
                this.orderSucceededCleanup()

                sendAlert({ event: 'success', data: null, message: 'Success' })
                // redirects
                this.$router.push({
                    name: 'orders',
                    params: {
                        orderProp: this.order,
                        id: this.order._id,
                        from: 'payment',
                    },
                })
            }, 3000)
        },

        // just creats the order object, it doesn't save to the DB yet
        createOrder() {
            return new Promise(async (resolve, reject) => {
                try {
                    let items = []

                    for (const lolaItem of this.cart.items) {
                        items.push({
                            availability: lolaItem.availability, //availability lola (from our db) it's just for reference to know where this booking originated
                            availabilityPk: lolaItem.availability.fareharborDetails.availabilityPk,
                            title: lolaItem.item.name,

                            amountTotal: lolaItem.total.total + lolaItem.total.totalExtra,
                            amountTotalCents:
                                (lolaItem.total.total + lolaItem.total.totalExtra) * 100,

                            description: lolaItem.availability.description
                                ? lolaItem.availability.description
                                : lolaItem.item.description
                                ? lolaItem.item.description
                                : lolaItem.item.fareharborDetails.description_safe_html, //if it exists in the availability we get, else we check if exists in the item and we get, else we get from FH
                            itinerary: lolaItem.availability.itinerary
                                ? lolaItem.availability.itinerary
                                : lolaItem.item.itinerary
                                ? lolaItem.item.itinerary
                                : lolaItem.item.fareharborDetails.booking_notes_safe_html, //if it exists in the availability we get, else we check if exists in the item and we get, else we get from FH
                            headline: lolaItem.availability.headline
                                ? lolaItem.availability.headline
                                : lolaItem.item.headline
                                ? lolaItem.item.headline
                                : lolaItem.item.fareharborDetails.headline, //if it exists in the availability we get, else we check if exists in the item and we get, else we get from FH

                            fareharborBooking: null,
                            fareharborItem: lolaItem.item.fareharborDetails,

                            status: 'created', // created means the item is created in Lola, but NOT booked in FH

                            lolaId: this.generateNanoId('IT'),

                            travellers: lolaItem.travellers,

                            //just one transaction per item when creating the order because all orders and tours are paid in full at the moment
                            transactions: [
                                {
                                    transactionType: 'payment',
                                    amountCents:
                                        (lolaItem.total.total + lolaItem.total.totalExtra) * 100,
                                    amount: lolaItem.total.total + lolaItem.total.totalExtra,

                                    //amount paid now is always the same as total as people pay the order in full
                                    amountPaidCents:
                                        (lolaItem.total.total + lolaItem.total.totalExtra) * 100,
                                    amountPaid: (lolaItem.total.total + lolaItem.total.totalExtra),

                                    feeLolaguideCents: this.getLolaCommission(
                                        (lolaItem.total.total + lolaItem.total.totalExtra) * 100
                                    ),
                                    feeLolaguide: (
                                        this.getLolaCommission(
                                            (lolaItem.total.total + lolaItem.total.totalExtra) * 100
                                        ) / 100
                                    ).toFixed(2),
                                    gatewayId: this.payment?.paymentIntent?.id,
                                    lolaId: this.transactionIdLola,

                                    partnerCommissionCents: this.getPartnerCommission(
                                        (lolaItem.total.total + lolaItem.total.totalExtra) * 100
                                    ), //gets in cents
                                    partnerCommission: (
                                        this.getPartnerCommission(
                                            (lolaItem.total.total + lolaItem.total.totalExtra) * 100
                                        ) / 100
                                    ).toFixed(2), //gets in dollars
                                },
                            ],
                        })
                    } //end for

                    //create order after payment, so we set paid to true and all prices
                    let order = {
                        _id: this.cart.orderUuid, //same as the cart, order
                        isPaid: false, // not paid since we create the order before the payment, it will be updated in the webhook
                        amountTotalCents: this.cart.totalSumCents,
                        amountTotal: this.cart.totalSum,
                        amountPaidCents: 0,
                        amountPaid: 0,
                        group: this.group._id,
                        customer: this.customerLola._id,
                        items: items,
                        lolaId: this.orderLolaId, //this.generateNanoId('OR'),
                        description: this.payment.description,
                        waiversToCreate: [],
                        bookingsToCreate: [],
                    }

                    resolve(order)
                } catch (error) {
                    reject(error)
                }
            })
        },

        saveOrder(orderToSave) {
            return new Promise(async (resolve, reject) => {
                try {
                    // upsert if not found. we do this because if the payment fails, we want to use the same order since we save the order before the payment
                    let { data }  = await this.$tornos.put(`orders/${orderToSave._id}/upsert`, orderToSave)
                    resolve(data)
                } catch (e) {
                    this.$log.error(e)
                    reject({ message: 'There was an error saving the order', error: e })
                }
            })
        },

        validateForm() {
            return new Promise(async (resolve, reject) => {
                const isValid = await this.$refs.form.validate()
                this.$log.info('isValid: ' + isValid)
                if (isValid) {
                    resolve()
                } else {
                    reject({
                        message: 'Customer Details form invalid. Please check again if all fields are complete, and if the email confirmation is correct.',
                    })
                }
            })
        },

        async createPayment() {
            return new Promise(async (resolve, reject) => {
                let lolaCommission = this.getLolaCommission(this.cart.totalSumCents)

                let partnerFullName =
                    this.group.partner && this.group.partner.name ? this.group.partner.name : ''
                let partner =
                    this.group.partner && this.group.partner.name
                        ? this.group.partner.name.slice(0, 16)
                        : ''
                let statementDescriptor = `TOUR*${partner}`
                let fhBookingIds = ''
                let fhBookedItemIds = this.cart.items
                    .map(el => el.item.fareharborDetails.pk)
                    .join(';')
                let fhBookedAvailabilityIds = this.cart.items
                    .map(el => el.availability.fareharborDetails.availabilityPk)
                    .join(';')
                let orderLink = `${process.env.VUE_APP_URL}/orders/${this.cart.orderUuid}`

                let payload = {
                    amountCents: this.cart.totalSumCents,
                    currency: 'cad',
                    applicationFeeCents: lolaCommission,
                    destinationAccount: this.group.division.company.settings
                        .stripeConnectedAccountId,
                    statementDescriptor: statementDescriptor,
                    description: `${this.payment.description}`,
                    // idempotencyKey: this.cart.orderUuid, // we don't need this anymore because we save the Order BEFORE the payment, which means the payment can fail so we allow the user to retry. We are also using an upsert of the order
                    metadata: {
                        // "origin":  this.userLocation.country || "-",
                        group: this.group.title,
                        partner: partnerFullName,
                        'fh-booking': fhBookingIds,
                        'fh-item': fhBookedItemIds, // FH item id (pk)
                        'fh-availability': fhBookedAvailabilityIds, // FH availability id (pk)
                        'lola-order': this.orderLolaId,
                        'lola-transaction': this.transactionIdLola,
                        'lola-commission': (lolaCommission / 100).toFixed(2), // it is in cents originally
                        order: orderLink,
                        'order-uuid': this.order._id,
                    },
                }

                // ====== CREATES PAYMENT INTENT (it will create a new intent every time the customer clicks the Pay button, this is useful because they can add more items to the cart after a first attempt that might have failed)
                try {
                    let paymentIntent = await this.$tornos.post(`/payment/intent`, payload)
                    let paymentIntentId = paymentIntent.data
                    this.stepMessage = 'Created new payment object'
                    this.payment.paymentIntent = paymentIntentId
                    this.processingValue = 50
                    resolve()
                } catch (error) {
                    reject(error)
                }
            }) // end promise
        },

        sendEventToGoogleAnalytics() {
            // sends to Google Analytics
            try {
                this.$gtag.purchase({
                    transaction_id: this.order.lolaId,
                    affiliation: this.order.group.title,
                    value: this.order.amountTotal,
                    currency: 'CAD',
                    shipping: 0,
                })
            } catch (e) {
                this.$log.warn('Error sending event to Analytics', e)
            }
        },

        async setOrCreateCustomer() {
            //creates new customer OR get the loggedUser if it's a customer logged in
            //used to set in the order
            //we do it here to avoid having a payment and then having an error when saving the order
            //so we try creating the customer before any payment is made
            return new Promise(async (resolve, reject) => {
                // user is logged in and is a customer
                if (this.loggedUser && this.loggedUser.type === 'customers') {
                    this.customerLola = this.loggedUser.user
                    this.stepMessage = 'Customer is logged in'
                    resolve('Customer is logged in')
                }

                try {
                    //gets from the DB
                    let savedCustomer = await this.getCustomerFromLolaDb(
                        this.payment.customer.email
                    )

                    //if it exists in the db we use that user (even if the user has not logged in)
                    //this way we avoid trying to create an existing user into Cognito, which will return an error and
                    //allow the customer to book without needing to login
                    if (savedCustomer) {
                        this.customerLola = savedCustomer
                        this.stepMessage = 'Customer found in the database'
                        resolve('Customer found in the database')
                    } else {
                        //gets from cognito
                        let userCognitoId = await this.getCustomerFromCognito(
                            this.payment.customer.email
                        )

                        if (userCognitoId) {
                            //user exists in cognito but not in the customers collection
                            //so we save this user as a customer and associate the same cognito id to it
                            let res = await this.createNewCustomer(true, userCognitoId)
                            this.customerLola = res.data
                            this.stepMessage = 'Customer created'
                            resolve('Customer created')
                        } else {
                            //create new customer
                            let res = await this.createNewCustomer(false)
                            this.customerLola = res.data
                            this.stepMessage = 'Customer created in the pool'
                            resolve('Customer created in the pool')
                        }
                    } //end if-else customerLola

                    //error getting from db or creating new customer
                } catch (error) {
                    reject(error)
                }
            }) // end promise
        },

        getLolaCommission(amount) {
            if (typeof amount !== Number) amount = Number(amount)
            let percentage = this.group.division.company.settings.lolaFeePercentage //commission from this company
            let commissionLola = (percentage * amount) / 100
            return Math.round(commissionLola) //round to the nearest integer
        },

        getPartnerCommission(amount) {
            if (typeof amount !== Number) amount = Number(amount)
            let percentage =
                this.group.partner && this.group.partner.comissionPercentage
                    ? this.group.partner.comissionPercentage
                    : 0 //partner commision
            let commision = (percentage * amount) / 100
            return Math.round(commision) //round to the nearest integer
        },

        async validateAndCreateFareharborPayload() {
            return new Promise(async (resolve, reject) => {
                //clear data
                this.errorsValidate = []
                this.errorsBook = []
                this.payment.description = '' // resets before setting below

                let bookings = []

                //creates the booking(s) objects to send to Fareharbor
                this.cart.items.forEach(item => {
                    //items are really availabilities

                    let customers = []

                    item.travellers.forEach(traveller => {
                        //if there's custom fields for this customer we add them to the customers array
                        if (
                            traveller.customerTypeRate.fields &&
                            traveller.customerTypeRate.fields.length > 0
                        ) {
                            let customFields = []

                            traveller.customerTypeRate.fields.forEach(field => {
                                customFields.push({
                                    custom_field: field.pk, //pk
                                    value: field.value, //value provided by customer (usually a pk from FH if not a short or long (text) field)
                                })
                            })

                            customers.push({
                                customer_type_rate: traveller.customerTypeRate.pk,
                                custom_field_values: customFields,
                                //waivers: waivers //waivers is a field for lola only - not fh
                            })

                            //there's no custom fields for this customer
                        } else {
                            customers.push({
                                customer_type_rate: traveller.customerTypeRate.pk,
                            })
                        }
                    })

                    //each item is a booking on FH (item is an individual availability with one or many travellers in it)
                    bookings.push({
                        companyShortname: this.group.division.company.settings.fareharborShortname,
                        itemName: item.item.name,
                        availabilityPk: item.availability.fareharborDetails.availabilityPk,
                        isProcessed: false, // this is used in the webook to check if this already was processed (created in FH)
                        payload: {
                            contact: {
                                name: this.payment.customer.name,
                                phone: this.payment.customer.phone,
                                email: this.payment.customer.email,
                            },
                            customers: customers,
                            voucher_number: this.orderLolaId,
                        },
                    })

                    //creates the payment description used in the paymentIntent
                    // (trip destination, start date, and the school's name). Ex. Whistler or RTS 03/25/2021 Seaquam SD;
                    const tripDestination = item.item.name
                    const tripDate = moment(item.availability.fareharborDetails.startDate).format(
                        'MM/DD/YYYY'
                    )
                    const tripPartner = this.group.partner.name
                    const description = `${tripDestination} ${tripDate} ${tripPartner}`
                    this.payment.description = this.payment.description
                        ? `${this.payment.description}; ${description}`
                        : `${description}`
                })

                //try validating and booking
                try {
                    await this.validateBookings(bookings)
                    resolve(bookings)
                } catch (error) {
                    reject(error)
                }
            })
        },

        validateBookings(bookings) {
            return new Promise(async (resolve, reject) => {
                //for that works with asyn await (forEach doesnt work with await because it doenst return a Promise)
                //https://stackoverflow.com/a/37576787/10903141
                for (const booking of bookings) {
                    try {
                        //validate booking on FH
                        let res = await this.$fareharbor.post(
                            `/companies/${this.group.division.company.settings.fareharborShortname}/availabilities/${booking.availabilityPk}/bookings/validate`,
                            booking.payload
                        )

                        //generally this request will work and give us a response whether the booking is bookable or not
                        //if not bookable we set to false and add to the erros array
                        if (res.data.is_bookable === false) {
                            reject({
                                message: `Item ${booking.itemName} is not valid`,
                                error: res.data.error,
                            })
                        }
                    } catch (e) {
                        //to get here it's a newtwork or not found error (wrong url for example)
                        reject({
                            message: `There was an error validating the item ${booking.itemName} `,
                            error: e,
                        })
                    }
                }

                resolve() // if passed all validations then it's okay to resolve
            })
        },

        createNewCustomer(isCognito, userCognitoId) {
            return new Promise(async (resolve, reject) => {
                try {
                    //user from cognito is provided, just save to customers
                    if (isCognito) {
                        let user = {
                            name: this.payment.customer.name,
                            email: this.payment.customer.email,
                            phone: this.payment.customer.phone,
                            cognitoId: userCognitoId,
                        }

                        let customer = await this.$tornos.post('customers', {
                            ...user,
                        })

                        resolve(customer)

                        //creates a new user in cognito and save to customers
                    } else {
                        let password = nanoid(10) //generates password for cognito and send via email to the user

                        let cognitoUser = await this.createNewUserCognito(
                            this.payment.customer.email,
                            password
                        )

                        if (cognitoUser.userSub) {
                            //if the userSub field exists it was successful
                            //sets the cognito id of the new user
                            let user = {
                                name: this.payment.customer.name,
                                email: this.payment.customer.email,
                                phone: this.payment.customer.phone,
                                cognitoId: cognitoUser.userSub,
                                tempPassword: password, //saves the temp password to show on the order page
                            }

                            let customer = await this.$tornos.post('customers', {
                                ...user,
                            })

                            //only sends the email to the customer about the portal if it's activated in the group
                            if (
                                this.group.isCustomerPortalEmail &&
                                this.group.isCustomerPortalEmail === true
                            ) {
                                this.$log.info('send email to customer')
                                this.sendEmailNewUser(customer.data, password) //sends email in a separate function so dont stop flow
                            }

                            //created in cognito and saved
                            resolve(customer)
                        } else {
                            sendAlert({
                                event: 'warning',
                                data: cognitoUser,
                                message: 'There was an error creating the user in the pool.',
                            })
                            reject()
                        }
                    }
                } catch (e) {
                    this.$log.error(e)
                    reject(e)
                }
            })
        },

        // waivers to be set in the order and created later on on the server side
        createWaivers() {
            return new Promise(async (resolve, reject) => {
                try {
                    let waivers = []
                    for (const lolaItem of this.cart.items) {
                        lolaItem.travellers.forEach(traveller => {
                            if (traveller.age) {
                                //let's add the template to this traveller
                                if (
                                    lolaItem.availability.waiverTemplates &&
                                    lolaItem.availability.waiverTemplates.length > 0
                                ) {
                                    lolaItem.availability.waiverTemplates.forEach(
                                        waiverTemplate => {
                                            //traveller is minor
                                            if (
                                                waiverTemplate.waiverType === 'minor' &&
                                                Number(traveller.age) < waiverTemplate.legalAge
                                            ) {
                                                waivers.push({
                                                    lolaId: this.generateNanoId('WA'),
                                                    traveller: {
                                                        name: `${traveller?.firstname ||
                                                            ''} ${traveller?.lastname || ''}`,
                                                        age: traveller.age,
                                                        email: '',
                                                        status: 'minor',
                                                    },
                                                    isSigned: false,
                                                    waiverTemplate: waiverTemplate._id,
                                                    order: this.cart.orderUuid,
                                                    item: {
                                                        name: lolaItem.item.name,
                                                        startDate:
                                                            lolaItem.availability.fareharborDetails
                                                                .startDate,
                                                        endDate:
                                                            lolaItem.availability.fareharborDetails
                                                                .endDate,
                                                        headline: lolaItem.availability.headline
                                                            ? lolaItem.availability.headline
                                                            : lolaItem.item.headline
                                                            ? lolaItem.item.headline
                                                            : lolaItem.item.fareharborDetails
                                                                  .headline, //if it exists in the availability we get, else we check if exists in the item and we get, else we get from FH
                                                    },
                                                    timezone: this.groupTimezone,
                                                })

                                                //traveller is adult
                                            } else if (
                                                waiverTemplate.waiverType === 'adult' &&
                                                Number(traveller.age) >= waiverTemplate.legalAge
                                            ) {
                                                waivers.push({
                                                    lolaId: this.generateNanoId('WA'),
                                                    traveller: {
                                                        name: `${traveller?.firstname ||
                                                            ''} ${traveller?.lastname || ''}`,
                                                        age: traveller.age,
                                                        email: '',
                                                        status: 'adult',
                                                    },
                                                    isSigned: false,
                                                    waiverTemplate: waiverTemplate._id,
                                                    order: this.cart.orderUuid,
                                                    item: {
                                                        name: lolaItem.item.name,
                                                        startDate:
                                                            lolaItem.availability.fareharborDetails
                                                                .startDate,
                                                        endDate:
                                                            lolaItem.availability.fareharborDetails
                                                                .endDate,
                                                        headline: lolaItem.availability.headline
                                                            ? lolaItem.availability.headline
                                                            : lolaItem.item.headline
                                                            ? lolaItem.item.headline
                                                            : lolaItem.item.fareharborDetails
                                                                  .headline, //if it exists in the availability we get, else we check if exists in the item and we get, else we get from FH
                                                    },
                                                    timezone: this.groupTimezone,
                                                })
                                            }
                                        }
                                    ) //end for each waiver templates
                                }
                            } //end if custom field age
                        }) //end for each traveller
                    } //end for cart->items

                    resolve(waivers)
                } catch (e) {
                    reject({
                        message:
                            'Failed to create waivers for items. No bookings or charges were created.',
                        error: e,
                    })
                }
            })
        },

        orderSucceededCleanup() {
            //cleans cart
            this.$store.dispatch('clearCart')

            //cleans the component
            this.payment = this.paymentDefault
            this.orderItems = []
            this.customerLola = null
            this.processing = false
        },

        //this is if it gets to the then error of the strip elements (if the card is denied for example)
        paymentFailed() {
            this.payment.ready = false
            this.stepMessage = 'Payment failed. No orders, payments or bookings made.'
            this.processing = false
            this.payment.loading = false //btn on stripe form
        },

        //this is if it gets to the catch of the stripe elements (if we send an undefined client id for example)
        paymentError(error) {
            this.payment.ready = false
            this.stepMessage =
                'There was an error attempting the payment. No orders, payments or bookings made.'
            this.errorsOrder.push({
                message: error.message
                    ? error.message
                    : 'We could not get more details. Please try again. No charges were created.',
                error: 'Order Creation Failed',
            })
            this.processing = false
            this.payment.loading = false //btn on stripe form
        },

        async sendEmailNewUser(customer, password) {
            //sends email
            await this.$tornos.post(`email/account/customer`, {
                customer: customer,
                password: password,
                division: {
                    name: this.group.division.name,
                    domain: this.group.division.domain,
                },
                partner: this.group.partner,
            })
            this.$log.info('Email account customer sent')
        },

        //create new cognito user
        createNewUserCognito(username, password) {
            return new Promise((resolve, reject) => {
                Auth.signUp({ username, password })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(e => {
                        reject(e)
                    })
            })
        },

        //get customer from lola db
        getCustomerFromLolaDb(email) {
            return new Promise((resolve, reject) => {
                this.$tornos
                    .get(`customers?email=${email}`)
                    .then(res => {
                        //customer found
                        if (res.data.length > 0) {
                            resolve(res.data[0])
                        } else {
                            resolve(false)
                        }
                    })
                    .catch(e => {
                        reject(e)
                    })
            })
        },

        //get customer from cognito pool
        getCustomerFromCognito(email) {
            return new Promise(resolve => {
                this.$tornos
                    .get(`cognito/${email}`)
                    .then(res => {
                        resolve(res.data.Username) //returns the user
                    })
                    .catch(() => {
                        resolve(false)
                    })
            })
        },

        logout() {
            this.$store.dispatch('logout')
            this.dialogLogin = false
        },

        // helper method to send email to admin (payload: {message, type, data-> json})
        sendEmailToAdmin(payload) {
            //sends email
            this.$tornos
                .post(`email/admin`, {
                    message: payload.message,
                    type: payload.type,
                    data: payload.data,
                })
                .then(() => {
                    this.$log.info('Email to admin sent | ' + payload.type)
                })
                .catch(e => {
                    this.$log.error('Failed to send email to admin', e)
                    this.$log.error(payload.message)
                })
        },

        generateNanoId(prefix) {
            let nano = generate('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 8)
            return `${prefix}${nano}`
        },

        /* Not in use anymore (November 2021)
        async initUserLocation() {
            try{
                this.$log.info("Fetching user location")
                let responseIpRegistry = await this.$ip.originLookup();
                if(responseIpRegistry.data){ // if the data exists we set it
                    responseIpRegistry = responseIpRegistry.data;
                    this.userLocation = {
                        ip: responseIpRegistry.ip,
                        continent: responseIpRegistry.location.continent.name,
                        country: responseIpRegistry.location.country.name,
                        countryCode: responseIpRegistry.location.country.code,
                        city: responseIpRegistry.location.country.city,
                        state: responseIpRegistry.location.region.name,
                        stateCode: responseIpRegistry.location.region.code,
                        currentTime: responseIpRegistry.time_zone.current_time
                    }
                }
            }catch(e) {
                this.$log.warn("Failed to fetch user location", e)
            }
        }
        */
    },

    mounted() {
        this.stepMessage = ''
        this.errorsValidate = []
        this.errorsBook = []

        // this.initUserLocation() // NOT NEEDED ANYMORE AS WE PAY GST TO CRA (we collect it in our invoices to DCT)
    },
}
</script>

<style scoped></style>
