<template>
   <v-main>
        <v-container class="fill-width ma-0 pa-0" fluid>
            <v-row align justify no-gutters>
                <v-col class="mt-0" cols="12">                    
                        <v-sheet elevation="0" color="grey lighten-4" class="pb-4">
                            <v-row>
                                <v-col cols="12" lg="8" offset-lg="2">
                                    <v-card class="ma-0 pa-4 mx-auto" outlined>
                                    <v-card-title class="justify-center display-1">
                                        <!-- {{group.title}} -->
                                    </v-card-title>
                                    <v-card-text>
                                        <div v-html="group.terms"></div>
                                    </v-card-text>
                                </v-card>
                                </v-col>
                            </v-row>                            
                        </v-sheet>                    
                </v-col>

            </v-row>
        </v-container>
   </v-main>
</template>

<script>
export default {
    name: "GroupTermsAndConditions",
    props: {
        slug: {
            type: String
        }
    },
    data: function(){
        return {
            group: null
        }
    },
    mounted() {
        this.$tornos.get(`groups?slug=${this.slug}`).then((res) =>{
            this.group = res.data[0]
        }).catch(() => {
            this.$router.push({name: "not.found"})
        })
    }
}
</script>

<style>

</style>