<template>
    <div id="register">
        <v-row>
            <v-col>
                <v-card outlined>
                    <v-container>
                        <v-card-title>{{ labelSubmitBtn }}</v-card-title>

                        <ValidationObserver
                            ref="formpartner"
                            v-slot="observerPartner"
                        >
                            <v-card-text>
                                <v-form
                                    @submit.prevent="
                                        observerPartner.handleSubmit(submit)
                                    "
                                >
                                    <div class="pa-2">
                                        <ValidationProvider
                                            name="Name"
                                            rules="required"
                                            v-slot="{ errors }"
                                        >
                                            <v-text-field
                                                outlined
                                                label="Name"
                                                name="Name"
                                                type="text"
                                                v-model="partner.name"
                                                :messages="errors[0]"
                                                counter="60"
                                                hint="Partner Name (Company Name)"
                                            />
                                        </ValidationProvider>
                                    </div>

                                    <div class="pa-2">
                                        <ValidationProvider
                                            name="Website"
                                            rules
                                            v-slot="{ errors }"
                                        >
                                            <v-text-field
                                                outlined
                                                label="Website"
                                                name="Website"
                                                type="text"
                                                v-model="partner.website"
                                                :messages="errors[0]"
                                                counter="60"
                                                hint="Partner Website"
                                            />
                                        </ValidationProvider>
                                    </div>

                                    <div class="pa-2">
                                        <ValidationProvider
                                            name="slug"
                                            rules
                                            v-slot="{ errors }"
                                        >
                                            <v-text-field
                                                outlined
                                                label="Slug (URL shortcut)"
                                                name="slug"
                                                type="text"
                                                v-model="partner.slug"
                                                :messages="errors[0]"
                                                counter="60"
                                                hint="Partner Slug (URL shortcut to be used in the group link)"
                                            />
                                        </ValidationProvider>
                                    </div>

                                    <div class="pa-2">
                                        <ValidationProvider
                                            name="Email"
                                            rules="required|email"
                                            v-slot="{ errors }"
                                        >
                                            <v-text-field
                                                outlined
                                                label="Email"
                                                name="Email"
                                                type="text"
                                                v-model="partner.email"
                                                :messages="errors[0]"
                                                counter="60"
                                                hint="Partner Email (main company email address)"
                                            />
                                        </ValidationProvider>
                                    </div>

                                    <div class="pa-2">
                                        <ValidationProvider
                                            name="Phone"
                                            rules="required"
                                            v-slot="{ errors }"
                                        >
                                            <v-text-field
                                                outlined
                                                label="Phone"
                                                name="Phone"
                                                type="text"
                                                v-model="partner.phone"
                                                :messages="errors[0]"
                                                counter="60"
                                                hint="Partner Phone Number"
                                            />
                                        </ValidationProvider>
                                    </div>

                                    <div class="pa-2">
                                        <ValidationProvider
                                            name="Comisison Percentage"
                                            rules="required"
                                            v-slot="{ errors }"
                                        >
                                            <v-text-field
                                                outlined
                                                label="Comisison Percentage"
                                                name="Comisison Percentage"
                                                type="text"
                                                v-model="
                                                    partner.comissionPercentage
                                                "
                                                :messages="errors[0]"
                                                counter="60"
                                                hint="Partner Comisison Percentage (used to track comissions due from bookings)"
                                            />
                                        </ValidationProvider>
                                    </div>

                                    <div class="pa-2">
                                        <ValidationProvider
                                            name="Timezone"
                                            rules="required"
                                            v-slot="{ errors }"
                                        >
                                            <v-autocomplete
                                                v-model="partner.timezone"
                                                :items="timezones"
                                                hide-no-data
                                                hide-selected
                                                label="Select the Partner timezone"
                                                placeholder="Start typing to search a timezone"
                                                :messages="errors[0]"
                                                outlined
                                                chips
                                            ></v-autocomplete>
                                            <p class="caption mt-0 pt-0">
                                                The timezone set here is used to
                                                format the dates shown on groups
                                                and items belonging to this
                                                partner
                                            </p>
                                        </ValidationProvider>
                                    </div>

                                    <div class="pa-2 mt-4" v-if="isUpdate">
                                        <p>Current logo</p>
                                        <v-avatar tile width="100">
                                            <img
                                                v-if="partner.logoUrl"
                                                :alt="partner.name"
                                                :src="partner.logoUrl"
                                            />
                                            <v-icon v-else color="primary"
                                                >fa-user-circle</v-icon
                                            >
                                        </v-avatar>

                                        <v-switch
                                            v-model="switchLogo"
                                            label="Change partner logo?"
                                        ></v-switch>
                                    </div>

                                    <div
                                        class="pa-2"
                                        v-if="
                                            switchLogo === true ||
                                            isUpdate === false
                                        "
                                    >
                                        <ValidationProvider
                                            name="Logo"
                                            rules="required"
                                            v-slot="{ errors }"
                                        >
                                            <v-file-input
                                                outlined
                                                label="Click to choose an image..."
                                                name="Logo"
                                                v-model="partner.logoFile"
                                                :messages="errors[0]"
                                                show-size
                                                hint="Partner Logo"
                                                accept="image/*"
                                            />
                                        </ValidationProvider>
                                    </div>

                                    <div class="pa-2">
                                        <v-row>
                                            <v-col cols="12" md="12">
                                                <label>Address</label>
                                            </v-col>
                                            <v-col cols="12" md="8">
                                                <ValidationProvider
                                                    name="Street Name"
                                                    rules="required"
                                                    v-slot="{ errors }"
                                                >
                                                    <v-text-field
                                                        outlined
                                                        label="Street Name"
                                                        name="Street Name"
                                                        type="text"
                                                        v-model="
                                                            partner.address
                                                                .streetName
                                                        "
                                                        :messages="errors[0]"
                                                        counter="100"
                                                        hint="Street Name"
                                                    />
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    name="Unit Number"
                                                    rules
                                                    v-slot="{ errors }"
                                                >
                                                    <v-text-field
                                                        outlined
                                                        label="Unit Number"
                                                        name="Unit Number"
                                                        type="text"
                                                        v-model="
                                                            partner.address.unit
                                                        "
                                                        :messages="errors[0]"
                                                        counter="20"
                                                        hint="Unit Number (optional)"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" md="3">
                                                <ValidationProvider
                                                    name="City"
                                                    rules="required"
                                                    v-slot="{ errors }"
                                                >
                                                    <v-text-field
                                                        outlined
                                                        label="City"
                                                        name="City"
                                                        type="text"
                                                        v-model="
                                                            partner.address.city
                                                        "
                                                        :messages="errors[0]"
                                                        counter="60"
                                                        hint="City"
                                                    />
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" md="3">
                                                <ValidationProvider
                                                    name="State/Province"
                                                    rules="required"
                                                    v-slot="{ errors }"
                                                >
                                                    <v-text-field
                                                        outlined
                                                        label="State or Province"
                                                        name="State"
                                                        type="text"
                                                        v-model="
                                                            partner.address
                                                                .state
                                                        "
                                                        :messages="errors[0]"
                                                        counter="60"
                                                        hint="State"
                                                    />
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" md="3">
                                                <ValidationProvider
                                                    name="Postal Code"
                                                    rules="required"
                                                    v-slot="{ errors }"
                                                >
                                                    <v-text-field
                                                        outlined
                                                        label="Postal Code"
                                                        name="Postal Code"
                                                        type="text"
                                                        v-model="
                                                            partner.address
                                                                .postalCode
                                                        "
                                                        :messages="errors[0]"
                                                        counter="6"
                                                        hint="Postal Code (ex: V5H2P6)"
                                                    />
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" md="3">
                                                <ValidationProvider
                                                    name="Country"
                                                    rules="required"
                                                    v-slot="{ errors }"
                                                >
                                                    <v-select
                                                        :items="[
                                                            'Canada',
                                                            'United States of America',
                                                            'China',
                                                            'Other',
                                                        ]"
                                                        outlined
                                                        label="Country"
                                                        name="Country"
                                                        v-model="
                                                            partner.address
                                                                .country
                                                        "
                                                        :messages="errors[0]"
                                                        hint="Country"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-form>

                                <v-divider></v-divider>

                                <!-- ######### AGENTS ######## 
                                ONLY SHOWS THIS WHEN IT'S AN UPDATE.
                                EMPLOYEE NEEDS TO ADD THE PARTNER, GO BACK, AND THEN UPDATE AND ADD THE AGENTS
                                This is because when we`re updating we have the partner id to set in the agent document
                                -->
                                <v-row v-if="isUpdate">
                                    <v-col md="12" v-if="agents.length > 0">
                                        <h4>Agents</h4>
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">
                                                            Name
                                                        </th>
                                                        <th class="text-center">
                                                            Lastname
                                                        </th>
                                                        <th class="text-center">
                                                            Email
                                                        </th>
                                                        <th class="text-center">
                                                            Created at
                                                        </th>
                                                        <th class="text-center">
                                                            Actions
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="item in agents"
                                                        :key="item.name"
                                                        class="text-center"
                                                    >
                                                        <td>{{ item.name }}</td>
                                                        <td>
                                                            {{ item.lastname }}
                                                        </td>
                                                        <td>
                                                            {{ item.email }}
                                                        </td>
                                                        <td>
                                                            {{
                                                                item.createdAt
                                                                    | moment(
                                                                        'timezone',
                                                                        currentTimezone,
                                                                        'DD-MMM-YYYY HH:mm:ss z'
                                                                    )
                                                            }}
                                                        </td>
                                                        <td>
                                                            <v-tooltip bottom>
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                    }"
                                                                >
                                                                    <v-icon
                                                                        v-on="
                                                                            on
                                                                        "
                                                                        small
                                                                        class="mr-2"
                                                                        @click="
                                                                            deleteItem(
                                                                                item
                                                                            )
                                                                        "
                                                                        >fa-trash-alt</v-icon
                                                                    >
                                                                </template>
                                                                <span
                                                                    >Delete</span
                                                                >
                                                            </v-tooltip>
                                                            <v-tooltip bottom>
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                    }"
                                                                >
                                                                    <v-icon
                                                                        v-on="
                                                                            on
                                                                        "
                                                                        small
                                                                        class="mr-2"
                                                                        @click="
                                                                            sendEmailAgentLogin(
                                                                                item
                                                                            )
                                                                        "
                                                                        >fa-paper-plane</v-icon
                                                                    >
                                                                </template>
                                                                <span
                                                                    >Send Login Instructions by Email</span
                                                                >
                                                            </v-tooltip>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-col>

                                    <v-col md="12">
                                        <v-dialog
                                            v-model="dialogAddAgent"
                                            max-width="600px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="primary"
                                                    dark
                                                    v-on="on"
                                                >
                                                    Add Agent
                                                    <v-icon small right
                                                        >fa fa-user-plus</v-icon
                                                    >
                                                </v-btn>
                                            </template>

                                            <v-card>
                                                <v-card-title>
                                                    <span class="headline"
                                                        >Add new agent</span
                                                    >
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-container>
                                                        <v-form>
                                                            <v-row>
                                                                <v-col
                                                                    cols="auto"
                                                                    md="12"
                                                                >
                                                                    <div
                                                                        class="pa-2"
                                                                    >
                                                                        <v-text-field
                                                                            outlined
                                                                            label="Name"
                                                                            name="Name"
                                                                            type="text"
                                                                            v-model="
                                                                                agent.name
                                                                            "
                                                                            counter="60"
                                                                            hint="Agent First Name"
                                                                        />
                                                                    </div>
                                                                </v-col>

                                                                <v-col
                                                                    cols="auto"
                                                                    md="12"
                                                                >
                                                                    <div
                                                                        class="pa-2"
                                                                    >
                                                                        <v-text-field
                                                                            outlined
                                                                            label="Lastname"
                                                                            name="Lastname"
                                                                            type="text"
                                                                            v-model="
                                                                                agent.lastname
                                                                            "
                                                                            counter="60"
                                                                            hint="Agent Lastname"
                                                                        />
                                                                    </div>
                                                                </v-col>

                                                                <v-col
                                                                    cols="auto"
                                                                    md="12"
                                                                >
                                                                    <div
                                                                        class="pa-2"
                                                                    >
                                                                        <v-text-field
                                                                            outlined
                                                                            label="Agent Email"
                                                                            name="Email"
                                                                            type="text"
                                                                            v-model="
                                                                                agent.email
                                                                            "
                                                                            counter="60"
                                                                            hint="Agent Email"
                                                                        />
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </v-form>
                                                    </v-container>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-btn
                                                        color="default"
                                                        @click="
                                                            dialogAddAgent = false
                                                        "
                                                        >Cancel</v-btn
                                                    >
                                                    <v-spacer></v-spacer>

                                                    <v-btn
                                                        color="primary"
                                                        @click="submitAgent"
                                                        >Save</v-btn
                                                    >
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                                </v-row>
                                <!-- ######### END AGENTS ######## -->

                                <v-divider></v-divider>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    color="default"
                                    light
                                    large
                                    to="/companies/divisions/partners"
                                >
                                    <v-icon left small>fa fa-arrow-left</v-icon
                                    >Go back
                                </v-btn>

                                <v-spacer />
                                <v-btn
                                    color="primary white--text"
                                    large
                                    :disabled="observerPartner.invalid"
                                    @click="submit"
                                >
                                    {{ labelSubmitBtn }}
                                    <v-icon right small>fa fa-check</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>


<script>
import { sendAlert, setLoading } from '../../../store/notifications-hub'
import { Auth } from 'aws-amplify'
import uuidv4 from 'uuid/v4'

import uploadMixin from '@/mixins/upload-mixin.js'
import filtersMixin from '@/mixins/filters-mixin.js'

import nanoid from 'nanoid'

export default {
    name: 'PartnersCreate',
    mixins: [uploadMixin, filtersMixin],
    props: {
        edit: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            dialogAddAgent: false,
            switchLogo: false,
            isUpdate: false,
            counter: 0, //used in the remove array to be an unique index (in the for) so it removes the correct element (binding to component is unique, index can become the same when you delete an item from the array)
            partner: {
                name: '',
                website: '',
                email: '',
                logoFile: null,
                logoUrl: '',
                slug: '',
                address: {
                    streetName: '',
                    streetNumber: '',
                    city: '',
                    state: '',
                    postalCode: '',
                    country: 'Canada',
                },
                phone: '',
                comissionPercentage: 0,
                timezone: 'America/Vancouver',
            },
            partnerDefault: {
                name: '',
                website: '',
                email: '',
                logoFile: null,
                logoUrl: '',
                slug: '',
                address: {
                    streetName: '',
                    streetNumber: '',
                    city: '',
                    state: '',
                    postalCode: '',
                    country: 'Canada',
                },
                phone: '',
                comissionPercentage: 0,
                timezone: 'America/Vancouver',
            },
            agents: [],
            agent: {
                name: '',
                lastname: '',
                email: '',
                cognitoId: '',
            },
            agentDefault: {
                name: '',
                lastname: '',
                email: '',
                cognitoId: '',
            },
            timezones: [
                'Africa/Abidjan',
                'Africa/Abidjan',
                'Africa/Abidjan',
                'Africa/Abidjan',
                'Africa/Abidjan',
                'Africa/Abidjan',
                'Africa/Abidjan',
                'Africa/Abidjan',
                'Africa/Abidjan',
                'Africa/Abidjan',
                'Africa/Cairo',
                'Africa/Johannesburg',
                'Africa/Johannesburg',
                'Africa/Lagos',
                'Africa/Lagos',
                'Africa/Lagos',
                'Africa/Lagos',
                'Africa/Lagos',
                'Africa/Lagos',
                'Africa/Lagos',
                'Africa/Lagos',
                'Africa/Lagos',
                'Africa/Maputo',
                'Africa/Maputo',
                'Africa/Maputo',
                'Africa/Maputo',
                'Africa/Maputo',
                'Africa/Maputo',
                'Africa/Maputo',
                'Africa/Nairobi',
                'Africa/Nairobi',
                'Africa/Nairobi',
                'Africa/Nairobi',
                'Africa/Nairobi',
                'Africa/Nairobi',
                'Africa/Nairobi',
                'Africa/Nairobi',
                'Africa/Nairobi',
                'Africa/Nairobi',
                'Africa/Tripoli',
                'America/Adak',
                'America/Adak',
                'America/Anchorage',
                'America/Argentina/Buenos_Aires',
                'America/Argentina/Catamarca',
                'America/Argentina/Catamarca',
                'America/Argentina/Cordoba',
                'America/Argentina/Cordoba',
                'America/Argentina/Jujuy',
                'America/Argentina/Mendoza',
                'America/Atikokan',
                'America/Chicago',
                'America/Curacao',
                'America/Curacao',
                'America/Curacao',
                'America/Denver',
                'America/Denver',
                'America/Denver',
                'America/Detroit',
                'America/Edmonton',
                'America/Fort_Wayne',
                'America/Fort_Wayne',
                'America/Fort_Wayne',
                'America/Halifax',
                'America/Havana',
                'America/Indiana/Knox',
                'America/Indiana/Knox',
                'America/Jamaica',
                'America/Kentucky/Louisville',
                'America/Los_Angeles',
                'America/Los_Angeles',
                'America/Manaus',
                'America/Mazatlan',
                'America/Mexico_City',
                'America/New_York',
                'America/Noronha',
                'America/Panama',
                'America/Phoenix',
                'America/Port_of_Spain',
                'America/Port_of_Spain',
                'America/Port_of_Spain',
                'America/Port_of_Spain',
                'America/Port_of_Spain',
                'America/Port_of_Spain',
                'America/Port_of_Spain',
                'America/Port_of_Spain',
                'America/Port_of_Spain',
                'America/Port_of_Spain',
                'America/Port_of_Spain',
                'America/Port_of_Spain',
                'America/Port_of_Spain',
                'America/Port_of_Spain',
                'America/Regina',
                'America/Rio_Branco',
                'America/Rio_Branco',
                'America/Santiago',
                'America/Sao_Paulo',
                'America/St_Johns',
                'America/Tijuana',
                'America/Tijuana',
                'America/Tijuana',
                'America/Toronto',
                'America/Toronto',
                'America/Vancouver',
                'America/Whitehorse',
                'America/Winnipeg',
                'Asia/Ashgabat',
                'Asia/Bangkok',
                'Asia/Bangkok',
                'Asia/Dhaka',
                'Asia/Dubai',
                'Asia/Ho_Chi_Minh',
                'Asia/Hong_Kong',
                'Asia/Jerusalem',
                'Asia/Jerusalem',
                'Asia/Kathmandu',
                'Asia/Kolkata',
                'Asia/Kuala_Lumpur',
                'Asia/Kuala_Lumpur',
                'Asia/Macau',
                'Asia/Makassar',
                'Asia/Nicosia',
                'Asia/Qatar',
                'Asia/Rangoon',
                'Asia/Riyadh',
                'Asia/Riyadh',
                'Asia/Seoul',
                'Asia/Shanghai',
                'Asia/Shanghai',
                'Asia/Shanghai',
                'Asia/Shanghai',
                'Asia/Taipei',
                'Asia/Tehran',
                'Asia/Thimphu',
                'Asia/Tokyo',
                'Asia/Ulaanbaatar',
                'Asia/Urumqi',
                'Atlantic/Faroe',
                'Atlantic/Reykjavik',
                'Atlantic/South_Georgia',
                'Australia/Adelaide',
                'Australia/Brisbane',
                'Australia/Broken_Hill',
                'Australia/Darwin',
                'Australia/Hobart',
                'Australia/Lord_Howe',
                'Australia/Melbourne',
                'Australia/Perth',
                'Australia/Sydney',
                'Australia/Sydney',
                'Australia/Sydney',
                'Etc/GMT-0',
                'Etc/GMT-0',
                'Etc/GMT-0',
                'Etc/GMT-0',
                'Etc/GMT-0',
                'Etc/GMT-0',
                'Etc/GMT-0',
                'Etc/GMT-0',
                'Etc/GMT-0',
                'Etc/UTC',
                'Etc/UTC',
                'Etc/UTC',
                'Etc/UTC',
                'Etc/UTC',
                'Etc/UTC',
                'Etc/UTC',
                'Europe/Belgrade',
                'Europe/Belgrade',
                'Europe/Belgrade',
                'Europe/Belgrade',
                'Europe/Belgrade',
                'Europe/Chisinau',
                'Europe/Dublin',
                'Europe/Helsinki',
                'Europe/Istanbul',
                'Europe/Istanbul',
                'Europe/Lisbon',
                'Europe/London',
                'Europe/London',
                'Europe/London',
                'Europe/London',
                'Europe/London',
                'Europe/London',
                'Europe/Moscow',
                'Europe/Oslo',
                'Europe/Oslo',
                'Europe/Prague',
                'Europe/Rome',
                'Europe/Rome',
                'Europe/Warsaw',
                'Europe/Zurich',
                'Europe/Zurich',
                'Indian/Christmas',
                'Pacific/Auckland',
                'Pacific/Auckland',
                'Pacific/Auckland',
                'Pacific/Chatham',
                'Pacific/Chuuk',
                'Pacific/Chuuk',
                'Pacific/Easter',
                'Pacific/Guam',
                'Pacific/Honolulu',
                'Pacific/Honolulu',
                'Pacific/Kwajalein',
                'Pacific/Pago_Pago',
                'Pacific/Pago_Pago',
                'Pacific/Pago_Pago',
                'Pacific/Palau',
                'Pacific/Pohnpei',
                'Pacific/Port_Moresby',
                'Pacific/Tarawa',
                'Pacific/Tarawa',
                'Pacific/Tarawa',
                'Pacific/Tarawa',
            ],
        }
    },
    computed: {
        labelSubmitBtn: function () {
            return this.isUpdate ? 'Update Partner' : 'Add Partner'
        },
        folder: function () {
            return `${this.division.company._id}/${this.division.shortname}/partners`
        },
    },
    methods: {
        async validateSlugUniqueness() {
            if (this.partner.slug === '') return true // empty is allowed

            const { data } = await this.$tornos.post(`partners/query`, {
                filter: { slug: this.partner.slug },
            })

            // if update, we compare if the current partner being edited is the one that has the slug, if not it's from another partner in the system
            if (this.isUpdate) {
                if (data.length > 0 && data[0]._id !== this.partner._id) {
                    return false
                }
            } else {
                if (data.length > 0) {
                    return false
                }
            }

            return true // if not caught by the ifs above, then it's all good
        },
        async submit() {
            const isSlugUnique = await this.validateSlugUniqueness()
            if (!isSlugUnique) {
                sendAlert({
                    event: 'warning',
                    data: null,
                    message: `There is a partner in the system already using this slug: ${this.partner.slug}`,
                })
                return
            }

            setLoading(true) //loading overlay

            if (this.isUpdate) {
                if (this.switchLogo && this.partner.logoFile) {
                    //upload logo
                    this.partner.logoUrl = await this.uploadFile(
                        this.partner.logoFile,
                        { folder: this.folder, level: 'protected' }
                    )
                }

                this.$tornos
                    .put(`partners/${this.partner._id}`, this.partner)
                    .then((res) => {
                        if (res.status === 200) {
                            sendAlert({
                                event: 'info',
                                data: res.body,
                                message: 'Updated Successfully',
                            })
                        } else {
                            sendAlert({
                                event: 'warning',
                                data: res.body,
                                message:
                                    'Error updating entity. Please try again',
                            })
                        }
                    })
                    .catch((e) => {
                        sendAlert({
                            event: 'danger',
                            data: e,
                            message: 'There was an error',
                        })
                    })
                    .finally(() => {
                        setLoading(false) //loading overlay
                        this.switchLogo = false
                    })
            } else {
                try {
                    //upload logo first
                    this.partner.logoUrl = await this.uploadFile(
                        this.partner.logoFile,
                        { folder: this.folder, level: 'protected' }
                    )

                    this.$tornos
                        .post('partners', {
                            division: this.division,
                            createdBy: this.loggedUser.user.email,
                            uuid: uuidv4(),
                            ...this.partner,
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                sendAlert({
                                    event: 'info',
                                    data: res.body,
                                    message: 'Created Successfully',
                                })
                                this.$router.push({ name: 'partners.list' })
                            } else {
                                sendAlert({
                                    event: 'warning',
                                    data: res.body,
                                    message:
                                        'Error creating entity. Please try again',
                                })
                            }
                        })
                        .catch((e) => {
                            sendAlert({
                                event: 'danger',
                                data: e,
                                message: 'There was an error',
                            })
                        })
                        .finally(() => {
                            setLoading(false) //loading overlay
                        })
                } catch (e) {
                    sendAlert({
                        event: 'danger',
                        data: e,
                        message:
                            'There was an error and the entity was not added.',
                    })
                    setLoading(false) //loading overlay
                }
            }
        },

        deleteItem(item) {
            //this.dialog = true
            // Trigger a confirmation dialog

            this.$dialog
                .confirm('Would you like to delete the agent: ' + item.name, {
                    loader: true,
                })
                .then(async (dialog) => {
                    let res = null
                    try {
                        await this.$tornos.delete(`/cognito/${item.email}`) //delete from cognito
                        res = await this.$tornos.delete(`agents/${item._id}`)
                    } catch (e) {
                        sendAlert({
                            event: 'danger',
                            data: e,
                            message: 'There was an error. Please try again',
                        })
                    }

                    //success
                    if (res.data.status === 200) {
                        sendAlert({
                            event: 'success',
                            data: res.body,
                            message: `Deleted: ${item.name}`,
                        })
                    } else {
                        sendAlert({
                            event: 'danger',
                            data: res.data,
                            message: 'There was an error. Please try again',
                        })
                    }

                    dialog.close()

                    this.initializeAgents() //re-load the table

                    //dialog closed
                })
                .catch(() => {
                    ////this.$log.info("dialog closed")
                })
        },

        initializeAgents() {
            if (this.partner._id) {
                this.$tornos
                    .get(`agents?partner=${this.partner._id}`)
                    .then((res) => {
                        this.agents = res.data
                    })
                    .catch((e) => {
                        sendAlert({
                            event: 'danger',
                            data: e,
                            message: 'There was an error fetching the agents.',
                        })
                    })
            }
        },

        async submitAgent() {
            //this.$log.info("save new agent")

            try {
                setLoading(true)

                let password = nanoid(10) //generates password for cognito and send via email to the user

                let cognitoUser = await this.createNewUser(
                    this.agent.email,
                    password
                )

                if (cognitoUser.userSub) {
                    //if the userSub field exists it was successful
                    //sets the cognito id of the new user
                    this.agent.cognitoId = cognitoUser.userSub

                    //this.$log.info("cognito done" + this.agent.cognitoId)

                    //the return of this promise is handled below (in the chained main promise)
                    await this.$tornos.post('agents', {
                        partner: this.partner._id,
                        ...this.agent,
                        passwordTemporary: password
                    })

                } else {
                    sendAlert({
                        event: 'warning',
                        data: cognitoUser,
                        message:
                            'There was an error creating the user in the pool.',
                    })
                    return false
                }

                sendAlert({
                    event: 'info',
                    data: null,
                    message: 'Agent Added Successfully',
                })
                this.initializeAgents() //reload the table
                this.dialogAddAgent = false //closes the dialog
                this.agent = this.agentDefault //clears the form
            } catch (e) {
                //this.$log.info(e)
                sendAlert({
                    event: 'error',
                    data: e,
                    message: 'There was an error saving the agent.',
                })
            } finally {
                setLoading(false)
            }
        },

        sendEmailAgentLogin(agent, password) {
            const passwordToSend = password || agent.passwordTemporary ? agent.passwordTemporary : `Click the 'Forgot Password' on the login page to set a new password`
            setLoading(true)
            this.$tornos
                .post(`email/account/agent`, {
                    agent: agent,
                    password: passwordToSend,
                    division: {
                        name: this.division.name,
                        domain: this.division.domain,
                    },
                })
                .then(() => {
                    this.$log.info(`Agent's account details sent by email`)
                    sendAlert({
                        event: 'success',
                        data: null,
                        message: `Agent's account details sent by email`,
                    })
                }).catch((e) => {
                    sendAlert({
                        event: 'error',
                        data: e,
                        message: `Error sending email`,
                    })
                }).finally(() => {
                    setLoading(false)
                })
        },

        //create new cognito user
        createNewUser(username, password) {
            return new Promise((resolve, reject) => {
                Auth.signUp({ username, password })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
    },

    mounted() {
        if (this.edit) {
            this.isUpdate = true
            this.partner = this.edit
            this.initializeAgents()
        }
    },
}
</script>

<style>
</style>