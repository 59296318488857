import { Hub } from 'aws-amplify';
//const logger = new Logger('NotificationsHubLogger');

//function to send alerts    
const sendAlert = (payload) => {
    Hub.dispatch('Alerts', payload);
}

//function to show or not the loading overlay
const setLoading = (status) => {
    Hub.dispatch('Loading', {event: 'setLoading', data: status, message: 'Set Loading Status'});
}

//function to scroll down to the group landing page where there's the items ref
const scrollToItems = (status) => {
    Hub.dispatch('Scroll', {event: 'scrollToItems', data: status, message: 'Scroll to Items'});
}

//somente export, assim preciso acessar via . na onde importo (service.function())
export {
    sendAlert,
    setLoading,
    scrollToItems
}