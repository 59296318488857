<template>
  <div id="register">
    <v-row>
      <v-col>
        <v-card outlined>
          <v-container>
            <v-card-title>{{labelSubmitBtn}}</v-card-title>

            <ValidationObserver v-slot="{ invalid, handleSubmit }">
              <v-card-text>
                <v-form @submit.prevent="handleSubmit(submit)">
                  <div class="pa-2">
                    <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
                      <v-text-field
                        outlined
                        label="Title"
                        name="Title"
                        type="text"
                        v-model="waiverTemplate.title"
                        :messages="errors[0]"
                        counter="60"
                        hint="Waiver Title"
                      />
                    </ValidationProvider>
                  </div>

                  <div class="pa-2">
                    <label>Header (optional)</label>
                    <ValidationProvider name="Body" v-slot="{ validate, errors }">
                      <!-- Use the component in the right place of the template -->
                      <QuillEditorLola
                        placeholder="Header text (this shows before the body text) ..."
                        :folder="folder"
                        storageLevel="protected"
                        name="Header"
                        v-model="waiverTemplate.header"
                        :content="waiverTemplate.header"
                        rows="20"
                        counter="200"
                        :messages="errors[0]"
                        @editorChange="validate($event.html)"
                      />
                    </ValidationProvider>
                  </div>

                  <div class="pa-2">
                    <label>Body of the template</label>
                    <ValidationProvider name="Body" rules="required" v-slot="{ validate, errors }">
                      <!-- Use the component in the right place of the template -->
                      <QuillEditorLola
                        placeholder="Body text of the waiver...."
                        :folder="folder"
                        storageLevel="protected"
                        minHeight="250"
                        v-model="waiverTemplate.body"
                        :content="waiverTemplate.body"
                        name="Body"
                        :messages="errors[0]"
                        @editorChange="validate($event)"
                      />
                    </ValidationProvider>
                  </div>

                  <div class="pa-2">
                    <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
                      <v-textarea
                        outlined
                        label="Electronic Consent"
                        name="Electronic Consent"
                        v-model="waiverTemplate.consent"
                        hint="The agreement in which the traveller/parent/guardian is agreeing"
                        :messages="errors[0]"
                      />
                    </ValidationProvider>
                  </div>

                  <div class="pa-2">
                    <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
                      <v-text-field
                        class="mb-n4"
                        outlined
                        label="Signature Description"
                        name="Signature Description"
                        type="text"
                        v-model="waiverTemplate.signatureDescription"
                        hint="The text to display on the default signature field. At least one signature is required on a waiver"
                        :messages="errors[0]"
                      />
                    </ValidationProvider>                   
                  </div>

                  <div class="pa-2">
                    <p class="body-1">Choose the waiver type (for which age group is this waiver)</p>
                    <ValidationProvider name="Legal Age" rules="required" v-slot="{ errors }">
                      <v-radio-group 
                        v-model="waiverTemplate.waiverType" 
                        row
                        :messages="errors[0]"
                      >
                        <v-radio :label="`Minor (younger than ${waiverTemplate.legalAge})`" value="minor"></v-radio>
                        <v-radio :label="`Adult (equal or older than ${waiverTemplate.legalAge})`" value="adult"></v-radio>
                      </v-radio-group>
                    </ValidationProvider>                    
                  </div>


                  <div class="pa-2">
                    <ValidationProvider name="Legal Age" rules="required" v-slot="{ errors }">
                      <v-text-field
                        class="mb-n4"
                        outlined
                        label="Legal age"
                        name="Legal age"
                        type="text"
                        v-model="waiverTemplate.legalAge"
                        hint="The age in which a traveller is considered to be of legal age"
                        :messages="errors[0]"
                      />
                    </ValidationProvider>                    
                  </div>

                  <!-- ####### CUSTOM FIELDS ###### #### -->
                  <div class="pa-2">
                    <label class="subtitle-1">Custom Fields</label>
                    <br />
                    <v-btn color="success" @click="addField()">
                      Add new field
                      <v-icon small right>fa fa-plus-circle</v-icon>
                    </v-btn>

                    <div class="mt-4">
                      <!-- https://vuejs.org/v2/guide/components-custom-events.html#sync-Modifier -->
                      <div v-for="(field) in waiverTemplate.fields" :key="field.counter">
                        <v-card class="pb-4">
                          <v-card-text class="mt-4">
                            <CustomFieldWaiverTemplate v-bind:field.sync="field"></CustomFieldWaiverTemplate>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn color="error" @click="removeField(field.counter)" small>
                              Remove field
                              <v-icon small right>fa fa-minus-circle</v-icon>
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </div>
                  </div>
                </v-form>

                <v-divider></v-divider>
              </v-card-text>
              <v-card-actions>
                <v-btn color="default" light large to="/companies/divisions/waivers/templates">
                  <v-icon left small>fa fa-arrow-left</v-icon>Go back
                </v-btn>

                <v-spacer />
                <v-btn color="primary white--text" large :disabled="invalid" @click="submit">
                  {{labelSubmitBtn}}
                  <v-icon right small>fa fa-check</v-icon>
                </v-btn>
              </v-card-actions>
            </ValidationObserver>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import QuillEditorLola from '@/components/QuillEditorLola'
import CustomFieldWaiverTemplate from '@/components/CustomFieldWaiverTemplate'
import { sendAlert, setLoading } from '@/store/notifications-hub'
import uuidv4 from 'uuid/v4'
import filtersMixin from '@/mixins/filters-mixin.js'

export default {
  name: 'WaiverTemplatesCreate',
  mixins: [filtersMixin],
  props: {
    edit: {
      type: Object,
      required: false
    }
  },
  components: {
    QuillEditorLola,
    CustomFieldWaiverTemplate
  },
  data() {
    return {
      isUpdate: false,
      counter: 0, //used in the remove array to be an unique index (in the for) so it removes the correct element (binding to component is unique, index can become the same when you delete an item from the array)
      waiverTemplate: {
        title: '',
        header: '',
        body: '',
        signatureDescription: 'Parent or Guardian Signature',
        consent: 'By checking here, you are consenting to the use of your electronic signature in lieu of an original signature on paper. You have the right to request that you sign a paper copy instead. By checking here, you are waiving that right. After consent, you may, upon written request to us, obtain a paper copy of an electronic record. No fee will be charged for such copy and no special hardware or software is required to view it. Your agreement to use an electronic signature with us for any documents will continue until such time as you notify us in writing that you no longer wish to use an electronic signature. There is no penalty for withdrawing your consent. You should always make sure that we have a current email address in order to contact you regarding any changes, if necessary.',
        fields: [],
        legalAge: 19,
        waiverType: 'minor'
      },
      waiverTemplateDefault: {
        title: '',
        header: '',
        body: '',
        signatureDescription: 'Parent or Guardian Signature',
        consent: 'By checking here, you are consenting to the use of your electronic signature in lieu of an original signature on paper. You have the right to request that you sign a paper copy instead. By checking here, you are waiving that right. After consent, you may, upon written request to us, obtain a paper copy of an electronic record. No fee will be charged for such copy and no special hardware or software is required to view it. Your agreement to use an electronic signature with us for any documents will continue until such time as you notify us in writing that you no longer wish to use an electronic signature. There is no penalty for withdrawing your consent. You should always make sure that we have a current email address in order to contact you regarding any changes, if necessary.',
        fields: [],
        legalAge: 19,
        waiverType: 'minor'
      }
    }
  },
  computed: {
    labelSubmitBtn: function () {
      return this.isUpdate ? 'Update Waiver Template' : 'Create Waiver Template'
    },
    folder: function () {
      return `${this.division.company._id}/${this.division.shortname}/waivertemplates`
    }
  },
  methods: {
    submit() {
      setLoading(true) //loading overlay

      if (this.isUpdate) {
        //this.$log.info("Update")
        this.$tornos.put(`waivertemplates/${this.waiverTemplate._id}`, this.waiverTemplate).then(res => {

          if (res.status === 200) {
            sendAlert({ event: 'info', data: res.body, message: 'Updated Successfully' })
          } else {
            sendAlert({ event: 'warning', data: res.body, message: 'Error updating entity. Please try again' })
          }

        }).catch(e => {
          sendAlert({ event: 'danger', data: e, message: 'There was an error' })
        }).finally(() => {
          setLoading(false) //loading overlay
        })

      } else {
        //this.$log.info("Create")
        this.$tornos.post("waivertemplates", {
          division: this.division,
          createdBy: this.loggedUser.user.email,
          uuid: uuidv4(),
          ...this.waiverTemplate
        }
        ).then(res => {

          if (res.status === 200) {
            sendAlert({ event: 'info', data: res.body, message: 'Created Successfully' })
            this.$router.push({ name: 'waiver.templates.list' })
          } else {
            sendAlert({ event: 'warning', data: res.body, message: 'Error creating entity. Please try again' })
          }

        }).catch(e => {
          sendAlert({ event: 'danger', data: e, message: 'There was an error' })
        }).finally(() => {
          setLoading(false) //loading overlay
        })
      }
    },
    addField() {
      this.counter++
      this.waiverTemplate.fields.push({
        title: '',
        type: 'text',
        counter: this.counter
      })
    },
    removeField(counter) {
      //filters array getting all but the counter being removed
      this.waiverTemplate.fields = this.waiverTemplate.fields.filter(f => f.counter !== counter)
    }
  },

  created() {
    //if edit exists sets in the data 
    //this is used when user clicks to edit a row in the tabel (list)
    if (this.edit) {
      this.isUpdate = true
      this.waiverTemplate = this.edit
      this.counter = this.waiverTemplate.fields.length //length is the current size
      this.waiverTemplate.fields.map((e,i) => e.counter = i) //sets a counter index so it can be removed in the dom
    }
  }

}
</script>

<style>
</style>