<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        persistent
        no-click-animation
    >
        <v-card>
            <v-toolbar dark color="primary" elevation="2">
                <v-toolbar-title class="px-2"
                    >Waivers - {{ item.item.title }} {{ item.item.title }} - FH
                    #{{ item.item.fareharborBooking.pk }}
                </v-toolbar-title>

                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    bottom
                    color="cyan lighten-3"
                ></v-progress-linear>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-divider vertical></v-divider>

                    <v-btn dark text @click="cancel()">
                        <v-icon left small>fa fa-times</v-icon>Close
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-row class="ma-0">
                <v-col>
                    <v-container fluid>
                        <v-sheet elevation="1" class="pa-4">
                            <div class="pa-2">
                                <h4>Waivers in this booking</h4>
                                <p class="caption">
                                    These are waivers that are already linked to
                                    the booking.
                                </p>
                                <v-simple-table :fixed-header="false">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Title
                                                </th>
                                                <th class="text-left">
                                                    Status
                                                </th>
                                                <th class="text-left">
                                                    Traveller
                                                </th>
                                                <th class="text-left">
                                                    Traveller Age
                                                </th>
                                                <th class="text-left">
                                                    Traveller Email
                                                </th>
                                                <th class="text-left">
                                                    Start Date
                                                </th>
                                                <th class="text-left">
                                                    End Date
                                                </th>
                                                <th class="text-left">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="waiver in waivers"
                                                :key="waiver._id"
                                            >
                                                <td>
                                                    {{
                                                        waiver.waiverTemplate
                                                            .title
                                                    }}
                                                </td>
                                                <td>
                                                    <v-chip
                                                        color="success"
                                                        v-if="waiver.isSigned"
                                                        small
                                                        >Signed</v-chip
                                                    >
                                                    <v-chip
                                                        color="warning"
                                                        v-if="!waiver.isSigned"
                                                        small
                                                        >Not Signed</v-chip
                                                    >
                                                </td>
                                                <td>
                                                    {{ waiver.traveller.name }}
                                                </td>
                                                <td>
                                                    {{ waiver.traveller.age }}
                                                </td>
                                                <td>
                                                    {{ waiver.traveller.email }}
                                                </td>
                                                <td>
                                                    {{
                                                        waiver.item.startDate
                                                            | moment(
                                                                'timezone',
                                                                waiver.timezone,
                                                                'DD-MMM-YYYY HH:mm:ss z'
                                                            )
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                        waiver.item.endDate
                                                            | moment(
                                                                'timezone',
                                                                waiver.timezone,
                                                                'DD-MMM-YYYY HH:mm:ss z'
                                                            )
                                                    }}
                                                </td>
                                                <td>
                                                    <v-tooltip bottom>
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                            }"
                                                        >
                                                            <v-icon
                                                                v-on="on"
                                                                small
                                                                class="mr-2"
                                                                @click="
                                                                    view(waiver)
                                                                "
                                                                >fa-external-link-alt</v-icon
                                                            >
                                                        </template>
                                                        <span>View Waiver</span>
                                                    </v-tooltip>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>

                                <div>
                                    <v-btn
                                        :loading="loading3"
                                        :disabled="loading3"
                                        color="blue-grey"
                                        class="ma-2 white--text"
                                        @click="sendEmailWaivers"
                                    >
                                        Send waivers email
                                        <v-icon right dark small>
                                            fa-envelope-open-text
                                        </v-icon>
                                    </v-btn>
                                </div>

                                <v-divider class="my-8"></v-divider>
                                <h4>Waivers not in this booking</h4>
                                <p class="caption">
                                    These are waivers that are set in the
                                    availability but are not yet linked to the
                                    booking.
                                </p>
                                <v-simple-table :fixed-header="false">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Title
                                                </th>
                                                <th class="text-left">
                                                    Created at
                                                </th>
                                                <th class="text-left">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="waiverTemplate in missingWaiverTemplates"
                                                :key="waiverTemplate._id"
                                            >
                                                <td>
                                                    {{ waiverTemplate.title }}
                                                </td>
                                                <td>
                                                    {{
                                                        waiverTemplate.createdAt
                                                            | moment(
                                                                'timezone',
                                                                currentTimezone,
                                                                'DD-MMM-YYYY HH:mm:ss z'
                                                            )
                                                    }}
                                                </td>
                                                <td>
                                                    <v-btn
                                                        color="primary"
                                                        fab
                                                        depressed
                                                        x-small
                                                        dark
                                                    >
                                                        <v-tooltip bottom>
                                                            <template
                                                                v-slot:[`activator`]="{
                                                                    on,
                                                                }"
                                                            >
                                                                <v-icon
                                                                    v-on="on"
                                                                    small
                                                                    @click="
                                                                        linkWaiverToBooking(
                                                                            waiverTemplate
                                                                        )
                                                                    "
                                                                    >fa-link</v-icon
                                                                >
                                                            </template>
                                                            <span
                                                                >Link waiver to
                                                                booking</span
                                                            >
                                                        </v-tooltip>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </v-sheet>
                    </v-container>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import { sendAlert, setLoading } from '../../../store/notifications-hub'
import filtersMixin from '@/mixins/filters-mixin.js'
import generate from 'nanoid/generate'

export default {
    name: 'ModalWaivers',
    mixins: [filtersMixin],
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data: function() {
        return {
            loading: false,
            dialog: true,
            message: {
                type: 'info',
                message: '',
            },
            availability: null,
            waivers: [],
            missingWaiverTemplates: [], // waiver templates that are in the availability but don't exist yet for this booking
        }
    },
    filters: {},
    methods: {
        view(item) {
            let routeData = this.$router.resolve({
                name: 'waivers.sign',
                params: { id: item._id },
            })
            window.open(routeData.href, '_blank')
        },
        generateNanoId(prefix) {
            let nano = generate('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 8)
            return `${prefix}${nano}`
        },

        cancel() {
            this.$router.push({ name: 'bookings.list' })
        },

        async linkWaiverToBooking(waiverTemplate) {
            try {
                setLoading(true)

                let waiversToSave = []
                let lolaItem = { ...this.item.item }
                lolaItem.availability = this.availability

                lolaItem.travellers.forEach(traveller => {
                    if (traveller.age) {
                        //traveller is minor
                        if (
                            waiverTemplate.waiverType === 'minor' &&
                            Number(traveller.age) < waiverTemplate.legalAge
                        ) {
                            waiversToSave.push({
                                lolaId: this.generateNanoId('WA'),
                                traveller: {
                                    name: `${traveller?.firstname ||
                                        ''} ${traveller?.lastname || ''}`,
                                    age: traveller.age,
                                    email: '',
                                    status: 'minor',
                                },
                                isSigned: false,
                                waiverTemplate: waiverTemplate._id,
                                order: this.item.order._id,
                                item: {
                                    name: lolaItem.title,
                                    startDate:
                                        lolaItem.availability.fareharborDetails
                                            .startDate,
                                    endDate:
                                        lolaItem.availability.fareharborDetails
                                            .endDate,
                                    headline: lolaItem.availability.headline
                                        ? lolaItem.availability.headline
                                        : lolaItem.headline
                                        ? lolaItem.headline
                                        : lolaItem.fareharborItem.headline, //if it exists in the availability we get, else we check if exists in the item and we get, else we get from FH
                                },
                                timezone: this.groupTimezone,
                            })

                            //traveller is adult
                        } else if (
                            waiverTemplate.waiverType === 'adult' &&
                            Number(traveller.age) >= waiverTemplate.legalAge
                        ) {
                            waiversToSave.push({
                                lolaId: this.generateNanoId('WA'),
                                traveller: {
                                    name: `${traveller?.firstname ||
                                        ''} ${traveller?.lastname || ''}`,
                                    age: traveller.age,
                                    email: '',
                                    status: 'adult',
                                },
                                isSigned: false,
                                waiverTemplate: waiverTemplate._id,
                                order: this.item.order._id,
                                item: {
                                    name: lolaItem.title,
                                    startDate:
                                        lolaItem.availability.fareharborDetails
                                            .startDate,
                                    endDate:
                                        lolaItem.availability.fareharborDetails
                                            .endDate,
                                    headline: lolaItem.availability.headline
                                        ? lolaItem.availability.headline
                                        : lolaItem.headline
                                        ? lolaItem.headline
                                        : lolaItem.fareharborItem.headline, //if it exists in the availability we get, else we check if exists in the item and we get, else we get from FH
                                },
                                timezone: this.groupTimezone,
                            })
                        }
                    } //end if custom field age
                }) //end for each traveller

                if (waiversToSave && waiversToSave.length > 0) {
                    //saves in db
                    for (const waiverToSave of waiversToSave) {
                        await this.$tornos.post(`waivers`, waiverToSave)
                    }
                    this.refresh()
                    this.message = {
                        type: 'success',
                        message: `Waiver linked successfully to booking`,
                    }
                } else {
                    this.message = {
                        type: 'info',
                        message: `No waivers were created. This can be due to a mismatch between the waiver's and travellers' age.`,
                    }
                }
            } catch (e) {
                this.message = {
                    type: 'warning',
                    message: `There was an error linking the waiver. Details: ${e}`,
                }
            } finally {
                sendAlert({
                    event: this.message.type,
                    data: null,
                    message: this.message.message,
                })
                setLoading(false)
            }
        },
        async refresh() {
            setTimeout(async () => {
                // resets the data fields
                this.missingWaiverTemplates = []
                this.waivers = []

                await this.initialize()
            }, 500)
        },
        async initialize() {
            try {
                // gets the availability of this item (booking)
                let res = await this.$tornos.get(
                    `availabilities?_id=${this.item.item.availability}&populate=waiverTemplates`
                )

                // ##### Populates waivers based on availablity id #####
                // this was the first implementation i created, i believe the idea behind it was to be able to display 'missing waivers' that werent associated with the booking (mostly for waiver templates added to a tour after bookings had happened)
                if(res && res.data && res.data.length > 0) {

                    this.availability = res.data[0]

                    // based on the availability's waiver templates and order id, grabs the waivers of this booking
                    // if the waiver doesn't exist, it means it needs to be added
                    this.availability.waiverTemplates.forEach(async element => {
                        res = await this.$tornos.get(
                            `waivers?waiverTemplate=${element._id}&order=${this.item.order._id}`
                        )
                        if (res.data && res.data.length > 0) {
                            let waiver = { ...res.data[0], waiverTemplate: element }
                            this.waivers.push(waiver)
                        } else {
                            // waiver exists in availability but not in 'waivers' collections
                            this.missingWaiverTemplates.push(element)
                        }
                    })

                }else {
                // ##### Populates waivers based on order
                // using -this method it won't be able to tell if there are any 'missing waiver templates' from the booking, because it will only get already created waivers for the booking (it should be ok now that we create the waivers on the webhook)
                    let resWaivers = await this.$tornos.get(`waivers?order=${this.item.order._id}&populate=waiverTemplate`)
                    this.waivers = resWaivers.data
                }
            } catch (e) {
                sendAlert({
                    event: 'warning',
                    data: e,
                    message:
                        'There was an error fetching the waivers from this booking. Please try again',
                })
                this.cancel() // closes modal
            }
        },
        sendEmailWaivers() {
            setLoading(true)

            this.$tornos
                .post(`email/orders/${this.item.order._id}/waivers`)
                .then(() => {
                    this.$log.info('Email order waivers sent')
                    sendAlert({
                        event: 'success',
                        data: null,
                        message: 'Email sent successfully.',
                    })
                })
                .catch(e => {
                    sendAlert({
                        event: 'error',
                        data: e,
                        message: `Error sending email. Details: ${e}`,
                    })
                })
                .finally(() => {
                    setLoading(false)
                })
        },
    },
    async mounted() {
        this.initialize()
    },
}
</script>
