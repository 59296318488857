var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Waivers "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"fa-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.waivers,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.isSigned",fn:function(ref){
var item = ref.item;
return [(item.isSigned)?_c('v-chip',{attrs:{"color":"success","small":""}},[_vm._v("Signed")]):_c('v-chip',{attrs:{"color":"warning","small":""}},[_vm._v("Not signed")])]}},{key:"item.item.startDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.item.startDate,'timezone', _vm.partnerTimezone, 'DD-MMM-YYYY HH:mm:ss z'))+" ("+_vm._s(_vm.partnerTimezone)+")")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.view(item)}}},on),[_vm._v("fa-external-link-alt")])]}}],null,true)},[_c('span',[_vm._v("View Waiver")])])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }