<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
    no-click-animation
  >
    <v-card>
      <v-toolbar dark color="secondary" elevation="2">
        <v-toolbar-title>
          Manage availabilities for item
          <strong>{{item.name}}</strong>
          <span class="subtitle-1 ml-1"> | PK: {{item.fareharborDetails.pk}}</span>          
        </v-toolbar-title>

        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="secondary"
        ></v-progress-linear>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="cancel()">
            <v-icon left small>fa fa-arrow-left</v-icon>Go back
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- data table only shows if item exists -->
      <v-row class="ma-0">
        <v-col>
          <v-container fluid>
            <v-sheet elevation="1" class="pa-4" id="table-availabilities">
              <p class="title">{{item.name}} current availabilities</p>

              <v-data-table
                :headers="headers"
                :items="item.availabilities"
                :search="search"
                :loading="loading"
              >
                <template v-slot:[`item.isCustomDetails`]="{ item }">
                  <span v-if="item.isCustomDetails">Yes</span>
                  <span v-else>No</span>
                </template>

                <template v-slot:[`item.fareharborDetails.startDate`]="{ item }">
                  <span>{{item.fareharborDetails.startDate | moment('timezone', currentTimezone, 'DD-MMM-YYYY HH:mm:ss z')}}</span>
                </template>

                <template v-slot:[`item.fareharborDetails.endDate`]="{ item }">
                  <span>{{item.fareharborDetails.endDate | moment('timezone', currentTimezone, 'DD-MMM-YYYY HH:mm:ss z')}}</span>
                </template>

                <template v-slot:[`item.waiverTemplates`]="{ item }">
                  <span v-for="(template, index) in item.waiverTemplates" :key="index">
                    <span v-if="index+1 % 2 == 0 || index == 0">{{template.title}}</span>
                    <span v-else>, {{template.title}}</span>
                  </span>
                </template>

                <template v-slot:[`item.isHidden`]="{ item }">
                  <v-chip v-if="item.isHidden" color="warning" small>Hidden</v-chip>
                  <v-chip v-else color="success" small>Visible</v-chip>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:[`activator`]="{ on }">
                      <v-icon v-on="on" small class="mr-2" @click.stop="editItem(item)">fa-edit</v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:[`activator`]="{ on }">
                      <v-icon v-on="on" small class="mr-2" @click="deleteItem(item)">fa-trash-alt</v-icon>
                    </template>
                    <span>Remove</span>
                  </v-tooltip>

                  <!-- if it exists and it's true (hidden) we show to show 
                      isHidden eq true means the availability is hidden
                  -->
                  <v-tooltip bottom v-if="item.isHidden === true">
                      <template v-slot:[`activator`]="{ on }">
                          <v-icon
                              v-on="on"
                              small
                              class="mr-2"
                              @click="hideItem(item, false)"
                          >fa-eye</v-icon>
                      </template>
                      <span>Show on landing page</span>
                  </v-tooltip>

                  <!-- else we show to hide -->
                  <v-tooltip bottom v-else>
                      <template v-slot:[`activator`]="{ on }">
                          <v-icon
                              v-on="on"
                              small
                              class="mr-2"
                              @click="hideItem(item, true)"
                          >fa-eye-slash</v-icon>
                      </template>
                      <span>Hide on landing page</span>
                  </v-tooltip>

                </template>
              </v-data-table>
            </v-sheet>
          </v-container>
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col>
          <v-container fluid>
            <v-sheet elevation="2" class="pa-4">
              <v-row>
                <v-col>
                  <v-sheet height="64">
                    <v-toolbar flat color="white">
                      <v-select
                        :items="monthSelect"
                        label="Select month..."
                        dense
                        outlined
                        class="mb-n6"
                        @change="setMonth"
                      ></v-select>
                      <v-btn fab text small color="grey darken-2" @click="prev">
                        <v-icon small>fa-chevron-left</v-icon>
                      </v-btn>
                      <span class="subtitle-1" v-if="start">{{start.date | moment('MMM')}}</span>
                      <v-btn fab text small color="grey darken-2" @click="next">
                        <v-icon small>fa-chevron-right</v-icon>
                      </v-btn>

                      <!-- only shows if it's month view -->
                      <!-- <div v-if="calendarType === 'month'"> -->
                      <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="prevYear"
                        v-if="calendarType === 'month'"
                      >
                        <v-icon>fa-angle-double-left</v-icon>
                      </v-btn>
                      <span
                        v-if="calendarType === 'month' && start"
                        class="subtitle-1"
                      >{{start.date | moment('YYYY')}}</span>
                      <v-btn
                        v-if="calendarType === 'month'"
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="nextYear"
                      >
                        <v-icon>fa-angle-double-right</v-icon>
                      </v-btn>
                      <!-- </div> -->

                      <v-toolbar-title>{{ calendarTitle }}</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <!-- <v-btn
                                                outlined
                                                class="mr-4"
                                                color="grey darken-2"
                                                @click="calendarType = 'month'"
                                            >
                                                Month View
                                                <v-icon right small>fa fa-calendar-alt</v-icon>
                      </v-btn>-->
                      <v-btn
                        outlined
                        class="mr-4"
                        color="grey darken-2"
                        @click="setToday"
                      >Go to Today</v-btn>
                      <v-menu bottom right>
                        <template v-slot:[`activator`]="{ on }">
                          <v-btn outlined color="grey darken-2" v-on="on">
                            View |
                            <span class="ml-1">{{ typeToLabel[calendarType] }}</span>
                            <v-icon right>fa-caret-down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="calendarType = 'day'">
                            <v-list-item-title>Day</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="calendarType = 'week'">
                            <v-list-item-title>Week</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="calendarType = 'month'">
                            <v-list-item-title>Month</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="calendarType = '4day'">
                            <v-list-item-title>4 days</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-toolbar>
                  </v-sheet>

                  <v-sheet height="600">
                    <v-progress-linear
                      :active="loadingCalendar"
                      :indeterminate="loadingCalendar"
                      color="secondary"
                    ></v-progress-linear>
                    <v-calendar
                      ref="calendar"
                      :short-months="false"
                      :short-weekdays="false"
                      v-model="calendarValue"
                      :type="calendarType"
                      :events="calendarEvents"
                      :now="today"
                      event-overlap-mode="column"
                      event-overlap-threshold="5"
                      event-height="30"
                      event-more-text="Click to view availabilities..."
                      :event-color="getEventColor"
                      @click:event="showEvent"
                      @click:more="viewDay"
                      @click:date="viewDay"
                      @change="onChangeCalendar"
                    ></v-calendar>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-sheet>
          
            <!-- ######## RIGHT BOX - IT'S A MODAL NOW ###### -->
            <v-dialog v-model="showRightPanel" max-width="100%" @keydown.esc="$emit('input')" @input="closeRightPanel">
                
              <v-card outlined>
                <v-card-title class="pb-0 grey lighten-4">
                  <v-chip class="my-2 title" :color="currentEvent.color" text-color="white" label>
                    {{item.name}}
                    <span
                      class="ml-1"
                      v-if="availability.headline"
                    >- {{availability.headline}}</span>
                  </v-chip>

                  <v-chip
                    class="subtitle-1 font-weight-medium ml-2"
                    color="default"
                    label
                  >{{availability.fareharborDetails.startDate | moment('DD-MMMM-YYYY HH:mm:ss Z')}}</v-chip>

                  <v-spacer></v-spacer>
                  <!-- Chip if it's editing -->
                  <v-chip class="ma-2 px-5" color="success" text-color="white" v-if="isEdit">
                    <v-icon left small>fa-edit</v-icon>Editing
                  </v-chip>

                  <v-btn color="default" @click="closeRightPanel">Cancel</v-btn>
                </v-card-title>
                <v-card-title class="mt-0 pt-0 mb-5 grey lighten-4"></v-card-title>

                <v-card-subtitle
                  class="pb-0 body-1"
                >Availability PK Fareharbor: {{availability.fareharborDetails.availabilityPk}}</v-card-subtitle>
                <v-card-subtitle
                  class="pt-0 body-1"
                >{{availability.fareharborDetails.startDate | moment('DD-MMM-YYYY HH:mm:ss Z')}} to {{availability.fareharborDetails.endDate | moment('DD-MMM-YYYY HH:mm:ss Z')}}</v-card-subtitle>
                <v-card-text>
                  <div class="pa-2 mb-0">
                    <p class="subtitle-1 font-weight-medium">Waiver Templates</p>
                    <v-autocomplete
                      v-model="availability.waiverTemplates"
                      :items="waiverTemplates"
                      chips
                      label="Waiver Templates"
                      placeholder="Start typing to search..."
                      multiple
                      item-text="title"
                      item-value="_id"
                      outlined
                    ></v-autocomplete>
                    <p>Choose the Waiver Templates required for this availability. If none is required leave it blank</p>
                  </div>

                  <v-row class="pa-2">
                    <v-col cols="12">
                      <p class="subtitle-1 font-weight-medium mb-0">Fareharbor Custom Fields Mapping</p>
                      <p
                        class
                      >These fields are used to map a traveller correctly to a waiver on Lola Groups. The PK is the primary key (numeric id) of the custom field on Fareharbor.</p>
                    </v-col>
                    <v-col>
                      <v-text-field
                        placeholder="Custom Field Age PK"
                        label="Custom Field Age PK"
                        type="number"
                        v-model="availability.customFieldMapping.age"
                        hint="Add the PK of the age custom field from Fareharbor"
                        outlined
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        placeholder="Custom Field First Name PK"
                        label="Custom Field First Name PK"
                        type="number"
                        v-model="availability.customFieldMapping.firstname"
                        hint="Add the PK of the first name custom field from Fareharbor"
                        outlined
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        placeholder="Custom Field Last Name PK"
                        label="Custom Field Last Name PK"
                        type="number"
                        v-model="availability.customFieldMapping.lastname"
                        hint="Add the PK of the last name custom field from Fareharbor"
                        outlined
                      />
                    </v-col>
                  </v-row>

                  <div class="pa-2">
                    <p class="subtitle-1 font-weight-medium">Headline</p>
                    <v-text-field
                      placeholder="Optional headline to show along the tour title"
                      label="Headline (optional)"
                      name="Headline"
                      type="text"
                      v-model="availability.headline"
                      counter="60"
                      hint="Availability Headline - a custom headline to show along the tour title. It overrides the 'Item' headline"
                      outlined
                    />
                  </div>

                  <div class="pa-2">
                    <p class="subtitle-1 font-weight-medium">Custom Details</p>
                    <v-switch
                      v-model="availability.isCustomDetails"
                      label="Would you like to provide custom details and itinerary for this availability? "
                    ></v-switch>
                    <p>It will override the 'Item' custom details (if it were provided)</p>
                  </div>

                  <div class="pa-2" v-if="availability.isCustomDetails === true">
                    <p class="subtitle-1 font-weight-medium">Custom Description</p>
                    <!-- Use the component in the right place of the template -->
                    <QuillEditorLola
                      placeholder="Custom Description ..."
                      :folder="folder"
                      name="Custom Description"
                      storageLevel="protected"
                      v-model="availability.description"
                      :content="availability.description"
                      rows="10"
                      counter="2000"
                      :minHeight="`400`"
                    />
                    <p>This field is the Description that will be shown to customer on the booking page.</p>
                  </div>

                  <div class="pa-2" v-if="availability.isCustomDetails === true">
                    <p class="subtitle-1 font-weight-medium">Custom Itinerary</p>
                    <!-- Use the component in the right place of the template -->
                    <QuillEditorLola
                      placeholder="Custom Itinerary ..."
                      :folder="folder"
                      name="Custom Itinerary"
                      storageLevel="protected"
                      v-model="availability.itinerary"
                      :content="availability.itinerary"
                      rows="10"
                      counter="2000"
                      :minHeight="`400`"
                    />
                    <p>This field is the Itinerary that will be shown to customer on the booking page.</p>
                  </div>
                </v-card-text>
                <v-card-actions class="grey lighten-4 py-4">
                  <v-btn color="default" @click="closeRightPanel">Cancel</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="success" @click="submitEditItem" v-if="isEdit">
                    Submit changes
                    <v-icon small right>fa-check-circle</v-icon>
                  </v-btn>

                  <v-btn color="primary" @click="addAvailability" v-else>
                    Add
                    <v-icon small right>fa-plus-circle</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { sendAlert, setLoading } from '../../../store/notifications-hub'
import moment from 'moment-timezone'
//import uuidv4 from 'uuid/v4'

import QuillEditorLola from '@/components/QuillEditorLola'
import materialColorHash from 'material-color-hash'
import filtersMixin from '@/mixins/filters-mixin.js'

export default {
  name: 'ModalChooseItemAvailabilities',
  mixins: [filtersMixin],
  components: {
    QuillEditorLola
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    group: {
      type: Object,
    },
    from: {
      type: String
    }
  },
  data: function () {
    return {
      search: '',
      headers: [
        //{ text: 'Lola UUID (Availability)', value: '_id' },
        { text: 'Availability PK', value: 'fareharborDetails.availabilityPk' },
        { text: 'Date Start', value: 'fareharborDetails.startDate' },
        { text: 'Date End', value: 'fareharborDetails.endDate' },
        { text: 'Headline', value: 'headline' },
        { text: 'Custom Details', value: 'isCustomDetails' },
        { text: 'Waiver Templates', value: 'waiverTemplates' },
        { text: 'Display', value: 'isHidden' },
        { text: 'Actions', value: 'action', sortable: false },
      ],

      switchCustomDetails: false,
      showRightPanel: false,
      calendarValue: '',
      calendarEvents: [],
      loading: true,
      dialog: true,
      calendarType: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      start: null,
      end: null,

      currentEvent: { color: '' },
      availability: {
        isCustomDetails: false,
        description: '',
        itinerary: '',
        headline: '',
        waiverTemplates: [],
        fareharborDetails: {
          availabilityPk: '',
          startDate: '',
          endDate: ''
        },
        customFieldMapping: {
          age: '',
          firstname: '',
          lastname: ''
        }
      },
      customFieldMapping: {
            age: '',
            firstname: '',
            lastname: ''
        },

      waiverTemplates: [],
      isEdit: false,
      loadingCalendar: false,
      monthSelect: [
        { text: 'January', value: 1 },
        { text: 'February', value: 2 },
        { text: 'March', value: 3 },
        { text: 'April', value: 4 },
        { text: 'May', value: 5 },
        { text: 'June', value: 6 },
        { text: 'July', value: 7 },
        { text: 'August', value: 8 },
        { text: 'September', value: 9 },
        { text: 'October', value: 10 },
        { text: 'November', value: 11 },
        { text: 'December', value: 12 },
      ]
    }
  },

  computed: {
    calendarTitle: function () {
      const { start, end } = this
      if (!start || !end) {
        return ''
      }

      let startDate = moment(start.date).format('YYYY-MM-DD')
      let endDate = moment(end.date).format('YYYY-MM-DD')

      const startMonth = moment(startDate).format('MMM')
      const endMonth = moment(endDate).format('MMM')
      //const suffixMonth = startMonth === endMonth ? '' : endMonth

      const startYear = moment(startDate).format('YYYY')
      const endYear = moment(endDate).format('YYYY')
      const suffixYear = startYear === endYear ? '' : endYear

      const startDay = moment(startDate).format('DD')
      const endDay = moment(endDate).format('DD')

      switch (this.calendarType) {
        case 'month':
          return `${startMonth} ${startYear}`
        case 'week':
        case '4day':
          return `${startMonth} ${startDay} ${startYear} - ${endMonth} ${endDay} ${suffixYear}`
        case 'day':
          return `${startMonth} ${startDay} ${startYear}`
      }

      return ''

    }
  },

  methods: {
    cancel() {
      this.$router.push({ name: 'groups.create.items', params: { groupId: this.group._id, from: this.from } })
    },

    //CALENDAR METHODS
    viewDay({ date }) {
      this.calendarValue = date
      this.calendarType = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    setMonth(monthNumber) {
      this.calendarValue = moment().month(monthNumber - 1).format('YYYY-MM-DD')
    },
    setToday() {
      this.calendarValue = moment().format('YYYY-MM-DD')
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    prevYear() {
      this.$refs.calendar.move(-12)
    },
    nextYear() {
      this.$refs.calendar.next(+12)
    },

    //show event on the sidebar
    showEvent({ event }) {
      //updates the current availability with details from the event being clicked
      //useful for editing or adding because we set the values already in the availability
      this.availability = {
        isCustomDetails: false,
        description: '',
        itinerary: '',
        headline: '',
        waiversTemplates: [],
        fareharborDetails: {
          availabilityPk: event.availability.pk,
          startDate: event.availability.start_at,
          endDate: event.availability.end_at
        },
        customFieldMapping: this.customFieldMapping //sets empty or filled if dct
      }
      this.isEdit = false
      this.currentEvent = event
      this.showRightPanel = true
    },

    onChangeCalendar({ start, end }) {
      this.start = start // moment(start).format('YYYY-MM-DDTHH:mm:ss')
      this.end = end //moment(end).format('YYYY-MM-DDTHH:mm:ss')
      this.initializeFareharbor()
    },

    //cancels adding or editing an item
    closeRightPanel() {
      this.isEdit = false //not editing anymore in case it was
      this.availability = {
        isCustomDetails: false,
        description: '',
        itinerary: '',
        headline: '',
        waiversTemplates: [],
        fareharborDetails: {
          availabilityPk: '',
          startDate: '',
          endDate: ''
        },
        customFieldMapping: {
          age: '',
          firstname: '',
          lastname: ''
        }
      }
      this.currentEvent = { color: '' }
      this.showRightPanel = false //hides panel         
    },

    //saves in the db
    async addAvailability() {

      try {

        setLoading(true)

        //this.$log.info(this.availability)

        this.availability = {
          ...this.availability, //add current fields
          //uuid: uuidv4(),
          //_id: uuidv4(),
          systemType: 'fareharbor'
        }

        //save availability
        let availabilitySaved = await this.$tornos.post("availabilities", this.availability)

        //add availability to item
        this.item.availabilities.push(availabilitySaved.data) //.data because of axios

        //updates item in db
        await this.$tornos.put(`items/${this.item._id}`, this.item)

        sendAlert({ event: 'info', data: null, message: 'Availability added successfully' })

        this.closeRightPanel() //clears and close modal
        this.reloadItem() //reloads the table

      } catch (e) {
        sendAlert({ event: 'error', data: e, message: 'Error adding availability' })
      } finally {
        setLoading(false)
      }

    },

    editItem(availability) {
      this.isEdit = true //editing
      this.availability = availability //sets to the current data
      this.showRightPanel = true //shows panel
      this.currentEvent.color = 'success' //changes chip title of availability
    },



    //edit availability
    submitEditItem() {
      setLoading(true)

      //updates in the db
      this.$tornos.put(`availabilities/${this.availability._id}`, this.availability).then(res => {
        sendAlert({ event: 'info', data: res, message: 'Availability updated successfully' })
        this.closeRightPanel() //closes the panel and sets isEdit to false        
        this.reloadItem() //reloads the item in case the user added templates to it so it's autopopulated                 
      }).catch(e => {
        sendAlert({ event: 'error', data: e, message: 'Error updating availability' })
      }).finally(() => setLoading(false))



    },

    //delete availability from db and updates item
    async deleteItem(availability) {

      this.$dialog
        .confirm(`Would you like to remove the availability ${availability.fareharborDetails.availabilityPk} (Lola UUID: ${availability._id})`, { loader: true })
        .then(async (dialog) => {

          try {
            setLoading(true)

            //gets a new array with all elements except the one being deleted (by _id)
            let arrayToUpdate = this.item.availabilities.filter(e => e._id !== availability._id)

            //updates the item with a new array without the availability being deleted
            this.item.availabilities = arrayToUpdate
            await this.$tornos.put(`items/${this.item._id}`, this.item)

            await this.$tornos.delete(`availabilities/${availability._id}`) //deletes the availability from the db

            sendAlert({ event: 'info', data: null, message: 'Availability deleted successfully.' })

          } catch (e) {
            sendAlert({ event: 'danger', data: e, message: 'There was an error deleting the availability' })
          } finally {
            setLoading(false)
          }

          dialog.close() //closes dialog

          //dialog closed
        }).catch(() => {
          ////this.$log.info("dialog closed")
        })

    },

    //changes the isHidden property
    async hideItem(availability, value) {

      let description = value ? `Would you like to hide the availability ${availability.fareharborDetails.availabilityPk} on the landing page?` : `Would you like to show the availability ${availability.fareharborDetails.availabilityPk} on the landing page?`

      this.$dialog
        .confirm(description, { loader: true })
        .then(async (dialog) => {

          try {
            setLoading(true)

            availability.isHidden = value //sets the new value
            await this.$tornos.put(`availabilities/${availability._id}`, availability) //updated the availability on the db

            this.reloadItem() //reloads
            sendAlert({ event: 'info', data: null, message: 'Availability display status changed successfully.' })

          } catch (e) {
            sendAlert({ event: 'danger', data: e, message: 'There was an error changing the display status of the availability' })
          } finally {
            setLoading(false)
          }

          dialog.close() //closes dialog

          //dialog closed
        }).catch(() => {
          ////this.$log.info("dialog closed")
        })

    },

    initializeFareharbor() {

      this.loadingCalendar = true
      this.calendarEvents = []
      let events = []

      this.$fareharbor.get(
        `/companies/${this.division.company.settings.fareharborShortname}/items/${this.item.fareharborDetails.pk}/availabilities/date-range/${this.start.date}/${this.end.date}/`)
        .then(res => {
          res.data.availabilities.forEach(a => {
            let color = materialColorHash(a.pk.toString(), 900)
            const title = `${this.item.name} - PK: ${a.pk}`
            if (a.start_at && a.end_at) {
              events.push({
                name: title,
                start: a.start_at ? moment(a.start_at).format('YYYY-MM-DD') : '',
                //end: a.end_at ? moment(a.end_at).format('YYYY-MM-DD') : '', commented on november 1st 2020 -- better show only the event on the day it starts to avoid confusion
                color: color.backgroundColor,
                availability: a
              })
            }
          })

          this.calendarEvents = events
        }).catch(e => {
          sendAlert({ event: 'danger', data: e, message: 'There was an error fetching the items/availabilities from Fareharbor' })
        }).finally(() => this.loadingCalendar = false)

    },

    initializeWaivers() {

      let divisionId = this.item.division._id ? this.item.division._id : this.item.division
      this.$tornos.get(`waivertemplates?division=${divisionId}`).then(res => {
        if (res.status === 200) {
          //the header field messes up with vuetify select/autocomplete, so we remove it from the waivers
          this.waiverTemplates = res.data.map(e => {
            delete e.header
            return e
          })
        } else {
          sendAlert({ event: 'warning', data: res.body, message: 'Error fetching the Waiver Templates. Please try again' })
        }
      }).catch(e => {
        sendAlert({ event: 'danger', data: e, message: 'There was an error fetching the Waiver Templates' })
      }).finally(() => {
        this.loading = false
      })

    },

    reloadItem() {
      this.loading = true
      this.$tornos.get(`items/${this.item._id}?populate=availabilities-waiverTemplates`).then(res => {
        this.item = res.data
      }).catch(e => {
        sendAlert({ event: 'danger', data: e, message: 'There was an error fetching the Item' })
      }).finally(() => {
        this.loading = false
      })
    }

  },
  mounted() {
    this.reloadItem()
    this.initializeWaivers()
    this.initializeFareharbor()

    //if the logged user is dct we set those so we always set it to the modal when loaded to avoid having the users to input it
    if(this.division.company.settings.fareharborShortname === 'discovercanadatours'){
        this.customFieldMapping = {
            age: 313066,
            firstname: 314216,
            lastname: 314217
        }
    }
  }
}
</script>
<style>
  .v-calendar .v-event-more {
    font-size: 16px !important;
    color: #ff9800 !important;
  }
</style>