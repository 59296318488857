import uuidv4 from 'uuid/v4' //to generate a random id to prepend at the file name
import { Storage} from 'aws-amplify'

export default {
    methods: {
        
      uploadFile: function (file, options) {
        return new Promise((resolve, reject) => {

            let folder = 'default'
            if(options.folder && options.folder !== undefined &&  options.folder !== ''){
                folder = options.folder
            }

            //s3 object level (permissions)
            let level = 'public'
            if(options.level && options.level !== undefined &&  options.level !== ''){
                level = options.level
            }

            let filename = ''
            //if filename is not passed we create from the file
            if(!options.filename || options.filename === undefined || options.filename === ''){
                let f = file.name
                f = f.replace(/\s/g, '') //removes whitespaces
                f = f.toLowerCase()
                filename = uuidv4() + '_' + f         
            }else{
                let ext = file.name.split('.').pop()
                filename = options.filename + "." + ext //extension at the end    
            }

            //saves the file and gets its URL using storage.get
            //the file to be public accessible needs the level:public
            //the get() file will get a signed URL
            //helped-> https://medium.com/@anjanava.biswas/uploading-files-to-aws-s3-from-react-app-using-aws-amplify-b286dbad2dd7
            //Changed policy for public for get objects in public and protected folder in the cloudformation template -> https://medium.com/floom/public-read-access-for-aws-amplify-storage-9eb5621abff
            Storage.put(`${folder}/${filename}`, file, {
                level: level,
                contentType: file.type
            })
            .then (async (result) => {
                let url = await Storage.get(result.key, { level: level })
                url =  url.split('?')[0] //pega tudo antes do param ? 
                ////this.$log.info("FILE UPLOADED url: " + url)
                resolve(url)
            }) // {key: "test.txt"}
            .catch(err => reject(err))
            
        })
      },

      removeFile: function (fileUrl) {
        return new Promise((resolve, reject) => {

            let key = ''
            let level = 'public'
            //gets the key from any folder
            //the key is the path after the public, private or protected folder
            //ex: 'partners/d69bb3f9-7783-4b8c-b98f-c25b927f58a4_premium.png'
            if(fileUrl.includes('public/')){
                key = fileUrl.split('?')[0].split('public/')[1]
            }else if(fileUrl.includes('protected/')){
                level = 'protected'
                key = fileUrl.split('?')[0].split('protected/')[1]
            }else if(fileUrl.includes('private/')){
                level = 'private'
                key = fileUrl.split('?')[0].split('private/')[1]
            }

            //this.$log.info("remove: " + key)

            Storage.remove(key, {level: level})
            .then(() => {
                //this.$log.info("file remove")
                //this.$log.info(res)
                resolve(true)                
            })
            .catch(() => {
                //this.$log.info("error delete file: ")
                //this.$log.info(err)
                reject(false)
            })
        })
      }
      
    }
}