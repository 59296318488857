<template>
    <div>
        <v-row>
            <v-col>
                <v-card outlined class="mx-auto">
                    <v-card-title>
                        Waivers
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            append-icon="fa-search"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="waivers"
                            :search="search"
                            :loading="loading"
                        >
                            <template v-slot:[`item.isSigned`]="{ item }">
                                <v-chip color="success" v-if="item.isSigned" small>Signed</v-chip>
                                <v-chip color="warning" v-else small>Not signed</v-chip>
                            </template>

                            <template v-slot:[`item.action`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:[`activator`]="{ on }">
                                        <v-icon v-on="on" small class="mr-2" @click="view(item)"
                                            >fa-external-link-alt</v-icon
                                        >
                                    </template>
                                    <span>View Waiver</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { sendAlert } from '@/store/notifications-hub'
export default {
    name: 'CustomerWaivers',
    data: function() {
        return {
            loading: true,
            waivers: [],
            search: '',
            headers: [
                { text: 'Trip', value: 'item.name' },
                { text: 'Status', value: 'isSigned' },
                { text: 'Traveller', value: 'traveller.name' },
                { text: 'Age', value: 'traveller.age' },
                { text: 'Waiver', value: 'waiverTemplate.title' },
                { text: 'Actions', value: 'action', sortable: false },
            ],
        }
    },
    computed: {
        loggedUser: function() {
            return this.$store.getters.loggedUser
        },
    },
    methods: {
        view(item) {
            //https://stackoverflow.com/questions/40015037/can-vue-router-open-a-link-in-a-new-tab
            let routeData = this.$router.resolve({ name: 'waivers.sign', params: { id: item._id } })
            window.open(routeData.href, '_blank')
        },
    },
    async mounted() {
        try {
            let id = this.loggedUser.user._id

            // gets order ids
            let res = await this.$tornos.post(`orders/query`, {
                filter: {
                    customer: id,
                },
                select: ['_id'],
            })
            const orderIds = res.data

            if (orderIds) {
                let populate = [
                    {
                        path: 'waiverTemplate',
                        select: ['title'],
                    },
                ]
                let select = ['traveller.name', 'traveller.age', 'item.name', '_id', 'isSigned']
                let filter = { order: { $in: orderIds } }
                let resWaivers = await this.$tornos.post(`waivers/query`, {
                    populate,
                    select,
                    filter,
                })
                this.waivers = resWaivers.data
            }
        } catch (e) {
            sendAlert({
                event: 'warning',
                data: e,
                message: 'There was an error fetching the waivers. Please try again',
            })
        }
        this.loading = false
    },
}
</script>

<style></style>
