<template>
    <div>
        <v-card class="mx-auto" outlined>
            <v-card-title
                class="justify-center"
            >Booking for {{availability.start_at | moment('timezone', getGroupTimezone, 'DD MMM YYYY HH:mm a' )}}</v-card-title>
            <v-card-subtitle
                class="text-center"
            >
            Here you can add travellers for this trip and fill out their information
            <br>
            When you are done click <strong>Add to Cart</strong> at the bottom of the page
            </v-card-subtitle>
            
            <v-card-text>
                <ValidationObserver ref="formAddToCart" v-slot="{ invalid }" @submit.prevent="addToCart()">
                <v-form>
                    <!-- create the fields given the availability -->

                    <!-- TRAVELLER DIV -->
                    <v-sheet elevation="2" class="my-4 pa-5" v-for="(counter, index) in numberOfTravellers" :key="index">
                        <p class="subtitle-1 font-weight-medium">Traveller {{counter}} 
                            <span v-if="travellers[index].customerTypeRate.obj"> - {{travellers[index].customerTypeRate.obj.customer_prototype.display_name}}</span>
                            <span v-if="travellers[index].customerTypeRate.obj">
                                <span v-if="travellers[index].customerTypeRate.obj.customer_prototype.note"> - {{travellers[index].customerTypeRate.obj.customer_prototype.note}}</span>
                                <span v-else> - {{travellers[index].customerTypeRate.obj.customer_prototype.display_name}}</span>
                            </span>
                        </p>
                        <ValidationProvider
                            name="customer type"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <v-select
                                :items="availability.customer_type_rates"
                                item-text="customer_prototype.note"
                                item-value="pk"
                                outlined
                                :messages="errors"
                                label="Customer type"
                                hint="Please choose the customer type rate..."
                                v-model="travellers[index].customerTypeRate.pk"
                                @change="selectCustomerTypeRate(index)"   
                                ref="selectCustomerTypeRate"                             
                            >
                                <template v-slot:item="{ item }">
                                    <!-- if the customer type rate has the note we show it, else we show the display_name -->
                                    <span v-if="item.customer_prototype.note">{{item.customer_prototype.note}}</span>
                                    <span v-else>{{item.customer_prototype.display_name}}</span>
                                    - {{item.total_including_tax / 100 | currency }}
                                </template>

                                <template v-slot:selection="{ item }">
                                    <!-- if the customer type rate has the note we show it, else we show the display_name -->
                                    <span v-if="item.customer_prototype.note">{{item.customer_prototype.note}}</span>
                                    <span v-else>{{item.customer_prototype.display_name}}</span>
                                    <span class="ml-1"> - {{item.total_including_tax / 100 | currency }}</span>
                                </template>

                            </v-select>
                        </ValidationProvider>

                        <!-- Custom fields - only show after customerTypeRate has been selected -->
                        <div v-if="travellers[index].customerTypeRate.obj">

                            <div
                                class
                                v-for="(customFieldInstance, indexCustomField) in travellers[index].customerTypeRate.obj.custom_field_instances"
                                :key="indexCustomField"
                            >
                                <CustomFieldText
                                    v-if="customFieldInstance.custom_field.type === 'short' || customFieldInstance.custom_field.type === 'long'"
                                    :customField="customFieldInstance.custom_field"
                                    :pk="customFieldInstance.custom_field.pk"
                                    :travellerIndex="index"
                                    v-model="travellers[index].customerTypeRate.fields[indexCustomField]"
                                    @input="inputChange"
                                    :ageMappingPk="availabilityLola.customFieldMapping.age"
                                />

                                <CustomFieldSelect
                                    v-if="customFieldInstance.custom_field.type === 'extended-option'"
                                    :customField="customFieldInstance.custom_field"
                                    :pk="customFieldInstance.custom_field.pk"
                                    :travellerIndex="index"
                                    v-model="travellers[index].customerTypeRate.fields[indexCustomField]"
                                    @input="inputChange"
                                />

                                <CustomFieldRadio
                                    v-if="customFieldInstance.custom_field.type === 'yes-no'"
                                    :customField="customFieldInstance.custom_field"
                                    :pk="customFieldInstance.custom_field.pk"
                                    :travellerIndex="index"
                                    v-model="travellers[index].customerTypeRate.fields[indexCustomField]"
                                    @input="inputChange"
                                />
                            </div>
                        </div>

                        <!-- at least one traveller is required -->
                        <div  v-if="numberOfTravellers > 1">
                            <v-btn text small dark color="blue-grey lighten-2" @click="removeTraveller(index)"><v-icon small left>fa-minus-circle</v-icon> Remove Traveller</v-btn>
                        </div>
                    </v-sheet>
                    <!-- END TRAVELLER DIV -->

                    <v-row>
                        <v-col cols="12" v-if="isShowAddTravellerButton">
                            <v-btn color="success" @click="addTraveller"><v-icon small left>fa-plus-circle</v-icon> Add Traveller</v-btn>
                        </v-col>
                        
                        <v-col cols="12 text-center">
                            <v-sheet elevation="2" class="my-2 pa-5">
                                <div class="title">
                                    <p class="mb-0" v-if="total > 0 || numberOfTravellers > 0">Tours Subtotal: {{total | currency}}</p>
                                    <p v-if="totalExtra > 0 || numberOfTravellers > 0">Optional Activities/Extras: {{totalExtra | currency}}</p>
                                    <p>Total: {{total + totalExtra | currency}}</p>
                                </div>                                
                                <v-btn :disabled="!isAddToCartEnabled || invalid" block color="primary" x-large @click="addToCart"><v-icon  left>fa-cart-arrow-down</v-icon> Add to Cart</v-btn>
                                <p v-if="!isAddToCartEnabled || invalid" class="py-2">Please, fill in all required fields to add to cart</p>
                            </v-sheet>
                        </v-col>
                                                
                    </v-row>

                </v-form>
                </ValidationObserver>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomFieldText from './CustomFieldText'
import CustomFieldSelect from './CustomFieldSelect'
import CustomFieldRadio from './CustomFieldRadio'
import {sendAlert} from '@/store/notifications-hub'

export default {
    name: 'FormAvailability',
    components: {
        CustomFieldText,
        CustomFieldSelect,
        CustomFieldRadio
    },
    props: {
        //this is Fareharbor availability
        availability: {
            type: Object,
            required: true
        },
        //group slug - used in add to cart
        slug: {
            type: String
        },

        //lola item
        item: {
            type: Object,
            required: true
        },

        //availability from lola - it's used to map the age and first and last name fields from Fh to lola and set to the traveller so we know how to create the waivers
        availabilityLola: {
            type: Object,
            required: true
        },

        // used in the add to cart to validate against other items in cart
        group: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters(['getGroupTimezone']),
        isAddToCartEnabled: function(){
            return this.travellers[0].customerTypeRate.pk ? true : false
        },
        isShowAddTravellerButton: function() {
            return this.slug !== 'westtrekparty' // David called me on Dece 9 2021 to hide the 'add traveller' button from the westrek party tour
        },
        ...mapGetters(['getCartGroupId']),
    },

    data: function () {
        return {
            travellers: [
                {
                    customerTypeRate: {
                        pk: '',
                        obj: null,
                        fields: []
                    }                                       
                }
            ],
            numberOfTravellers: 1,
            total: 0,
            totalExtra: 0          
        }
    },

    methods: {

        isFieldAgePresent(customerTypeRate){
            let isPresent = false
            customerTypeRate.custom_field_instances.forEach(e => {
                if(e.custom_field.pk === 313066) isPresent = true
            })
            return isPresent
        },

        selectCustomerTypeRate(index) {
            //get the chosen customer type rate given the index (number of the traveller) and sets in the array creating a new object in the array
            let array = this.availability.customer_type_rates.filter(e => e.pk === this.travellers[index].customerTypeRate.pk) //gets the chosen customer_type_rate
            let customerTypeRate = array[0] //first element in the array
            this.travellers[index].customerTypeRate.obj = customerTypeRate //sets in the array travellers 
            
            //minimum party size - adds more customer to this form
            if(customerTypeRate.minimum_party_size){
                for(let i = 0; i < customerTypeRate.minimum_party_size -1 ; i++){
                    this.addTraveller()
                } 

                //only shows the message if it's more than one required traveller (2 or more)         
                if(customerTypeRate.minimum_party_size > 1){
                    sendAlert({ event: 'info', data: null, message: `This option requires a minimum party of ${customerTypeRate.minimum_party_size} travellers.` })
                }

            }

            this.calculatePrice()
        },
        addTraveller(){
            this.numberOfTravellers++ //counter 
            let emptyNewCustomerTypeRate = {
                customerTypeRate: {
                    pk: '',
                    obj: null,
                    fields: []
                },
                total: 0,
                totalExtra: 0                       
            }
            this.travellers.push(emptyNewCustomerTypeRate)
        },

        removeTraveller(index){
             this.$dialog
                .confirm(`Would you like to remove traveller number ${index+1} ?`, { loader: true })
                .then(async (dialog) => {

                    this.numberOfTravellers-- //counter removes
                    this.travellers.splice(index, 1) //removes from array
                    this.calculatePrice()
                    
                    dialog.close()

                //dialog closed
                }).catch(() => {
                   //cancelled the dialog
                })
            
        },

        //when a field is updated by the user
        inputChange(field){

            //WAVIVERS - we set these values here so we can create the waivers for each specific traveller in a order
            //age to be used in the waivers in the checkout
            // -> the customFieldMapping are the pk of the custom fields on FH that were set in the availability by an employee on the dashboard
            if(field && field.pk === Number(this.availabilityLola.customFieldMapping.age)){
                this.travellers[field.travellerIndex].age = field.value
            }

            //firstname to be used in the waivers in the checkout
            if(field && field.pk === Number(this.availabilityLola.customFieldMapping.firstname)){
                this.travellers[field.travellerIndex].firstname = field.value
            }

            //lastname to be used in the waivers in the checkout
            if(field && field.pk === Number(this.availabilityLola.customFieldMapping.lastname)){
                this.travellers[field.travellerIndex].lastname = field.value
            }

            //updates the price
            this.calculatePrice()
        },

        calculatePrice(){

            let total = 0
            let totalExtra = 0
            if(this.travellers.length > 0){ //only do the calc if there are travellers in the array
                this.travellers.forEach(traveller => {

                    //checks if a customerTypeRate has been selected
                    if(traveller.customerTypeRate.obj){

                        //price base for the customer type rate
                        total += traveller.customerTypeRate.obj.total_including_tax //customer type rate
                        
                        traveller.customerTypeRate.fields.forEach(e => {

                            //amount is in the custom field
                            //se CustomField[].vue files to see calculation
                            //we set the price in the amount poperty when a custom field has a price or an extended option with a price
                            //so we can get here the extra costs - this is for the custom field, not the customer type rate
                            if(e && e.amount){
                                //this.$log.info('amount: ' + e.amount)
                                totalExtra = totalExtra + e.amount
                            }
                        })

                    }
                })
            }//end if travellers length
            
            //divide by 100 because price is in cents in FH
            this.total = total / 100
            this.totalExtra = totalExtra /100
        },

        async addToCart(){

            const isValid = await this.$refs.formAddToCart.validate();
            if (!isValid) {
                sendAlert({ event: 'warning', data: null, message: 'One or more required fields are missing. Please try again.' })
                return
            }

            // validate if user is adding item belonging to same group 
            // this could happen if the user has opened two tabs with different groups, so we prevent him from buying items from different groups
            if(this.getCartGroupId !== null && this.getCartGroupId !== this.group._id) {
                sendAlert({ event: 'warning', data: null, message: 'You already have an item in your cart from another group. Please, remove all items from your cart to continue.' })
                return
            }

            let availability = null
            if(this.item.availabilities){
                availability = this.item.availabilities.filter(e => e.fareharborDetails.availabilityPk === this.availability.pk.toString()) //gets the current availability 
            }

            this.travellers.forEach(t => {
                t.customerTypeRate.fields = t.customerTypeRate.fields.filter((f) => {
                    //if field value exists we return the field  
                    //we do this to set only fields selected by the customer and avoid adding the field she select as '-' (empty extend field option)
                    if(f && f.value){
                        return f
                    }
                })                
            })

            let item = this.item //gets the item
            //item.availabilities = [] //removes all other availabilities
            
            this.$store.dispatch('addToCart', {availability: availability[0], item: item, travellers: this.travellers, groupId: this.group._id})
            sendAlert({ event: 'info', data: null, message: 'Added to cart' })
            if(this.slug){ //only redirect if slug is present
                this.$router.push({name: 'group.booking.flow', params: {slug: this.slug, stepProp: 2}})
            }
        }
    },

    mounted(){
        //minimum party size at the AVAILABILITY level
        //this is required independent from the customer type rate, it's in the availability
        //if it exists we add more travellers to this form
        //1 traveller is always required so we do the minus 1 there (2 minimum is 1 plus one more)
        if(this.availability.minimum_party_size){            
            for(let i = 0; i < this.availability.minimum_party_size -1 ; i++){
                this.addTraveller()
            }   
            //only shows the message if it's more than one required traveller (2 or more)         
            if(this.availability.minimum_party_size > 1){
                sendAlert({ event: 'info', data: null, message: `This trip requires a minimum party of ${this.availability.minimum_party_size} travellers.` })
            }
        }

        //if this availability only has one customer type rate we set the first option first so the form is already created as the user enters the page
        if(this.availability && this.availability.customer_type_rates && this.availability.customer_type_rates.length === 1){
            this.travellers[0].customerTypeRate.pk = this.availability.customer_type_rates[0].pk
            this.selectCustomerTypeRate(0)
        }
    }
}
</script>

<style>
</style>