<template>
  <div class="px-8 pt-8">
    <v-text-field
      outlined
      label="Field title"
      name="Field title"
      v-model="customField.title"
      hint="The title of the new field"
    />
    <v-select
      outlined
      :items="fieldTypes"
      item-text="text"
      item-value="value"
      label="Field type"
      name="Field type"
      v-model="customField.type"
      hint="The type of the new field"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "CustomFieldWaiverTemplate",
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      fieldTypes: [
        {
          value: 'date',
          text: 'Date'
        },
        {
          value: 'text',
          text: 'Text'
        },
        {
          value: 'signature',
          text: 'Signature'
        },
      ],
      customField: this.field,
    }
  }

}
</script>

<style>
</style>