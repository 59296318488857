<template>
  <div>
    <v-row>
      <v-col>
        <v-card outlined class="mx-auto">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" :to="`/companies/divisions/waivers/templates/create`">
              Create New Template
              <v-icon right>fa fa-plus-circle</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <!-- <v-skeleton-loader
                class="mx-auto"
                type="table-heading, table-thead, table-tbody, table-tfoot"
            ></v-skeleton-loader>-->
            <v-data-table :headers="headers" :items="items" :search="search" :loading="loading">

              <template v-slot:[`item.createdAt`]="{ item }">
                    <span>{{item.createdAt | moment('timezone', currentTimezone, 'DD-MMM-YYYY HH:mm:ss z')}}</span>
                </template>

                <template v-slot:[`item.updatedAt`]="{ item }">
                    <span>{{item.updatedAt | moment('timezone', currentTimezone, 'DD-MMM-YYYY HH:mm:ss z')}}</span>
                </template>

              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:[`activator`]="{ on }">
                    <v-icon v-on="on" small class="mr-2" @click="editItem(item)">fa-edit</v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:[`activator`]="{ on }">
                    <v-icon v-on="on" small class="mr-2" @click="deleteItem(item)">fa-trash-alt</v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { sendAlert } from '../../../store/notifications-hub'
import filtesrMixin from '@/mixins/filters-mixin.js'

export default {
  name: "WaiverTemplatesList",
  mixins: [filtesrMixin],
  data: function () {
    return {
      search: '',
      loading: true,
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'UUID', value: 'uuid' },
        { text: 'Created by', value: 'createdBy' },
        { text: 'Date created', value: 'createdAt' },
        { text: 'Last updated', value: 'updatedAt' },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      items: []
    }
  },
  methods: {
    editItem(item) {
      //redirects to the form to tedit
      this.$router.push({ name: 'waiver.templates.create', params: { edit: item } })
    },

    deleteItem(item) {
      //this.dialog = true
      // Trigger a confirmation dialog
      
      this.$dialog
        .confirm('Would you like to delete the item: ' + item.title, {loader: true})
          .then(async (dialog) => {
            let res = null
            try{
              res = await this.$tornos.delete(`waivertemplates/${item._id}`)
            }catch(e){
              sendAlert({ event: 'danger', data: e, message: 'There was an error. Please try again' })
            }

            //success
            if(res.data.status === 200){
              sendAlert({ event: 'success', data: res.body, message: `Deleted: ${item.title}` })         
            }else{
              sendAlert({ event: 'danger', data: res.data, message: 'There was an error. Please try again' })
            }

            dialog.close()

            this.initialize() //re-load the table
          
        //dialog closed
        })
        // .catch(e => {
        //     sendAlert({ event: 'info', data: e, message: 'Cancelled' })
        // })
        

    },

    initialize(){
      this.$tornos.get(`waivertemplates?division=${this.division._id}`).then(res => {
        if (res.status === 200) {
          this.items = res.data
        } else {
          sendAlert({ event: 'warning', data: res.body, message: 'Error fetching. Please try again' })
        }
      }).catch(e => {
        sendAlert({ event: 'danger', data: e, message: 'There was an error' })
      }).finally(() => {
        this.loading = false
      })
    }
  },

  mounted() {
    this.initialize()
  }
}
</script>

<style>
</style>