<template>
  <v-app id="login">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">

            <!--
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Login</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field label="Login" name="login" prepend-icon="fa-user" type="text" />

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="fa-lock"
                    type="password"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="login()">Login</v-btn>
              </v-card-actions>
            </v-card>
            <v-divider></v-divider>
            -->

            <!-- We use AWS Amplify to control all Auth related operations -->
            
            <v-card class="elevation-12">

              <v-toolbar color="primary" dark flat>
                <v-toolbar-title class="capitalize">Lola Groups | Login {{userType}}</v-toolbar-title>                            
              </v-toolbar>              
              
              <v-card-text v-if="isLoggedIn === false">
                <amplify-authenticator v-bind:authConfig="authConfig" class="amplify-auth"></amplify-authenticator>
              </v-card-text>   

              <v-card-text v-else>
                <v-btn @click="$store.dispatch('logout')">Logout</v-btn>
              </v-card-text>              
            </v-card>
            

            
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { components} from 'aws-amplify-vue'
import {Auth} from 'aws-amplify'

export default {
  name: 'Login',
  components: {
    ...components
  },
  props:{
    userType: {
      validator: function (value) { //https://vuejs.org/v2/guide/components-props.html#Prop-Validation
        // The value must match one of these strings
        return ['employees', 'agents', 'customers'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    isLoggedIn: function(){
      return this.$store.getters.isLoggedIn    
    }
  },
  created(){
    //this.$log.info("Mounted: " + this.userType)
    this.$store.dispatch('setUserType', this.userType)
  },
  data: function(){
    return{
      authConfig: {
        signInConfig: {
          header: 'Sign In',
          username: 'email',
          signInFields:{
              label: 'Email',
              key: 'username',
          } 
        },
        signUpConfig: {
          header: 'Sign Up',
          hideAllDefaults: true,
          signUpFields: [
            {
              label: 'Email',
              key: 'username',
              required: true,
              displayOrder: 1,
              type: 'string',
              signUpWith: true
            },
            {
                label: 'Password (min 6 letters/numbers)',
                key: 'password',
                required: true,
                displayOrder: 2,
                type: 'password'
              }
              // ,
              // {
              //   label: 'Phone Number',
              //   key: 'phone_number',
              //   required: false,
              //   displayOrder: 3,
              //   type: 'string'
              // }              
          ]
        }

      }
      
    }
  },

  methods: {
    verifyUserLoggedAmplify(){
      //this.$log.info('verifyUserLoggedAmplify: ' + this.isLoggedIn)
      Auth.currentAuthenticatedUser().then(() => {
        //if there`s an Amplify user but not on the store, we logout to make sure it's all clear
        if(!this.isLoggedIn){
          //this.$log.info('verifyUserLoggedAmplify - logout')
          this.$store.dispatch('logout', {userType: this.userType}) 
        }
      })
      .catch(() => {
        if(!this.isLoggedIn){
          //this.$log.info('verifyUserLoggedAmplify catch - logout')
          this.$store.dispatch('logout', {userType: this.userType}) 
        }
      })
    }
  },
  

  beforeRouteEnter (to, from, next) {
    //if user is logged in he cannot access this page anymore
    next(vm => {
      if(vm.isLoggedIn){          
        next(false)
      }else{
        vm.verifyUserLoggedAmplify()
        next()
      }
    })
    
  }

}
</script>


<style>

:root {
    /* Colors 
      This is to redefine AWS amplify components colors.
      https://github.com/aws-amplify/amplify-js/issues/2471#issuecomment-451550198
    */
    --amazonOrange: #00ACC1; /* Redefined to dark green */
    --lightAmazonOrange: #009688;
    --darkAmazonOrange: #009688;
    --squidInk: #232F3E;
    --lightSquidInk: #31465F;
    --deepSquidInk: #152939;
    --grey: #828282;
    --lightGrey: #C4C4C4;
    --silver: #E1E4EA;
    --darkBlue: #31465F;
    --red: #DD3F5B;
    --white: #FFFFFF;
    --light-blue: #00a1c9;
  }

  .amplify-auth .error{
    margin: 5px 0 5px 0;
    padding: 10px;
    border-radius: 2px;
    color: #FFFFFF;
  }

  .amplify-auth div{
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  
</style>

