<template>
<div>
    <ValidationProvider :name="customField.name" :rules="rules" v-slot="{ errors }">
        <v-select
            :items="customField.extended_options"
            item-text="name"
            item-value="pk"
            :label="label"
            :hint="customField.title"
            @change="update"
            v-model="field.value"       
            outlined 
            :error-messages="errors"   
            :key="`${travellerIndex}-${field.pk}`"                   
        >
            <template v-slot:selection="{ item }">
                {{item.name}}
                <span v-if="item.modifier_kind === 'offset' && item.offset" class="ml-1"> - {{item.offset / 100 | currency}}</span>
            </template>

            <template v-slot:item="{ item }">
                {{item.name}}
                <span v-if="item.modifier_kind === 'offset' && item.offset" class="ml-1"> - {{item.offset / 100 | currency}}</span>
            </template>

        </v-select>  
    </ValidationProvider>
    <span v-if="customField.description_safe_html" v-html="customField.description_safe_html" class="ma-0 pa-0"></span>  
    <span v-if="extendedOption && extendedOption.description_safe_html" v-html="extendedOption.description_safe_html"></span>  <!-- this is the selected extend option html value - if any -->
    
</div>
  
</template>

<script>
export default {
    name: 'CustomFieldSelect',
    props: ['pk', 'customField', 'travellerIndex'],
    data: function(){
        return {
            field: {
                pk: this.pk,                
                customField: this.customField,
                value: '',
                amount: 0,
                travellerIndex: this.travellerIndex
            }            
        }
    },
    computed: {
        extendedOption: function(){
            if(this.field.customField && this.field.customField.extended_options){
                let array = this.field.customField.extended_options.filter(e => e.pk === this.field.value)
                return array[0]                
            }else{
                return null
            }
        },

        //validation rules
        rules: function(){
            return this.customField.is_required ? 'required' : ''
        },

        label: function(){
            if(this.rules.includes('required')){
                return `${this.customField.name} (required)`
            }else{
                return `${this.customField.name}`
            }
        }
    },
    methods: {
        update() {
            //updates the field amount before emmiting
            //this is the extra amount that is used in the add to cart calculate price function
            let extendOptionAmount = 0
            if(this.extendedOption.modifier_kind === "offset" && this.extendedOption.modifier_type !== 'none'){
                extendOptionAmount = this.extendedOption.offset
            }

            //this is an offset (price) in the custom_field level (not in an option, but in the root of the custom_field itself)
            let customFieldAmount = 0
            if(this.field.customField.modifier_kind === "offset" && this.field.customField.modifier_type !== 'none'){
                customFieldAmount = this.customField.offset
            }

            this.field.amount = extendOptionAmount + customFieldAmount
            
            //emits update
            this.$emit('input', this.field)
        },
    },
    mounted(){
        //adds a default option of none in the select so users can `unselect` if they ever chose anything in the select
        //otherwise they'd be stuck at having to select an option from the field extended_options
        if(this.customField.name.includes('Optional Activities')){
            this.customField.extended_options.push({
                name: 'None $ 0',
                pk: null
            })
        }else{
            this.customField.extended_options.push({
                name: '',
                pk: null
            })
        }
        
    }
}
</script>

<style>

</style>