<template>
    <v-main>
        <v-container class="fill-width ma-0 pa-0" fluid >
            <v-row align justify no-gutters>
                <v-col class="mt-0" cols="12" v-if="!order && !loading">
                    <v-card class="ma-0 pa-0 mx-auto" tile>
                        <v-sheet elevation="1" color="grey lighten-4" class="text-center">
                            <!-- <v-card-title class="justify-center headline text--lighten-0">
                                Shopping Cart
                                <v-icon right>fa fa-shopping-cart</v-icon>
                            </v-card-title>-->
                            <v-card-text class="text-center">
                                <div>
                                    <p class="title pa-6">Order not found</p>
                                </div>
                            </v-card-text>
                        </v-sheet>
                    </v-card>
                </v-col>

                <v-col class="mt-0" cols="12" v-if="order">
                    <v-card class="ma-0 pa-0 mx-auto grey lighten-5" outlined>
                        <v-card-text>
                            <v-container>
                                <v-row class="mt-0" no-gutters>
                                    <v-col>
                                        <v-sheet elevation="1" class="pa-5">
                                            <v-row align-content="center">
                                                <v-col>
                                                    <div class="text-center">
                                                        <!-- <p class="display-1">
                                                    <v-icon left color large>fa-file-invoice</v-icon>Order Receipt
                                                        </p>-->
                                                        <p
                                                            class="headline mb-0"
                                                        >
                                                        <span v-if="!order.isPaid">
                                                            Order not yet confirmed.
                                                        </span>
                                                        <span v-else>
                                                            Thank you for booking with us!
                                                        </span>
                                                        </p>
                                                        <p
                                                            class="title mb-0"
                                                        >Order ID: {{order.lolaId}}</p>
                                                        <p
                                                            class="body-2 mb-0"
                                                        >UUID #{{order._id}}</p>

                                                        <p
                                                            class="body-2 mb-0"
                                                        >{{order.createdAt | moment('timezone', order.group.partner.timezone, 'DD-MMM-YYYY HH:mm:ss')}} ({{order.group.partner.timezone | timezoneFilter}})</p>

                                                        <p
                                                            class="body-2 mb-0"
                                                        >Total Paid: CAD {{order.amountPaid | currency}}</p>
                                                        <p
                                                            v-if="order.amountRemaining"
                                                            class="body-2 mb-0"
                                                        >Total Remaining: CAD {{order.amountRemaining | currency}}</p>
                                                        <p
                                                            v-if="order.amountRefunded"
                                                            class="body-2 mb-0"
                                                        >Total Refunded: CAD {{order.amountRefunded | currency}}</p>

                                                        <p v-if="order.isPaid && !order.isRefunded && !order.amountRefunded" class="body-2 mb-0">
                                                            <v-icon
                                                                small
                                                                left
                                                                color="success"
                                                            >fa-check-circle</v-icon>Paid
                                                        </p>

                                                        <p v-if="!order.isPaid" class="body-2 mb-0">
                                                            <v-icon
                                                                small
                                                                left
                                                                color="warning"
                                                            >fa-times-circle</v-icon>Not Paid
                                                        </p>
                                                       
                                                        <p v-if="!order.isRefunded && order.amountRefunded && order.amountRefunded > 0" class="body-2 mb-0">
                                                            <v-icon
                                                                small
                                                                left
                                                                color="warning"
                                                            >fa-undo</v-icon>Partially Refunded
                                                        </p>
                                                        
                                                        <p v-if="order.isRefunded" class="body-2 mb-0">
                                                            <v-icon
                                                                small
                                                                left
                                                                color="warning"
                                                            >fa-undo</v-icon>Refunded
                                                        </p>
                                                        

                                                        <v-divider inset class="my-2"></v-divider>
                                                        <p class="body-2 mb-2">
                                                            {{order.customer.name}}
                                                            <br />
                                                            {{order.customer.email}}
                                                            <br />
                                                            {{order.customer.phone}}
                                                        </p>
                                                    </div>
                                                </v-col>
                                                <v-col>
                                                    <div class="text-center">
                                                        <v-img
                                                            class="ma-0 pa-0"
                                                            v-if="order.group.partner.logoUrl"
                                                            :alt="order.group.partner.name"
                                                            :src="order.group.partner.logoUrl"
                                                            position="top"
                                                            contain
                                                            aspect-ratio="6"
                                                        />
                                                        <v-icon v-else color="primary">fa-building</v-icon>

                                                        <p
                                                            class="title mb-0"
                                                        >{{order.group.partner.name}}</p>
                                                        <p class="mb-0">
                                                            <v-icon left small>fa-envelope</v-icon>
                                                            {{order.group.partner.email}}
                                                        </p>
                                                        <p>
                                                            <v-icon left small>fa-phone</v-icon>
                                                            {{order.group.partner.phone}}
                                                        </p>
                                                    </div>
                                                </v-col>

                                                <!-- Customer portal - only shows on the first time the purchase was made 
                                                <v-col cols="12" v-if="from === 'payment'"> 
                                                    <v-sheet color="blue-grey lighten-5 text-center pa-4">
                                                        <h4>To view your orders, tours and waivers login to the customer portal</h4>
                                                        <p class="mb-0 mt-2"><span class="font-weight-medium">Link to access:</span> <a :href="`${appUrl}/login/customers`" target="_blank">{{`${appUrl}/login/customers`}}</a></p>
                                                        <p class="mb-0"><span class="font-weight-medium">Email:</span> {{order.customer.email}}</p>
                                                        <p><span class="font-weight-medium">Temporary password:</span> {{order.customer.tempPassword}}</p>
                                                        <p class="mb-0">Keep a copy of the link and password for yourself so you can login to the portal later</p>
                                                        <p class="caption">This login information will not be shown again</p>
                                                    </v-sheet>
                                                </v-col>
                                                -->
                                                
                                            </v-row>
                                        </v-sheet>
                                    </v-col>
                                </v-row>

                                <v-sheet elevation="1" color="pa-2 mt-2">
                                    <v-row>
                                        <v-col cols="12"> 
                                            <p class="title pa-2">Waivers</p>
                                            <booking-waivers :orderId="order._id" />
                                        </v-col>
                                    </v-row>
                                </v-sheet>

                                <v-sheet elevation="1" color="pa-2 mt-2">
                                    <v-row>
                                        <v-col cols="12">
                                            <p class="title pa-2">Bookings</p>

                                            <v-expansion-panels focusable hover :value="0">
                                                <v-expansion-panel class="pa-0"
                                                    v-for="(item, idx) in order.items"
                                                    :key="idx"                                            
                                                >
                                                    <v-expansion-panel-header>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <p class="subtitle-1 font-weight-medium mb-0">
                                                                    {{item.title}} {{item.headline}} 
                                                                </p>
                                                                <div v-if="item.fareharborBooking">
                                                                    <p class="body-2 mb-0">
                                                                        {{item.fareharborBooking.availability.start_at | moment('timezone', timezone, 'dddd, MMMM Do YYYY @ HH:mma')}}
                                                                    </p>
                                                                    <p class="caption mb-0">
                                                                        ID #{{item.lolaId}} (FH #{{item.fareharborBooking.pk}})
                                                                    </p>
                                                                    
                                                                    <v-chip
                                                                        v-if="item.fareharborBooking.status === 'cancelled'"
                                                                        class="my-2"
                                                                        color="secondary"
                                                                        >
                                                                        Cancelled
                                                                    </v-chip>
                                                                </div>
                                                                <div v-else>
                                                                    <v-chip
                                                                        class="my-2"
                                                                        color="secondary"
                                                                        >
                                                                        Booking not confirmed
                                                                    </v-chip>
                                                                </div>
                                                            
                                                            </v-col>
                                                        </v-row>
                                                        
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content class="mx-n6 pa-0 mb-n4">
                                                        <!-- The item is a FH booking, that we show in the component below. We also need the timezone to show the dates -->
                                                        <div v-if="item.fareharborBooking">
                                                            <booking-item  v-if="item" :item="item" :timezone="order.group.partner.timezone"/>
                                                        </div>
                                                        <div v-else class="pa-4">
                                                            <p class="subtitle-2">This booking is not yet confirmed. Please, refresh this page in a few minutes to check again.</p>
                                                            <p>Booking confirmations should happen in a few minutes after payment. If your booking is not confirmed in 24 hours, please reach out support at <i>hello@dcstudentadventures.com</i></p>
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-col>
                                    </v-row>
                                </v-sheet>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import { sendAlert, setLoading } from '@/store/notifications-hub'
import BookingItem from '@/components/Booking/BookingItem'
import BookingWaivers from '@/components/Booking/BookingWaivers'
import filtersMixin from '@/mixins/filters-mixin.js'

export default {
    name: 'OrderConfirmation',
    mixins: [filtersMixin],
    props: {
        id: {
            type: String,
            required: false,
        },
        orderProp: {
            type: Object,
            required: false
        },
        from: {
            type: String,
            required: false
        }
    },
    components: {        
        BookingItem,
        BookingWaivers
    },
    data: function () {
        return {
            order: null,
            loading: true,
            fareharborItem: null,
            waivers: []            
        }
    },
    computed: {
        title: function () {
            let title = ''
            this.order.items.map((item, i) => {
                i === 0 ? title = `${item.title}` : title = `${title}, ${item.title}` //first element has no comma             
            })
            return title
        },
        appUrl: function(){
            return this.order.group.division.domain ? `http://${this.order.group.division.domain}` : process.env.VUE_APP_URL
        }
    },
    watch: {
        'loading': function (newVal) {
            if (newVal) {
                setLoading(true)
            } else {
                setLoading(false)
            }
        }
    },
    methods: {

        // initializeFareharbor(){
        //     this.$tornos.fareharbor(`/companies/${this.group.division.company.settings.fareharborShortname}/items/${itemPk}`)



        //     /companies/{companyShortname}/bookings/{bookingUuid}
        // }

        getWaivers(){
            this.$tornos.get(`waivers?order=${this.order._id}`).then(res => {
                this.waivers = res.data
            })
            // .catch(e => {
            //     this.$log.info("Erro getting waives" + e)
            // })
        }

    },
    

    mounted() {
        if(this.id){

            setLoading(true)
            this.$tornos.get(`orders/${this.id}?populate=group-partner,customer,group-division`).then((res) => {
                /*
                Object.freeze -> Make the object NOT reactive in Vue -- to avoid memory leaks (do this for big objects)
                    https://github.com/vuejs/vue/issues/4384#issuecomment-265776921
                    https://vuedose.tips/improve-performance-on-large-lists-in-vue-js/
                */
                const order = res.data
                this.order = Object.freeze(order) // make it not reactive in Vue
                this.getWaivers()
            }).catch(e => {
                sendAlert({ event: 'warning', data: e, message: 'There was an error fetching the order' })
            }).finally(() => this.loading = false)
        
        //coming from the orderProp
        }else if(this.orderProp){
            this.order = this.orderProp
            this.loading = false
            this.getWaivers()
        }else{
            this.loading = false
        }

        
    }
}
</script>

<style>
</style>