<template>
  <v-app light>
    <v-toolbar color="primary" dark>
      <v-toolbar-title v-text="site.title"></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon v-on="on"></v-app-bar-nav-icon>        
        </template>
        <v-list>
          <v-list-item
            @click="$router.push({name: 'login', params: {userType: 'employees'}})"
          >
            <v-list-item-title><v-icon left>fa-building</v-icon> Login Company</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="$router.push({name: 'login', params: {userType: 'agents'}})"
          >
            <v-list-item-title><v-icon left>fa-handshake</v-icon> Login Agent</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="$router.push({name: 'login', params: {userType: 'customers'}})"
          >
            <v-list-item-title><v-icon left>fa-suitcase-rolling</v-icon> Login Traveller</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      
    </v-toolbar>
    <v-main>
      <section>
        <v-img src="img/home/bg1.jpg" position="50% 30%"  height="360" class="primary" gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.3)"> 
          <v-row align="center" class="white--text fill-height ">
          <v-col>
            <h1 class="white--text mb-2 display-1 text-center d-flex-inline">Welcome to {{site.title}}</h1>
            <div class="subtitle-1 mb-4 text-center">This is {{site.title}} group travel booking platform for easy and safe online bookings</div>           
          </v-col>
          </v-row>
        </v-img>
      </section>

      <section>
        <v-layout
          column
          wrap
          class="my-12"
          align-center
        >
          <v-flex xs12 sm4 class="my-4">
            <div class="text-center">
              <h2 class="headline">Easy and safe online tour bookings </h2>
              <span class="subheading">
                An online portal for group tours and bookings management
              </span>
            </div>
          </v-flex>
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout row wrap align-center>
                <v-flex xs12 md4>
                  <v-card flat class="transparent">
                    <v-card-text class="text-center">
                      <v-icon x-large color="primary">fa-building</v-icon>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline text-center">Operator Portal</div>
                    </v-card-title>
                    <v-card-text class="text-center">
                      Access the Operator Portal to manage groups, tours, view bookings and more
                    </v-card-text>
                    <v-card-text class="text-center">
                      <v-btn color="primary" @click="$router.push({name: 'login', params: {userType: 'employees'}})"><v-icon left>fa-user-circle</v-icon> Company Portal</v-btn>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 md4>
                  <v-card flat class="transparent">
                    <v-card-text class="text-center">
                      <v-icon x-large color="info">fa-handshake</v-icon>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline">Agencies and Schools</div>
                    </v-card-title>
                    <v-card-text class="text-center">
                      Access the Partner Portal to access your school/agency bookings, waivers and more
                    </v-card-text>
                    <v-card-text class="text-center">
                      <v-btn color="info" @click="$router.push({name: 'login', params: {userType: 'agents'}})"><v-icon left>fa-handshake</v-icon> Partner Portal</v-btn>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 md4>
                  <v-card flat class="transparent">
                    <v-card-text class="text-center">
                      <v-icon x-large color="secondary">fa-route</v-icon>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline text-center">Travellers</div>
                    </v-card-title>
                    <v-card-text class="text-center">
                      Access the Traveller Portal to access your bookings, waivers and receipts
                    </v-card-text>
                    <v-card-text class="text-center">
                      <v-btn color="secondary" @click="$router.push({name: 'login', params: {userType: 'customers'}})"><v-icon left>fa-suitcase-rolling</v-icon> Traveller Portal</v-btn>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>

      <section>
        <v-sheet color="default" elevation="2" class="py-10" >
          <v-row>
            <v-col cols="12" sm="12" md="6" class="text-center">
              <p class="headline mb-4 text-center"><v-icon left>fa-map-marked</v-icon> Trip support</p>
              <p class="pa-4">If you need support regarding your trip, such as details about itinerary, cancellations, pricing, please contact your agency or school. You can find their contact information on the group page or confirmation email.</p>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="text-center">
              <p class="headline mb-4 text-center"><v-icon left>fa-laptop-code</v-icon> Technical support</p>
              <p class="pa-4">If you need support regarding technical issues, such as not being able to access a tour or finish a payment, contact us on hello@dcstudentadventures.com</p>
            </v-col>         
          </v-row>
        </v-sheet>
       
      </section>

      <!-- <section>
        <v-sheet color="primary" elevation="2" class="py-6" dark>
          <v-row>
            <v-col class="text-center">
              <p class="headline mb-4 text-center">Are you a tour company looking to manage custom groups?</p>
                <p class="title mb-0">Talk to us today</p>
                <p class="mt-0"><v-icon small>fa-envelope</v-icon> support@lolaguide.com</p>
                </v-col>
          </v-row>
        </v-sheet>
      </section> -->

      <!-- <section>
        <v-container grid-list-xl>
          <v-layout row wrap justify-center class="my-12">
            <v-flex xs12 sm5>
              <v-card flat class="transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline">Lolaguide Internet Inc.</div>
                </v-card-title>
                <v-card-text class="text-justify">
                  {{site.title}} is a service brought to you by Lolaguide Internet Inc., a Canadian software startup focused on
                  making travel easier so everyone can experience the best of the world.
                  <br>
                  <span class="font-weight-medium">Be always among friends!</span>
                  
                </v-card-text>
                <v-list class="transparent">
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon class="blue--text text--lighten-2">fa-globe</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>lolaguide.com</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>                  
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs12 sm5 offset-sm1>
              <v-card flat class="transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline">Questions</div>
                </v-card-title>
                <v-card-text class="text-justify">
                  If you are a traveller and have questions regarding your trip details, please contact the partner (your agency or school) 
                  to learn more about it. If you have technical questions, just send us a note.                 
                </v-card-text>
                <v-list class="transparent">
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon class="blue--text text--lighten-2">fa-envelope</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>support@lolaguide.com</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>                  
                </v-list>
              </v-card>
            </v-flex>
            <v-flex sm12>
              <div class=" text-center caption">
                <p class="mt-6 mb-0">Fareharbor's name and its logos are trademarks of FareHarbor Holdings, LLC.</p>                
              </div>
            </v-flex> 
          </v-layout>
        </v-container>
      </section> -->

      <v-footer color="primary darken-2">
        <v-layout row wrap align-center>
          <v-flex xs12>
            <div class="white--text text-center caption">
                <p class="mb-1">Developed in Vancouver, BC  © {{new Date().getFullYear()}}</p>
                <p class="mb-0">Version {{version}}</p>
                <p class="mb-0" v-if="environment === 'DEVELOPMENT'">Environment {{environment}}</p>           
            </div>
          </v-flex>
        </v-layout>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
export default {
    name: 'HomePage',
    data: function(){
        return{
            site:{
              title: 'Lola Groups'
            }
        }
    },
     computed: {
      isLoggedIn: function(){
        return this.$store.getters.isLoggedIn    
      },
      version: function(){
        return process.env.VUE_APP_VERSION
      },
      environment: function(){
        return process.env.VUE_APP_ENVIRONMENT
      },
      currentDomain: function() {
        return window.location.hostname;
      }
    },
    mounted() {
      if(this.currentDomain.includes("destinationcanadatours")){
        this.site = {
          title: "Destination Canada Tours"
        }
      } else if(this.currentDomain.includes("destinationsnow")){
         this.site = {
          title: "Destination Snow"
        }
      } else if(this.currentDomain.includes("localhost")){
         this.site = {
          title: "Localhost"
        }
      }
    }
}
</script>

<style>

</style>