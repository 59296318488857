<template>
    <div>
        <v-row>
            <v-col>
                <v-card outlined class="mx-auto">
                    <v-card-title>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="search"
                                    append-icon="fa-search"
                                    label="Search"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete
                                    :loading="loading"
                                    :items="itemsAggregate"
                                    item-text="text"
                                    item-value="value"
                                    label="Tours and Dates filter"
                                    name="Tours and Dates filter"
                                    v-model="searchByItem"
                                    hint="Search by a tour and date"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <!-- <v-skeleton-loader
                class="mx-auto"
                type="table-heading, table-thead, table-tbody, table-tfoot"
            ></v-skeleton-loader>-->
                        <!--
            <v-data-table :headers="headers" :items="waivers" :search="search" :loading="loading" 
            :server-items-length="pagination.count" :items-per-page.sync="pagination.limit" :page.sync="pagination.page"
            @pagination="fetch">
            -->
                        <v-data-table
                            :headers="headers"
                            :items="waivers"
                            :search="search"
                            :loading="loading"
                        >
                            <template v-slot:item.item.startDate="{ item }">
                                <span
                                    >{{
                                        item.item.startDate
                                            | moment(
                                                'timezone',
                                                currentTimezone,
                                                'DD-MMM-YYYY HH:mm:ss z'
                                            )
                                    }}
                                    ({{ currentTimezone }})</span
                                >
                            </template>

                            <template v-slot:item.item.endDate="{ item }">
                                <span
                                    >{{
                                        item.item.endDate
                                            | moment(
                                                'timezone',
                                                currentTimezone,
                                                'DD-MMM-YYYY HH:mm:ss z'
                                            )
                                    }}
                                    ({{ currentTimezone }})</span
                                >
                            </template>

                            <template v-slot:item.isSigned="{ item }">
                                <v-chip color="success" v-if="item.isSigned" small>Signed</v-chip>
                                <v-chip color="warning" v-if="!item.isSigned" small
                                    >Not Signed</v-chip
                                >
                            </template>

                            <template v-slot:item.title="{ item }">
                                <p v-if="item.signedWaiver && item.signedWaiver.title">
                                    {{ item.signedWaiver.title }}
                                </p>
                                <p v-else>{{ item.waiverTemplate.title }}</p>
                            </template>

                            <template v-slot:item.action="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" small class="mr-2" @click="view(item._id)"
                                            >fa-external-link-alt</v-icon
                                        >
                                    </template>
                                    <span>View Waiver</span>
                                </v-tooltip>
                                <br />

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-on="on"
                                            small
                                            class="mr-2"
                                            @click="viewOrder(item.order)"
                                            >fa-file-invoice</v-icon
                                        >
                                    </template>
                                    <span>View Order</span>
                                </v-tooltip>
                                <br />

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-on="on"
                                            small
                                            class="mr-2"
                                            @click="sendEmailWaivers(item.order, item.lolaId)"
                                            >fa-paper-plane</v-icon
                                        >
                                    </template>
                                    <span>Send waiver email</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { sendAlert } from '../../../store/notifications-hub'
import filtesrMixin from '@/mixins/filters-mixin.js'
import moment from 'moment-timezone'

export default {
    name: 'WaiversList',
    mixins: [filtesrMixin],
    computed: {
        paginationLimit() {
            return this.pagination.limit === -1 ? this.pagination.count : this.pagination.limit // ALL is -1 in the datatable
        },
        paginationPageIndexZero() {
            return this.pagination.page - 1
        },
        paginationSkip() {
            return this.paginationPageIndexZero * this.paginationLimit
        },
    },
    watch: {
        'searchByItem': function(value){
            if(value) {                
                const values = value.split("#")
                const tour = values[0]
                const date = values[1]     
                this.waivers = this.waiversOriginal.filter(el => el.item.name === tour && el.item.startDate === date)
            } else{
                this.waivers = this.waiversOriginal
            }
        }
    },
    data: function() {
        return {
            search: '',
            loading: true,
            headers: [
                { text: 'Title', value: 'title' },
                { text: 'Status', value: 'isSigned' },
                { text: 'Traveller', value: 'traveller.name' },
                { text: 'Traveller Age', value: 'traveller.age' },
                { text: 'Traveller Email', value: 'traveller.email' },
                { text: 'Tour', value: 'item.name' },
                { text: 'Start Date', value: 'item.startDate' },
                { text: 'End Date', value: 'item.endDate' },
                { text: 'Actions', value: 'action', sortable: false },
                { text: 'Lola ID', value: 'lolaId' },
            ],
            waivers: [],
            pagination: {
                limit: 10,
                page: 1,
                count: 1000,
            },
            itemsAggregate: [], // holds all distinct items from all waivers
            searchByItem: '',
            waiversOriginal: []
        }
    },
    methods: {
        view(waiverMongoId) {
            //https://stackoverflow.com/questions/40015037/can-vue-router-open-a-link-in-a-new-tab
            let routeData = this.$router.resolve({
                name: 'waivers.sign',
                params: { id: waiverMongoId },
            })
            window.open(routeData.href, '_blank')
        },

        viewOrder(orderMongoId) {
            //https://stackoverflow.com/questions/40015037/can-vue-router-open-a-link-in-a-new-tab
            let routeData = this.$router.resolve({ name: 'orders', params: { id: orderMongoId } })
            window.open(routeData.href, '_blank')
        },

        fetchCount() {
            this.$tornos
                .get(`waivers/count`)
                .then(res => {
                    this.pagination.count = res.data
                })
                .catch(() => {
                    this.pagination.count = 1000 // default when it fails
                })
        },

        async fetch() {
            try {
                // gets groups in division, then will fetch orders under groups
                let resGroups = await this.$tornos.get(
                    `groups?division=${this.division._id}&select=_id`
                )
                let groupsIds = resGroups.data

                let resOrders = await this.$tornos.post(`orders/query`, {
                    filter: {
                        group: { $in: groupsIds },
                    },
                    select: ['_id'],
                })
                let orderIds = resOrders.data

                let resWaivers = await this.$tornos.post(`waivers/query`, {
                    populate: [{ path: 'waiverTemplate', select: ['title'] }],
                    filter: {
                        order: { $in: orderIds },
                    },
                    select: [
                        '_id',
                        'lolaId',
                        'isSigned',
                        'traveller.age',
                        'traveller.name',
                        'traveller.email',
                        'item.name',
                        'item.startDate',
                        'item.endDate',
                        'order',
                    ],
                })
                this.waivers = resWaivers.data
                this.waiversOriginal = resWaivers.data // used in the filter to restore the values when filter 'All' applied
            } catch (e) {
                sendAlert({
                    event: 'warning',
                    data: e,
                    message: 'There was an error fetching the orders. Please try again',
                })
            }

            this.loading = false
        },

        sendEmailWaivers(orderId, waiverLolaId) {
            this.loading = true

            this.$tornos
                .post(`email/orders/${orderId}/waivers`)
                .then(() => {
                    this.$log.info('Email order waivers sent')
                    sendAlert({
                        event: 'success',
                        data: null,
                        message: `Email sent successfully. (Waiver Lola Id: ${waiverLolaId})`,
                    })
                })
                .catch(e => {
                    sendAlert({
                        event: 'error',
                        data: e,
                        message: `Error sending email. Details: ${e}`,
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async generateItemsAggregate() {            
            const items = this.waivers.map(w => w.item)
            const agg = [...new Map(items.map(item => [item['name'], 
            { value: `${item.name}#${item.startDate}`, text: `TOUR: ${item.name} ### START: ${moment(item.startDate).format('YYYY-MM-DD HH:mm ')}` }])).values()]
            this.itemsAggregate.push({text: "All", value: null})
            this.itemsAggregate.push(...agg)            
        },
    },

    async mounted() {
        //this.fetchCount()
        await this.fetch()
        await this.generateItemsAggregate()
    },
}
</script>

<style></style>
