import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // primary: '#ee44aa',
        // secondary: '#424242',
        // accent: '#82B1FF',
        // error: '#FF5252',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FFC107'
        primary: '#00ACC1',
        secondary: '#ff9800',
        accent: '#009688',
        error: '#ff5722',
        warning: '#ffc107',
        info: '#03a9f4',
        success: '#8BC34A'
      }
    }
  },
  icons: {
    iconfont: 'fa'
  }
})
