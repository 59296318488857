var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":"fa-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"loading":_vm.loading,"items":_vm.itemsAggregate,"item-text":"text","item-value":"value","label":"Tours and Dates filter","name":"Tours and Dates filter","hint":"Search by a tour and date"},model:{value:(_vm.searchByItem),callback:function ($$v) {_vm.searchByItem=$$v},expression:"searchByItem"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.waivers,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.item.startDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.item.startDate, 'timezone', _vm.currentTimezone, 'DD-MMM-YYYY HH:mm:ss z' ))+" ("+_vm._s(_vm.currentTimezone)+")")])]}},{key:"item.item.endDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.item.endDate, 'timezone', _vm.currentTimezone, 'DD-MMM-YYYY HH:mm:ss z' ))+" ("+_vm._s(_vm.currentTimezone)+")")])]}},{key:"item.isSigned",fn:function(ref){
var item = ref.item;
return [(item.isSigned)?_c('v-chip',{attrs:{"color":"success","small":""}},[_vm._v("Signed")]):_vm._e(),(!item.isSigned)?_c('v-chip',{attrs:{"color":"warning","small":""}},[_vm._v("Not Signed")]):_vm._e()]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [(item.signedWaiver && item.signedWaiver.title)?_c('p',[_vm._v(" "+_vm._s(item.signedWaiver.title)+" ")]):_c('p',[_vm._v(_vm._s(item.waiverTemplate.title))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.view(item._id)}}},on),[_vm._v("fa-external-link-alt")])]}}],null,true)},[_c('span',[_vm._v("View Waiver")])]),_c('br'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewOrder(item.order)}}},on),[_vm._v("fa-file-invoice")])]}}],null,true)},[_c('span',[_vm._v("View Order")])]),_c('br'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.sendEmailWaivers(item.order, item.lolaId)}}},on),[_vm._v("fa-paper-plane")])]}}],null,true)},[_c('span',[_vm._v("Send waiver email")])])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }