<template>
    <div>
        <v-row>
            <v-col>
                <v-card outlined class="mx-auto">
                    <v-card-title>
                        Orders
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            append-icon="fa-search"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="headers" :items="orders" :search="search" :loading="loading">
                            <template v-slot:[`item.isPaid`]="{ item }">
                                <v-chip color="success" v-if="item.isPaid && !item.isRefunded" small>Paid</v-chip>
                                <v-chip color="default" v-if="item.isRefunded" small>Refunded</v-chip>
                                <v-chip color="warning" v-if="!item.isPaid" small>Not paid</v-chip>
                            </template>

                            <template v-slot:[`item.amountTotal`]="{ item }">
                                <span>{{ item.amountTotal | currency }}</span>
                            </template>

                            <template v-slot:[`item.amountPaid`]="{ item }">
                                <span>{{ item.amountPaid | currency }}</span>
                            </template>

                            <template v-slot:[`item.amountRefunded`]="{ item }">
                                <span>{{ item.amountRefunded | currency }}</span>
                            </template>

                            <template v-slot:[`item.amountRemaining`]="{ item }">
                                <span>{{ (item.amountTotal - item.amountPaid) | currency }}</span>
                            </template>

                            <template v-slot:[`item.createdAt`]="{ item }">
                                <span>{{
                                    item.createdAt | moment('timezone', currentTimezone, 'DD-MMM-YYYY HH:mm:ss z')
                                }}</span>
                            </template>

                            <template v-slot:[`item.updatedAt`]="{ item }">
                                <span>{{
                                    item.updatedAt | moment('timezone', currentTimezone, 'DD-MMM-YYYY HH:mm:ss z')
                                }}</span>
                            </template>

                            <template v-slot:[`item.action`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:[`activator`]="{ on }">
                                        <v-icon v-on="on" small class="mr-2" @click="view(item)"
                                            >fa-external-link-alt</v-icon
                                        >
                                    </template>
                                    <span>View Order</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template v-slot:[`activator`]="{ on }">
                                        <v-icon v-on="on" small class="mr-2" @click="sendConfirmationEmail(item)"
                                            >fa-paper-plane</v-icon
                                        >
                                    </template>
                                    <span>Send confirmation email</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template v-slot:[`activator`]="{ on }">
                                        <v-icon v-on="on" small class="mr-2" @click="sendEmailWaivers(item)"
                                            >fa-envelope-open-text</v-icon
                                        >
                                    </template>
                                    <span>Send waivers email reminder</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { sendAlert } from '@/store/notifications-hub'
import filtersMixin from '@/mixins/filters-mixin.js'
import { setLoading } from '../../../store/notifications-hub'
export default {
    name: 'DivisionOrders',
    mixins: [filtersMixin],
    data: function() {
        return {
            loading: true,
            orders: [],
            search: '',
            headers: [
                { text: 'ID', value: 'lolaId' },
                { text: 'Status', value: 'isPaid' },
                { text: 'Total Amount', value: 'amountTotal' },
                { text: 'Amount Paid', value: 'amountPaid' },
                { text: 'Amount Refunded', value: 'amountRefunded' },
                { text: 'Balance Remaining', value: 'amountRemaining' },
                { text: 'Customer', value: 'customer.name' },
                { text: 'Email', value: 'customer.email' },
                { text: 'Group', value: 'group.title' },
                { text: 'Date created', value: 'createdAt' },
                { text: 'Last updated', value: 'updatedAt' },
                { text: 'Actions', value: 'action', sortable: false },
            ],
        }
    },
    methods: {
        view(order) {
            //https://stackoverflow.com/questions/40015037/can-vue-router-open-a-link-in-a-new-tab
            let routeData = this.$router.resolve({ name: 'orders', params: { id: order._id } })
            window.open(routeData.href, '_blank')
        },

        sendConfirmationEmail(order) {
            setLoading(true)
            this.$tornos
                .post(`/email/orders/${order._id}/only`)
                .then(() => {
                    sendAlert({ event: 'info', data: null, message: 'Email sent successfully' })
                })
                .catch(e => {
                    sendAlert({
                        event: 'warning',
                        data: e,
                        message: 'There was an error sending the email',
                    })
                })
                .finally(() => {
                    setLoading(false)
                })
        },
        filterByDivision(orders) {
            return orders.filter(o => o.group.division === this.division._id)
        },
        sendEmailWaivers(order) {
            setLoading(true)

            this.$tornos
                .post(`email/orders/${order._id}/waivers`)
                .then(() => {
                    this.$log.info('Email order waivers sent')
                    sendAlert({
                        event: 'success',
                        data: null,
                        message: 'Email sent successfully.',
                    })
                })
                .catch(e => {
                    sendAlert({
                        event: 'error',
                        data: e,
                        message: `Error sending email. Details: ${e}`,
                    })
                })
                .finally(() => {
                    setLoading(false)
                })
        },
    },
    async mounted() {
        try {
            // gets groups in division, then will fetch orders under groups
            let resGroups = await this.$tornos.get(`groups?division=${this.division._id}&select=_id`)
            let groupsIds = resGroups.data

            let populate = [
                {
                    path: 'customer',
                    select: ['name', 'email'],
                },
                {
                    path: 'group',
                    select: ['title', '_id'],
                },
            ]
            let resOrders = await this.$tornos.post(`orders/query`, {
                populate,
                select: [
                    'lolaId',
                    'isPaid',
                    'isRefunded',
                    'amountTotal',
                    'amountPaid',
                    'amountRefunded',
                    'amountRemaining',
                    'createdAt',
                    'updatedAt',
                    '_id',
                ],
                filter: {
                    group: { $in: groupsIds },
                },
            })
            this.orders = resOrders.data
        } catch (e) {
            sendAlert({
                event: 'warning',
                data: e,
                message: 'There was an error fetching the orders. Please try again',
            })
        }

        this.loading = false
    },
}
</script>

<style></style>
