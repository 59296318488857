<template>
  <v-main>
    <v-container class="fill-width ma-0 pa-0" fluid>
      <v-row align justify no-gutters>
        <v-col class="mt-0" cols="12" v-if="!waiver && !loading">
          <v-card class="ma-0 pa-0 mx-auto" tile>
            <v-sheet elevation="1" color="grey lighten-4" class="text-center">
              <v-card-title class="justify-center headline text--lighten-0">
                Waiver nof found
                <v-icon right>fa-file-signature</v-icon>
              </v-card-title>
            </v-sheet>
          </v-card>
        </v-col>

        <v-col class="mt-0" cols="12" v-else>
          <v-card class="ma-0 pa-0 mx-auto grey lighten-5" outlined>
            <v-card-text>
              <v-container>
                <v-row class="mt-0" no-gutters>
                  <v-col cols="12">
                    <v-sheet elevation="1" class="pa-5">
                      <v-row align-content="center">
                        <v-col cols="6">
                          <div class>
                            <p class="title mb-0">Traveller Details</p>
                            <p class="subtitle-1 mb-0">Name: {{waiver.traveller.name}}</p>
                            <p class="subtitle-1 mb-0">Email: {{waiver.traveller.email}}</p>
                            <p class="subtitle-1 mb-0">Age: {{waiver.traveller.age}}</p>
                          </div>
                        </v-col>
                        <v-col md="6">
                          <div class>
                            <p class="title mb-0">Trip Details</p>
                            <p class="subtitle-1 mb-0">Trip: {{waiver.item.name}}</p>
                            <p
                              class="subtitle-1 mb-0"
                            >Start Date: {{waiver.item.startDate | moment('timezone', waiver.timezone, 'DD-MMM-YYYY HH:mm:ss')}}</p>
                            <p
                              class="subtitle-1 mb-0"
                            >End Date: {{waiver.item.endDate | moment('timezone', waiver.timezone, 'DD-MMM-YYYY HH:mm:ss')}}</p>
                          </div>
                        </v-col>

                        <v-col md="12" class="py-0">
                          <v-chip color="success" v-if="waiver.isSigned">Signed</v-chip>
                          <span
                            v-if="waiver.isSigned"
                            class="ml-2"
                          >Signed on {{waiver.dateSigned | moment('timezone', waiver.timezone ,'MMMM Do, YYYY')}}</span>
                          <v-chip color="warning" v-else>Not Signed</v-chip>
                        </v-col>
                      </v-row>
                    </v-sheet>

                    <!-- not signed - gets from waiver template -->
                    <v-sheet elevation="1" class="pa-5 mt-2" v-if="!waiver.isSigned">
                      <v-row align-content="center">
                        <v-col cols="12">
                          <div class="text-center">
                            <p class="headline">{{waiver.waiverTemplate.title}}</p>
                          </div>
                          <div class="text-justify" v-if="waiver.waiverTemplate.header">
                            <p v-html="waiver.waiverTemplate.header"></p>
                            <v-divider></v-divider>
                          </div>
                          <div class="text-justify mt-4">
                            <p v-html="waiver.waiverTemplate.body"></p>
                          </div>
                        </v-col>
                      </v-row>
                    </v-sheet>

                    <!-- IS signed - gets from waiver signed -->
                    <v-sheet elevation="1" class="pa-5 mt-2" v-else>
                      <v-row align-content="center">
                        <v-col cols="12">
                          <div class="text-center">
                            <p class="headline">{{waiver.signedWaiver.title}}</p>
                          </div>
                          <div v-if="waiver.signedWaiver.header">
                            <p v-html="waiver.signedWaiver.header"></p>
                            <v-divider></v-divider>
                          </div>
                          <div class="mt-4">
                            <p v-html="waiver.signedWaiver.body"></p>
                          </div>
                        </v-col>
                      </v-row>
                    </v-sheet>

                    <!-- Custom Fields Waiver not signed -->
                    <v-sheet elevation="1" class="pa-5 mt-2" v-if="!waiver.isSigned">
                      <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-row align-content="center">
                          <v-col cols="12">
                            <p class="mb-2 font-weight-medium">Traveller Name</p>
                            <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                              <v-text-field
                                outlined
                                label="Full name of the traveller"
                                type="text"
                                :messages="errors[0]"
                                hint="Full name of the traveller"
                                v-model="waiver.traveller.name"
                              />
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="12">
                            <p class="mb-2 font-weight-medium">Traveller email address</p>
                            <ValidationProvider
                              name="email"
                              rules="required|email"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                outlined
                                label="Traveller email address"
                                name="email"
                                type="text"
                                :messages="errors[0]"
                                hint="Traveller email address"
                                v-model="waiver.traveller.email"
                              />
                            </ValidationProvider>
                          </v-col>

                          <!-- Custom Fields -->
                          <v-col cols="12" v-for="(f, i) in customFields" :key="i">
                            <div v-if="f.type === 'text'">
                              <p class="mb-2 font-weight-medium">{{f.title}}</p>
                              <ValidationProvider
                                :name="f.title"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  outlined
                                  :label="f.title"
                                  type="text"
                                  :messages="errors[0]"
                                  :hint="f.title"
                                  v-model="f.value"
                                />
                              </ValidationProvider>
                            </div>

                            <div v-if="f.type === 'date'">
                              <p class="mb-2">{{f.title}}</p>
                              <ValidationProvider
                                :name="f.title"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  outlined
                                  :label="f.title"
                                  type="date"
                                  :messages="errors[0]"
                                  :hint="f.title"
                                  v-model="f.value"
                                />
                              </ValidationProvider>
                            </div>

                            <div v-if="f.type === 'signature'">
                              <p class="mb-2">{{f.title}}</p>
                              <VueSignaturePad
                                :name="f.title"
                                height="200px"
                                class="grey lighten-4"
                                :ref="f.ref"
                              />
                              <div class="mt-1 float-right">
                                <v-btn class="mr-2" @click="undo('custom', f.ref)">
                                  <v-icon small left>fa-undo</v-icon>Undo
                                </v-btn>
                                <v-btn @click="clear('custom', f.ref)">
                                  Clear
                                  <v-icon small right>fa-eraser</v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </v-col>
                          <!-- END CUSTOM FIELDS -->

                          <v-col cols="12" class="pa-2">
                            <p
                              class="mb-2 font-weight-medium"
                            >{{waiver.waiverTemplate.signatureDescription}}</p>
                            <VueSignaturePad
                              height="200px"
                              class="grey lighten-4"
                              ref="signaturePad"
                              v-model="waiver.signature"
                            />
                            <div class="mt-1 float-right">
                              <v-btn class="mr-2" @click="undo('default', 'signaturePad')">
                                <v-icon small left>fa-undo</v-icon>Undo
                              </v-btn>
                              <v-btn @click="clear('default', 'signaturePad')">
                                Clear
                                <v-icon small right>fa-eraser</v-icon>
                              </v-btn>
                            </div>
                          </v-col>

                          <v-col cols="12">
                            <p class="mb-2 font-weight-medium">Electronic Signature Consent</p>
                            <p
                              class="text-justify grey lighten-4 pa-2"
                            >{{waiver.waiverTemplate.consent}}</p>
                            <ValidationProvider name="Consent" rules="required" v-slot="{ errors }">
                              <v-checkbox
                                name="Consent"
                                v-model="checkbox"
                                label="By clicking here you agree to this electronic signature consent"
                                :messages="errors"
                              ></v-checkbox>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="12">
                            <v-btn
                              color="primary white--text"
                              large
                              block
                              :disabled="invalid"
                              @click="signWaiver"
                            >
                              Sign Waiver
                              <v-icon right small>fa fa-check-circle</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </ValidationObserver>
                    </v-sheet>

                    <!-- Signed fields -->
                    <v-sheet elevation="1" class="pa-5 mt-2" v-else>
                      <signed-fields :waiver="waiver"></signed-fields>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { sendAlert, setLoading } from '@/store/notifications-hub'
import uploadMixin from '@/mixins/upload-mixin.js'
import SignedFields from '@/components/Waiver/SignedFields'

export default {
  name: 'WaiverSign',
  mixins: [uploadMixin],
  components: {
    SignedFields
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    folder: function () {
      return `waivers/${this.waiver.lolaId}`
    },
    // isSignatureEmpty: function() {
    //   return this.$refs.signaturePad.isEmpty();
    // }
  },
  data: function () {
    return {
      loading: true,
      waiver: null,
      customFields: [],
      //customSignatures: [],
      signature: null
    }
  },
  methods: {
    undo(source, ref) {
      //console.log(this.$refs)
      if (source === 'custom') {
        this.$refs[ref][0].undoSignature() //it's inside an array - who knows
      } else {
        this.$refs[ref].undoSignature()
      }
    },
    clear(source, ref) {
      if (source === 'custom') {
        this.$refs[ref][0].clearSignature()
      } else {
        this.$refs[ref].clearSignature()
      }
    },

    generateSignatures() {
      if (this.customFields) {
        //gets the field signature and sets the data in the value property
        this.customFields.map((field) => {
          if (field.type === 'signature') {
            const { data } = this.$refs[field.ref][0].saveSignature()
            field.value = data
          }
        })
      }

      const { data } = this.$refs['signaturePad'].saveSignature()

      //adds the main signature to the customFields - which will be saved to the signedFields
      this.customFields.push({
        title: this.waiver.waiverTemplate.signatureDescription,
        value: data,
        type: 'signature'
      })
      this.signature = data
    },

    async signWaiver() {

      this.generateSignatures() //sets signatures into the customFields

      if (this.customFields.forEach(e => !e.value)) {
        sendAlert({ event: 'warning', data: null, message: 'There are validation erros. Please check if all signature fields are complete and try again.' })
        return
      }

      const isValid = await this.$refs.form.validate()

      if (!isValid) {
        sendAlert({ event: 'warning', data: null, message: 'There are validation erros. Please check if all fields are complete and try again.' })
        return
      }

      this.waiver.signedWaiver = this.waiver.waiverTemplate //creates a copy of the waiver template to hard copy into the signed waiver

      this.waiver.signedFields = this.customFields
      this.waiver.dateSigned = new Date()
      this.waiver.isSigned = true
      this.$tornos.put(`waivers/${this.waiver._id}`, this.waiver).then(res => {
        sendAlert({ event: 'info', data: res, message: 'Waiver signed successfully' })
      }).catch(e => {
        //this.$log.info(e)
        sendAlert({ event: 'danger', data: e, message: 'There was an error saving the waiver. Please try again' })
      })

    },

    generateFields() {
      if (this.waiver.waiverTemplate.fields) {
        this.waiver.waiverTemplate.fields.map((e, i) => {
          this.customFields.push({
            title: e.title,
            value: '',
            type: e.type,
            counter: i,
            ref: 'customFieldRef' + i
          })
        })
      } else {
        return []
      }
    }
  },
  mounted() {
    setLoading(true)
    this.$tornos.get(`waivers/${this.id}?populate=waiverTemplate`).then(res => {
      this.waiver = res.data
      this.generateFields()
    }).catch(e => {
      sendAlert({ event: 'danger', data: e, message: 'There was an error fetching the waiver. Please try again' })
    }).finally(() => {
      setLoading(false)
      this.loading = false
    })
  }
}
</script>

<style>
</style>