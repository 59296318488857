<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" 
   persistent no-click-animation>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Hello. Please, choose your division:</v-toolbar-title>

        <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="cyan lighten-3"
          ></v-progress-linear>

        <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="cancel()">Cancel & Sign Out</v-btn>           
          </v-toolbar-items>
      </v-toolbar>
      <v-subheader>We noticed that you have more than one division associated with your profile. Please choose one of the options below.</v-subheader>

        <v-row class="ma-0">

          <v-col v-for="(division, index) in divisions" v-bind:key="index">
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">{{division.name}}</v-list-item-title>
                  <span>{{division.shortname}}</span>
                  <span>{{division.company.name}}</span>
                </v-list-item-content>
                <v-list-item-avatar tile size="100">
                  <v-img :src="division.logoUrl"></v-img>
                </v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn color="primary" @click="selectDivision(division)" class="capitalize">Go To {{division.name}}</v-btn>                
              </v-card-actions>
            </v-card>
          </v-col>

        </v-row>
      
    </v-card>
  </v-dialog>
</template>

<script>
export default {  
  name: 'ModalChooseDivision',
  data: function(){
    return{
      loading: true,
      dialog: true,
      divisions: []
    }
  },
  methods: {
    cancel() {
      this.$store.dispatch('logout')  
      this.$router.push(`/login/employees`)
    },
    selectDivision(division){
      this.$store.dispatch('setDivision', division)      
      this.$router.push({name: 'groups.list'})
    }
  },
  mounted(){
    this.divisions = this.$store.getters.loggedUser.user.divisions
    if(this.divisions.length === 1){
      this.selectDivision(this.divisions[0])
    }
    this.loading = false
  }
}
</script>