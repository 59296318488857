<template>
<div>
    <ValidationProvider :name="customField.name" :rules="rules" v-slot="{ errors }">
        <v-text-field
            :label="label"
            :hint="customField.title"
            outlined        
            v-model="field.value"            
            @input="update"   
            :error-messages="errors"
            :key="`${travellerIndex}-${field.pk}`"
            :type="fieldType"
        ></v-text-field>
    </ValidationProvider>
    <span v-if="customField.description_safe_html" v-html="customField.description_safe_html"></span>  
</div>
  
</template>

<script>
export default {
    name: 'CustomFieldText',
    props: ['pk', 'customField', "travellerIndex", "ageMappingPk"],
    data: function(){
        return {
            field: {
                pk: this.pk,
                customField: this.customField,
                value: '',
                amount: 0,
                travellerIndex: this.travellerIndex
            }
        }
    },

    computed: {
        rules: function(){
            return this.customField.is_required ? 'required' : ''
        },
        label: function(){
            if(this.rules.includes('required')){
                return `${this.customField.name} (required)`
            }else{
                return `${this.customField.name}`
            }
        },
        fieldType: function(){
            //if the props exists and they're equal it's an age field
            if(this.ageMappingPk && this.pk && Number(this.ageMappingPk) === this.pk){
                return 'number'
            }else{
                return 'text'
            }
        }
    },

    methods: {
        update() {

            //this is an offset (price) in the custom_field level (not in an option, but in the root of the custom_field itself)
            let customFieldAmount = 0
            if(this.field.customField.modifier_kind === "offset" && this.field.customField.modifier_type !== 'none'){
                customFieldAmount = this.customField.offset
            }

            this.field.amount = customFieldAmount
            
            this.$emit('input', this.field)
        },
    }
}
</script>

<style>

</style>