import Vue from 'vue'
import { IpregistryClient } from '@ipregistry/client'

const IP_REGISTRY_API_KEY = process.env.VUE_APP_IP_REGISTRY_API_KEY
const client = new IpregistryClient(IP_REGISTRY_API_KEY);
 
Vue.use({
    install(Vue){
        Vue.prototype.$ip = client
    }
})