<template>
  <div id="register">
    <v-row>
      <v-col>
        <v-card outlined>
          <v-container>
            <v-card-title>{{labelSubmitBtn}}</v-card-title>

            <ValidationObserver ref="formgroup" v-slot="observerGroup">
              <v-card-text>

                <v-sheet v-if="isUpdate" color="grey lighten-3" class="pa-4 mx-2 mb-6" elevation="1">
                  <h4 class="title">Details</h4>

                  <label class="subtitle-1">Group Link</label>
                  <p>
                    <a :href="getGroupLink()" target="_blank"> {{getGroupLink()}} </a>
                  </p>

                  <label class="subtitle-1">Group UUID (Universally Unique Identifier)</label>
                  <p> {{group._id}}</p>

                  <label class="subtitle-1">Date created</label>
                  <p>{{group.createdAt | moment('DD-MMM-YYYY HH:mm:ss ZZ')}}</p>

                  <label class="subtitle-1">Last modified</label>
                  <p>By {{group.lastModifiedBy}} on {{group.updatedAt | moment('DD-MMM-YYYY HH:mm:ss ZZ')}}</p>

                </v-sheet>

                <v-form @submit.prevent="observerGroup.handleSubmit(submit)">
                  <div class="pa-2">
                    <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
                      <v-text-field
                        outlined
                        label="Title"
                        name="Title"
                        type="text"
                        v-model="group.title"
                        :messages="errors[0]"
                        counter="60"
                        hint="Group Title"
                      />
                    </ValidationProvider>
                  </div>

                  <div class="pa-2">
                    <ValidationProvider name="Slug" rules v-slot="{ errors }">
                      <v-text-field
                        class="mb-n2"
                        outlined
                        label="Slug (URL)"
                        name="Slug"
                        type="text"
                        v-model="group.slug"
                        :messages="errors[0]"
                        counter="60"
                        hint="Group Slug (custom URL - ex: /my-group)"
                      />
                    </ValidationProvider>
                    <p>
                      <router-link v-if="isUpdate" target='_blank' :to="`/groups/${group.slug}`">  
                       <v-icon color="primary" small left>fa-external-link-alt</v-icon>                      
                      {{group.slug}}</router-link>
                    </p>
                    
                  </div>

                  <div class="px-2 pt-2 mb-0 pb-0">
                    <ValidationProvider name="Partner" rules v-slot="{ errors }">
                      <v-autocomplete
                        v-model="group.partner"
                        :items="partners"
                        :loading="isLoadingPartners"
                        hide-no-data
                        hide-selected
                        item-text="name"
                        item-value="_id"
                        label="Select the Partner"
                        placeholder="Start typing to search a partner"
                        :messages="errors[0]"
                        outlined
                        chips
                      >
                        <template v-slot:item="{item}">
                          <v-avatar size="36px" tile>
                            <img v-if="item.logoUrl" :alt="item.name" :src="item.logoUrl" />
                            <v-icon v-else color="primary">fa-user-circle</v-icon>
                          </v-avatar> 
                          {{item.name}}
                        </template>

                        <template v-slot:selection="{item}">
                          <v-avatar size="36px" tile>
                            <img v-if="item.logoUrl" :alt="item.name" :src="item.logoUrl" />
                            <v-icon v-else color="primary">fa-user-circle</v-icon>
                          </v-avatar> 
                          {{item.name}}
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                  </div>

                  <div class="mt-n4 px-2">
                    <v-btn class="px-4 mb-2" outlined color="primary" @click="initialize">
                      <v-icon left>fa-sync-alt</v-icon>Refresh Partners
                    </v-btn>
                  </div>

                  <div class="pa-2" v-if="isUpdate">
                    <p>Current Header</p>
                    <v-avatar size="92px" tile>
                      <img v-if="group.headerUrl" :alt="group.title" :src="group.headerUrl" />
                      <v-icon v-else color="primary">fa-image</v-icon>
                    </v-avatar>

                    <v-switch v-model="switchHeader" label="Change group header image?"></v-switch>
                  </div>

                  <div class="pa-2 mt-2" v-if="switchHeader === true || isUpdate === false">
                    <p>Header Image</p>
                    <ValidationProvider name="Header Image" rules="required" v-slot="{ errors }">
                      <v-file-input
                        outlined
                        label="Click to choose an image..."
                        name="Header Image"
                        v-model="group.headerFile"
                        :messages="errors[0]"
                        show-size
                        hint="Group Header Image"
                        accept="image/*"
                      />
                    </ValidationProvider>
                  </div>

                  <div class="pa-2">
                    <label>Description</label>
                    <ValidationProvider
                      name="Description"
                      rules="required"
                      v-slot="{ validate, errors }"
                    >
                      <!-- Use the component in the right place of the template -->
                      <QuillEditorLola
                        placeholder="Description ..."
                        :folder="folder"
                        name="Description"
                        storageLevel="protected"
                        v-model="group.description"
                        :content="group.description"
                        rows="20"
                        counter="3000"
                        :messages="errors[0]"
                        :minHeight="`400`"
                        @editorChange="validate($event.html)"
                      />
                    </ValidationProvider>
                  </div>

                  <div class="pa-2">
                    <p>Custom Footer</p>
                    <v-switch
                      v-model="switchCustomFooter"
                      label="Activate or deactivate custom footer"
                    ></v-switch>
                  </div>

                  <div class="pa-2" v-if="switchCustomFooter === true">
                    <ValidationProvider name="Custom Footer" v-slot="{ validate, errors }">
                      <!-- Use the component in the right place of the template -->
                      <QuillEditorLola
                        placeholder="Custom Footer ..."
                        :folder="folder"
                        name="Custom Footer"
                        storageLevel="protected"
                        v-model="group.customFooter"
                        :content="group.customFooter"
                        rows="10"
                        counter="2000"
                        :messages="errors[0]"
                        @editorChange="validate($event.html)"
                      />
                    </ValidationProvider>
                  </div>


                  <div class="pa-2">
                    <label>Terms and Conditions
                      <v-btn target="_blank" small text color="primary" :href="`/group/${group.slug}/terms`">Link to terms <v-icon small right>fa-external-link-alt</v-icon></v-btn>                
                    </label>
                    <ValidationProvider
                      name="terms"
                      rules=""
                      v-slot="{ validate, errors }"
                    >
                      <!-- Use the component in the right place of the template -->
                      <QuillEditorLola
                        placeholder="Terms and Conditions ..."
                        :folder="folder"
                        name="Terms and Conditions"
                        storageLevel="protected"
                        v-model="group.terms"
                        :content="group.terms"
                        rows="20"
                        counter="3000"
                        :messages="errors[0]"
                        :minHeight="`300`"
                        @editorChange="validate($event.html)"
                      />
                    </ValidationProvider>                        
                  </div>

                  <div class="pa-2">
                    <label>Group Status (Active or Inactive)</label>
                    <br />
                    <v-switch v-model="group.isActive" label="Activate or deactivate group status"></v-switch>
                  </div>

                  <div class="pa-2">
                    <label>Send email of new bookings to partner?</label>
                    <br />
                    <v-switch
                      v-model="group.isSendEmailToPartner"
                      label="Activate or deactivate sending of emails to partner"
                    ></v-switch>
                  </div>

                  <div class="pa-2 mb-4">
                    <label>Activate customer portal email?</label>
                    <br />
                    <v-switch
                      v-model="group.isCustomerPortalEmail"
                      label="Activate or deactivate the sending of the customer portal email to new customers when they make their first booking"
                      hint="When new users make their first booking an email with instructions to login to the customer portal will be sent if activated"
                      persistent-hint
                    ></v-switch>
                  </div>

                  <v-dialog
                    ref="dialogDateLive"
                    v-model="dialogDateLive"
                    :return-value.sync="group.dateLive"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="group.dateLive"
                        label="Date Live"
                        prepend-icon="fa-calendar-alt"
                        readonly
                        v-on="on"
                        hint="Date for this group to be available online"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="group.dateLive"
                      scrollable
                      :min="new Date() | moment('YYYY-MM-DD')"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dialogDateLive = false">Cancel</v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogDateLive.save(group.dateLive)"
                      >OK</v-btn>
                    </v-date-picker>
                  </v-dialog>

                  <!-- Date Expiry -->
                  <v-dialog
                    ref="dialogDateExpiry"
                    v-model="dialogDateExpiry"
                    :return-value.sync="group.dateExpiry"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="group.dateExpiry"
                        label="Date Expiry"
                        prepend-icon="fa-calendar-alt"
                        readonly
                        v-on="on"
                        hint="Date for this group to stop being available online"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="group.dateExpiry" scrollable :min="group.dateLive">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dialogDateExpiry = false">Cancel</v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogDateExpiry.save(group.dateExpiry)"
                      >OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-form>

                <!-- ######### items ######## 
                    ONLY SHOWS THIS WHEN IT'S AN UPDATE.
                    EMPLOYEE NEEDS TO ADD THE ITEM, GO BACK, AND THEN UPDATE AND ADD THE items
                    This is because when we`re updating we have the group id to set in the item document
                -->
                <v-row v-if="isUpdate">
                  <v-col md="12">
                    <v-btn color="success" dark large @click="openItemsModal">
                      Manage Items
                      <v-icon small right>fa fa-stream</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- ######### END items ######## -->

                <v-divider></v-divider>
              </v-card-text>
              <v-card-actions>
                <v-btn color="default" light large to="/companies/divisions/groups">
                  <v-icon left small>fa fa-arrow-left</v-icon>Go back
                </v-btn>

                <v-spacer />
                <v-btn
                  color="primary white--text"
                  large
                  :disabled="observerGroup.invalid"
                  @click="submit"
                >
                  {{labelSubmitBtn}}
                  <v-icon right small>fa fa-check</v-icon>
                </v-btn>
              </v-card-actions>
            </ValidationObserver>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { sendAlert, setLoading } from '../../../store/notifications-hub'
import uuidv4 from 'uuid/v4'
import moment from 'moment-timezone'

import uploadMixin from '@/mixins/upload-mixin.js'
import filtersMixin from '@/mixins/filters-mixin.js'

import QuillEditorLola from '@/components/QuillEditorLola'

export default {
  name: 'GroupsCreate',
  mixins: [uploadMixin, filtersMixin],
  props: {
    edit: {
      type: Object,
      required: false
    }
  },
  components: {
    QuillEditorLola    
  },
  data() {
    return {
      isLoadingPartners: true,
      dialogDateExpiry: false,
      dialogDateLive: false,
      switchCustomFooter: false,
      dialogAddNew: false,
      switchHeader: false,
      isUpdate: false,
      group: {
        title: '',
        slug: '',
        description: '',
        isActive: false,
        headerUrl: '',
        headerFile: null,
        customFooter: '',
        dateLive: '',
        dateExpiry: '',
        isSendEmailToPartner: false,
        lastModifiedBy: '',
        terms: '',
        isCustomerPortalEmail: true
      },
      groupDefault: {
        title: '',
        slug: '',
        description: '',
        isActive: false,
        headerUrl: '',
        headerFile: null,
        customFooter: '',
        dateLive: '',
        dateExpiry: '',
        isSendEmailToPartner: false,
        lastModifiedBy: '',
        terms: '',
        isCustomerPortalEmail: true
      },
      partners: [],
      items: []
    }
  },
  watch: {
    'group.customFooter': function (newVal) {
      newVal ? this.switchCustomFooter = true : this.switchCustomFooter = false
    }
  },
  computed: {
    labelSubmitBtn: function () {
      return this.isUpdate ? 'Update Group' : 'Add Group'
    },
    folder: function () {
      return `${this.division.company._id}/${this.division.shortname}/groups`
    }
  },

  methods: {
    getGroupLink(){
      const domain = this.division.domain ? `https://${this.division.domain}` : process.env.VUE_APP_URL
      return this.group.partner.slug ? `${domain}/${this.group.partner.slug}/${this.group.slug}` : `${domain}/groups/${this.group.slug}`
    },

    viewTerms(){
      this.$router.push({name: "group.terms", params: {partnerSlug: "group", slug: this.group.slug}})
    },

    openItemsModal() {
      this.$router.push({ name: 'groups.create.items', params: { groupId: this.group._id, from: 'create' } })
    },

    async submit() {
      setLoading(true) //loading overlay

      //update
      if (this.isUpdate) {

        if (this.switchHeader && this.group.headerFile) {
          //upload header
          this.group.headerUrl = await this.uploadFile(this.group.headerFile, { folder: this.folder, level: 'protected' })
        }

        //if the custom footer switch is inactivated we erase the customFooter field so nothing is saved in the DB
        if (!this.switchCustomFooter) this.group.customFooter = null

        this.group.lastModifiedBy = this.loggedUser.user.email
        this.$tornos.put(`groups/${this.group._id}`, {          
          ...this.group          
        }).then(res => {

          if (res.status === 200) {
            sendAlert({ event: 'info', data: res.body, message: 'Updated Successfully' })
          } else {
            sendAlert({ event: 'warning', data: res.body, message: 'Error updating entity. Please try again' })
          }

        }).catch(e => {
          sendAlert({ event: 'danger', data: e, message: 'There was an error' })
        }).finally(() => {
          setLoading(false) //loading overlay
          this.switchHeader = false
        })

        //save new
      } else {

        try {
          //upload header first
          this.group.headerUrl = await this.uploadFile(this.group.headerFile, { folder: this.folder, level: 'protected' })

          //if the custom footer switch is inactivated we erase the customFooter field so nothing is saved in the DB
          if (!this.switchCustomFooter) this.group.customFooter = null

          this.$tornos.post("groups", {
            division: this.division,
            createdBy: this.loggedUser.user.email,
            uuid: uuidv4(),
            ...this.group
          }
          ).then(res => {

            if (res.status === 200) {
              sendAlert({ event: 'info', data: res.body, message: 'Created Successfully' })
              this.$router.push({ name: 'groups.list' })
            } else {
              sendAlert({ event: 'warning', data: res.body, message: 'Error creating entity. Please try again' })
            }

          }).catch(e => {
            sendAlert({ event: 'danger', data: e, message: 'There was an error' })
          }).finally(() => {
            setLoading(false) //loading overlay
          })

        } catch (e) {
          sendAlert({ event: 'danger', data: e, message: 'There was an error and the entity was not added.' })
          setLoading(false) //loading overlay
        }

      }
    },

    deleteItem(item) {
      //this.dialog = true
      // Trigger a confirmation dialog

      this.$dialog
        .confirm('Would you like to delete the item: ' + item.title, { loader: true })
        .then(async (dialog) => {
          let res = null
          try {
            res = await this.$tornos.delete(`items/${item._id}`)
          } catch (e) {
            sendAlert({ event: 'danger', data: e, message: 'There was an error. Please try again' })
          }

          //success
          if (res.data.status === 200) {
            sendAlert({ event: 'success', data: res.body, message: `Deleted: ${item.title}` })
          } else {
            sendAlert({ event: 'danger', data: res.data, message: 'There was an error. Please try again' })
          }

          dialog.close()

          this.initialize() //re-load the table

          //dialog closed
        }).catch(() => {
          ////this.$log.info("dialog closed")
        })

    },

    initialize() {
      this.$tornos(`partners?division=${this.division._id}`).then(res => {
        this.partners = res.data
      }).catch(e => {
        sendAlert({ event: 'danger', data: e, message: 'There was an error fetching the Partners. Please try again.' })
      }).finally(() => this.isLoadingPartners = false)
    }
  },

  //it had to be created here otherwise it wont show the Description field, although it shows the custom footer. Weird
  created() {
    if (this.edit) {
      this.isUpdate = true
      this.group = this.edit
      //formats the dates for the datepicker
      this.group.dateLive ? this.group.dateLive = moment(this.group.dateLive).format('YYYY-MM-DD') : null
      this.group.dateExpiry ? this.group.dateExpiry = moment(this.group.dateExpiry).format('YYYY-MM-DD') : null
    }

    this.initialize()

  }
}
</script>

<style>
</style>