import Vue from 'vue'

//https://github.com/vuetifyjs/vuetify/issues/9999#issuecomment-569932219
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.'
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage && vm && trace) {
    msg = null;
    vm = null;
    trace = null;
  }
}