var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Bookings "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"fa-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.order.lolaId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.order.lolaId))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.view(item.order)}}},on),[_vm._v("fa-external-link-alt")])]}}],null,true)},[_c('span',[_vm._v("View Order")])])]}},{key:"item.item.amountTotal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.item.amountTotal)))])]}},{key:"item.amountPaid",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.item.amountPaid)))])]}},{key:"item.item.fareharborBooking.availability.start_at",fn:function(ref){
                            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.item.fareharborBooking ? item.item.fareharborBooking.availability.start_at : '', 'timezone', _vm.currentTimezone, 'DD-MMM-YYYY HH:mm:ss z' )))])]}},{key:"item.item.fareharborBooking.availability.end_at",fn:function(ref){
                            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.item.fareharborBooking ? item.item.fareharborBooking.availability.end_at : '', 'timezone', _vm.currentTimezone, 'DD-MMM-YYYY HH:mm:ss z' )))])]}},{key:"item.item.createdAt",fn:function(ref){
                            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.item.createdAt, 'timezone', _vm.currentTimezone, 'DD-MMM-YYYY HH:mm:ss z' )))])]}},{key:"item.item.status",fn:function(ref){
                            var item = ref.item;
return [(item.item.status === 'booked')?_c('v-chip',{attrs:{"color":"success","small":""}},[_vm._v("Booked")]):_vm._e(),(item.item.status === 'rebooked')?_c('v-chip',{attrs:{"color":"default","small":""}},[_vm._v("Rebooked")]):_vm._e(),(item.item.status === 'cancelled')?_c('v-chip',{attrs:{"color":"warning","small":""}},[_vm._v("Cancelled")]):_vm._e()]}},{key:"item.action",fn:function(ref){
                            var item = ref.item;
return [_c('v-btn',{staticClass:"my-1",attrs:{"color":"primary","fab":"","depressed":"","x-small":"","dark":"","loading":_vm.loading}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"disabled":item.item.status == 'created',"small":""},on:{"click":function($event){return _vm.openRefundModal(item.order._id, item.item._id)}}},on),[_vm._v("fa-undo")])]}}],null,true)},[_c('span',[_vm._v("Refund (click to open modal)")])])],1),_c('v-btn',{attrs:{"color":"primary","fab":"","depressed":"","x-small":"","dark":"","loading":_vm.loading}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.openWaiversModal(item.order._id, item.item._id)}}},on),[_vm._v("fa-file-alt")])]}}],null,true)},[_c('span',[_vm._v("Waivers (click to add waiver)")])])],1)]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }