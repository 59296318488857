<template>
  <v-app id="app">
    <vue-extend-layouts />
    <v-snackbar
      v-model="snackbar.on"
      :multi-line="true"
      :color="snackbar.type"
    >
      {{ snackbar.msg }}
      <v-btn        
        text
        @click="snackbar.on = false"
        class="float-right"
      >      
      <v-icon>fa-times-circle</v-icon>  
      </v-btn>      
    </v-snackbar>

    <!-- Loading Overlay -->
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="128"></v-progress-circular>
    </v-overlay>

  </v-app>
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout'
import {Hub} from 'aws-amplify'

export default {
  name: 'App',
  components: { VueExtendLayouts },
  data: function(){
    return{ 
      snackbar: {
        type: 'default',
        msg: '',
        on: false
      },
      overlay: false
    }
  },
  mounted(){
    Hub.listen('Alerts', (data) => {
      let { payload } = data

      //if there's an event we set it as the type, otherwise it will be default
      if(payload.event){        
        this.snackbar.type = payload.event
      }else{
        this.snackbar.type = 'default'
      }

      let message = payload.message
      if(payload.data && payload.data.message){ //if there's an error message we add to the message
        message = message + ` (${payload.data.message})`
      }

      //fareharbor errors
      if(payload.data && payload.data.error){ //if there's an error message we add to the message
        message = message + ` (${payload.data.error})`
      }

      //tornos api errors
      if(payload.data && payload.data.errorMessage){ //if there's an error message we add to the message
        message = message + ` (${payload.data.errorMessage})`
      }

      this.snackbar.msg = message
      this.snackbar.on = true
      
    })

    Hub.listen('Loading', (data) => {
      let { payload } = data

      //if data is true set overlay, else set to false
      if(payload.data){        
        this.overlay = true
      }else{
        this.overlay = false
      }
    })

  },
  created(){
    this.$log.info("APP ENVIRONMENT: " + process.env.VUE_APP_ENVIRONMENT)
    this.$log.info("APP VERSION: " + process.env.VUE_APP_VERSION)
    this.$log.info("APP API FAREHARBOR URL: " + process.env.VUE_APP_API_LOLAFAREHARBOR_URL)
    this.$log.info("APP API LOLAGROUPS URL: " + process.env.VUE_APP_API_LOLAGROUPS_URL)
  }
}
</script>

<style>
.capitalize{
    text-transform: capitalize;
  }

/* VUE DIALOG - main content styles */
.dg-main-content {
  font-family: 'Barlow Condensed', sans-serif;
}

.dg-content-body{
  padding-top: 10px;
}
 
</style>
