var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Transactions "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"fa-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.uniqueTransactions,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.transaction.transactionType",fn:function(ref){
var item = ref.item;
return [(item.transaction.transactionType === 'payment')?_c('v-chip',{attrs:{"color":"success","small":""}},[_vm._v("Payment")]):_vm._e(),(item.transaction.transactionType === 'refund')?_c('v-chip',{attrs:{"color":"error","small":""}},[_vm._v("Refund")]):_vm._e(),(item.transaction.transactionType === 'cash')?_c('v-chip',{attrs:{"color":"default","small":""}},[_vm._v("Cash")]):_vm._e()]}},{key:"item.transaction.amount",fn:function(ref){
var item = ref.item;
return [(
                                    item.transaction.transactionType === 'payment' ||
                                        item.transaction.transactionType === 'cash'
                                )?_c('span',[_vm._v(" "+_vm._s(item.transaction.amount)+" ")]):_vm._e(),(item.transaction.transactionType === 'refund')?_c('span',[_vm._v("-"+_vm._s(item.transaction.amount))]):_vm._e()]}},{key:"item.transaction.feeLolaguide",fn:function(ref){
                                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.transaction.feeLolaguide || '-'))])]}},{key:"item.transaction.partnerCommission",fn:function(ref){
                                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.transaction.partnerCommission || '-'))])]}},{key:"item.transaction.createdAt",fn:function(ref){
                                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.transaction.createdAt, 'timezone', _vm.currentTimezone, 'DD-MMM-YYYY HH:mm:ss z' )))])]}},{key:"item.action",fn:function(ref){
                                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.view(item.order)}}},on),[_vm._v("fa-external-link-alt")])]}}],null,true)},[_c('span',[_vm._v("View Order")])])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }