<template>
  <v-container class="fill-width ma-0 pa-0" fluid>
    <v-row align justify no-gutters>
      <v-col>
        <v-card>
          <v-card-title
            class="title  text-capitalize"
            primary-title
          >Lola Groups | Reset Password</v-card-title>

          <div>
            <v-card-text>
              <ValidationObserver ref="form" v-slot="observer">
                <v-form @submit.prevent="observer.handleSubmit(submit)">
                  <v-row class="my-2">
                    <v-col class cols="12" md="12">
                      <ValidationProvider
                        name="Email"
                        rules="required|email|max:200"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          outlined
                          label="Email"
                          name="Email"
                          type="text"
                          v-model="user.email"
                          :messages="errors[0]"
                          counter="200"
                          placeholder="Email address"
                        />
                      </ValidationProvider>
                    </v-col>



                    <v-col class cols="12" md="12" v-if="isCodeSent">
                      <ValidationProvider
                        name="code"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          outlined
                          label="Verification Code"
                          name="Code"
                          type="text"
                          v-model="user.code"
                          :messages="errors[0]"
                          counter="20"
                          placeholder="Code"
                        />
                      </ValidationProvider>
                    </v-col>

                    <v-col class cols="12" md="12" v-if="isCodeSent">
                      <ValidationProvider
                        name="Password"
                        rules="required|max:200|min:8"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          outlined
                          label="New Password"
                          name="Password"
                          type="password"
                          v-model="user.password"
                          :messages="errors[0]"
                          counter="200"
                          placeholder="New Password"                          
                        />
                      </ValidationProvider>
                    </v-col>


                    <v-col cols="12">
                      <v-btn
                        large
                        color="primary"
                        :disabled="observer.invalid"
                        @click="forgotPasswordSendCode"
                        block   
                        v-if="!isCodeSent"                     
                      >
                        Send code
                        <v-icon right small>fa fa-check-circle</v-icon>
                      </v-btn>

                      <v-btn
                        large
                        color="primary"
                        :disabled="observer.invalid"
                        @click="forgotPasswordSubmit"
                        block     
                        v-else                   
                      >
                        Reset Password
                        <v-icon right small>fa fa-check-circle</v-icon>
                      </v-btn>
                    </v-col>

                    <v-col cols="12">
                        <v-alert v-if="isCodeSent">
                            <p>A verification code was sent to your email address. Please fill in the code above with a new password and click 'Reset Password'</p>
                        </v-alert>
                    </v-col>

                    <v-col cols="12" v-if="!isCodeSent">
                        <v-alert type="info" colored-border border="bottom">
                            <p>To reset your password fill in your email address above and click 'Send Code'. After receiving the code enter it here with a new password.</p>
                        </v-alert>
                    </v-col>

                    <v-col>
                        <v-btn text @click="$emit('login')"><v-icon small left>fa-arrow-left</v-icon> Go back to login</v-btn>
                    </v-col>

                  </v-row>
                </v-form>
              </ValidationObserver>
            </v-card-text>
            
          </div>

          
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from 'aws-amplify'
import { sendAlert } from '@/store/notifications-hub'

export default {
  name: 'ResetPassword',
  components: {
    
  },
  props: {
    
  },
  
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    }
  },
  data: function () {
    return {
      user: {
        email: '',
        password: ''
      },
      isCodeSent: false
    }
  },

  methods: {

    forgotPasswordSendCode(){
        Auth.forgotPassword(this.user.email)
        .then((data) => {
            sendAlert({ event: 'info', data: null, message: 'A verification code was sent to ' + data.CodeDeliveryDetails.Destination})
            this.isCodeSent = true
        })
        .catch(e => {
            sendAlert({ event: 'error', data: e, message: 'There was an error sending the code. Please, try again.' })
        });
    },

    forgotPasswordSubmit(){
        Auth.forgotPasswordSubmit(this.user.email, this.user.code, this.user.password)
        .then(() => {
            sendAlert({ event: 'info', data: null, message: 'Password reset successful'})
            this.$emit('login') //redirects to login form
        })
        .catch(e => {
            sendAlert({ event: 'error', data: e, message: 'There was an error reseting the password. Please, try again.' })            
        })
    }



  },

  mounted() {

  }

}
</script>




