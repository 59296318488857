<template>
    <div>
        <v-skeleton-loader
            class="mx-auto"
            type="card, text,  text, sentences, divider, text, sentences, image, date-picker-options, actions"
            v-if="loading"
        ></v-skeleton-loader>

        <div v-if="group.isActive && isGroupDatesValid && group.loaded">
            <v-main>
                <v-container class="fill-width ma-0 pa-0" fluid>
                    <v-stepper v-model="step">
                        <v-stepper-header>
                            <v-stepper-step
                                :complete="isStep1"
                                :editable="isStep1"
                                step="1"
                                edit-icon="fa-check"
                            >Group</v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step
                                :complete="isStep2"
                                :editable="isStep2"
                                step="2"
                                edit-icon="fa-check"
                            >Cart</v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step
                                :complete="isStep3"
                                step="3"
                                edit-icon="fa-check"
                            >Checkout</v-stepper-step>
                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content step="1" class="pa-0">
                                <Step1GroupLandingPage
                                    v-if="group"
                                    :group="group"
                                    @changeStep="moveStep"
                                />
                            </v-stepper-content>

                            <v-stepper-content step="2" class="pa-0">
                                <Step2CartPage @changeStep="moveStep" @showItems="showItems" :group="group" />
                            </v-stepper-content>

                            <v-stepper-content step="3" class="pa-0">
                                <Step3CheckoutPage v-if="group" :group="group" />
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-container>
            </v-main>

            <v-footer color="grey lighten-1">
                <span
                    class="white--text overline"
                >{{currentYear}} Lola Groups by Lolaguide Internet Inc.</span>

                <v-spacer></v-spacer>
                <span class="white--text overline">Timezone: {{groupTimezone | timezoneFilter}}</span>

                <span v-if="environment === 'DEVELOPMENT'" class="black--text subtitle-2 ml-4">Environment: {{environment}} - version {{version}}</span>

                <!-- <v-btn color="primary" small class="mr-1" @click="step--">
                    <v-icon left small>fa-chevron-left</v-icon>Prev
                </v-btn>
                <v-btn color="primary" small class="ml-1" @click="step++">
                    Next
                    <v-icon right small>fa-chevron-right</v-icon>
                </v-btn>-->
            </v-footer>
        </div>

        <!-- Group not active or dates not available -->
        <v-card class="mx-auto" outlined v-else>
            <!-- we set the not loading condition so it doesnt show on loading when the group is not in the data yet -->
            <v-card-title class="justify-center text-center" v-if="!loading">
                <v-alert type="info" class="pa-5" colored-border border="left" outlined prominent>
                    <div v-if="!group.loaded">
                        <p class="display-1">Group not found</p>
                        <p>Please, check the URL (website link).</p>
                    </div>

                    <div v-else>
                        <p class="display-1">This group is not available at the moment.</p>
                    </div>

                    <p class="headline">If you need assistance please contact support.</p>
                </v-alert>
            </v-card-title>
        </v-card>
    </div>
</template>

<script>
//import { mapGetters } from 'vuex'
import Step1GroupLandingPage from '@/components/GroupBooking/Steps/Step1/Step1GroupLandingPage'
import Step2CartPage from '@/components/GroupBooking/Steps/Step2/Step2CartPage'
import Step3CheckoutPage from '@/components/GroupBooking/Steps/Step3/Step3CheckoutPage'

import { sendAlert, setLoading } from '@/store/notifications-hub'
import momentTz from 'moment-timezone'

import environmentMixin from '@/mixins/environment-mixin.js'
import filtersMixin from '@/mixins/filters-mixin.js'

export default {
    name: 'GroupBookingFlow',
    mixins: [environmentMixin, filtersMixin],
    props: {
        partnerSlug: {
            type: String
        },
        slug: {
            type: String
        },
        uuid: {
            type: String
        },
        stepProp: {
            type: Number
        }
    },
    components: {
        Step1GroupLandingPage,
        Step2CartPage,
        Step3CheckoutPage
    },
    data: function () {
        return {
            step: 1,
            currentYear: new Date().getFullYear(),
            group: {
                items: [],
                partner: {
                    logoUrl: '',
                    address: {
                        city: ''
                    }
                },
                dateLive: '',
                loaded: false
            },
            isGroupDatesValid: false,
            loading: true,
            isStep3: false,
            isShowItems: false, //to send a notification to the
            groupDivision: null
        }
    },
    computed: {
        groupTimezone: function () {
            //if partner has timezone gets it, otherwise it's vancouver by default
            let timezone = this.group.partner.timezone ? this.group.partner.timezone : 'America/Vancouver'
            this.$store.dispatch('setGroupTimezone', timezone) //sets in the store to be used in the layout
            return timezone
        },
        isStep1: function () {
            return true
        },
        isStep2: function () {
            return this.$store.getters.getCart && this.$store.getters.getCart.items.length > 0 ? true : false
        }
    },


    methods: {

        showItems() {
            this.step = 1
            this.isShowItems = true //changes the data so it changes the prop in the LandingPage that is watching it
        },

        moveStep(step) {
            this.step = step
            //step 2 is controlled by the vuex (shoppingCar if exists show item 2)
            if (step === 3) this.isStep2 = true
        },

        getGroupDatesValid() {
            let dateExpiry = this.group.dateExpiry ? momentTz(this.group.dateExpiry).tz("UTC").format('YYYY-MM-DD') : null
            let dateLive = this.group.dateLive ? momentTz(this.group.dateLive).tz("UTC").format('YYYY-MM-DD') : null

            //now, today
            let now = momentTz().tz("UTC").format('YYYY-MM-DD')

            //if today is after dateLive is valid - if it exits test, else return true (not blocking to show the tour it might be empty)
            let isValidLive = dateLive ? momentTz(now).isAfter(this.group.dateLive, 'day') : true
            
            //if today is before dateExpiry is valid - - if it exits test, else return true (not blocking to show the tour it might be empty)
            let isValidExpiry = dateExpiry ? momentTz(now).isBefore(this.group.dateExpiry, 'day') : true
            
            let isValidFinal = isValidLive && isValidExpiry
            
            this.isGroupDatesValid = isValidFinal //sets in the data
        },

        goto(refName) {
            let element = this.$refs[refName];
            element.scrollIntoView({ behavior: 'smooth' });
        },

        initialize() {
            setLoading(true)

            let param = this.slug ? { slug: this.slug } : { _id: this._id }
            let populate = [
                { path: 'items.item', populate: { path: 'availabilities', populate: { path: 'waiverTemplates' } } },
                { path: 'partner' },
                { path: 'division', populate: { path: 'company' } },
            ]

            //we call the post query endpoint to send custom filter and custom populate
            //complex query we use POST with a body - this is great!
            this.$tornos.post(`groups/query`, {
                filter: param,
                populate
            }).then(res => {

                //group found
                if (res.data.length > 0) {
                    this.group = res.data[0] //gets the 0 because this query returns an array  
                    this.group.loaded = true
                    this.getGroupDatesValid() //check if tour is available given the dateLive and dateExpiry

                    // new poperty in the avails: capacity. we set a value here to initialize them
                    this.group.items.forEach((el) => {
                        for(const avail of el.item.availabilities) {
                            avail.capacity = -1 // -1 means 'Not available'. On the GroupItems component we fetch FH to set the capacity
                        }
                    })
                } else {
                    this.group.loaded = false
                }

            }).catch(e => {
                sendAlert({ event: 'danger', data: e, message: 'There was an error fetching the group. Please try again' })
            }).finally(() => {
                setLoading(false)
                this.loading = false
            })


            /*
            let param = this.slug ? `slug=${this.slug}` : `_id=${this._id}`
            this.$tornos.get(`groups?${param}&populate=partner,items.item,items.item-availabilities`).then(res => {

                //group found
                if(res.data.length > 0){
                    this.group = res.data[0] //gets the 0 because this query returns an array  
                    this.group.loaded = true             
                    this.getGroupDatesValid() //check if tour is available given the dateLive and dateExpiry

                    //loads the division of this group and the company
                    return this.$tornos.get(`divisions/${this.group.division}?populate=company`)       

                }else{
                    this.group.loaded = false
                }     
                
                
            }).then(resDivision => {
                this.group.division = resDivision.data
            }).catch(e => {
                sendAlert({ event: 'danger', data: e, message: 'There was an error fetching the group. Please try again' })
            }).finally(() => {
                setLoading(false)
                this.loading = false
            })
            */

        },

    },
    mounted() {
        //this.$log.info('Flow mounted')
        this.initialize()
    },

    beforeRouteEnter(to, from, next) {

        next(vm => {

            // access to component's instance using `vm` .
            // this is done because this navigation guard is called before the component is created.            
            //for some reason it DOESNOT work with props - Filipe
            if (to.params.stepProp) {
                if (to.params.slug) {
                    vm.slug = to.params.slug //sets in the component - it happens when we reload the modal availability
                    vm.partnerSlug = to.params.partnerSlug
                    vm.initialize()
                }
                vm.moveStep(to.params.stepProp)
                next()
            }

        })

    },
}
</script>