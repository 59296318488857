<template>
  <div>
    <v-row>
      <v-col>
        <v-card outlined class="mx-auto">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" :to="`/companies/divisions/groups/create`">
              Add New Group
              <v-icon right>fa fa-plus-circle</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <!-- <v-skeleton-loader
                class="mx-auto"
                type="table-heading, table-thead, table-tbody, table-tfoot"
            ></v-skeleton-loader>-->
            <v-data-table :headers="headers" :items="items" :search="search" :loading="loading">

                <template v-slot:[`item.slug`]="{ item }">
                  <a :href="getGroupLink(item)" target="_blank">{{getGroupLink(item)}}</a>                  
                </template>

                <template v-slot:[`item.isActive`]="{ item }">
                    <v-chip color="success" v-if="item.isActive" small>Active</v-chip>
                    <v-chip color="default" v-else small>Inactive</v-chip>
                </template>

                <template v-slot:[`item.dateLive`]="{ item }">
                    <span>{{item.dateLive | moment('timezone', currentTimezone, 'DD-MMM-YYYY')}}</span>
                </template>

                <template v-slot:[`item.createdAt`]="{ item }">
                    <span>{{item.createdAt | moment('timezone', currentTimezone, 'DD-MMM-YYYY HH:mm:ss z')}}</span>
                </template>

                <template v-slot:[`item.updatedAt`]="{ item }">
                    <span>{{item.updatedAt | moment('timezone', currentTimezone, 'DD-MMM-YYYY HH:mm:ss z')}}</span>
                </template>

                <template v-slot:[`item.partner`]="{ item }">
                  <v-avatar size="24px" tile>
                    <img
                      v-if="item.partner.logoUrl"
                      :alt="item.partner.name"
                      :src="item.partner.logoUrl"
                    />
                    <v-icon v-else color="primary">fa-user-circle</v-icon>
                  </v-avatar>
                  {{item.partner.name}}
                </template>
              
                <template v-slot:[`item.headerUrl`]="{ item }">
                  <v-avatar size="36px">
                    <img
                      v-if="item.headerUrl"
                      :alt="item.title"
                      :src="item.headerUrl"
                    />
                    <v-icon v-else color="primary">fa-image</v-icon>
                  </v-avatar>
                </template>
                
              <template v-slot:[`item.action`]="{ item }">

                <v-tooltip bottom>
                  <template v-slot:[`activator`]="{ on }">
                    <v-icon v-on="on" small class="mr-2" @click="editItem(item)">fa-edit</v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:[`activator`]="{ on }">
                    <v-icon v-on="on" small class="mr-2" @click="openItemsModal(item)">fa-stream</v-icon>
                  </template>
                  <span>Items</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:[`activator`]="{ on }">
                    <v-icon v-on="on" small class="mr-2" @click="copyItem(item)">fa-copy</v-icon>
                  </template>
                  <span>Copy</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:[`activator`]="{ on }">
                    <v-icon v-on="on" small class="mr-2" @click="deleteItem(item)">fa-trash-alt</v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { sendAlert, setLoading } from '../../../store/notifications-hub'
import uploadMixin from '@/mixins/upload-mixin.js'
import filtersMixin from '@/mixins/filters-mixin.js'
import uuidv4 from 'uuid/v4'

export default {
  name: "GroupsList",
  mixins: [uploadMixin, filtersMixin],
  data: function () {
    return {
      search: '',
      loading: true,
      headers: [
        { text: 'Title', value: 'title' },
        { text: '', value: 'headerUrl', sortable: false },
        //{ text: 'UUID', value: '_id' },
        { text: 'Slug', value: 'slug' },
        { text: 'Status', value: 'isActive' },
        { text: 'Partner', value: 'partner' },
        { text: 'Date Live', value: 'dateLive' },
        { text: 'Date created', value: 'createdAt' },
        { text: 'Last updated', value: 'updatedAt' },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      headersSubTable:[
        { text: 'Tour', value: 'item.name' },
        //{ text: 'UUID', value: 'item._id'},
      ],
      items: []
    }
  },
  methods: {
    getGroupLink(group){
      const domain = this.division.domain ? `http://${this.division.domain}` : process.env.VUE_APP_URL
      return group.partner.slug ? `${domain}/${group.partner.slug}/${group.slug}` : `${domain}/groups/${group.slug}`
    },
    openItemsModal(group){
      //opens modal passing the group to the modal
      this.$router.push({name: 'groups.create.items', params: {groupId: group._id, from: 'list'}})
    },

    editItem(item) {
      //redirects to the form to edit
      this.$router.push({ name: 'groups.create', params: { edit: item } })
    },

    deleteItem(item) { //item is the group

      //save in the function scope to use in the catch if necessary (to revert it back)
      let itemSlug = item.slug
      
      // Trigger a confirmation dialog
      this.$dialog
        .confirm(`Would you like to delete the group ${item.title} (UUID: ${item._id})`, { loader: true })
        .then(async (dialog) => {
          let res = null
          try {

            //this.$log.info("Start delete group")

            //updates the slug
            //we add the '_deleted' in the slug to avoid cloning it again and it being not unique
            //we also and uuidv4 that makes it unique
            item.slug = item.slug + "_deleted_"+uuidv4()
            await this.$tornos.put(`groups/${item._id}`,item)

            res = await this.$tornos.delete(`groups/${item._id}`)

            //deletes all items of this group
            let items = await this.$tornos.get(`items?group=${item._id}`)
            items.data.forEach(async(itemGroup) => { //.data because of axios it comes like this the response
                await this.$tornos.delete(`items/${itemGroup._id}`)
            })

          } catch (e) {

            dialog.close()

            sendAlert({ event: 'danger', data: e, message: 'There was an error. Please try again' })

            //reverts the slug back
            item.slug = itemSlug
            this.$tornos.put(`groups/${item._id}`,item).catch(() => { 
              sendAlert({ event: 'danger', data: e, message: 'The slug of the group changed. Please modify it manually.' })
            })
          }

          //success
          if (res.data.status === 200) {
            sendAlert({ event: 'success', data: res.body, message: `Deleted: ${item.title}` })
          } else {
            sendAlert({ event: 'danger', data: res.data, message: 'There was an error. Please try again' })
          }

          dialog.close()

          this.initialize() //re-load the table

        //dialog closed
        }).catch(() => {
            ////this.$log.info("dialog closed")
        })

    },

    copyItem(item){

      // Trigger a confirmation dialog
      this.$dialog
        .confirm('Would you like to copy the group: ' + item.title, { loader: true })
        .then((dialog) => {

          //setLoading(true)
          try{

          delete item._id //deletes the id from mongo
          delete item.items //removes the array items so we avoid having many groups pointing to the same items/availabilities
          
          item.title = item.title + " (Copy)"
          item.slug = item.slug + '-copy-'+uuidv4() //edits the slug (it has to be unique)
          item.isActive = false //not active by default
          item.lastModifiedBy = this.loggedUser.user.email
          item.createdBy = this.loggedUser.user.email
          item.createdAt = new Date()
          item.updatedAt = new Date()
          
          this.$tornos.post("groups", item).then(res => {
                if (res.status === 200) {
                  sendAlert({ event: 'info', data: res.body, message: 'Created Successfully. Copied group is NOT active by default.' })
                  this.initialize() //reloads the table
                } else {
                  sendAlert({ event: 'warning', data: res.body, message: 'Error creating entity. Please try again' })
                }
              }).catch(e => {
                sendAlert({ event: 'danger', data: e, message: 'There was an error' })
              }).finally(() => {
                setLoading(false) //loading overlay
                dialog.close()
              })

          }catch(e){
            //this.$log.info(e)
            sendAlert({ event: 'danger', data: e, message: 'There was an error (general)' })
            dialog.close()
          }

        //dialog closed
        }).catch(() => {
            ////this.$log.info("dialog closed")
        })

        
    },

    initialize() {
      this.$tornos.get(`groups?division=${this.division._id}&populate=partner`).then(res => {
        if (res.status === 200) {
          this.items = res.data          
        } else {
          sendAlert({ event: 'warning', data: res.body, message: 'Error fetching. Please try again' })
        }
      }).catch(e => {
        sendAlert({ event: 'danger', data: e, message: 'There was an error' })
      }).finally(() => {
        this.loading = false
      })
    }
  },

  mounted() {
    this.initialize()
  }
}
</script>

<style>
</style>