<template>
  <v-main>
    <v-container class="fill-width ma-0 pa-0" fluid>
      <v-row align justify no-gutters>
        <v-col>
          <v-card class="ma-0 pa-0 mx-auto" tile>
            <v-img
              :src="group.headerUrl"
              max-height="360px"
              lazy-src="https://lola-static.s3-us-west-2.amazonaws.com/image-placeholder-mountains.png"
              class="white--text align-center"
              gradient="to top right, rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.20)"
            >
              <v-card-title class="justify-center text-center">
                <div class>
                  <p class="display-1">{{group.title}}</p>
                  <div class="title">
                    <v-btn class="my-2" color="white" small @click="goto('items')">
                      View Tours
                      <v-icon small right>fa-chevron-down</v-icon>
                    </v-btn>

                    <br />
                    <v-btn
                      class="my-2"
                      color="primary"
                      small
                      @click="$emit('changeStep', 2)"
                      v-if="isCart"
                    >
                      Go To Cart
                      <v-icon small right>fa-shopping-cart</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-card-title>
            </v-img>

            <v-card-subtitle class="text-center pt-2">
              <v-sheet elevation="1" class="pa-2" color>
                <v-row>
                  <v-col cols="12" class="pa-0">
                    <img
                      v-if="group.partner.logoUrl"
                      :alt="group.partner.name"
                      :src="group.partner.logoUrl"
                      class="image"
                    />
                    <v-icon v-else color="primary">fa-user-circle</v-icon>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <p class="title ma-0">{{group.partner.name}}</p>
                    <p
                      class="subtitle-1 ma-0"
                      v-if="group.partner.address.city"
                    >{{group.partner.address.city}} - {{group.partner.address.state}}</p>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <v-btn target="_blank" small text color="primary" :href="`/group/${group.slug}/terms`">Terms and Conditions <v-icon small right>fa-external-link-alt</v-icon></v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-card-subtitle>

            <!-- Section Description -->
            <div class="pa-4 pt-0 tour-details">
              <v-sheet elevation="1" class="pa-10" color>
                <div class="box-description" v-html="group.description"></div>
              </v-sheet>
            </div>

            <!-- Section items -->
            <div v-if="isItemsInSameGroup">
              <v-card-text class="pa-4 pt-0" ref="items" v-if="group.items.length > 0">
                <v-toolbar dense elevation="1" color="primary" dark>
                  <v-toolbar-title>Tours</v-toolbar-title>
                </v-toolbar>

                <v-sheet elevation="1" class="pa-10">
                  <!-- <p class="title">Items Available</p> -->
                  <p>All times and dates displayed for this group are in {{groupTimezone | timezoneFilter}} timezone</p>

                  <div class>
                    <v-btn
                      class="my-2"
                      outlined
                      color="primary"
                      @click="$emit('changeStep', 2)"
                      v-if="isCart"
                    >
                      Go To Cart
                      <v-icon small right>fa-shopping-cart</v-icon>
                    </v-btn>
                  </div>
                  <div v-if="canAddMoreItems">
                    <GroupItems :group="group" :itemsLola="itemsToDisplay" :timezone="groupTimezone" />
                  </div>
                  <div v-else>
                    <p><strong>Your cart is full. Please checkout and make another purchase to add more tours.</strong>
                    <br>This is a technical limitation of the website. If you want to book more tours, just finish this purchase and then make another one.</p>
                  </div>
                </v-sheet>
              </v-card-text>
            </div>
            <div v-else>
              <v-alert
                border="bottom"
                elevation="2"
                color="warning"
                class="ma-5 text-center"
              >
                <p>
                  You have items in your cart from different groups.
                  You cannot book items from different groups in the same transaction.
                  <br>
                  This issue can happen when you have multiple other trips/groups tabs open.
                  <br>
                  <br>
                  Please, remove items from different groups from your cart or empty the cart and start again.
                </p>
              </v-alert>
            </div>

            <!-- Section Custom Footer -->
            <v-card-text v-if="group.customFooter" class="mt-2">
              <v-divider class="mb-4"></v-divider>
              <div v-html="group.customFooter"></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import GroupItems from './GroupItems'
import { Hub } from 'aws-amplify'

import filtersMixin from '@/mixins/filters-mixin.js'

export default {
  name: 'GroupLandingPage',
  mixins: [filtersMixin],
  components: {
    GroupItems
  },
  props: {
    group: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    groupTimezone: function () {
      return this.$store.getters.getGroupTimezone
    },
    isCart: function () {
      return this.$store.getters.getCart && this.$store.getters.getCart.items.length > 0 ? true : false
    },
    cartSize: function() {
      return this.$store.getters.getCartSize
    },
    canAddMoreItems: function() {
      return this.cartSize < 5; // limit cart to 5 items (this is to prevent big orders from failing creation)
    },
    itemsToDisplay: function() {
      if(this.group && this.group.items) {
        return this.group.items.filter(it => it && !it.isHidden)
      }else {
        return []
      }
    },
    isItemsInSameGroup: function() {
      return this.$store.getters.getCart?.items.every(el => el?.groupId === this.group?._id)
    }
  },
  methods: {
    goto(refName) {
      try {
        let element = this.$refs[refName];
        element.scrollIntoView({ behavior: 'smooth' });
      } catch{
        this.$log.info('nothing')
      }
    }
  },
  mounted() {
    //scroll down to items (used in the cart in step two button Add More)
    Hub.listen('Scroll', (data) => {
      let { payload } = data
      if (payload.data) {
        //we wait a little because the page has to focus on the landing page first (change step), and then scroll down
        setTimeout(() => {
          this.goto('items')
        }, 800)
      }
    })
  }
}
</script>

<style >
.image {
max-height: 4rem;
max-width: 10rem;
}

.v-card__title{
  word-break: break-word !important;
}

@media screen and (max-width: 1024px){
    .box-description .ql-video {
        width: 100% !important;    
        display: block;
        margin: 0 auto;
    }
}

</style>
