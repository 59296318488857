var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"fa-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":"/companies/divisions/groups/create"}},[_vm._v(" Add New Group "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fa fa-plus-circle")])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.slug",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm.getGroupLink(item),"target":"_blank"}},[_vm._v(_vm._s(_vm.getGroupLink(item)))])]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [(item.isActive)?_c('v-chip',{attrs:{"color":"success","small":""}},[_vm._v("Active")]):_c('v-chip',{attrs:{"color":"default","small":""}},[_vm._v("Inactive")])]}},{key:"item.dateLive",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.dateLive,'timezone', _vm.currentTimezone, 'DD-MMM-YYYY')))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.createdAt,'timezone', _vm.currentTimezone, 'DD-MMM-YYYY HH:mm:ss z')))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.updatedAt,'timezone', _vm.currentTimezone, 'DD-MMM-YYYY HH:mm:ss z')))])]}},{key:"item.partner",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"24px","tile":""}},[(item.partner.logoUrl)?_c('img',{attrs:{"alt":item.partner.name,"src":item.partner.logoUrl}}):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fa-user-circle")])],1),_vm._v(" "+_vm._s(item.partner.name)+" ")]}},{key:"item.headerUrl",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"36px"}},[(item.headerUrl)?_c('img',{attrs:{"alt":item.title,"src":item.headerUrl}}):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fa-image")])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v("fa-edit")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openItemsModal(item)}}},on),[_vm._v("fa-stream")])]}}],null,true)},[_c('span',[_vm._v("Items")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.copyItem(item)}}},on),[_vm._v("fa-copy")])]}}],null,true)},[_c('span',[_vm._v("Copy")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v("fa-trash-alt")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }