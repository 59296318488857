<template>
    <div>
        <v-row>
            <v-col>
                <v-card outlined class="mx-auto">
                    <v-card-title>
                        Transactions
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            append-icon="fa-search"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="uniqueTransactions"
                            :search="search"
                            :loading="loading"
                        >
                            <template v-slot:[`item.transaction.transactionType`]="{ item }">
                                <v-chip
                                    color="success"
                                    v-if="item.transaction.transactionType === 'payment'"
                                    small
                                    >Payment</v-chip
                                >
                                <v-chip
                                    color="error"
                                    v-if="item.transaction.transactionType === 'refund'"
                                    small
                                    >Refund</v-chip
                                >
                                <v-chip
                                    color="default"
                                    v-if="item.transaction.transactionType === 'cash'"
                                    small
                                    >Cash</v-chip
                                >
                            </template>

                            <template v-slot:[`item.transaction.amount`]="{ item }">
                                <span
                                    v-if="
                                        item.transaction.transactionType === 'payment' ||
                                            item.transaction.transactionType === 'cash'
                                    "
                                >
                                    {{ item.transaction.amount }}
                                </span>
                                <span v-if="item.transaction.transactionType === 'refund'"
                                    >-{{ item.transaction.amount }}</span
                                >
                            </template>

                            <template v-slot:[`item.transaction.feeLolaguide`]="{ item }">
                                <span>{{ item.transaction.feeLolaguide || '-' }}</span>
                            </template>

                            <template v-slot:[`item.transaction.partnerCommission`]="{ item }">
                                <span>{{ item.transaction.partnerCommission || '-' }}</span>
                            </template>

                            <template v-slot:[`item.transaction.createdAt`]="{ item }">
                                <span>{{
                                    item.transaction.createdAt
                                        | moment(
                                            'timezone',
                                            currentTimezone,
                                            'DD-MMM-YYYY HH:mm:ss z'
                                        )
                                }}</span>
                            </template>

                            <template v-slot:[`item.action`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:[`activator`]="{ on }">
                                        <v-icon
                                            v-on="on"
                                            small
                                            class="mr-2"
                                            @click="view(item.order)"
                                            >fa-external-link-alt</v-icon
                                        >
                                    </template>
                                    <span>View Order</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { sendAlert } from '@/store/notifications-hub'
import filtersMixin from '@/mixins/filters-mixin.js'
export default {
    name: 'DivisionTransactions',
    mixins: [filtersMixin],
    computed: {
        uniqueTransactions() {
            // because every item in an order has a transaction -- and that transaction can be the same for multiple items -- we do this here just not show duplicates
            const unique = []
            for (const tx of this.transactions) {
                if (!unique.some(el => el.transaction.gatewayId === tx.transaction.gatewayId)) {
                    unique.push(tx)
                }
            }
            return unique
        },
    },
    data: function() {
        return {
            loading: true,
            transactions: [],
            search: '',
            headers: [
                { text: 'ID', value: 'transaction.lolaId' },
                { text: 'Type', value: 'transaction.transactionType' },
                { text: 'Total Amount (CAD)', value: 'transaction.amount' },
                { text: 'Lolaguide Fee (CAD)', value: 'transaction.feeLolaguide' },
                { text: 'Partner Fee (CAD)', value: 'transaction.partnerCommission' },
                { text: 'Gateway ID', value: 'transaction.gatewayId' },
                { text: 'Customer', value: 'item.fareharborBooking.contact.email' },
                { text: 'Item', value: 'item.title' },
                // { text: 'Item ID', value: 'item.lolaId' },
                { text: 'Order ID', value: 'order.lolaId' },
                { text: 'Created at', value: 'transaction.createdAt' },
                { text: 'Actions', value: 'action', sortable: false },
            ],
        }
    },
    methods: {
        view(order) {
            //https://stackoverflow.com/questions/40015037/can-vue-router-open-a-link-in-a-new-tab
            let routeData = this.$router.resolve({ name: 'orders', params: { id: order._id } })
            window.open(routeData.href, '_blank')
        },

        formatData() {
            this.orders.forEach(order => {
                //items are inside an order
                order.items.forEach(item => {
                    //order has many items, so we iterate again
                    item.transactions.forEach(transaction => {
                        //items has many transactions
                        this.transactions.push({
                            transaction,
                            item,
                            order,
                        })
                    })
                })
            })
        },

        filterByDivision(orders) {
            return orders.filter(o => o.group.division === this.division._id)
        },
    },
    async mounted() {
        try {
            // gets groups in division, then will fetch orders under groups
            let resGroups = await this.$tornos.get(
                `groups?division=${this.division._id}&select=_id`
            )
            let groupsIds = resGroups.data

            let populate = [
                {
                    path: 'customer',
                    select: ['name', 'email'],
                },
                {
                    path: 'group',
                    select: ['title', '_id'],
                },
            ]
            let resOrders = await this.$tornos.post(`orders/query`, {
                populate,
                select: [
                    'lolaId',
                    'isPaid',
                    'isRefunded',
                    'amountTotal',
                    'amountPaid',
                    'amountRefunded',
                    'amountRemaining',
                    'createdAt',
                    'updatedAt',
                    '_id',
                    'items._id',
                    'items.lolaId',
                    'items.title',
                    'items.amountTotal',
                    'items.status',
                    'items.fareharborBooking.contact.email',
                    'items.fareharborBooking.pk',
                    'items.fareharborBooking.availability.start_at',
                    'items.fareharborBooking.availability.end_at',
                    'items.createdAt',
                    'items.amountTotal',
                    'items.amountPaid',
                    'items.transactions',
                ],
                filter: {
                    group: { $in: groupsIds },
                },
            })
            this.orders = resOrders.data
            this.formatData()
        } catch (e) {
            sendAlert({
                event: 'warning',
                data: e,
                message: 'There was an error fetching the orders. Please try again',
            })
        }

        this.loading = false
    },
}
</script>

<style></style>
