<template>
    <v-container fluid class="fill-width ma-0 pa-0">
        <v-row class="my-5">
            <v-col cols="12">
                <p class="title text-left">Payment Details</p>
                <div id="card-element"></div>
            </v-col>
            
            <v-col cols="12" v-if="alert && alert.type">
                <v-alert :type="alert.type" text border="left" class="text-center"  dense>
                    <div class="text-justify">
                    <span>{{alert.message.message}}
                        <span v-if="!alert.message.message">({{alert.message.code}})</span>    
                    </span>
                    </div>
                </v-alert>
            </v-col>

            
            <v-col cols="12" class="text-center">
                <v-btn :loading="loading" :disabled="disabled" block color="success" x-large @click="clickPay">{{buttonLabel}} <v-icon right >fa-check-circle</v-icon></v-btn>                                               
            </v-col>            

            <v-col cols="12" class="mt-6">                
                <v-img class="d-inline-flex" src="/img/cards/cards.png" max-height="40" max-width="180"></v-img>
                <p>We accept Visa, MasterCard and American Express</p>
                <v-chip color="#78909C" dark label><v-icon small left>fa-lock</v-icon> Payment Secure - SSL Encrypted</v-chip>                
            </v-col>
        </v-row>

    </v-container>
</template>

<script>

/* global Stripe */
const stripe = Stripe(process.env.VUE_APP_STRIPE_PK_KEY);

export default {
    name: 'StripeElements',
    props: {
        buttonLabel: {
            type: String,
            required: false,
            default: 'Pay Now'
        },
        payment: {
            type: String,
            required: true
        }
    },
    computed: {
        isReady: function(){
            return this.payment.ready
        }
    },
    watch: {
        //btn loading is controlled by prop
        'payment.loading': function(newVal){
            this.loading = newVal
        },

        //this is used in sync with the Checkout page
        //so whenever there's a booking and it's all good to be paid this property will change
        'payment.ready': function(newVal){
            //this.$log.info('watch payment.ready ' + newVal )
            if(newVal === true){
                //this.$log.info("Payment is ready to be made!")
                //attemps payment now
                this.pay() //uses the payment in the prop
            }
        },

        //if there's an error we invalidate the payment, user needs to click the btn again
        'alert.type': function(newVal){
            if(newVal === 'error' || newVal === 'warning'){
                this.payment.ready = false
                this.loading = false //payment not ready, enables the button again
            }
        }
    },

    data: function () {
        return {
            disabled: true,
            loading: false,
            alert: {
                message: {},                
                type: ''
            },
            alertDefault: {
                message: {},                
                type: ''
            },

            options: {
                classes: {
                    base: 'stripe-input',
                    focus: 'stripe-input-focused',
                    invalid: 'stripe-input-invalid',
                    complete: 'stripe-input-complete'
                },
                style: {
                    base: {
                        //iconColor: '#c4f0ff',
                        fontWeight: 500,
                        fontFamily: 'Roboto, sans-serif',
                        fontSize: '16px',
                        fontSmoothing: 'antialiased',
                        ':-webkit-autofill': {
                            color: '#424242',
                        },
                        '::placeholder': {
                            color: '#5f5f5f',
                        },
                    },
                    invalid: {
                        iconColor: '#FF9800',
                        color: '#FF9800',
                    },
                }
            }
        }

    },

    methods: {
        clickPay(){
            this.$emit('clickPay')
            this.loading = true
        },

        pay() {

            this.loading = true //btn loading

            stripe.confirmCardPayment(this.payment.paymentIntent.client_secret, {
                payment_method: {
                    card: this.card,
                    billing_details: {
                        name: this.payment.customer.name,
                        email: this.payment.customer.email
                    }                    
                },
                setup_future_usage: 'off_session'
            }).then((result) => {
                if (result.error) {
                    // Show error to your customer (e.g., insufficient funds)
                    this.alert = {
                        message: result.error,
                        type: 'warning'
                    }        
                    
                    this.$emit('paymentFailed', result)
                    
                } else {

                    //this.$log.info("PAYMENT INTENT: " + result.paymentIntent.status)

                    if (result.paymentIntent.status === 'succeeded') {
                        //emits an event so the CheckoutPage knows that the payment is sucessfull and there it will save the order in Lola DB
                        this.$emit('paymentSucceeded', result.paymentIntent)


                    }else if (result.paymentIntent.status === 'payment_failed') {
                        //emits an event so the CheckoutPage knows what to do
                        this.$emit('paymentFailed', result.paymentIntent)

                    }

                    
                }
            }).catch(e => {
                this.$emit('error', e)
            })
        },
    },

    mounted() {

        let elements = stripe.elements()

        // Create an instance of the card Element.
        this.card = elements.create('card', this.options);

        // Add an instance of the card Element into the `card-element` <div>.
        this.card.mount('#card-element');
        
        this.card.addEventListener('change', ({ error }) => {
            if (error) {
                this.alert = {
                    message: error,
                    type: 'warning'
                }
                this.disabled = true //disable-enable btn
            } else {
                this.alert = this.alertDefault            
                this.disabled = false //disable-enable btn
            }
        })

    }
}
</script>



<style scoped>
.stripe-input {
    border: 1px solid #9e9e9e;
    padding: 18px;
    border-radius: 4px;
}
.stripe-input-focus {
    border: 1px solid #00acc1;
}
.stripe-input-invalid {
    border: 1px solid #FF9800;
}
.stripe-input-complete {
    border: 1px solid #43a047;
}
</style>