import Vue from 'vue'
import axios from 'axios'

Vue.use({
    install(Vue){
        Vue.prototype.$tornos = axios.create({
            baseURL: process.env.VUE_APP_API_LOLAGROUPS_URL,
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                'x-api-key': process.env.VUE_APP_API_LOLAGROUPS_KEY             
            }
        })

        Vue.prototype.$fareharbor = axios.create({
            baseURL: process.env.VUE_APP_API_LOLAFAREHARBOR_URL,
            headers: {
                'x-api-key': process.env.VUE_APP_API_LOLAFAREHARBOR_KEY             
            }
        })
    }
})