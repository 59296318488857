<template>
    <v-sheet elevation="0">
        <v-progress-linear
        indeterminate
        v-show="loading"
        ></v-progress-linear>
        <v-data-iterator
            :items="items"
            :items-per-page.sync="itemsPerPage"
            :page="page"
            :search="searchFieldItems"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            hide-default-footer
            :loading="loading"
            :custom-filter="customFilter"
            class="mt-5"
            v-show="!loading"
        >
            <template v-slot:header>
                <v-text-field
                    v-model="searchFieldItems"
                    clearable
                    hide-details
                    prepend-inner-icon="fa-search"
                    label="Search items..."
                    solo
                ></v-text-field>
            </template>

            <template v-slot:default="{items}">
                <v-row>
                    <v-col
                        v-for="itemLola in items"
                        :key="itemLola.item.name"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                    >
                        <!-- ONLY SHOWS ITEMS THAT ARE THE TYPE ITEM -- NO ADDONS -->
                        <v-card
                            class="mx-auto"
                            max-width="100%"
                            v-if="itemLola.item.itemType === 'item'"
                        >
                            <!-- if there's an image -->
                            <v-img
                                class="white--text align-end"
                                height="200px"
                                :src="itemLola.item.fareharborDetails.image_cdn_url"
                                gradient="to top right, rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30)"
                                lazy-src="https://lola-static.s3-us-west-2.amazonaws.com/image-placeholder-mountains.png"
                            >
                                <v-card-title class="d-block">{{itemLola.item.name}}

                                <!-- item headline - shows if present -->
                                <p class="subtitle-1 d-block mb-0 font-weight-medium" v-if="itemLola.item.headline">
                                    {{itemLola.item.headline}}
                                </p>
                                </v-card-title>
                            </v-img>

                            <!-- if there's NOT image show default
                            <v-img
                                v-else
                                class="white--text align-end"
                                height="200px"
                                src="https://lola-static.s3-us-west-2.amazonaws.com/image-not-available.png"
                            >
                                <v-card-title>{{itemLola.item.name}}</v-card-title>
                                <v-spacer></v-spacer>
                            </v-img> -->

                            <!-- <v-card-subtitle
                                class="pb-0"
                                v-if="itemLola.item.headline"
                            >{{itemLola.item.headline}}</v-card-subtitle> -->
                            <v-card-subtitle class="py-1"></v-card-subtitle>
                            <!-- just a padding to fill the spot so all cards have the same height -->

                            <!-- <v-card-text class="text--primary">
                                <div class="subtitle-1">{{itemLola.item.name}}</div>
                            </v-card-text> -->

                            <v-card-actions>
                                <v-row
                                    v-if="itemLola.item.availabilities && itemLola.item.availabilities.length > 0"
                                >
                                    <v-col                                        
                                        cols="12"
                                        class="text-center pb-0 font-weight-medium grey--text"
                                    >
                                        <p
                                            class="font-weight-medium grey--text"
                                        >Click on a date below to see more details</p>
                                    </v-col>

                                    <!-- AVAILABILITIES -->
                                    <v-col
                                        cols="12"
                                        class="text-center pt-0"
                                        v-for="(availability, index) in itemLola.item.availabilities"
                                        :key="index"
                                    >

                                    <!-- if not hidden (isHidden not equals to true) we show -->
                                    <div v-if="availability.isHidden !== true && availability.capacity > 0">

                                        <p class="caption grey--text">{{availability.capacity}} seats available</p>

                                        <!-- SMALL SCREENS -->
                                        <v-chip 
                                            class="ma-2 d-block d-lg-none" 
                                            @click="viewItem(availability, itemLola.item)"
                                            color="primary"
                                        >
                                            {{availability.fareharborDetails.startDate | moment('timezone', timezone, 'DD-MMM-YYYY' )}}
                                            <span
                                                class="ml-1"
                                                v-if="availability.headline"
                                            > {{availability.headline}}</span>
                                            <v-icon right small>fa-external-link-alt</v-icon>
                                        </v-chip>

                                        <!-- MEDIUM AND LARGE SCREENS -->
                                        <v-btn
                                            class="d-none d-lg-inline"
                                            color="primary"
                                            @click="viewItem(availability, itemLola.item)"
                                        >
                                            {{availability.fareharborDetails.startDate | moment('timezone', timezone, 'DD-MMM-YYYY' )}}
                                            <span
                                                class="ml-1"
                                                v-if="availability.headline"
                                            > {{availability.headline}}</span>
                                            <v-icon right small>fa-external-link-alt</v-icon>
                                        </v-btn>

                                    </div>
                                    <div v-if="availability.capacity == 0" class="mt-9">
                                        <v-btn
                                            color="grey darken-1"
                                            dark
                                            @click="viewItem(availability, itemLola.item)"
                                        >
                                            Sold out
                                        </v-btn>
                                    </div>
                                    <div v-if="availability.capacity <= -1" class="mt-9">
                                        <p class="grey--text">No dates available</p>
                                    </div>
                                    </v-col>
                                </v-row>

                                <v-row
                                    v-else
                                >
                                    <v-col                                        
                                        cols="12"
                                        class="text-center pb-0 font-weight-medium grey--text"
                                    >
                                        <p
                                            class="font-weight-medium grey--text"
                                        >Tour availability coming soon!</p>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:footer>
                <v-container fluid>
                    <v-row class="mt-2" align="center" justify="center">
                        <span class="grey--text">Items per page</span>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on }">
                                <v-btn dark text color="primary" class="ml-2" v-on="on">
                                    {{ itemsPerPage }}
                                    <v-icon>fa-chevron-down</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item
                                    v-for="(number, index) in itemsPerPageArray"
                                    :key="index"
                                    @click="updateItemsPerPage(number)"
                                >
                                    <v-list-item-title>{{ number }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-spacer></v-spacer>

                        <span class="mr-4 grey--text">Page {{ page }} of {{ numberOfPages }}</span>
                        <v-btn fab dark color="primary" class="mr-1" @click="formerPage" small>
                            <v-icon small>fa-chevron-left</v-icon>
                        </v-btn>
                        <v-btn fab dark color="primary" class="ml-1" @click="nextPage" small>
                            <v-icon small>fa-chevron-right</v-icon>
                        </v-btn>
                    </v-row>
                </v-container>
            </template>
        </v-data-iterator>
    </v-sheet>
</template>

<script>
//import { sendAlert } from '@/store/notifications-hub'
export default {
    name: 'GroupItems',
    props: {
        itemsLola: {
            type: Array,
            required: true
        },
        timezone: {
            type: String,
            required: true
        },
        group: {
            type: Object,
            required: true
        }
    },
    computed: {
        numberOfPages() {
            return Math.ceil(this.items.length / this.itemsPerPage)
        },
    },
    data: function () {
        return {
            items: [],

            showItemDetails: {
                itemPk: '',
                show: false
            },

            loading: true,
            dialog: true,
            itemsPerPageArray: [1, 4, 8, 12, 16, 24, 48],
            searchFieldItems: '',
            filter: {},
            sortDesc: false,
            page: 1,
            itemsPerPage: 20,
            sortBy: 'order', //order from lola item - TOPPPP
        }
    },
    watch: {
        //the search field cannot be null otherwise it messes up with the data iterator. It is null when the user click on the X to clear the input
        'searchFieldItems': function (newVal) {
            newVal === null ? this.searchFieldItems = '' : this.searchFieldItems = newVal
        }
    },
    methods: {
        //### DATA ITERATOR METHODS ####
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage(number) {
            this.itemsPerPage = number
        },
        //### END DATA ITERATOR METHODS ####

        customFilter(items, str) {
            //we use the custom filter to filter by the item name otherwise it doesnt work
            //we lowercase the item name and the str because the includes is case-sensitive
            if (items) {
                return items.filter(e => e.item.name.toLowerCase().includes(str.toLowerCase()) ? e : null)
            }
        },

        viewItem(availability, item) {
            this.$router.push({ name: 'availability.book', params: { group: this.group, availability: availability, item: item } })
        },

        async setCapacityInAvailabilities(){
            for(const el of this.itemsLola) {
                for(const avail of el.item.availabilities) {
                    // makes a request to FH and updates the capacity in the each avail of an item in Lola
                    try{
                        const { data } = await this.$fareharbor.get(`/companies/${this.group.division.company.settings.fareharborShortname}/availabilities/${avail.fareharborDetails.availabilityPk}`)
                        avail.capacity = data.availability.capacity
                    }catch(e) {
                        this.$log.error('Error fecthing availability', e)
                    }
                }
            }

            // for some reason the v-data-iterator needs an update event to use the new data in the component, so we just change one of its poperties
            this.itemsPerPage = 16
        },
    },
    async mounted() {
        this.loading = true
        //sets in the data
        //only shows items of type ITEM
        this.items = this.itemsLola.filter(e => e && e.item && e.item.itemType === 'item')
        await this.setCapacityInAvailabilities()
        this.loading = false
    }

}
</script>

<style>
</style>