<template>
  <div id="register">
    <v-row>
      <v-col>
        <v-card outlined>
          <v-container>
            <v-card-title>Manage Employees</v-card-title>

            <v-card-text>
              <!-- ######### EMPLOYEES LIST ######## 
              -->
              <v-row>
                <v-col md="12">
                  <h4>Employees</h4>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">Name</th>
                          <th class="text-center">Lastname</th>
                          <th class="text-center">Email</th>
                          <th class="text-center">Created at</th>
                          <th class="text-center">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in employees" :key="item.name" class="text-center">
                          <td>{{ item.name }}</td>
                          <td>{{ item.lastname }}</td>
                          <td>{{ item.email }}</td>
                          <td>{{ item.createdAt | moment('timezone', currentTimezone, 'DD-MMM-YYYY HH:mm:ss z') }}</td>
                          <td>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  small
                                  class="mr-2"
                                  @click="deleteItem(item)"
                                >fa-trash-alt</v-icon>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>

                <v-col md="12">
                  <v-dialog v-model="dialog" max-width="600px">
                    <template v-slot:activator="{ on }">
                      <v-btn color="primary" dark v-on="on">
                        Add Employee
                        <v-icon small right>fa fa-user-plus</v-icon>
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title>
                        <span class="headline">Add new Employee</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-form>
                            <v-row>
                              <v-col cols="auto" md="12">
                                <div class="pa-2">
                                  <v-text-field
                                    outlined
                                    label="Name"
                                    name="Name"
                                    type="text"
                                    v-model="employee.name"
                                    counter="60"
                                    hint="Employee First Name"
                                  />
                                </div>
                              </v-col>

                              <v-col cols="auto" md="12">
                                <div class="pa-2">
                                  <v-text-field
                                    outlined
                                    label="Lastname"
                                    name="Lastname"
                                    type="text"
                                    v-model="employee.lastname"
                                    counter="60"
                                    hint="Employee Lastname"
                                  />
                                </div>
                              </v-col>

                              <v-col cols="auto" md="12">
                                <div class="pa-2">
                                  <v-text-field
                                    outlined
                                    label="Employee Email"
                                    name="Email"
                                    type="text"
                                    v-model="employee.email"
                                    counter="60"
                                    hint="Employee Email"
                                  />
                                </div>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="default" @click="dialog = false">Cancel</v-btn>
                        <v-spacer></v-spacer>

                        <v-btn color="primary" @click="submitEmployee">Save</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <!-- ######### END EMPLOYEES ######## -->

              <v-divider></v-divider>
            </v-card-text>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { sendAlert, setLoading } from '../../../store/notifications-hub'
import { Auth } from 'aws-amplify'
//import uuidv4 from 'uuid/v4'

import uploadMixin from '@/mixins/upload-mixin.js'
import filtersMixin from '@/mixins/filters-mixin.js'

import nanoid from 'nanoid'

export default {
  name: 'EmployeesList',
  mixins: [uploadMixin, filtersMixin],
  data() {
    return {
      dialog: false,
      employees: [],
      employee: {
        name: '',
        lastname: '',
        email: '',
        cognitoId: '',
        divisions: []
      },
      employeeDefault: {
        name: '',
        lastname: '',
        email: '',
        cognitoId: '',
        divisions: []
      }
    }
  },
  methods: {

    deleteItem(item) {
      //this.dialog = true
      // Trigger a confirmation dialog

      this.$dialog
        .confirm('Would you like to delete: ' + item.name, { loader: true })
        .then(async (dialog) => {
          this.$log.info("Item: ", item)
          let res = null
          try {
            await this.$tornos.delete(`/cognito/${item.email}`) //delete from cognito
            res = await this.$tornos.delete(`employees/${item._id}`)
          } catch (e) {
            dialog.close()
            sendAlert({ event: 'danger', data: e, message: 'There was an error. Please try again' })
          }

          //success
          if (res.data.status === 200) {
            sendAlert({ event: 'success', data: res.body, message: `Deleted: ${item.name}` })
          } else {
            sendAlert({ event: 'danger', data: res.data, message: 'There was an error. Please try again' })
          }

          dialog.close()

          this.initialize() //re-load the table

          //dialog closed
        }).catch(() => {
          ////this.$log.info("dialog closed")
        })

    },

    initialize() {
      this.$tornos.get(`employees?divisions=${this.division._id}`).then(res => {
        this.employees = res.data
      }).catch(e => {
        sendAlert({ event: 'danger', data: e, message: 'There was an error fetching the employees.' })
      })
    },

    async submitEmployee() {

      try {

        setLoading(true)

        let password = nanoid(10) //generates password for cognito and send via email to the user

        let cognitoUser = await this.createNewUser(this.employee.email, password)

        if (cognitoUser.userSub) { //if the userSub field exists it was successful
          //sets the cognito id of the new user
          this.employee.cognitoId = cognitoUser.userSub

          this.$log.info("cognito done" + this.employee.cognitoId)

          this.employee.divisions.push(this.division._id)
          let savedUser = await this.$tornos.post("employees", {
            ...this.employee
          })

          //this.$log.info("tornos done")

          this.sendEmailNewUser(savedUser.data, password) //sends email in a separate function so dont stop flow

        } else {
          sendAlert({ event: 'warning', data: cognitoUser, message: 'There was an error creating the user in the pool.' })
          return false
        }

        sendAlert({ event: 'info', data: null, message: 'Employee Added Successfully' })
        this.initialize() //reload the table
        this.dialog = false //closes the dialog
        this.employee = this.employeeDefault //clears the form                

      } catch (e) {
        //this.$log.info(e)
        sendAlert({ event: 'error', data: e, message: 'There was an error saving the employee.' })
      } finally {
        setLoading(false)
      }

    },

    sendEmailNewUser(employee, password) {
      this.$tornos.post(`email/account/employee`, {
        employee: employee,
        password: password,
        division: {
          name: this.division.name,
          domain: this.division.domain
        }
      }).then(() => {
        this.$log.info("Email account employee sent")
      })
    },

    //create new cognito user
    createNewUser(username, password) {
      return new Promise((resolve, reject) => {
        Auth.signUp({ username, password }).then(res => {
          resolve(res)
        }).catch(e => {
          reject(e)
        })
      })
    }
  },

  mounted() {
    this.initialize()
  }

}
</script>