import Vue from 'vue'

import VuejsDialog from 'vuejs-dialog'

// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

// Tell Vue to install the plugin.
Vue.use(VuejsDialog,
    {
        //loader: true,
        okText: 'Confirm',
        cancelText: 'Cancel'
    })