<template>
    <v-container class="py-0">
        <v-row no-gutters>
            <v-col cols="12" v-if="loading">
                <v-progress-linear indeterminate :active="loading"></v-progress-linear>
            </v-col>

            <v-col cols="12" v-else>
                <v-list elevation="2" color v-if="waiversArray.length > 0" > 
                    <v-list-item v-for="(waiver, idxWaiver) in waiversArray" :key="idxWaiver" >
                        <v-list-item-content class="pt-1">
                            <v-list-item-title >
                                <p class="title mb-0">
                                    <v-icon small>fa-file-alt</v-icon>
                                    {{waiver.waiverTemplate.title}}  
                                        -
                                    {{waiver.item.name}}  
                                    
                                </p>                  
                            </v-list-item-title>

                            <v-list-item-subtitle class="subtitle-1">
                                <p class="font-weight-medium mb-0">Traveller</p>
                                <p class="mb-0">Name: {{waiver.traveller.name}}</p>
                                <p class="mb-0">Age: {{waiver.traveller.age}}</p>
                                <div class="mt-2">
                                    <v-chip
                                        small
                                        color="success"
                                        v-if="waiver.isSigned"
                                    >Signed</v-chip>
                                    <span v-if="waiver.isSigned" class="ml-2 body-2">Signed on {{waiver.dateSigned | moment('timezone', waiver.timezone ,'MMMM Do, YYYY')}}</span>
                                    <v-chip small color="warning" v-else>Not Signed</v-chip>
                                </div>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="my-1">
                                <v-btn color="primary" outlined @click="view(waiver)">View <v-icon small right>fa-external-link-alt</v-icon></v-btn>                                
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="mt-3 ">
                                <v-divider></v-divider>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <div v-if="error">
                    <p>There was an error. Details: {{error}} </p>
                </div>

                <div v-if="waiversArray.length <= 0">
                    <p>No waivers for this order.</p>
                </div>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'BookingWaivers',
    props: {
        //order _id from mongo
        orderId: {
            type: String,
            required: false
        },
        // waivers: {
        //     type: Object,
        //     required: true
        // }
    },
    data: function(){
        return {
            error: null,
            loading: true,
            waiversArray: []
        }
    },
    methods: {
        view(waiver){
            //https://stackoverflow.com/questions/40015037/can-vue-router-open-a-link-in-a-new-tab
            let routeData = this.$router.resolve({name: 'waivers.sign', params: {id: waiver._id}})
            window.open(routeData.href, '_blank')
        }
    },
    mounted(){
        this.$tornos.get(`waivers?order=${this.orderId}&populate=waiverTemplate`).then(res => {
            const waivers = res.data
            this.waiversArray = Object.freeze(waivers) // make object not Vue reactive
        }).catch(e => {
            this.error = e
        }).finally(() => this.loading = false)
    }
    

}
</script>

<style>
</style>