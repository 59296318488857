<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        persistent
        no-click-animation
    >
        <v-card>
            <v-toolbar dark color="primary" elevation="2">
                <v-toolbar-title class="px-2">Manage the items for {{group.title}}</v-toolbar-title>

                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    bottom
                    color="cyan lighten-3"
                ></v-progress-linear>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-divider vertical></v-divider>

                    <v-btn dark text @click="cancel()">
                        <v-icon left small>fa fa-times</v-icon>Close
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-row class="ma-0">
                <v-col>
                    <v-container fluid>
                        <v-sheet elevation="1" class="pa-4">
                            <p class="title">{{group.title}} </p>

                            <v-text-field
                                v-model="searchDatatable"
                                append-icon="fa-search"
                                label="Search"
                                single-line
                                hide-details
                                class="py-4"
                            ></v-text-field>

                            <v-data-table
                                :headers="headers"
                                :items="group.items"
                                :search="searchDatatable"
                                :loading="false"
                                show-expand
                                single-expand
                                sort-by="order"
                                item-key="item._id"
                            >
                                <!-- ######### Subtable ############ -->
                                <template v-slot:[`expanded-item`]="{ headers, item }">
                                    <td :colspan="headers.length" class="pa-0 ma-0">
                                        <v-data-table
                                            :headers="headersSubTable"
                                            :items="item.item.availabilities"
                                            dark                                            
                                        >
                                            <template v-slot:[`item.isCustomDetails`]="{ item }">
                                                <span v-if="item.isCustomDetails">Yes</span>
                                                <span v-else>No</span>
                                            </template>

                                            <template
                                                v-slot:[`item.fareharborDetails.startDate`]="{ item }"
                                            >
                                                <span>{{item.fareharborDetails.startDate | moment('timezone', currentTimezone, 'DD-MMM-YYYY HH:mm:ss z')}}</span>
                                            </template>

                                            <template
                                                v-slot:[`item.fareharborDetails.endDate`]="{ item }"
                                            >
                                                <span>{{item.fareharborDetails.endDate | moment('timezone', currentTimezone, 'DD-MMM-YYYY HH:mm:ss z')}}</span>
                                            </template>

                                            <template v-slot:[`item.waiverTemplates`]="{ item }">
                                                <span
                                                    v-for="(template, index) in item.waiverTemplates"
                                                    :key="index"
                                                >
                                                    <span
                                                        v-if="index+1 % 2 == 0 || index == 0"
                                                    >{{template.title}}</span>
                                                    <span v-else>, {{template.title}}</span>
                                                </span>
                                            </template>

                                        </v-data-table>
                                    </td>
                                </template>
                                <!-- ######### END SUBTABLE ############## -->

                                <template v-slot:[`item.isCustomDetails`]="{ item }">
                                    <span v-if="item.item.isCustomDetails">Yes</span>
                                    <span v-else>No</span>
                                </template>

                                <template v-slot:[`item.itemType`]="{ item }">
                                    <!-- item.item because the first item is for the groupItem and the second the item in lola db -->
                                    <span v-if="item.item.itemType === 'item'">Item/Tour</span>
                                    <span
                                        v-if="item.item.itemType === 'addon'"
                                    >Add-on/Optional Activity</span>
                                </template>

                                <template v-slot:[`item.isHidden`]="{ item }">
                                    <span v-if="item.isHidden">Hidden</span>
                                    <span v-else>Visible</span>
                                </template>

                                <template v-slot:[`item.action`]="{ item }">
                                    <v-tooltip bottom>
                                        <template v-slot:[`activator`]="{ on }">
                                            <v-icon
                                                v-on="on"
                                                small
                                                class="mr-2"
                                                @click="editItem(item)"
                                            >fa-edit</v-icon>
                                        </template>
                                        <span>Edit</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                        <template v-slot:[`activator`]="{ on }">
                                            <v-icon
                                                v-on="on"
                                                small
                                                class="mr-2"
                                                @click="manageAvailabilities(item)"
                                            >fa-calendar-alt</v-icon>
                                        </template>
                                        <span>Manage availabilities</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                        <template v-slot:[`activator`]="{ on }">
                                            <v-icon
                                                v-on="on"
                                                small
                                                class="mr-2"
                                                @click="deleteItem(item)"
                                            >fa-trash-alt</v-icon>
                                        </template>
                                        <span>Delete</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                        <template v-slot:[`activator`]="{ on }">
                                            <v-icon
                                                v-on="on"
                                                small
                                                class="mr-2"
                                                @click="syncItemFareharbor(item)"
                                            >fa-sync</v-icon>
                                        </template>
                                        <span>Sync with Fareharbor</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                        </v-sheet>
                    </v-container>
                </v-col>
            </v-row>

            <!-- PANEL ITEMS FROM FAREHARBOR -->
            <v-row class="ma-0">
                <v-col cols="12">
                    <v-container fluid>
                        <v-sheet elevation="2" class="pa-4 mt-4">
                            <p class="title"><v-icon color="primary">fa fa-plus-circle</v-icon> Add more items </p>

                            <v-btn outlined color="primary" @click="showFareharborPanel">
                                <span v-if="!showItemsFhPanel">
                                    View items from Fareharbor
                                    <v-icon right small>fa fa-eye</v-icon>
                                </span>
                                <span v-else>
                                    Hide items from Fareharbor
                                    <v-icon right small>fa fa-eye-slash</v-icon>
                                </span>
                            </v-btn>

                            <v-data-iterator
                                v-if="showItemsFhPanel"
                                :items="itemsFareharbor"
                                :items-per-page.sync="itemsPerPage"
                                :page="page"
                                :search="search"
                                :sort-by="sortBy.toLowerCase()"
                                :sort-desc="sortDesc"
                                hide-default-footer
                                :loading="loading"
                                class="mt-5"
                            >
                                <template v-slot:header>
                                    <v-toolbar class="mb-1 pa-0" elevation="0">
                                        <v-text-field
                                            v-model="search"
                                            clearable
                                            hide-details
                                            prepend-inner-icon="fa-search"
                                            label="Search items..."
                                        ></v-text-field>
                                        <v-spacer></v-spacer>

                                        <!-- refresh items - get from API -->
                                        <v-btn
                                            dark
                                            color="primary"
                                            @click="reloadItemsFareharbor()"
                                        >
                                            <v-icon left small>fa fa-sync</v-icon>Refresh Items
                                        </v-btn>
                                    </v-toolbar>
                                </template>

                                <template v-slot:default="props">
                                    <v-row>
                                        <v-col
                                            v-for="itemFh in props.items"
                                            :key="itemFh.pk"
                                            cols="12"
                                            sm="6"
                                            md="4"
                                            lg="3"
                                        >
                                            <v-card class="mx-auto" max-width="400">
                                                <!-- if there's an image -->
                                                <v-img
                                                    v-if="itemFh.image_cdn_url"
                                                    class="white--text align-end"
                                                    height="200px"
                                                    :src="itemFh.image_cdn_url"
                                                >
                                                    <v-card-title>{{itemFh.name}}</v-card-title>
                                                </v-img>

                                                <!-- if there's NOT image show default -->
                                                <v-img
                                                    v-else
                                                    class="white--text align-end"
                                                    height="200px"
                                                    src="https://lola-static.s3-us-west-2.amazonaws.com/image-not-available.png"
                                                >
                                                    <v-card-title>{{itemFh.name}}</v-card-title>
                                                </v-img>

                                                <v-card-subtitle
                                                    class="pb-0"
                                                    v-if="itemFh.headline"
                                                >{{itemFh.headline}}</v-card-subtitle>
                                                <v-card-subtitle class="pb-0" v-else>-</v-card-subtitle>

                                                <v-card-text class="text--primary">
                                                    <div class="subtitle-1">{{itemFh.name}}</div>
                                                    <div class="subtitle-2">PK: {{itemFh.pk}}</div>
                                                </v-card-text>

                                                <v-card-actions>
                                                    <v-btn color="primary" @click="addItem(itemFh)">
                                                        Add Item
                                                        <v-icon right small>fa-plus-circle</v-icon>
                                                    </v-btn>

                                                    <v-btn
                                                        icon
                                                        @click="showItemDetails = { itemPk: itemFh.pk, show: !showItemDetails.show  }"
                                                    >
                                                        <v-icon>{{ show ? 'fa-chevron-up' : 'fa-chevron-down' }}</v-icon>
                                                    </v-btn>
                                                </v-card-actions>

                                                <v-expand-transition>
                                                    <div
                                                        v-show="showItemDetails.itemPk === itemFh.pk && showItemDetails.show === true"
                                                    >
                                                        <v-divider></v-divider>

                                                        <v-card-text>
                                                            <p class="subtitle-2">Description</p>
                                                            {{itemFh.description}}
                                                        </v-card-text>

                                                        <v-divider></v-divider>

                                                        <v-card-text>
                                                            <p class="subtitle-2">Booking notes</p>
                                                            {{itemFh.booking_notes}}
                                                        </v-card-text>
                                                    </div>
                                                </v-expand-transition>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </template>

                                <template v-slot:footer>
                                    <v-container fluid>
                                        <v-row class="mt-2" align="center" justify="center">
                                            <span class="grey--text">Items per page</span>
                                            <v-menu offset-y>
                                                <template v-slot:[`activator`]="{ on }">
                                                    <v-btn
                                                        dark
                                                        text
                                                        color="primary"
                                                        class="ml-2"
                                                        v-on="on"
                                                    >
                                                        {{ itemsPerPage }}
                                                        <v-icon>fa-chevron-down</v-icon>
                                                    </v-btn>
                                                </template>

                                                <v-list>
                                                    <v-list-item
                                                        v-for="(number, index) in itemsPerPageArray"
                                                        :key="index"
                                                        @click="updateItemsPerPage(number)"
                                                    >
                                                        <v-list-item-title>{{ number }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>

                                            <v-spacer></v-spacer>

                                            <span
                                                class="mr-4 grey--text"
                                            >Page {{ page }} of {{ numberOfPages }}</span>
                                            <v-btn
                                                fab
                                                dark
                                                color="primary"
                                                class="mr-1"
                                                @click="formerPage"
                                                small
                                            >
                                                <v-icon small>fa-chevron-left</v-icon>
                                            </v-btn>
                                            <v-btn
                                                fab
                                                dark
                                                color="primary"
                                                class="ml-1"
                                                @click="nextPage"
                                                small
                                            >
                                                <v-icon small>fa-chevron-right</v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-container>
                                </template>
                            </v-data-iterator>
                        </v-sheet>
                    </v-container>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import { sendAlert, setLoading } from '../../../store/notifications-hub'
//import momentTz from 'moment-timezone'
import filtersMixin from '@/mixins/filters-mixin.js'

export default {
    name: 'ModalChooseGroupItems',
    mixins: [filtersMixin],
    props: {
        //group _id from mongo - we fetch it on mounted
        groupId: {
            type: String,
            required: true
        },
        from: {
            type: String,
            required: false
        }
    },
    data: function () {
        return {
            group: {}, //group has all items that we show in the datatable
            showItemsFhPanel: false,
            searchDatatable: '',
            headers: [
                //{ text: 'Item UUID (Lola)', value: 'item._id' },
                { text: 'Item Name', value: 'item.name' },
                { text: 'Item PK (FH)', value: 'item.fareharborDetails.pk' },
                { text: 'Item Headline', value: 'item.headline' },
                { text: 'Item Type', value: 'itemType' },
                { text: 'Custom Details', value: 'isCustomDetails' },
                { text: 'Display Order', value: 'order' },
                { text: 'Visibility', value: 'isHidden' },
                { text: 'Actions', value: 'action', sortable: false },
            ],

            headersSubTable: [
                { text: 'Availability PK (FH)', value: 'fareharborDetails.availabilityPk' },
                { text: 'Date Start', value: 'fareharborDetails.startDate' },
                { text: 'Date End', value: 'fareharborDetails.endDate' },
                { text: 'Headline', value: 'headline' },
                { text: 'Custom Details', value: 'isCustomDetails' },
                { text: 'Waiver Templates', value: 'waiverTemplates' }
            ],

            itemsFareharbor: [],

            showItemDetails: {
                itemPk: '',
                show: false
            },

            loading: false,
            dialog: true,
            itemsPerPageArray: [1, 4, 8, 12, 24, 48],
            search: '',
            filter: {},
            sortDesc: false,
            page: 1,
            itemsPerPage: 4,
            sortBy: 'name',

            item: {},

        }
    },

    computed: {
        numberOfPages() {
            return Math.ceil(this.itemsFareharbor.length / this.itemsPerPage)
        },
        itemsCache() {
            return this.$store.getters.itemsFareharbor
        }
    },

    watch: {
        //hides loading if there's a value in the array
        'itemsFareharbor': function (newVal) {
            if (newVal.length > 0) {
                this.loading = false
            }
        }
    },

    methods: {

        //### DATA ITERATOR METHODS ####
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage(number) {
            this.itemsPerPage = number
        },
        //### END DATA ITERATOR METHODS ####

        cancel() {
            //if the modal was opened coming from the create/update form we need to send back the group so it's loaded in the form again, otherwise it will be empty
            if (this.from && this.from === 'create') {
                this.$router.push({ name: 'groups.create', params: { edit: this.group } })
            } else {
                this.$router.push({ name: 'groups.list' })
            }

        },

        reloadItemsFareharbor() {
            this.initializeFareharbor(true) //force api
        },

        showFareharborPanel() {
            this.showItemsFhPanel = !this.showItemsFhPanel

            //if it's to show we load the items
            //if the items are in cache it's gonna be fast. We do it to avoid loading on mounted an load only when the user requests
            if (this.showItemsFhPanel) {
                this.initializeFareharbor()
            }
        },

        async initializeFareharbor(forceApi) {

            try {
                setLoading(true)

                //get items from cache if they exist
                //if forceApi is true we will skip cache and get from the API
                if (this.itemsCache && this.itemsCache.length > 0 && !forceApi) {
                    this.itemsFareharbor = this.itemsCache

                //get items from FH API
                } else {

                    this.itemsFareharbor = []

                    let itemsResponse = await this.$fareharbor.get(`/companies/${this.division.company.settings.fareharborShortname}/items/`)

                    //this.$log.info(itemsResponse)

                    this.itemsFareharbor = itemsResponse.data.items
                    this.isItemsFromCache = false
                    //let expiry = momentTz().add(15, 'minutes').valueOf() //expiry in milliseconds since epoch - 15 minutes
                    this.$store.dispatch('setItemsFareharbor', itemsResponse.data.items)                        

                }//end if cache

            } catch (e) {
                sendAlert({ event: 'danger', data: e, message: 'There was an error fetching the items/availabilities from Fareharbor' })
            } finally {
                setLoading(false)
            }
        },

        //add new item to Lola and associate to the group
        addItem(itemFh) {
            let item = {
                systemType: 'fareharbor',
                name: itemFh.name,
                fareharborDetails: itemFh, //adds the entire FH item so we dont need to fetch it on the booking page
                isCustomDetails: false,
                division: this.division,
                itemType: 'item'
            }

            let itemGroup = {
                item: item,
                order: this.group.items.length + 1 //order is plus 1 whatever the array is at the moment
            }
            this.$router.push({ name: 'groups.create.items.edit', params: { itemGroup: itemGroup, group: this.group, action: 'create', from: this.from } })
        },

        manageAvailabilities(itemGroup) {
            this.$router.push({ name: 'groups.create.items.availabilities', params: { item: itemGroup.item, group: this.group, from: this.from } })
        },

        //edit lola item
        editItem(itemGroup) {
            //itemGroup because it's the item from the array in the Group
            this.$router.push({ name: 'groups.create.items.edit', params: { itemGroup: itemGroup, group: this.group, action: 'update', from: this.from } })
        },

        //delete lola item from group and db
        async deleteItem(itemGroup) {

            this.$dialog
                .confirm(`Would you like to delete the item ${itemGroup.item.name}`, { loader: true })
                .then(async (dialog) => {

                    try {
                        setLoading(true)

                        //gets a new array with all elements except the one being deleted (by _id)
                        let itemGroupArrayToUpdate = this.group.items.filter(e => e._id !== itemGroup._id)

                        //updates the group with a new array without the itemGroup being deleted
                        this.group.items = itemGroupArrayToUpdate
                        await this.$tornos.put(`groups/${this.group._id}`, this.group)
                        
                        //deletes the availabilities of this item
                        itemGroup.item.availabilities.forEach(async (a) => {
                            await this.$tornos.delete(`availabilities/${a._id}`)                            
                        })
                        
                        await this.$tornos.delete(`items/${itemGroup.item._id}`) //deletes the item from the db
                        
                        sendAlert({ event: 'info', data: null, message: 'Item deleted successfully. All availabilities related to it were also deleted.' })

                        this.initialize() //reloads the table
                    } catch (e) {
                        sendAlert({ event: 'danger', data: e, message: 'There was an error deleting the item from Lola Groups' })                        
                    } finally {
                        setLoading(false)
                        dialog.close() //closes dialog
                    }

                    //dialog.close() //closes dialog

                    //dialog closed
                }).catch(() => {
                    this.$log.info("dialog closed")
                })

        },

        syncItemFareharbor(itemGroup){

            setLoading(true)
            
            let item = itemGroup.item //item is inside the itemGroup (itemGroup is the object inside the array inside the group)

            this.$fareharbor.get(
                `companies/${this.division.company.settings.fareharborShortname}/items/${item.fareharborDetails.pk}`)
            .then(res => {

                if(res.data.length > 0){
                    item.fareharborDetails = res.data[0] //sets the item from FH

                    //updates the item in the db
                    this.$tornos.put(`items/${item._id}`, item)

                    sendAlert({ event: 'info', data: null, message: 'Item synced successfully' })
                }else{
                    sendAlert({ event: 'warning', data: null, message: 'Failed - Item not found.' })
                }
            }).catch(e => {
                sendAlert({ event: 'error', data: e, message: 'There was an error' })
            }).finally(() => setLoading(false))
        },

        initialize() {

            this.loading = true
            let populate = [
                {
                    path: "items.item",
                    populate: { path: 'availabilities', 
                        populate: { path: 'waiverTemplates'}
                    }
                }
            ]
           
            //this.$tornos.post(`groups/${this.groupId}?populate=items.item`).then(res => {
            this.$tornos.post(`groups/query`, {
                filter: { _id: this.groupId },
                populate
            }).then(res => {
                this.group = res.data[0] //data comes as an array, gets first element which is the group
            }).catch(e => {
                sendAlert({ event: 'danger', data: e, message: 'There was an error fetching the group details' })
            }).finally(() => this.loading = false)
        }

    },
    mounted() {
        this.initialize()
    }
}
</script>

