<template>
    <v-container class="pa-0">
        <v-row no-gutters>
            <v-col>
                <v-card class="mx-auto" color="grey lighten-4" tile>
                    <v-img
                        class="white--text align-end"
                        max-height="200"
                        :src="item.fareharborItem.image_cdn_url"
                        lazy-src="https://lola-static.s3-us-west-2.amazonaws.com/image-placeholder-mountains.png"
                        gradient="to top right, rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40)"
                    >
                        <v-card-title
                            class="headline font-weight-bold"
                        >{{item.title}} {{item.headline}}</v-card-title>
                    </v-img>

                    <v-card-subtitle class="pb-0">
                        <v-row>
                            <v-col class="pt-0">
                                <v-sheet elevation="1" class="pa-2 mb-2">
                                    <p class="subtitle-2">
                                        Booking ID #{{item.lolaId}} (FH #{{item.fareharborBooking.pk}})
                                    </p>
                                </v-sheet>
                                
                                <v-list elevation="2" >
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="title my-2">
                                                <v-icon left>fa-calendar-alt</v-icon>
                                                {{item.fareharborBooking.availability.start_at | moment('timezone', timezone, 'dddd, MMMM Do YYYY @ HH:mma')}}
                                            </v-list-item-title>

                                            <v-list-item-subtitle class="subtitle-1">
                                                {{item.fareharborBooking.availability.start_at | moment('timezone', timezone, 'DD-MMM-YYYY HH:mm:ss')}}
                                                <v-icon small>fa-clock</v-icon>
                                                {{item.fareharborBooking.availability.end_at | moment('timezone', timezone, 'DD-MMM-YYYY HH:mm:ss')}} ({{timezone | timezoneFilter}})
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>

                    <v-card-subtitle class="body-1 pb-0" v-if="item.fareharborItem.locations && item.fareharborItem.locations.length > 0">
                        <v-row>
                            <v-col class="pt-0">
                                <v-list elevation="0" color="primary darken-1" dark>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="capitalize my-2">
                                                <v-icon left>fa-map-marker-alt</v-icon>Please meet us at:                                                
                                            </v-list-item-title>
                                            <v-list-item-title class="my-2">
                                                <span class="">{{item.fareharborItem.locations[0].note}}</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle
                                                v-if="item.fareharborItem.locations.length > 0 && item.fareharborItem.locations[0].address.street"
                                            >
                                                {{item.fareharborItem.locations[0].address.street}}
                                                <br />
                                                {{item.fareharborItem.locations[0].address.city}},
                                                {{item.fareharborItem.locations[0].address.province}} -
                                                {{item.fareharborItem.locations[0].address.postal_code}},
                                                {{item.fareharborItem.locations[0].address.country}}
                                            </v-list-item-subtitle>

                                            <v-list-item-subtitle
                                                v-if="item.fareharborItem.locations.length > 0 && item.fareharborItem.locations[0].google_place_id"
                                            >
                                                <v-btn
                                                    class="pa-0"
                                                    link
                                                    text
                                                    target="_blank"
                                                    :href="`https://www.google.com/maps/search/?api=1&query=${item.fareharborItem.locations[0].address.street},${item.fareharborItem.locations[0].address.postal_code}&query_place_id=${item.fareharborItem.locations[0].google_place_id}`"
                                                >
                                                    <v-icon small class="mr-1">fa-map-marked-alt</v-icon>Google Maps
                                                </v-btn>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>

                    <!-- TRAVELLERS - FROM FH BOOKING -->
                    <v-card-subtitle class="body-1 pb-0">
                        <v-row>
                            <v-col class="pt-0">
                                <v-list elevation="1">
                                    <v-subheader class="subtitle-1 font-weight-bold">Travellers</v-subheader>
                                    <v-list-item
                                        v-for="(traveller, idx) in item.fareharborBooking.customers"
                                        :key="idx"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title class="capitalize subtitle-2 mb-2">
                                                <v-icon small>fa-user</v-icon>
                                                {{traveller.customer_type_rate.customer_prototype.display_name}}
                                            </v-list-item-title>

                                            <!-- CUSTOM FIELDS -->
                                            <v-list-item-subtitle>
                                                <booking-custom-field
                                                    :field="field"
                                                    v-for="(field, idxField) in getArraySorted(traveller.custom_field_values)"
                                                    :key="idxField"
                                                />
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>

                    <!-- TRANSACTIONS -->
                    <v-card-subtitle class="body-1 pb-0">
                        <v-row>
                            <v-col class="pt-0">
                                <v-list elevation="1">
                                    <v-subheader class="subtitle-1 font-weight-bold">Transactions</v-subheader>
                                    <v-list-item
                                        v-for="(transaction, idx) in item.transactions"
                                        :key="idx"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title class="capitalize subtitle-2">
                                                <v-icon
                                                    small
                                                    v-if="transaction.transactionType === 'payment'"
                                                >fa-credit-card</v-icon>
                                                <v-icon
                                                    small
                                                    v-if="transaction.transactionType === 'refund'"
                                                >fa-undo</v-icon>
                                                <v-icon
                                                    small
                                                    v-if="transaction.transactionType === 'cash'"
                                                >fa-money-bill</v-icon>
                                                {{transaction.transactionType}}
                                            </v-list-item-title>
                                            <v-list-item-subtitle
                                                class="mt-2"
                                            >ID: #{{transaction.lolaId}}</v-list-item-subtitle>
                                            <v-list-item-subtitle>Amount: {{transaction.amount | currency}}</v-list-item-subtitle>
                                            <v-list-item-subtitle>Created at: {{transaction.createdAt | moment('timezone', timezone, 'DD-MMM-YYYY HH:mm:ss')}} ({{timezone | timezoneFilter}})</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>

                    <v-card-subtitle class="pb-4">
                        <v-sheet elevation="1">
                            <v-tabs grow background-color="grey lighten-2">
                                <v-tabs-slider color="grey darken-4"></v-tabs-slider>
                                <v-tab href="#tab-1" class="grey--text text--darken-4">
                                    <v-icon left>fa-align-justify</v-icon>Important Information
                                </v-tab>
                                <v-tab href="#tab-2" class="grey--text text--darken-4">
                                    <v-icon left>fa-map-marked</v-icon>Itinerary
                                </v-tab>                      

                                <v-tab-item value="tab-1">
                                    <div class="pa-2 custom-html"  v-html="item.description"></div>                                    
                                </v-tab-item>
                                <v-tab-item value="tab-2">
                                    <div class="pa-2 custom-html"  v-html="item.itinerary"></div>
                                </v-tab-item>                                
                            </v-tabs>

                        </v-sheet>
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import BookingCustomField from '@/components/Booking/BookingCustomField'
import filtersMixin from '@/mixins/filters-mixin.js'

export default {
    name: 'BookingItem',
    mixins: [filtersMixin],
    props: {
        item: {
            type: Object,
            required: true
        },
        timezone: {
            type: String,
            required: false,
            default: 'America/Vancouver'
        }
    },
    components:{
        BookingCustomField
    },
    data: function () {
        return {
            tab: 0
        }
    },
    methods: {
        //this method is just a filter for the custom fields so we always get it in the same order for every customer
        //we get every field in the same order because we sort them comparing the pk
        getArraySorted(val){
            return val.sort((a, b) => {
                return a.pk - b.pk
            })
        }
    }
}
</script>

<style scoped>
/* used to avoid images in the html to exceed the size of the screen */
.custom-html > p > img{
    max-width: 100%;
}
</style>