<template>
  <v-container>
      <v-row>
          
          <v-col cols="12" v-for="(field, i) in waiver.signedFields" :key="i">
            <p class="font-weight-medium">{{field.title}}</p>
            <v-img v-if="field.type === 'signature'" :src="field.value" max-width="600"></v-img>
            <p v-else>{{field.value}}</p>
          </v-col>

          <v-col cols="12" >
            <p class="mb-2 font-weight-medium">Electronic Signature Consent</p>
            <p class="text-justify grey lighten-4 pa-2">{{waiver.signedWaiver.consent}}</p>
            <p class="font-weight-medium">Date signed: {{waiver.dateSigned | moment('timezone', waiver.timezone ,'MMMM Do, YYYY HH:mm:ss')}} ({{waiver.timezone | timezoneFilter}})</p>
        </v-col>

      </v-row>
  </v-container>
</template>

<script>
import filtersMixin from '@/mixins/filters-mixin.js'

export default {
    name: 'SignedFields',
    mixins: [filtersMixin],
    props: {
        waiver:{
            type: Object,
            required: true
        }
        
    }
}
</script>

<style>

</style>