import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from "vuex-persistedstate"
import {Auth} from 'aws-amplify'
import uuidv4 from 'uuid/v4'

Vue.use(Vuex)


function calculatePrice({travellers}){
  let total = 0
  let totalExtra = 0

  if(travellers.length > 0){ //only do the calc if there are travellers in the array
      travellers.forEach(traveller => {

          //checks if a customerTypeRate has been selected
          if(traveller.customerTypeRate.obj){

              //price base for the customer type rate
              total += traveller.customerTypeRate.obj.total_including_tax
              
              traveller.customerTypeRate.fields.forEach(e => {

                //amount is in the custom field
                //se CustomField[].vue files to see calculation
                //we set the price in the amount poperty when a custom field has a price or an extended option with a price
                //so we can get here the extra costs - this is for the custom field, not the customer type rate
                if(e && e.amount){
                    //this.$log.info('amount: ' + e.amount)
                    totalExtra = totalExtra + e.amount
                }
            })
                      
          }
      })
      
  }//end if travellers length
  
  //divide by 100 because price is in cents in FH
  total = total / 100
  totalExtra = totalExtra /100

  return {
    total ,
    totalExtra
  }
}


///// --- VUEX
export default new Vuex.Store({
  // plugins: [createPersistedState({
  //   storage: {
  //     getItem: key => Cache.getItem(key),
  //     setItem: (key, value) => Cache.setItem(key, value),
  //     removeItem: key => Cache.removeItem(key)
  //   }
  // })],

  plugins: [createPersistedState()],

  state: {
    groupTimezone: 'America/Vancouver',
    cart: {
      total: 0,
      totalExtra: 0,
      totalSum: 0,
      totalSumCents: 0,      
      items: [],
      orderUuid: null,
      groupId: null
    },

    //login / session management
    loggedUser: {      
      type: '',
      user: '',
    },

    //employees
    currentDivision: {},
    itemsFareharbor: [] //used in the group/items management

  },
  getters: {
    getGroupTimezone: state => {
      return state.groupTimezone
    },
    getCart: state => {
      return state.cart
    },
    loggedUser: state => {
      return state.loggedUser
    },
    userType: state => {
      return state.loggedUser.type
    },
    isLoggedIn: state => {
      return state.loggedUser.user ? true : false
    },
    currentDivision: state => {
      return state.currentDivision
    },
    itemsFareharbor: state => {
      return state.itemsFareharbor
    },
    getCartSize: state => {
      return state.cart.items ? state.cart.items.length : 0
    },
    getCartAvailabilities: state => {
      if(state.cart) {
        return state.cart.items.map(item => Number(item.availability.fareharborDetails.availabilityPk))
      }else {
        return []
      }      
    },
    getCartGroupId: state => { 
      return state.cart?.groupId
    }
  },
  mutations: {
    SET_GROUP_TIMEZONE(state, payload){
      state.groupTimezone = payload
    },
    SET_CART(state, payload){
      state.cart = payload
    },

    //both for login, one sets the user and one the type
    SET_LOGGED_USER(state, payload){
      state.loggedUser.user = payload
    },
    SET_USER_TYPE(state, payload){
      state.loggedUser.type = payload
    },

    SET_CURRENT_DIVISION(state, payload){
      state.currentDivision = payload
    },
    SET_ITEMS_FAREHARBOR(state, payload){
      state.itemsFareharbor = payload
    }
  },
  actions: {
    setGroupTimezone({commit}, payload){
      commit('SET_GROUP_TIMEZONE', payload)
    },

    clearCart({commit}){
      commit('SET_CART', {
        total: 0,
        totalExtra: 0,
        totalSum: 0,
        totalSumCents: 0,
        items: [],
        orderUuid: null,
        groupId: null
      })
    },

    addToCart({commit}, payload){

      let resulTotalForThisItem = calculatePrice(payload)

      let cart = this.getters.getCart

      //updates cart
      cart.total += resulTotalForThisItem.total
      cart.totalExtra += resulTotalForThisItem.totalExtra
      cart.totalSum = cart.total + cart.totalExtra
      cart.totalSumCents = cart.totalSum * 100 //cents used in the payment for stripe
      cart.items.push( {...payload, total: resulTotalForThisItem})

      //order uuid -> it's unique per cart and when the customer finishes the payment it's used to create and order uuid and set the Stripe idempotency key
      cart.orderUuid = cart.orderUuid ? cart.orderUuid : uuidv4() //if it doesnt exist create a new one

      // sets the group _id that is currently being checked out (this will be the group of the first item added to the cart)
      cart.groupId = payload.groupId
      
      commit('SET_CART', cart)      
    },

    removeFromCart({commit}, index){
      let cart = this.getters.getCart
      let item = cart.items[index]

      cart.total -=  item.total.total //subtracts
      cart.totalExtra -= item.total.totalExtra //subtracts

      cart.totalSum = cart.total + cart.totalExtra
      cart.totalSumCents = cart.totalSum * 100 //cents used in the payment for stripe
      
      //removes from array
      cart.items.splice(index, 1)

      commit('SET_CART', cart) //updates the cart
      
    },

    //SESSION MANAGEMENT
    login({commit}, {user, type}){
      commit('SET_LOGGED_USER', user)      
      commit('SET_USER_TYPE', type)      
    },

    setUserType({commit}, payload){
      commit('SET_USER_TYPE', payload)
    },

    logout({commit}, payload){
      //we might send a userType when the user is in the login page
      payload && payload.userType ? commit('SET_USER_TYPE', payload.userType) : commit('SET_USER_TYPE', '')
      commit('SET_LOGGED_USER', '')
      commit('SET_CURRENT_DIVISION', {})
      commit('SET_ITEMS_FAREHARBOR', [])
      Auth.signOut()
      //this.$log.info('logout')
    },

    //EMPLOYESS
    setDivision({commit}, payload){
      commit('SET_CURRENT_DIVISION', payload)
    },

    setItemsFareharbor({commit}, payload){
      commit('SET_ITEMS_FAREHARBOR', payload)
    },


  }  
})
