import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/index-router'
import store from './store/index-store'
import './plugins/vee-validate'
import './plugins/quill'
import './plugins/amplify'
import './plugins/axios'
import './plugins/vue-dialog'
import './plugins/vue-moment'
import './plugins/vue-filters2'
import './plugins/remove-warnings'
import './plugins/vue-currency-filter'
import './plugins/vue-signature-pad'
import '@babel/polyfill'
import './plugins/vuejs-logger'
import './plugins/ipregistry'
import './plugins/vue-gtag'

import './store/auth'
import './in18/in18-default'

import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
