<template>
    <v-main>
        <v-container class="fill-width ma-0 pa-0" fluid>
            <v-row align justify no-gutters>
                <v-col class="mt-0" >
                    <v-card class="ma-0 pa-0 mx-auto" tile>
                        <v-sheet elevation="1" color="grey lighten-4" class="text-center ">
                            <v-chip class="headline pa-6 mt-5" color=""  large>
                                Shopping Cart
                                <v-icon right>fa fa-shopping-cart</v-icon>
                            </v-chip>
                            <!-- <v-card-title class="justify-center headline text--lighten-0">
                                Shopping Cart
                                <v-icon right>fa fa-shopping-cart</v-icon>
                            </v-card-title> -->
                            <v-card-text class="text-center">
                                <div>
                                    <p class="subtitle-1 mb-0">Items: {{cart.total | currency}}</p>
                                    <p class="subtitle-1 mb-0">Add-ons/Extras: {{cart.totalExtra | currency}}</p>
                                    <p class="title pb-2">Total: CAD {{cart.totalSum | currency}}</p>
                                
                                    <v-btn dark color="amber" text small @click="clearCart">
                                        <v-icon small left>fa-undo-alt</v-icon>Empty cart
                                    </v-btn>     
                                </div>
                            </v-card-text>
                            <v-card-text class="text-center pt-0">
                                <div v-if="canAddMoreItems">
                                    <v-btn dark color="success" @click="addMore" class="ma-2">
                                        Add more items
                                        <v-icon small right>fa-plus-circle</v-icon>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <span>Your cart is full. Please checkout and make another purchase to add more tours.</span>
                                </div>

                                <div v-if="isItemsInSameGroup">
                                    <v-btn dark color="primary" @click="$emit('changeStep', 3)" class="ma-2" v-if="cart.items.length > 0">
                                        Go to Checkout <v-icon small right>fa-arrow-circle-right</v-icon>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-alert
                                        border="bottom"
                                        elevation="2"
                                        color="warning"
                                        class="ma-5 text-center"
                                    >
                                        <p>
                                        You have items in your cart from different groups.
                                        You cannot book items from different groups in the same transaction.
                                        <br>
                                        Please, remove items from different groups from your cart or empty the cart and start again.
                                        </p>
                                    </v-alert>
                                </div>

                            </v-card-text>
                        </v-sheet>
                        <v-card-text>
                            <v-container>
                                <v-sheet elevation="1" color="pa-5" v-if="cart.items.length > 0">
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-center">Item</th>
                                                    <th class="text-center">Amount</th>
                                                    <th class="text-center">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(item, index) in cart.items"
                                                    :key="index"
                                                >
                                                    <td>
                                                        <v-avatar size="128px" tile class="float-left d-none d-sm-flex">
                                                            <img
                                                                class="py-1"
                                                                
                                                                v-if="item.item.fareharborDetails.image_cdn_url"
                                                                :alt="item.item.name"
                                                                :src="item.item.fareharborDetails.image_cdn_url"
                                                                lazy-src="https://lola-static.s3-us-west-2.amazonaws.com/image-placeholder-mountains.png"
                                                            />
                                                            <v-icon v-else size="48">fa-image</v-icon>
                                                        </v-avatar>

                                                        <div class="text-center justify py-8">
                                                            <span class="subtitle-1">{{item.item.name}}</span>                                                        
                                                            <!-- HEADLINE -->
                                                            <!-- availability 1st priority -->
                                                            <span
                                                                v-if="item.availabilities && item.availabilities[0].headline"
                                                            >
                                                                <span
                                                                    class="subtitle-2 ml-2"
                                                                >{{item.availabilities[0].headline}}</span>
                                                            </span>

                                                            <span v-else>
                                                                <!-- item 2nd priority -->
                                                                <span v-if="item.item.headline">
                                                                    <span
                                                                        class="subtitle-2 ml-2"
                                                                    >{{item.item.headline}}</span>
                                                                </span>

                                                                <!-- otherwise fareharbor item -->
                                                                <span v-else>
                                                                    <span
                                                                        v-if="item.item.fareharborDetails.headline"
                                                                        class="subtitle-2 ml-2"
                                                                    >{{item.item.fareharborDetails.headline}}</span>
                                                                </span>
                                                            </span>
                                                            <!-- END HEADLINE -->
                                                            
                                                            <br>
                                                            <span class="body-2">{{item.availability.fareharborDetails.startDate | moment('timezone', groupTimezone, 'DD-MMM-YYYY HH:mm:ss' )}} ({{groupTimezone | timezoneFilter}})</span>

                                                            <br>
                                                            <span class="subtitle-2">{{item.travellers.length}} traveller(s)</span>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">
                                                        Total: {{item.total.total | currency}}
                                                        <br>
                                                        Extra/Add-on: {{item.total.totalExtra | currency}}                                                        
                                                    </td>
                                                    <td class="text-center">
                                                        <v-btn outlined small color="primary" @click="removeFromCart(index)">
                                                            <v-icon small left>fa-minus-circle</v-icon>Remove
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>

                                    <div class="text-center my-8" v-if="isItemsInSameGroup">
                                        <v-btn dark color="primary" x-large @click="$emit('changeStep', 3)">
                                            Go to Checkout <v-icon right>fa-arrow-circle-right</v-icon>
                                        </v-btn>
                                    </div>
                                </v-sheet>

                                <v-sheet v-else elevation="2" class="pa-10 text-center">
                                    <p class="title">You don't have any items in the shopping cart.</p>   
                                    <v-btn dark color="primary" @click="$emit('changeStep', 1)">
                                        <v-icon left>fa-arrow-left</v-icon> Go back to group
                                    </v-btn>                                 
                                </v-sheet>
                            </v-container>
                        </v-card-text>
                    </v-card>
                    
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import { sendAlert, scrollToItems } from '@/store/notifications-hub'
//import momentTz from 'moment-timezone'
import filtersMixin from '@/mixins/filters-mixin.js'

export default {
    name: 'Step2CartPage',
    mixins: [filtersMixin],
    components: {

    },
    props: {
        group: {
            type: Object,
            required: true,
        },
    },
    computed: {
        cart: function () {
            return this.$store.getters.getCart
        },
        groupTimezone: function(){
            return this.$store.getters.getGroupTimezone
        },
        cartSize: function() {
            return this.$store.getters.getCartSize
        },
        canAddMoreItems: function() {
            return this.cartSize < 5; // limit cart to 5 items (this is to prevent big orders from failing creation)
        },
        isItemsInSameGroup: function() {
            return this.cart.items.every(el => el?.groupId === this.group?._id)
        }
    },

    methods: {
        clearCart() {
            this.$dialog
                .confirm(`Would you like to remove all items from the cart?`, { loader: true })
                .then(async (dialog) => {
                    this.$store.dispatch('clearCart')
                    sendAlert({ event: 'info', data: null, message: 'Cart emptied!' })
                    dialog.close()
                }).catch(() => {}) //cancelled the dialog
            
        },
        removeFromCart(index){
            this.$store.dispatch('removeFromCart', index)
        },
        addMore(){
            this.$emit('changeStep', 1)
            scrollToItems(true)
        }
    },


}
</script>

<style scoped>
.bg-custom{
    background-color: #F5F5F5;
}
</style>
