<template>
    <div>
        <v-row>
            <v-col>
                <v-card outlined class="mx-auto">
                    <v-card-title>
                        Bookings
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            append-icon="fa-search"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            :search="search"
                            :loading="loading"
                        >
                            <template v-slot:[`item.order.lolaId`]="{ item }">
                                <span>{{ item.order.lolaId }}</span>
                                <v-tooltip bottom>
                                    <template v-slot:[`activator`]="{ on }">
                                        <v-icon
                                            v-on="on"
                                            small
                                            class="mr-2"
                                            @click="view(item.order)"
                                            >fa-external-link-alt</v-icon
                                        >
                                    </template>
                                    <span>View Order</span>
                                </v-tooltip>
                            </template>

                            <template
                                v-slot:[`item.item.amountTotal`]="{ item }"
                            >
                                <span>{{
                                    item.item.amountTotal | currency
                                }}</span>
                            </template>

                            <template v-slot:[`item.amountPaid`]="{ item }">
                                <span>{{
                                    item.item.amountPaid | currency
                                }}</span>
                            </template>

                            <template
                                v-slot:[`item.item.fareharborBooking.availability.start_at`]="{
                                    item,
                                }"
                            >
                                <span>{{
                                    item.item.fareharborBooking ? item.item.fareharborBooking.availability.start_at : ''
                                        | moment(
                                            'timezone',
                                            currentTimezone,
                                            'DD-MMM-YYYY HH:mm:ss z'
                                        )
                                }}</span>
                            </template>

                            <template
                                v-slot:[`item.item.fareharborBooking.availability.end_at`]="{
                                    item,
                                }"
                            >
                                <span>{{
                                    item.item.fareharborBooking ? item.item.fareharborBooking.availability.end_at : ''
                                        | moment(
                                            'timezone',
                                            currentTimezone,
                                            'DD-MMM-YYYY HH:mm:ss z'
                                        )
                                }}</span>
                            </template>

                            <template v-slot:[`item.item.createdAt`]="{ item }">
                                <span>{{
                                    item.item.createdAt
                                        | moment(
                                            'timezone',
                                            currentTimezone,
                                            'DD-MMM-YYYY HH:mm:ss z'
                                        )
                                }}</span>
                            </template>

                            <template v-slot:[`item.item.status`]="{ item }">
                                <v-chip
                                    color="success"
                                    v-if="item.item.status === 'booked'"
                                    small
                                    >Booked</v-chip
                                >
                                <v-chip
                                    color="default"
                                    v-if="item.item.status === 'rebooked'"
                                    small
                                    >Rebooked</v-chip
                                >
                                <v-chip
                                    color="warning"
                                    v-if="item.item.status === 'cancelled'"
                                    small
                                    >Cancelled</v-chip
                                >
                            </template>

                            <template v-slot:[`item.action`]="{ item }">
                                <v-btn
                                    color="primary"
                                    fab
                                    depressed
                                    x-small
                                    dark
                                    class="my-1"
                                    :loading="loading"
                                >
                                    <v-tooltip bottom>
                                        <template v-slot:[`activator`]="{ on }">
                                            <v-icon
                                                :disabled="item.item.status == 'created'"
                                                v-on="on"
                                                small
                                                @click="openRefundModal(item.order._id, item.item._id)"
                                                >fa-undo</v-icon
                                            >
                                        </template>
                                        <span>Refund (click to open modal)</span>
                                    </v-tooltip>
                                </v-btn>
                                
                                <v-btn
                                    color="primary"
                                    fab
                                    depressed
                                    x-small
                                    dark
                                    :loading="loading"
                                >
                                    <v-tooltip bottom>
                                        <template v-slot:[`activator`]="{ on }">
                                            <v-icon
                                                v-on="on"
                                                small
                                                @click="openWaiversModal(item.order._id, item.item._id)"
                                                >fa-file-alt</v-icon
                                            >
                                        </template>
                                        <span>Waivers (click to add waiver)</span>
                                    </v-tooltip>
                                </v-btn>
                            </template>                            
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { sendAlert } from '@/store/notifications-hub'
import filtersMixin from '@/mixins/filters-mixin.js'
export default {
    name: 'DivisionBookings',
    mixins: [filtersMixin],
    data: function() {
        return {
            items: [],
            loading: true,
            orders: [],
            search: '',
            headers: [
                { text: 'Booking ID Lola', value: 'item.lolaId' },
                { text: 'Order ID', value: 'order.lolaId' },
                { text: 'Booking ID FH', value: 'item.fareharborBooking.pk' },
                { text: 'Tour', value: 'item.title' },
                { text: 'Total', value: 'item.amountTotal' },
                //{ text: 'Amount Paid', value: 'item.amountPaid' },
                { text: 'Status', value: 'item.status' },
                { text: 'Group', value: 'order.group.title' },
                {
                    text: 'Customer',
                    value: 'item.fareharborBooking.contact.email',
                },
                {
                    text: 'Trip Start Date',
                    value: 'item.fareharborBooking.availability.start_at',
                },
                {
                    text: 'Trip End Date',
                    value: 'item.fareharborBooking.availability.end_at',
                },
                { text: 'Booked at', value: 'item.createdAt' },
                { text: 'Actions', value: 'action', sortable: false },
            ],
        }
    },
    methods: {
        view(order) {
            //https://stackoverflow.com/questions/40015037/can-vue-router-open-a-link-in-a-new-tab
            let routeData = this.$router.resolve({
                name: 'orders',
                params: { id: order._id },
            })
            window.open(routeData.href, '_blank')
        },

        //this functions gets the items as the root element of an item in the array
        //so it's easier for us to create the datatable
        formatData() {
            this.orders.forEach(order => {
                //items are inside an order
                order.items.forEach(item => {
                    //order has many items, so we iterate again
                    this.items.push({
                        item,
                        order: order,
                    })
                })
            })
        },

        async openRefundModal(orderMongoId, itemMongoId) {
            this.loading = true
            //opens modal passing the item and order to it
            let itemAndOrder = await this.getItemAndOrder(orderMongoId, itemMongoId)
            this.$router.push({ name: 'modal.refund', params: { item: itemAndOrder } })
            this.loading = false
        },

        async openWaiversModal(orderMongoId, itemMongoId) {
            this.loading = true
            //opens modal passing the item and order to it
            let itemAndOrder = await this.getItemAndOrder(orderMongoId, itemMongoId)
            this.$router.push({ name: 'modal.waivers', params: { item: itemAndOrder } })
            this.loading = false
        },

        // this is used to pass the order and item with all info to the modal
        async getItemAndOrder(orderMongoId, itemMongoId) {
            let res = await this.$tornos.get(`/orders/${orderMongoId}`)
            let order = res.data
            let item = order.items.find(el => el._id === itemMongoId);
            return {
                item,
                order
            }
        }
    },
    async mounted() {
        try {
            // gets groups in division, then will fetch orders under groups
            let resGroups = await this.$tornos.get(`groups?division=${this.division._id}&select=_id`)
            let groupsIds = resGroups.data

            let populate = [
                {
                    path: 'customer',
                    select: ['name', 'email'],
                },
                {
                    path: 'group',
                    select: ['title', '_id'],
                },
            ]
            let resOrders = await this.$tornos.post(`orders/query`, {
                populate,
                select: [
                    'lolaId',
                    'isPaid',
                    'isRefunded',
                    'amountTotal',
                    'amountPaid',
                    'amountRefunded',
                    'amountRemaining',
                    'createdAt',
                    'updatedAt',
                    '_id',
                    'items._id',
                    'items.lolaId',
                    'items.title',
                    'items.amountTotal',
                    'items.status',
                    'items.fareharborBooking.contact.email',
                    'items.fareharborBooking.pk',
                    'items.fareharborBooking.availability.start_at',
                    'items.fareharborBooking.availability.end_at',
                    'items.createdAt',
                    'items.amountTotal',
                    'items.amountPaid',
                ],
                filter: {
                    group: { $in: groupsIds },
                },
            })
            this.orders = resOrders.data
            this.formatData()
        } catch (e) {
            sendAlert({
                event: 'warning',
                data: e,
                message: 'There was an error fetching the orders. Please try again',
            })
        }

        this.loading = false
    },
}
</script>

<style></style>
