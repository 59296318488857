<template>
  <v-app id="login">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">

            <login-form :userType="userType" :refFrom="`login`"/>

            <p class="text-center caption mt-4">Version {{version}}</p>
            <p class="text-center caption mt-4" v-if="environment === 'DEVELOPMENT'">Environment {{environment}}</p>
            
          </v-col>
          <v-col cols="12 text-center">
            <v-btn small text color="primary" to="/" > <v-icon small left>fa-arrow-left</v-icon> Go to homepage </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LoginForm from '@/components/Login/LoginForm'

export default {
  name: 'Login',
  components: {
    LoginForm
  },
  props:{
    userType: {
      validator: function (value) { //https://vuejs.org/v2/guide/components-props.html#Prop-Validation
        // The value must match one of these strings
        return ['employees', 'agents', 'customers'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    isLoggedIn: function(){
      return this.$store.getters.isLoggedIn    
    },
    version: function(){
      return process.env.VUE_APP_VERSION
    },
    environment: function(){
      return process.env.VUE_APP_ENVIRONMENT
    }
  },
  created(){
    
  },
  data: function(){
    return{
      
      
    }
  },

  methods: {
   
  },


}
</script>

