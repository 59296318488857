var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"register"}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-container',[_c('v-card-title',[_vm._v(_vm._s(_vm.labelSubmitBtn))]),_c('ValidationObserver',{ref:"formpartner",scopedSlots:_vm._u([{key:"default",fn:function(observerPartner){return [_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return observerPartner.handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"pa-2"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Name","name":"Name","type":"text","messages":errors[0],"counter":"60","hint":"Partner Name (Company Name)"},model:{value:(_vm.partner.name),callback:function ($$v) {_vm.$set(_vm.partner, "name", $$v)},expression:"partner.name"}})]}}],null,true)})],1),_c('div',{staticClass:"pa-2"},[_c('ValidationProvider',{attrs:{"name":"Website","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Website","name":"Website","type":"text","messages":errors[0],"counter":"60","hint":"Partner Website"},model:{value:(_vm.partner.website),callback:function ($$v) {_vm.$set(_vm.partner, "website", $$v)},expression:"partner.website"}})]}}],null,true)})],1),_c('div',{staticClass:"pa-2"},[_c('ValidationProvider',{attrs:{"name":"slug","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Slug (URL shortcut)","name":"slug","type":"text","messages":errors[0],"counter":"60","hint":"Partner Slug (URL shortcut to be used in the group link)"},model:{value:(_vm.partner.slug),callback:function ($$v) {_vm.$set(_vm.partner, "slug", $$v)},expression:"partner.slug"}})]}}],null,true)})],1),_c('div',{staticClass:"pa-2"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Email","name":"Email","type":"text","messages":errors[0],"counter":"60","hint":"Partner Email (main company email address)"},model:{value:(_vm.partner.email),callback:function ($$v) {_vm.$set(_vm.partner, "email", $$v)},expression:"partner.email"}})]}}],null,true)})],1),_c('div',{staticClass:"pa-2"},[_c('ValidationProvider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Phone","name":"Phone","type":"text","messages":errors[0],"counter":"60","hint":"Partner Phone Number"},model:{value:(_vm.partner.phone),callback:function ($$v) {_vm.$set(_vm.partner, "phone", $$v)},expression:"partner.phone"}})]}}],null,true)})],1),_c('div',{staticClass:"pa-2"},[_c('ValidationProvider',{attrs:{"name":"Comisison Percentage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Comisison Percentage","name":"Comisison Percentage","type":"text","messages":errors[0],"counter":"60","hint":"Partner Comisison Percentage (used to track comissions due from bookings)"},model:{value:(
                                                _vm.partner.comissionPercentage
                                            ),callback:function ($$v) {_vm.$set(_vm.partner, "comissionPercentage", $$v)},expression:"\n                                                partner.comissionPercentage\n                                            "}})]}}],null,true)})],1),_c('div',{staticClass:"pa-2"},[_c('ValidationProvider',{attrs:{"name":"Timezone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.timezones,"hide-no-data":"","hide-selected":"","label":"Select the Partner timezone","placeholder":"Start typing to search a timezone","messages":errors[0],"outlined":"","chips":""},model:{value:(_vm.partner.timezone),callback:function ($$v) {_vm.$set(_vm.partner, "timezone", $$v)},expression:"partner.timezone"}}),_c('p',{staticClass:"caption mt-0 pt-0"},[_vm._v(" The timezone set here is used to format the dates shown on groups and items belonging to this partner ")])]}}],null,true)})],1),(_vm.isUpdate)?_c('div',{staticClass:"pa-2 mt-4"},[_c('p',[_vm._v("Current logo")]),_c('v-avatar',{attrs:{"tile":"","width":"100"}},[(_vm.partner.logoUrl)?_c('img',{attrs:{"alt":_vm.partner.name,"src":_vm.partner.logoUrl}}):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fa-user-circle")])],1),_c('v-switch',{attrs:{"label":"Change partner logo?"},model:{value:(_vm.switchLogo),callback:function ($$v) {_vm.switchLogo=$$v},expression:"switchLogo"}})],1):_vm._e(),(
                                        _vm.switchLogo === true ||
                                        _vm.isUpdate === false
                                    )?_c('div',{staticClass:"pa-2"},[_c('ValidationProvider',{attrs:{"name":"Logo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-file-input',{attrs:{"outlined":"","label":"Click to choose an image...","name":"Logo","messages":errors[0],"show-size":"","hint":"Partner Logo","accept":"image/*"},model:{value:(_vm.partner.logoFile),callback:function ($$v) {_vm.$set(_vm.partner, "logoFile", $$v)},expression:"partner.logoFile"}})]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"pa-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('label',[_vm._v("Address")])]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Street Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Street Name","name":"Street Name","type":"text","messages":errors[0],"counter":"100","hint":"Street Name"},model:{value:(
                                                        _vm.partner.address
                                                            .streetName
                                                    ),callback:function ($$v) {_vm.$set(_vm.partner.address
                                                            , "streetName", $$v)},expression:"\n                                                        partner.address\n                                                            .streetName\n                                                    "}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Unit Number","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Unit Number","name":"Unit Number","type":"text","messages":errors[0],"counter":"20","hint":"Unit Number (optional)"},model:{value:(
                                                        _vm.partner.address.unit
                                                    ),callback:function ($$v) {_vm.$set(_vm.partner.address, "unit", $$v)},expression:"\n                                                        partner.address.unit\n                                                    "}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"City","name":"City","type":"text","messages":errors[0],"counter":"60","hint":"City"},model:{value:(
                                                        _vm.partner.address.city
                                                    ),callback:function ($$v) {_vm.$set(_vm.partner.address, "city", $$v)},expression:"\n                                                        partner.address.city\n                                                    "}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"State/Province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"State or Province","name":"State","type":"text","messages":errors[0],"counter":"60","hint":"State"},model:{value:(
                                                        _vm.partner.address
                                                            .state
                                                    ),callback:function ($$v) {_vm.$set(_vm.partner.address
                                                            , "state", $$v)},expression:"\n                                                        partner.address\n                                                            .state\n                                                    "}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Postal Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Postal Code","name":"Postal Code","type":"text","messages":errors[0],"counter":"6","hint":"Postal Code (ex: V5H2P6)"},model:{value:(
                                                        _vm.partner.address
                                                            .postalCode
                                                    ),callback:function ($$v) {_vm.$set(_vm.partner.address
                                                            , "postalCode", $$v)},expression:"\n                                                        partner.address\n                                                            .postalCode\n                                                    "}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                            var errors = ref.errors;
return [_c('v-select',{attrs:{"items":[
                                                        'Canada',
                                                        'United States of America',
                                                        'China',
                                                        'Other' ],"outlined":"","label":"Country","name":"Country","messages":errors[0],"hint":"Country"},model:{value:(
                                                        _vm.partner.address
                                                            .country
                                                    ),callback:function ($$v) {_vm.$set(_vm.partner.address
                                                            , "country", $$v)},expression:"\n                                                        partner.address\n                                                            .country\n                                                    "}})]}}],null,true)})],1)],1)],1)]),_c('v-divider'),(_vm.isUpdate)?_c('v-row',[(_vm.agents.length > 0)?_c('v-col',{attrs:{"md":"12"}},[_c('h4',[_vm._v("Agents")]),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(" Name ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Lastname ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Email ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Created at ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Actions ")])])]),_c('tbody',_vm._l((_vm.agents),function(item){return _c('tr',{key:item.name,staticClass:"text-center"},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(" "+_vm._s(item.lastname)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.email)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt, 'timezone', _vm.currentTimezone, 'DD-MMM-YYYY HH:mm:ss z' ))+" ")]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(
                                                                            item
                                                                        )}}},
                                                                        on
                                                                    ),[_vm._v("fa-trash-alt")])]}}],null,true)},[_c('span',[_vm._v("Delete")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.sendEmailAgentLogin(
                                                                            item
                                                                        )}}},
                                                                        on
                                                                    ),[_vm._v("fa-paper-plane")])]}}],null,true)},[_c('span',[_vm._v("Send Login Instructions by Email")])])],1)])}),0)]},proxy:true}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"md":"12"}},[_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":""}},on),[_vm._v(" Add Agent "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("fa fa-user-plus")])],1)]}}],null,true),model:{value:(_vm.dialogAddAgent),callback:function ($$v) {_vm.dialogAddAgent=$$v},expression:"dialogAddAgent"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Add new agent")])]),_c('v-card-text',[_c('v-container',[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto","md":"12"}},[_c('div',{staticClass:"pa-2"},[_c('v-text-field',{attrs:{"outlined":"","label":"Name","name":"Name","type":"text","counter":"60","hint":"Agent First Name"},model:{value:(
                                                                            _vm.agent.name
                                                                        ),callback:function ($$v) {_vm.$set(_vm.agent, "name", $$v)},expression:"\n                                                                            agent.name\n                                                                        "}})],1)]),_c('v-col',{attrs:{"cols":"auto","md":"12"}},[_c('div',{staticClass:"pa-2"},[_c('v-text-field',{attrs:{"outlined":"","label":"Lastname","name":"Lastname","type":"text","counter":"60","hint":"Agent Lastname"},model:{value:(
                                                                            _vm.agent.lastname
                                                                        ),callback:function ($$v) {_vm.$set(_vm.agent, "lastname", $$v)},expression:"\n                                                                            agent.lastname\n                                                                        "}})],1)]),_c('v-col',{attrs:{"cols":"auto","md":"12"}},[_c('div',{staticClass:"pa-2"},[_c('v-text-field',{attrs:{"outlined":"","label":"Agent Email","name":"Email","type":"text","counter":"60","hint":"Agent Email"},model:{value:(
                                                                            _vm.agent.email
                                                                        ),callback:function ($$v) {_vm.$set(_vm.agent, "email", $$v)},expression:"\n                                                                            agent.email\n                                                                        "}})],1)])],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"default"},on:{"click":function($event){_vm.dialogAddAgent = false}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submitAgent}},[_vm._v("Save")])],1)],1)],1)],1)],1):_vm._e(),_c('v-divider')],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"default","light":"","large":"","to":"/companies/divisions/partners"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("fa fa-arrow-left")]),_vm._v("Go back ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary white--text","large":"","disabled":observerPartner.invalid},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.labelSubmitBtn)+" "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("fa fa-check")])],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }