var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"register"}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-container',[_c('v-card-title',[_vm._v(_vm._s(_vm.labelSubmitBtn))]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"pa-2"},[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Title","name":"Title","type":"text","messages":errors[0],"counter":"60","hint":"Waiver Title"},model:{value:(_vm.waiverTemplate.title),callback:function ($$v) {_vm.$set(_vm.waiverTemplate, "title", $$v)},expression:"waiverTemplate.title"}})]}}],null,true)})],1),_c('div',{staticClass:"pa-2"},[_c('label',[_vm._v("Header (optional)")]),_c('ValidationProvider',{attrs:{"name":"Body"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('QuillEditorLola',{attrs:{"placeholder":"Header text (this shows before the body text) ...","folder":_vm.folder,"storageLevel":"protected","name":"Header","content":_vm.waiverTemplate.header,"rows":"20","counter":"200","messages":errors[0]},on:{"editorChange":function($event){return validate($event.html)}},model:{value:(_vm.waiverTemplate.header),callback:function ($$v) {_vm.$set(_vm.waiverTemplate, "header", $$v)},expression:"waiverTemplate.header"}})]}}],null,true)})],1),_c('div',{staticClass:"pa-2"},[_c('label',[_vm._v("Body of the template")]),_c('ValidationProvider',{attrs:{"name":"Body","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('QuillEditorLola',{attrs:{"placeholder":"Body text of the waiver....","folder":_vm.folder,"storageLevel":"protected","minHeight":"250","content":_vm.waiverTemplate.body,"name":"Body","messages":errors[0]},on:{"editorChange":function($event){return validate($event)}},model:{value:(_vm.waiverTemplate.body),callback:function ($$v) {_vm.$set(_vm.waiverTemplate, "body", $$v)},expression:"waiverTemplate.body"}})]}}],null,true)})],1),_c('div',{staticClass:"pa-2"},[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","label":"Electronic Consent","name":"Electronic Consent","hint":"The agreement in which the traveller/parent/guardian is agreeing","messages":errors[0]},model:{value:(_vm.waiverTemplate.consent),callback:function ($$v) {_vm.$set(_vm.waiverTemplate, "consent", $$v)},expression:"waiverTemplate.consent"}})]}}],null,true)})],1),_c('div',{staticClass:"pa-2"},[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n4",attrs:{"outlined":"","label":"Signature Description","name":"Signature Description","type":"text","hint":"The text to display on the default signature field. At least one signature is required on a waiver","messages":errors[0]},model:{value:(_vm.waiverTemplate.signatureDescription),callback:function ($$v) {_vm.$set(_vm.waiverTemplate, "signatureDescription", $$v)},expression:"waiverTemplate.signatureDescription"}})]}}],null,true)})],1),_c('div',{staticClass:"pa-2"},[_c('p',{staticClass:"body-1"},[_vm._v("Choose the waiver type (for which age group is this waiver)")]),_c('ValidationProvider',{attrs:{"name":"Legal Age","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"row":"","messages":errors[0]},model:{value:(_vm.waiverTemplate.waiverType),callback:function ($$v) {_vm.$set(_vm.waiverTemplate, "waiverType", $$v)},expression:"waiverTemplate.waiverType"}},[_c('v-radio',{attrs:{"label":("Minor (younger than " + (_vm.waiverTemplate.legalAge) + ")"),"value":"minor"}}),_c('v-radio',{attrs:{"label":("Adult (equal or older than " + (_vm.waiverTemplate.legalAge) + ")"),"value":"adult"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"pa-2"},[_c('ValidationProvider',{attrs:{"name":"Legal Age","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n4",attrs:{"outlined":"","label":"Legal age","name":"Legal age","type":"text","hint":"The age in which a traveller is considered to be of legal age","messages":errors[0]},model:{value:(_vm.waiverTemplate.legalAge),callback:function ($$v) {_vm.$set(_vm.waiverTemplate, "legalAge", $$v)},expression:"waiverTemplate.legalAge"}})]}}],null,true)})],1),_c('div',{staticClass:"pa-2"},[_c('label',{staticClass:"subtitle-1"},[_vm._v("Custom Fields")]),_c('br'),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.addField()}}},[_vm._v(" Add new field "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("fa fa-plus-circle")])],1),_c('div',{staticClass:"mt-4"},_vm._l((_vm.waiverTemplate.fields),function(field){return _c('div',{key:field.counter},[_c('v-card',{staticClass:"pb-4"},[_c('v-card-text',{staticClass:"mt-4"},[_c('CustomFieldWaiverTemplate',{attrs:{"field":field},on:{"update:field":function($event){field=$event}}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.removeField(field.counter)}}},[_vm._v(" Remove field "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("fa fa-minus-circle")])],1)],1)],1)],1)}),0)],1)]),_c('v-divider')],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"default","light":"","large":"","to":"/companies/divisions/waivers/templates"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("fa fa-arrow-left")]),_vm._v("Go back ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary white--text","large":"","disabled":invalid},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.labelSubmitBtn)+" "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("fa fa-check")])],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }