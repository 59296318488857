<template>
<div>
    <ValidationProvider :name="customField.name" :rules="rules" v-slot="{ errors }">
        <v-checkbox
        v-model="field.value"
        :label="label"
        :hint="customField.title"
        @change="update"
        :error-messages="errors"  
        :key="`${travellerIndex}-${field.pk}`"        
        ></v-checkbox>  
    </ValidationProvider>
    <span v-if="customField.description_safe_html" v-html="customField.description_safe_html"></span>  
</div>
  
</template>

<script>
export default {
    name: 'CustomFieldRadio',
    props: ['pk', 'customField', 'travellerIndex'],
    data: function(){
        return {
            field: {
                pk: this.pk,                
                customField: this.customField,
                value: '',
                amount: 0,
                travellerIndex: this.travellerIndex
            }
        }
    },

    watch: {
        //if this checkbox is for Terms and Conditions it needs to be selected, so we set the value to null for vee-validate to work
        //https://github.com/vuetifyjs/vuetify/issues/1813
        'field.value': function(newVal){
            if(this.customField.description.includes('Terms')){
                if(newVal === false) this.field.value = null
            }
        }
    },

    computed: {
        //validation rules
        rules: function(){
            return this.customField.is_required ? 'required' : ''
        },

        label: function(){
            if(this.rules.includes('required')){
                return `${this.customField.name} (required)`
            }else{
                return `${this.customField.name}`
            }
        }
    },

    methods: {
        update() {

            //this is an offset (price) in the custom_field level (not in an option, but in the root of the custom_field itself)
            let customFieldAmount = 0
            if(this.field.customField.modifier_kind === "offset" && this.field.customField.modifier_type !== 'none'){
                //if selected (true) we set the amount. 
                //we do this if/else here because it's a radio button, there's no more options to choose, usually it's just one check (true) or unchecked (false)
                if(this.field.value){
                    customFieldAmount = this.customField.offset
                
                //else we reset
                }else{
                    customFieldAmount = 0
                }
            }

            this.field.amount = customFieldAmount

            this.$emit('input', this.field)
        },
    }
}
</script>

<style>

</style>