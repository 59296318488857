<template>
  <v-container class="fill-width ma-0 pa-0" fluid>
    <v-row align justify no-gutters>
      <v-col>
        <v-card>
          <v-card-title
            class="title primary text-capitalize white--text"
            primary-title
          >Login {{userType}}</v-card-title>

          <div v-if="!isResetPassword">
            <v-card-text v-if="!isLoggedIn">
              <ValidationObserver ref="form" v-slot="observer">
                <v-form @submit.prevent="observer.handleSubmit(submit)">
                  <v-row class="my-2">
                    <v-col class cols="12" md="12">
                      <ValidationProvider
                        name="Email"
                        rules="required|email|max:200"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          outlined
                          label="Email"
                          name="Email"
                          type="text"
                          v-model="user.email"
                          :messages="errors[0]"
                          counter="200"
                          placeholder="Email address"
                        />
                      </ValidationProvider>
                    </v-col>

                    <v-col class cols="12" md="12">
                      <ValidationProvider
                        name="Password"
                        rules="required|max:200"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          outlined
                          label="Password"
                          name="Password"
                          type="password"
                          v-model="user.password"
                          :messages="errors[0]"
                          counter="200"
                          placeholder="Password"                          
                        />
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12">
                      <v-btn
                        large
                        color="primary"
                        :disabled="observer.invalid"
                        @click="login"
                        block
                        :loading="loading"
                      >
                        Login
                        <v-icon right small>fa fa-check-circle</v-icon>
                      </v-btn>
                    </v-col>

                    <v-col cols="12">
                        <v-btn text class="caption pa-0" @click="forgotPassword" >Forgot Password? Click here to reset</v-btn>
                    </v-col>

                    <v-col cols="12" v-if="isSendVerificationLink">
                      <v-btn
                        large
                        color="primary"
                        @click="sendVerificationLink"
                        block
                      >
                        Send verification link
                        <v-icon right small>fa fa-envelope</v-icon>
                      </v-btn>
                    </v-col>

                    

                  </v-row>
                </v-form>
              </ValidationObserver>
            </v-card-text>

            <v-card-text v-else class="pa-5">
              <p class="subtitle-1">
                You are logged in as an
                <strong>{{loggedUser.type | singular}}</strong> under the username
                <strong>{{loggedUser.user.email}}</strong>
              </p>
              <v-btn @click="logout">Logout</v-btn>
            </v-card-text>
          </div> <!-- end div !isResetPassword -->

          <div v-else>
              <reset-password @login="isResetPassword = false" />
          </div>
          
          
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from 'aws-amplify'
import ResetPassword from '@/components/Login/ResetPassword'

import { sendAlert } from '@/store/notifications-hub'

export default {
  name: 'LoginForm',
  components: {
    ResetPassword
  },
  props: {
    userType: {
      validator: function (value) { //https://vuejs.org/v2/guide/components-props.html#Prop-Validation
        // The value must match one of these strings
        return ['employees', 'agents', 'customers'].indexOf(value) !== -1
      }
    },
    //this is to know where the user is logging in - if it's a customer on the checkout we cannot redirect him
    refFrom: {
      type: String,
      default: 'login' //login page redirects - it is default
    }
  },
  filters: {
    'singular': function (val) {
      return val.slice(0, -1)
    }
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
    loggedUser: function () {
      return this.$store.getters.loggedUser.user ? this.$store.getters.loggedUser : null
    }
  },
  data: function () {
    return {
      user: {
        email: '',
        password: ''
      },
      loading: false,

      confirmSignUpConfig: {
        header: 'Confirm account',
        username: ''
      },
      isResetPassword: false,

      isSendVerificationLink: false
    }
  },

  methods: {

    async login() {
      try {

        this.isSendVerificationLink = false
        this.loading = true
        const user = await Auth.signIn(this.user.email, this.user.password);
        //this.$log.info(user)
        if (user.challengeName === 'SMS_MFA' ||
          user.challengeName === 'SOFTWARE_TOKEN_MFA') {
          // // You need to get the code from the UI inputs
          // // and then trigger the following function with a button click
          // //const code = getCodeFromUserInput();

          // // If MFA is enabled, sign-in should be confirmed with the confirmation code
          // const loggedUser = await Auth.confirmSignIn(
          //     user,   // Return object from Auth.signIn()
          //     code,   // Confirmation code  
          //     mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
          // );
          

        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          // const {requiredAttributes} = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
          // // You need to get the new password and required attributes from the UI inputs
          // // and then trigger the following function with a button click
          // // For example, the email and phone_number are required attributes
          // const {username, email, phone_number} = getInfoFromUserInput();
          // const loggedUser = await Auth.completeNewPassword(
          //     user,              // the Cognito User Object
          //     newPassword,       // the new password
          //     // OPTIONAL, the required attributes
          //     {
          //         email,
          //         phone_number,
          //     }
          // );
          
          sendAlert({ event: 'info', data: null, message: 'Please, verify your account' })
          this.loading = false
        } else if (user.challengeName === 'MFA_SETUP') {
          // This happens when the MFA method is TOTP
          // The user needs to setup the TOTP before using it
          // More info please check the Enabling MFA part
          //Auth.setupTOTP(user);

        } else {
          // The user directly signs in
          this.processUser(user)
        }

      } catch (err) {
        this.loading = false
        //this.$log.info(err);
        if (err.code === 'UserNotConfirmedException') {
          // The error happens if the user didn't finish the confirmation step when signing up
          // In this case you need to resend the code and confirm the user
          // About how to resend the code and confirm the user, please check the signUp part

            //this will happen when the user dont click the verification link sent automatticaly when their account is created
          this.isSendVerificationLink = true
          sendAlert({ event: 'info', data: null, message: 'You need to verify your account. If you do not have a link or code, click to "Send Verification Link"' })

        } else if (err.code === 'PasswordResetRequiredException') {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
            sendAlert({ event: 'info', data: null, message: 'You need to setup a new password to continue. Please reset your password' })
          this.isResetPassword = true

        } else if (err.code === 'NotAuthorizedException') {
          // The error happens when the incorrect password is provided
          sendAlert({ event: 'warning', data: null, message: 'Wrong credentials' })
        } else if (err.code === 'UserNotFoundException') {
          // The error happens when the supplied username/email does not exist in the Cognito user pool
          sendAlert({ event: 'warning', data: null, message: 'Wrong user credentials' })
        } else {
          sendAlert({ event: 'error', data: err, message: 'There was an error. Please, try again' })
        }
      }
    },

    forgotPassword(){
        this.isResetPassword = true
        this.isSendVerificationLink = false
    },

    sendVerificationLink(){
        if(this.user.email){
            Auth.resendSignUp(this.user.email).then(() => {
                sendAlert({ event: 'info', data: null, message: 'Sent successfully' })
                this.isSendVerificationLink = false
            }).catch(e => {
                sendAlert({ event: 'error', data: e, message: 'There was an error. Please, try again' })
            })
        }else{
            sendAlert({ event: 'info', data: null, message: 'Please, fill in your email address above' })
        }
    },
   
   
    //looks up this user in lola db and logs in the session if found
    processUser(cognitoUser) {

      let cognitoId = cognitoUser.attributes.sub
      let userType = this.userType

      let urlToSearch = `/${userType}/?cognitoId=${cognitoId}` //url to search uses the userType that was set in the cache in the login form

      if (userType === 'employees') {
        urlToSearch = `/${userType}/?cognitoId=${cognitoId}&populate=divisions-company`
      }

      if (userType === 'agents') {
        urlToSearch = `/${userType}/?cognitoId=${cognitoId}&populate=partner`
      }

      //this.$log.info('url to search login form: ' + urlToSearch)
      this.$tornos.get(urlToSearch).then(res => {

        //if a user is found in lola db we continue
        if (res.data.length > 0) {
          this.$store.dispatch('login', { user: res.data[0], type: this.userType })

          
          if(this.refFrom === 'login'){
            if (userType === 'employees') {
              this.$router.push({ name: 'divisions.choose' }) //redirects to divisions if employees
            } else if (userType === 'agents') {
              this.$router.push({ name: 'partner.orders' }) //redirects
            }
            else if (userType === 'customers') {
              this.$router.push({ name: 'customer.orders' }) //redirects
            }
          }

        } else {
          this.$store.dispatch('logout', { userType }) //pass the userType so we can still try to login with this user type
          sendAlert({ event: 'warning', data: null, message: 'User not found' })
        }
      }).catch(e => {
        sendAlert({ event: 'error', data: e, message: 'There was an error fetching the user' })
      }).finally(() => this.loading = false)
    },


    logout() {
      this.$store.dispatch('logout')
    },


    //if user is logged in Amplify but not in the store we logout him from the store
    verifyUserLoggedAmplify() {
      Auth.currentAuthenticatedUser().then(() => {

        //this.$log.info('Amplify logged in')

        //if there`s an Amplify user but not on the store, we logout to make sure it's all clear
        if (!this.isLoggedIn) {
          //this.$log.info('if - logout')
          //this.$log.info('verifyUserLoggedAmplify - logout')
          this.$store.dispatch('logout', { userType: this.userType })

          //it's logged in
        } else {
          //if the logged user is an employees and this form is for an employee we redirect
          if (this.userType === 'employees' && this.userType === this.loggedUser.type) {
            this.$router.push({ name: 'divisions.choose' }) //redirects to divisions if employees

            //customer
          } else if (this.userType === 'customers' && this.userType === this.loggedUser.type) {
            this.$router.push({ name: 'customer.orders' }) //redirects

            //partner
          } else if (this.userType === 'partner' && this.userType === this.loggedUser.type) {
            this.$router.push({ name: 'partner.orders' }) //redirects
          }
        }
      })
      //.catch(() => //this.$log.info('Not logged'))
    }
  },

  mounted() {
    this.verifyUserLoggedAmplify()
  }

}
</script>



<style>
:root {
  /* Colors 
      This is to redefine AWS amplify components colors.
      https://github.com/aws-amplify/amplify-js/issues/2471#issuecomment-451550198
    */
  --amazonOrange: #00acc1; /* Redefined to dark green */
  --lightAmazonOrange: #009688;
  --darkAmazonOrange: #009688;
  --squidInk: #232f3e;
  --lightSquidInk: #31465f;
  --deepSquidInk: #152939;
  --grey: #828282;
  --lightGrey: #c4c4c4;
  --silver: #e1e4ea;
  --darkBlue: #31465f;
  --red: #dd3f5b;
  --white: #ffffff;
  --light-blue: #00a1c9;
}

.amplify-auth .error {
  margin: 5px 0 5px 0;
  padding: 10px;
  border-radius: 2px;
  color: #ffffff;
}

.amplify-auth div {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>



