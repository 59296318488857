<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        persistent
        no-click-animation
    >
        <v-card>
            <v-toolbar dark color="primary" elevation="2">
                <v-toolbar-title class="px-2">Refund</v-toolbar-title>

                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    bottom
                    color="cyan lighten-3"
                ></v-progress-linear>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-divider vertical></v-divider>

                    <v-btn dark text @click="cancel()">
                        <v-icon left small>fa fa-times</v-icon>Close
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-row class="ma-0">
                <v-col>
                    <v-container fluid>
                        <v-sheet elevation="1" class="pa-4">
                            <div class="pa-2">
                                <v-simple-table :fixed-header="false">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Customer
                                                </th>
                                                <th class="text-left">
                                                    Email
                                                </th>
                                                <th class="text-left">
                                                    Tour
                                                </th>
                                                <th class="text-left">
                                                    Tour Date
                                                </th>
                                                <th class="text-left">
                                                    Fareharbor Booking Id
                                                </th>
                                                <th class="text-left">
                                                    Lola Order Id
                                                </th>
                                                <th class="text-left">
                                                    Lola Item Id
                                                </th>
                                                <th class="text-left">
                                                    Total Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {{
                                                        item.item
                                                            .fareharborBooking
                                                            .contact.name
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                        item.item
                                                            .fareharborBooking
                                                            .contact.email
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                        item.item
                                                            .fareharborBooking
                                                            .availability.item
                                                            .name
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                        item.item
                                                            .fareharborBooking
                                                            .availability
                                                            .start_at
                                                            | moment(
                                                                'timezone',
                                                                'America/Vancouver',
                                                                'DD-MMM-YYYY HH:mm z'
                                                            )
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                        item.item
                                                            .fareharborBooking
                                                            .pk
                                                    }}
                                                </td>
                                                <td>
                                                    {{ item.order.lolaId }}
                                                </td>
                                                <td>
                                                    {{ item.item.lolaId }}
                                                </td>
                                                <td>
                                                    {{
                                                        item.item.amountTotal
                                                            | currency
                                                    }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>

                            <ValidationObserver
                                ref="formgroup"
                                v-slot="observerGroup"
                            >
                                <v-form
                                    @submit.prevent="
                                        observerGroup.handleSubmit(submit)
                                    "
                                    v-if="!cannotRefund"
                                >
                                    <p class="title">
                                        Refund
                                        {{
                                            item.item.fareharborBooking
                                                .availability.item.name
                                        }}
                                    </p>

                                    <div class="pa-2">
                                        <label>Cancel on Fareharbor?</label>
                                        <v-switch
                                            v-if="!isCancelled"
                                            v-model="refund.isCancelFareharbor"
                                            label="Select to cancel the booking on Fareharbor"
                                            hint="If checked the booking will be automatically cancelled on Fareharbor"
                                            persistent-hint
                                        ></v-switch>
                                        <p v-if="isCancelled">
                                            Booking is already cancelled on
                                            Fareharbor
                                        </p>
                                    </div>

                                    <div class="pa-2">
                                        <label>Partial Refund?</label>
                                        <v-switch
                                            v-model="refund.isPartial"
                                            label="Select to refund a partial amount"
                                            hint="If checked you can input the amount to be refunded to the customer. If not selected a full refund will be issued"
                                            persistent-hint
                                        ></v-switch>
                                    </div>

                                    <div class="pa-2" v-if="refund.isPartial">
                                        <ValidationProvider
                                            name="partial amount"
                                            :rules="{
                                                required: refund.isPartial,
                                                max_value:
                                                    item.item.amountTotal -
                                                    amountAlreadyRefunded,
                                                min_value: 1,
                                            }"
                                            v-slot="{ errors }"
                                        >
                                            <v-text-field
                                                outlined
                                                label="Partial refund amount"
                                                name="amount"
                                                type="number"
                                                v-model="refund.amount"
                                                :messages="errors[0]"
                                                hint="Amount to be refunded to the customer"
                                            />
                                        </ValidationProvider>
                                    </div>

                                    <div class="pa-2">
                                        <label>Send email to customer?</label>
                                        <v-switch
                                            v-model="
                                                refund.isSendEmailToCustomer
                                            "
                                            label="Select to send an email to the customer automatically"
                                            hint="If checked an email will be sent to the customer automatically informing of the refund"
                                            persistent-hint
                                        ></v-switch>
                                    </div>
                                </v-form>

                                <v-divider class="my-4"></v-divider>

                                <div class="py-2" v-if="!cannotRefund">
                                    <p>
                                        {{ confirmationMessage }}
                                    </p>
                                </div>

                                <v-sheet
                                    color="grey lighten-2"
                                    class="pa-4 mb-2"
                                    v-if="
                                        existentRefunds &&
                                            existentRefunds.length > 0
                                    "
                                >
                                    <h4 class="mb-2">
                                        There are already processed refunds for
                                        this booking:
                                    </h4>
                                    <v-simple-table :fixed-header="false">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Date
                                                    </th>
                                                    <th class="text-left">
                                                        Amount
                                                    </th>
                                                    <th class="text-left">
                                                        Stripe Id
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="transaction in existentRefunds"
                                                    :key="transaction.lolaId"
                                                >
                                                    <td>
                                                        {{
                                                            transaction.createdAt
                                                                | moment(
                                                                    'timezone',
                                                                    'America/Vancouver',
                                                                    'DD-MMM-YYYY HH:mm z'
                                                                )
                                                        }}
                                                    </td>

                                                    <td>
                                                        {{
                                                            transaction.amount
                                                                | currency
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            transaction.gatewayId
                                                        }}
                                                    </td>
                                                    <td>
                                                        <v-btn
                                                            class="pa-2"
                                                            color="grey lighten-2"
                                                            light
                                                            small
                                                            @click="
                                                                openRefundEmailModal(
                                                                    transaction
                                                                )
                                                            "
                                                        >
                                                            <v-icon left small
                                                                >fa
                                                                fa-paper-plane</v-icon
                                                            >Send Refund Email
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-sheet>

                                <div class="py-2" v-if="message.message">
                                    <v-alert :type="message.type">
                                        {{ message.message }}
                                    </v-alert>
                                </div>

                                <div class="py-2" v-if="cannotRefund">
                                    <v-alert type="info">
                                        This booking has been fully refunded on
                                        Stripe and cancelled on Fareharbor.
                                    </v-alert>
                                </div>

                                <v-card-actions>
                                    <v-btn
                                        color="default"
                                        light
                                        large
                                        @click="cancel()"
                                    >
                                        <v-icon left small
                                            >fa fa-arrow-left</v-icon
                                        >Close
                                    </v-btn>

                                    <v-spacer />
                                    <v-btn
                                        v-if="!cannotRefund"
                                        color="primary white--text"
                                        large
                                        :disabled="observerGroup.invalid"
                                        @click="submit"
                                    >
                                        Submit Refund
                                        <v-icon right small>fa fa-check</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-sheet>
                    </v-container>
                </v-col>
            </v-row>
        </v-card>

        <!-- ### REFUND EMAIL MODAL ### -->
        <v-dialog v-model="refundModal.active" max-width="600px">
            <v-card>
                <v-card-title>
                    Send Refund Email
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        outlined
                        label="Email address"
                        name="email"
                        type="text"
                        v-model="refundModal.email"
                        class="mt-3"
                    />
                    <p>
                        Send email to
                        <strong>{{ refundModal.email }}</strong> about refund of
                        <strong
                            >${{
                                refundModal.transaction
                                    ? refundModal.transaction.amount
                                    : ''
                            }}</strong
                        >?
                    </p>
                </v-card-text>
                <v-card-actions class="px-6 pb-4">
                    <v-btn
                        color="primary"
                        text
                        @click="refundModal.active = false"
                    >
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark @click="sendRefundEmail">
                        Send email
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import { sendAlert, setLoading } from '../../../store/notifications-hub'
import filtersMixin from '@/mixins/filters-mixin.js'
import generate from 'nanoid/generate'

export default {
    name: 'ModalRefund',
    mixins: [filtersMixin],
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data: function() {
        return {
            loading: false,
            dialog: true,
            refund: {
                amount: 0, // default total - see mounted
                amountCents: 0, // default total
                isPartial: false,
                isCancelFareharbor: this.item.item.status !== 'cancelled', // if cancelled it will be false
                isSendEmailToCustomer: false,
            },
            message: {
                type: 'info',
                message: '',
            },
            refundModal: {
                active: false,
                email: this.item.item.fareharborBooking.contact.email,
                transaction: null,
            },
        }
    },
    watch: {
        'refund.amount': function(newValue) {
            this.refund.amountCents = parseFloat(newValue) * 100 // amount to refund on stripe is in cents
        },
    },

    filters: {},
    computed: {
        confirmationMessage() {
            let message = `You are processing a refund of $${this.refund.amount}.`

            if (this.refund.isCancelFareharbor) {
                message = `${message} The booking ${this.item.item.fareharborBooking.pk} will also be cancelled on Fareharbor.`
            } else {
                message = `${message} The booking ${this.item.item.fareharborBooking.pk} will NOT be cancelled on Fareharbor.`
            }

            if (this.refund.isSendEmailToCustomer) {
                message = `${message} An email will be sent to the customer.`
            } else {
                message = `${message} An email will NOT be sent to the customer.`
            }

            return message
        },
        existentRefunds() {
            return this.item.item.transactions.filter(
                tx => tx.transactionType === 'refund'
            )
        },
        amountAlreadyRefunded() {
            return this.existentRefunds.reduce(
                (acc, { amount }) => acc + amount,
                0
            )
        },
        amountAlreadyRefundedCents() {
            return this.existentRefunds.reduce(
                (acc, { amountCents }) => acc + amountCents,
                0
            )
        },
        cannotRefund() {
            return (
                this.amountAlreadyRefunded === this.item.item.amountTotal &&
                this.item.item.status === 'cancelled'
            )
        },
        isCancelled() {
            return this.item.item.status === 'cancelled'
        },
    },

    methods: {
        generateNanoId(prefix) {
            let nano = generate('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 8)
            return `${prefix}${nano}`
        },

        cancel() {
            this.$router.push({ name: 'bookings.list' })
        },

        async submit() {
            setLoading(true)

            const transactionLolaId = this.generateNanoId('TR')
            let stripeRefundId = ''

            this.message.message = ''

            const emailTo = this.item.item.fareharborBooking.contact.email

            // ######### REFUNDS ON STRIPE #########
            try {
                // gets the paymentIntentId of a transaction of type payment for this item
                const paymentIntentId = this.item.item.transactions.find(
                    tx => tx.transactionType === 'payment'
                ).gatewayId

                this.$log.info('Start refund on Stripe')
                const refundParams = {
                    amount: this.refund.amountCents,
                    metadata: {
                        'lola-transaction': transactionLolaId,
                        'lola-order': this.item.order.lolaId,
                        'fh-booking': this.item.item.fareharborBooking.pk,
                        'fh-item': this.item.item.fareharborItem.pk,
                        'fh-availability': this.item.item.fareharborBooking.availability.pk
                    },
                    payment_intent: paymentIntentId,
                    reason: 'requested_by_customer',
                }
                const responseStripe = await this.$tornos.post(
                    `payment/refund`,
                    refundParams
                )

                if (
                    responseStripe.data &&
                    responseStripe.data.status !== 'succeeded'
                ) {
                    sendAlert({
                        event: 'warning',
                        data: responseStripe.data,
                        message:
                            'The refund was not processed by Stripe. Please, try again. If this error continues check your Stripe dashboard.',
                    })
                    setLoading(false)
                    return // exits method
                }

                // used below
                stripeRefundId = responseStripe.data.id
            } catch (e) {
                sendAlert({
                    event: 'warning',
                    data: e,
                    message:
                        'Step: Stripe Refund | There was an error and no refund was issued. Please try again',
                })
                setLoading(false)
                return // exits method
            }

            // ######### CANCELS ON FAREHARBOR #########
            let fareharborCancelledBooking = null
            try {
                if (this.refund.isCancelFareharbor) {
                    this.$log.info('Start cancel on Fareharbor')
                    const fhResponse = await this.$fareharbor.delete(
                        `/companies/${this.division.company.settings.fareharborShortname}/bookings/${this.item.item.fareharborBooking.uuid}`
                    )
                    fareharborCancelledBooking = fhResponse.data.booking // used below to update on Lola db
                } else {
                    this.$log.info('Skip cancel on Fareharbor')
                }
            } catch (e) {
                this.message.type = 'warning'
                this.message.message =
                    'Step: Fareharbor Cancel | There was an error cancelling the booking. A refund HAS BEEN processed however. Please check the booking on your Fareharbor dashboard.'
                // even if there is an error cancelling on FH we do not stop the flow
            }

            // ######### ON LOLAGROUPS #########
            let transaction = {}
            try {
                this.$log.info('Start update on Lolagroups')
                transaction = {
                    userLocation: {},
                    transactionType: 'refund',
                    amountCents: this.refund.amountCents,
                    amount: this.refund.amount,
                    gatewayId: stripeRefundId,
                    lolaId: transactionLolaId,
                    createdAt: new Date(),
                }

                const totalRefundedAmountCents =
                    parseInt(this.item.order.amountRefundedCents) +
                    parseInt(this.refund.amountCents)
                const orderToUpdate = {
                    ...this.item.order,
                    amountRefundedCents: totalRefundedAmountCents,
                    amountRefunded:
                        this.item.order.amountRefunded + this.refund.amount,
                    isRefunded:
                        this.item.order.amountPaidCents ===
                        totalRefundedAmountCents, // if equals true -> yes full order refunded
                    items: this.item.order.items.map(it => {
                        if (it._id === this.item.item._id) {
                            it.transactions.push(transaction) // adds the new transaction to this item in the order

                            // if cancelled on FH we update the object and status of this item/booking on Lola db
                            if (this.refund.isCancelFareharbor) {
                                it.fareharborBooking = fareharborCancelledBooking
                                it.status = fareharborCancelledBooking.status
                            }
                        }

                        return it
                    }),
                }
                await this.$tornos.put(
                    `orders/${this.item.order._id}`,
                    orderToUpdate
                )

                this.message.type = 'info'
                this.message.message = `${this.message.message} | Refund processed successfully - Refund Id on Stripe: ${stripeRefundId}`
            } catch (e) {
                this.message.type = 'warning'
                this.message.message = `${this.message.message} | Step: Lolagroups Save | There was an error saving the data in Lolagroups. Please contact Lolagroups support.`
            }

            // ##### EMAIL - don't await for it
            if (this.refund.isSendEmailToCustomer) {
                this.$tornos.post(
                    `email/orders/${this.item.order._id}/refund`,
                    {
                        item: this.item.item,
                        transaction: transaction,
                        emailTo: emailTo
                    }
                )
            }

            sendAlert({
                event: this.message.type,
                data: null,
                message: this.message.message,
            })

            setLoading(false)
        },

        openRefundEmailModal(transaction) {
            this.refundModal.transaction = transaction // sets the transaction selected (button clicked) in a temp var
            this.refundModal.active = true // opens modal
        },

        clearRefundEmailModal() {
            this.refundModal = {
                active: false,
                email: this.item.item.fareharborBooking.contact.email,
                transaction: null,
            }
        },

        sendRefundEmail() {
            const transaction = this.refundModal.transaction
            if (!transaction) {
                sendAlert({
                    event: 'warning',
                    data: null,
                    message:
                        'There was an error sending the email. Transaction data is null. Please, try again.',
                })
                this.clearRefundEmailModal()
                return
            }

            try {
                this.$tornos.post(
                    `email/orders/${this.item.order._id}/refund`,
                    {
                        item: this.item.item,
                        transaction: transaction,
                        emailTo: this.refundModal.email
                    }
                )
                sendAlert({
                    event: 'info',
                    data: null,
                    message: 'Email sent successfully',
                })
            } catch (e) {
                sendAlert({
                    event: 'warning',
                    data: e,
                    message: 'There was an error sending the email',
                })
            } finally {
                this.clearRefundEmailModal()
            }
        },
    },
    mounted() {
        this.refund.amount =
            this.item.item.amountTotal - this.amountAlreadyRefunded
        this.refund.amountCents =
            this.item.item.amountTotalCents - this.amountAlreadyRefundedCents
    },
}
</script>
