import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import { Quill } from 'vue-quill-editor'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module-withfix'
import ImageUpload from 'quill-plugin-image-upload'
import htmlEditButton from 'quill-html-edit-button'

//https://quilljs.com/playground/#class-vs-inline-style
//https://github.com/quilljs/quill/blob/develop/quill.js#L46
let AlignStyle = Quill.import('attributors/style/align')
let DirectionStyle = Quill.import('attributors/style/direction')
let BackgroundStyle = Quill.import('attributors/style/background')
let ColorStyle = Quill.import('attributors/style/color')
let FontStyle = Quill.import('attributors/style/font')
let SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = [
    '6px', '8px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '42px', '48px', '54px', '60px', '80px', '92px'
];
Quill.register(AlignStyle, true)
Quill.register(DirectionStyle, true)
Quill.register(BackgroundStyle, true)
Quill.register(ColorStyle, true)
Quill.register(FontStyle, true)
Quill.register(SizeStyle, true)
Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/imageUpload', ImageUpload)
Quill.register('modules/htmlEditButton', htmlEditButton)

//import Counter from './quill-counter'
 // Implement and register module
 //Quill.register('modules/counter', Counter)
 //DID Not work the counter, it's implemented on the QuillEditor onChange event

// const editorOptions = {} //options are passed in the Vue component we created just for quill

Vue.use(VueQuillEditor)
