import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index-store'

//to handle nested child routes - https://dev.to/berniwittmann/my-approach-on-vue-router-architecture-and-nested-routes-2kmo
import EmptyRouterView from '../views/EmptyRouterView'

import Home from '../views/Home'
import Login from '../views/Login'
import LoginAmplify from '../views/LoginAmplify'

//DIVISIONS
import ModalChooseDivision from '../views/Divisions/ModalChooseDivision'
//DIVISIONS->GROUPS
import GroupsList from '../views/Divisions/Groups/GroupsList'
import GroupsCreate from '../views/Divisions/Groups/GroupsCreate'
import ModalChooseGroupItems from '../views/Divisions/Groups/ModalChooseGroupItems'
import ModalChooseItemAvailabilities from '../views/Divisions/Groups/ModalChooseItemAvailabilities'
import ModalGroupEditItem from '../views/Divisions/Groups/ModalGroupEditItem'
//DIVISIONS->REPORTS (BOOKINGS, ORDERS, TRANSACTIONS)
import BookingsList from '../views/Divisions/Bookings/BookingsList'
import OrdersList from '../views/Divisions/Bookings/OrdersList'
import TransactionsList from '../views/Divisions/Bookings/TransactionsList'
import ModalRefund from '../views/Divisions/Bookings/ModalRefund.vue'
import ModalWaivers from '../views/Divisions/Bookings/ModalWaivers.vue'

//DIVISIONS->WAIVER TEMPLATES
import WaiverTemplatesList from '../views/Divisions/WaiverTemplates/WaiverTemplatesList'
import WaiverTemplatesCreate from '../views/Divisions/WaiverTemplates/WaiverTemplatesCreate'
//DIVISIONS->WAIVERS
import WaiversList from '../views/Divisions/Waivers/WaiversList'
//DIVISIONS->PARTNERS
import PartnersList from '../views/Divisions/Partners/PartnersList'
import PartnersCreate from '../views/Divisions/Partners/PartnersCreate'
//DIVISIONS->EMPLOYEES
import EmployeesList from '../views/Divisions/Employees/EmployeesList'

//PUBLIC->GROUPLANDINGPAGE
import GroupBookingFlow from '../views/Public/GroupBookingFlow'
import ModalBookAvailability from '../components/GroupBooking/Steps/Step1/ModalBookAvailability'
import OrderConfirmation from '../views/Public/OrderConfirmation'
// import GroupLandingPage from '../views/Public/GroupLandingPage'
// import AvailabilityBookingPage from '../views/Public/AvailabilityBookingPage'
import GroupTermsAndConditions from '../views/Public/GroupTermsAndConditions'

//CUSTOMERS
import CustomerOrders from '../views/Customers/CustomerOrders'
import CustomerBookings from '../views/Customers/CustomerBookings'
import CustomerWaivers from '../views/Customers/CustomerWaivers'

//PARTNER (AGENTS)
import PartnerOrders from '../views/Partners/PartnerOrders'
import PartnerBookings from '../views/Partners/PartnerBookings'
import PartnerWaivers from '../views/Partners/PartnerWaivers'

//WAIVERS
import WaiverSign from '../views/Public/WaiverSign'

//NOT FOUND
import PageNotFound from '../views/PageNotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    //redirect: { name: 'login', params: { userType: store.getters.userType ?  store.getters.userType : 'employees'} },
    name: 'home',
    component: Home
  },
  {
    path: '/about',    
    component: EmptyRouterView,
    children: [
      {
        path: '',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
      }
    ]
  },
  {
    path: '/login-amplify',
    component: EmptyRouterView,   
    children: [
      {
        path: ':userType',
        alias: '/',
        name: 'login.amplify',  
        props: true,      
        component: LoginAmplify
      }
    ]
  },
  {
    path: '/login',
    component: EmptyRouterView,   
    children: [
      {
        path: ':userType',
        alias: '/',
        name: 'login',  
        props: true,      
        component: Login
      }
    ]
  },
  //companies->divisions
  {
    path: '/companies/divisions',
    component: EmptyRouterView, 
    meta: {
      requiresAuth: true,
      authType: 'employees'
    },
    children: [
      {
        path: '',
        name: 'division',
        component: ModalChooseDivision
      },
      {
        path: 'choose',
        name: 'divisions.choose',
        component: ModalChooseDivision
      },
      //divisions->groups
      {
        path: 'groups',        
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'groups.list',            
            component: GroupsList,
            meta: {
              layout: 'DashboardCompany'
            }
          },
          {
            path: 'create',
            name: 'groups.create',            
            component: GroupsCreate,
            props: true,
            meta: {
              layout: 'DashboardCompany'
            }                        
          },
          {
            path: 'items',
            name: 'groups.create.items',
            component: ModalChooseGroupItems,
            props: true,
          },
          {
            path: 'items/edit',
            name: 'groups.create.items.edit',
            component: ModalGroupEditItem,
            props: true,
          },
          {
            path: 'items/availabilities',
            name: 'groups.create.items.availabilities',
            component: ModalChooseItemAvailabilities,
            props: true,
          }          
        ]
      },
      //divisions->bookings
      {
        path: 'bookings',        
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'bookings.list',
            component: BookingsList,
            meta: {
              layout: 'DashboardCompany',
              requiresAuth: true,
              authType: 'employees'
            }
          },
          {
            path: 'refund',
            name: 'modal.refund',
            component: ModalRefund,
            props: true,
            meta: {
              layout: 'DashboardCompany',
              requiresAuth: true,
              authType: 'employees'
            }
          },
          {
            path: 'waivers',
            name: 'modal.waivers',
            component: ModalWaivers,
            props: true,
            meta: {
              layout: 'DashboardCompany',
              requiresAuth: true,
              authType: 'employees'
            }
          }
        ]
      },
      //divisions->orders
      {
        path: 'orders',        
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'orders.list',
            component: OrdersList,
            meta: {
              layout: 'DashboardCompany',
              requiresAuth: true,
              authType: 'employees'
            }
          }
        ]
      },
      //divisions->transactions
      {
        path: 'transactions',        
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'transactions.list',
            component: TransactionsList,
            meta: {
              layout: 'DashboardCompany',
              requiresAuth: true,
              authType: 'employees'
            }
          }
        ]
      },
      //divisions->waivertemplates
      {
        path: 'waivers/templates',        
        component: EmptyRouterView,        
        children: [
          {
            path: '',
            name: 'waiver.templates.list',            
            component: WaiverTemplatesList,
            meta: {
              layout: 'DashboardCompany',
              requiresAuth: true,
              authType: 'employees'
            }
          },
          {
            path: 'create',
            name: 'waiver.templates.create',            
            component: WaiverTemplatesCreate,
            props: true, //params as props
            meta: {
              layout: 'DashboardCompany',
              requiresAuth: true,
              authType: 'employees'
            }
          }
        ]
      },

      //divisions->waivers
      {
        path: 'waivers',        
        component: EmptyRouterView,        
        children: [
          {
            path: '',
            name: 'waivers.list',            
            component: WaiversList,
            meta: {
              layout: 'DashboardCompany',
              requiresAuth: true,
              authType: 'employees'
            }
          }
        ]
      },

       //divisions->partners
       {
        path: 'partners',        
        component: EmptyRouterView,        
        children: [
          {
            path: '',
            name: 'partners.list',            
            component: PartnersList,
            meta: {
              layout: 'DashboardCompany',
              requiresAuth: true,
              authType: 'employees'
            }
          },
          {
            path: 'create',
            name: 'partners.create',            
            component: PartnersCreate,
            props: true, //params as props
            meta: {
              layout: 'DashboardCompany',
              requiresAuth: true,
              authType: 'employees'
            }
          }
        ]
      },

      //divisions->employees
      {
        path: 'employees',        
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'employees.list',
            component: EmployeesList,
            meta: {
              layout: 'DashboardCompany',
              requiresAuth: true,
              authType: 'employees'
            }
          }
        ]
      },

    ]
  },

  //order receipt - public
  {
    path: '/orders',
    component: EmptyRouterView,   
    children: [
      {
        path: ':id',
        name: 'orders',  
        props: true,      
        component: OrderConfirmation ,             
      }    
    ]
  },


  //########## CUSTOMER (PORTAL) ###########
  {
    path: '/customers',
    component: EmptyRouterView,   
    children: [
      {
        path: 'orders',
        name: 'customer.orders',  
        props: true,      
        component: CustomerOrders,
        meta: {
          layout: 'DashboardCustomer',
          requiresAuth: true,
          authType: 'customers'
        }
      } ,
      {
        path: 'bookings',
        name: 'customer.bookings',  
        props: true,      
        component: CustomerBookings,
        meta: {
          layout: 'DashboardCustomer',
          requiresAuth: true,
          authType: 'customers'
        }
      },

      {
        path: 'waivers',
        name: 'customer.waivers',  
        props: true,      
        component: CustomerWaivers,
        meta: {
          layout: 'DashboardCustomer',
          requiresAuth: true,
          authType: 'customers'
        }
      }
      
    ]
  },


  //########## PARTNER (PORTAL) ###########
  {
    path: '/partners',
    component: EmptyRouterView,   
    children: [
      {
        path: 'orders',
        name: 'partner.orders',  
        props: true,      
        component: PartnerOrders,
        meta: {
          layout: 'DashboardPartner',
          requiresAuth: true,
          authType: 'agents'
        }
      } ,
      {
        path: 'bookings',
        name: 'partner.bookings',  
        props: true,      
        component: PartnerBookings,
        meta: {
          layout: 'DashboardPartner',
          requiresAuth: true,
          authType: 'agents'
        }
      },

      {
        path: 'waivers',
        name: 'partner.waivers',  
        props: true,      
        component: PartnerWaivers,
        meta: {
          layout: 'DashboardPartner',
          requiresAuth: true,
          authType: 'agents'
        }
      }
      
    ]
  },

  ///########## WAIVERS #######
  {
    path: '/waivers',
    component: EmptyRouterView,   
    children: [
      {
        path: ':id',
        name: 'waivers.sign',  
        props: true,      
        component: WaiverSign ,             
      }    
    ]
  },


  //########### PUBLIC #######
  //leave it at the end because it loads by order (this one has no root path thats why)
  {
    path: '',
    component: EmptyRouterView,   
    children: [
      {
        path: ':partnerSlug/:slug',
        name: 'group.booking.flow',  
        props: true,      
        component: GroupBookingFlow        
      },
      {
        path: ':partnerSlug/:slug/availability',
        name: 'availability.book',  
        //props: true,      
        components:{
          default: GroupBookingFlow,
          dialog: ModalBookAvailability
        }             
      },
      {
        path: ':partnerSlug/:slug/terms',
        name: 'group.terms',  
        props: true,      
        component: GroupTermsAndConditions        
      }
    ]
  },


  //####### PAGE NOT FOUND ####
  {
    path: '*',    
    component: EmptyRouterView,
    children: [
      {
        path: '',
        name: 'not.found',
        component: PageNotFound
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

import {sendAlert} from '../store/notifications-hub'
router.beforeResolve((to, from, next) => {

  //protect routes
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if(to.matched.some(record => record.meta.authType === store.getters.userType)){
      next()
    }else{
      next({
        name: 'login'
      })
      sendAlert({event: 'warning', data: null, message: 'Permissions denied. Please login first.'})   
    }
  }else{
    next()
  }

})

export default router
